import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Card,
  SectionTitle,
  Row,
  Col,
  AnglePlanBadge,
  Button
} from 'react-angle-dashboard-components'
import { Paths } from '../../routes/paths'
import { getRelevantDedFields, getRelevantOopFields } from '../MyPlan/PlanContent/PlanGlanceCard/PlanGlanceCard'
import { getMostRecentPlanAndAccumFromPlans, mapDTOtoPlans } from '../../services/api/coverage/mappers'
import AccumDetails from '../MyPlan/PlanContent/PlanGlanceCard/AccumDetails'

import './PlanWidget.css'


export interface IPlanWidgetProps {
  hidePlanInfo: boolean
  isMobile?: boolean
  metalTier?: string
  planName?: string
  memberSinceDate?: string
  memberId: string
  planData: ReturnType<typeof mapDTOtoPlans>
}

const PlanWidget = ({ hidePlanInfo, isMobile, metalTier, planName, memberSinceDate, memberId, planData }: IPlanWidgetProps) => {

  const [isIndSelected, setIsIndSelected] = useState(true)

  // currently only supports in network
  const getAccumContent = () => {
    const { accum, planDetails } = getMostRecentPlanAndAccumFromPlans(planData)

    const step = accum?.innCurrentStep
    
    if (step === 1) {
      const { showIndFlag, showFamFlag, indNum, indDen, indMed, indRx, famNum, famDen, famMed, famRx } = getRelevantDedFields(accum, planDetails, 'inn')

      if (!showIndFlag && showFamFlag && isIndSelected) {
        setIsIndSelected(false)
      }

      return (
        <>
          <Row className="m-0" style={{ justifyContent: 'space-between', paddingBottom: '15px' }}>
            { showIndFlag && showFamFlag &&
            (<Button
              className={isIndSelected ? "accumButtonActive planWidgetBtns" : ""}
              onClick={() => setIsIndSelected(!isIndSelected)}
              variant={isIndSelected ? "outline-primary" : "outline-secondary"}
              size="md"
              style={{
                border: 'none'
                }}>
              Individual Deductible
            </Button>)}

            { showIndFlag && showFamFlag &&
            (<Button
              className={!isIndSelected ? "accumButtonActive planWidgetBtns" : ""}
              onClick={() => setIsIndSelected(!isIndSelected)}
              variant={isIndSelected ? "outline-secondary" : "outline-primary"}
              size="md"
              style={{ border: 'none' }}>
              Family Deductible
            </Button>)}
          </Row>
          <AccumDetails
            numerator={isIndSelected ? indNum: famNum}
            denominator={isIndSelected ? indDen: famDen}
            medClaimsCost={isIndSelected ? indMed: famMed}
            rxClaimsCost={isIndSelected ? indRx: famRx}
            widgetMode={true}
            isMobile={isMobile}
          />
          <p style={{ color: '#6C757D' }}>
            <span style={{ color: '#212529' }}>Phase 1:</span> You pay for costs until the deductible is met
          </p>
        </>
      )
    }

    if ( step === 2) {
      return (
        <div style={{ maxWidth: '413px' }}>
          <h6 className="fs-18">{'Member pays for a percentage of costs'}</h6>
          <span className="text-secondary fs-12">
            {'After your deductible is met, both you and your plan pay a predetermined percentage of health care costs for covered services'}
          </span>
        </div>)
    }

    const { showIndFlag, showFamFlag, indNum, indDen, indMed, indRx, famNum, famDen, famMed, famRx, isUnlimited } = getRelevantOopFields(accum, planDetails, 'inn')

    if (!showIndFlag && showFamFlag && isIndSelected) {
      setIsIndSelected(false)
    }
    
    return (
      <>
        <Row className="m-0" style={{ justifyContent: 'space-between'}}>
          { showIndFlag && showFamFlag &&
          (<Button
            className={isIndSelected ? "accumButtonActive planWidgetBtns" : ""}
            onClick={() => setIsIndSelected(!isIndSelected)}
            variant={isIndSelected ? "outline-primary" : "outline-secondary"}
            size="md"
            style={{
              border: 'none'
              }}>
            Individual Deductible
          </Button>)}

          { showIndFlag && showFamFlag &&
          (<Button
            className={!isIndSelected ? "accumButtonActive planWidgetBtns" : ""}
            onClick={() => setIsIndSelected(!isIndSelected)}
            variant={isIndSelected ? "outline-secondary" : "outline-primary"}
            size="md"
            style={{ border: 'none' }}>
            Family Deductible
          </Button>)}
        </Row>
        <AccumDetails
          isUnlimited={isUnlimited}
          numerator={isIndSelected ? indNum: famNum}
          denominator={isIndSelected ? indDen: famDen}
          medClaimsCost={isIndSelected ? indMed: famMed}
          rxClaimsCost={isIndSelected ? indRx: famRx}
          widgetMode={true}
        />
        <p style={{ color: '#6C757D' }}>
          <span style={{ color: '#212529' }}>Phase 3:</span> Once out-of-pocket max is met, the plan pays 100%
        </p>
      </>
    )
  }
  
  return (
    <>
      {!hidePlanInfo && (
      <section style={{ marginBottom: '1.25rem' }}>
        <Card>
          <Card.Body>
            <Row className="m-0">
              <SectionTitle>Plan Details</SectionTitle>
              <Col className="text-right">
                <Link to={Paths.myPlan}>
                  <Button className="p-0" variant="link" size="md">
                    View Plan Details
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row className="m-0" style={{ gap: '15px' }}>
              <Card style={{ flexGrow: 1 }}>
                <Card.Body>
                  {metalTier && planName && (
                    <>
                      <Row className="pt-2">
                        <Col className="pb-4">
                          <AnglePlanBadge
                            metalTier={metalTier}
                            planName={planName}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {memberSinceDate && (
                    <>
                      <Row>
                        <Col>
                          <p>Member Since</p>
                        </Col>
                        <Col className="text-right">
                          <span className="text-muted">
                            {memberSinceDate}
                          </span>
                        </Col>
                      </Row>
                      <hr className="mt-0" />
                    </>
                  )}
                  <Row>
                    <Col>
                      <p>Member ID</p>
                    </Col>
                    <Col className="text-right">
                      <span className="text-muted">
                        {memberId.substring(0, 10)}
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card style={{ flexGrow: 1 }}>
                <Card.Body>
                    {getAccumContent()}
                </Card.Body>
              </Card>
            </Row>
          </Card.Body>
        </Card>
      </section>)}
    </>
  )
}

export default PlanWidget
