import React from 'react'
import { HomeLayout } from '../layouts/HomeLayout'
import { Container } from 'react-angle-dashboard-components'

import AccessPlusInfoCard from '../../components/AccessPlusInfoCard/AccessPlusInfoCard'

const AccessPlusPlanDetails = () => {
  return (
    <HomeLayout noBreadcrumbs>
      <div
        style={{
          backgroundColor: '#F9F9FC'
        }}
        className="py-8"
      >
        <Container>
          <AccessPlusInfoCard />
        </Container>
      </div>
    </HomeLayout>
  )
}

export default AccessPlusPlanDetails
