import React, { useContext, useState } from 'react'
import {
  CenteredSpinner,
  PageContainer,
  Tabs,
  Tab
} from 'react-angle-dashboard-components'
import Cookies from 'js-cookie'
import { useQuery } from 'react-query'
import { coverageService, memberService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'
import { PlanContent } from './PlanContent/PlanContent'
import { useGetVoluntaryCoverage } from './hooks/useGetVoluntaryCoverage'
import { mapMemberDataToVoluntaryOnboarding } from './mapper'
import { ErrorMessage } from '../../components/ErrorMessage'
import { AuthContext } from '../../context/Auth/Auth'
import { useQueryParams } from '@angle/hooks'

export const MyPlan = () => {
  const [tabKey, setTabKey] = useState('medical')

  const { loginWithPassedCookie } = useContext(AuthContext)

  const params = useQueryParams()
  const token = params.get('token') || ''

  const { isLoading: isTempAuthLoading, isError } = useQuery(
    ['loginWithToken', token],
    () =>  {
      Cookies.set("passed_access_token", token)
      loginWithPassedCookie()
    },
    {
      enabled: !!token,
    }
  )

  const {
    data,
    isLoading: isLoadingMedical,
    error
  } = useQuery('getMedicalPlanV2', () =>
    coverageService.getMedicalPlanV2(),
    { enabled: !isError && ((!!token && !isTempAuthLoading) || !token ) }
  )

  const { voluntaryBenefits, isLoading: isLoadingVoluntary } =
    useGetVoluntaryCoverage({ disabled: isError || isTempAuthLoading })

  const {
    data: member,
    isLoading: isLoadingMember,
    error: memberError
  } = useQuery('getMemberForPlanInfo', () =>
    memberService.getMemberInfo(mapMemberDataToVoluntaryOnboarding),
    { enabled: !isError && ((!!token && !isTempAuthLoading) || !token ) }
  )

  const isLoading = isTempAuthLoading || isLoadingMedical || isLoadingVoluntary || isLoadingMember

  if (isTempAuthLoading) {
    return (
      <div style={{ marginTop: 100 }}>
        <CenteredSpinner />
      </div>
    )
  }
  
  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'My Plan', link: '/my-plan' }
      ]}
    >
      <div className="bg-light pt-5">
        <PageContainer size="xl">
          <h1 className="ml-2 mb-5">My Plan</h1>
          <Tabs
            activeKey={tabKey}
            defaultActiveKey="medical"
            onSelect={(key) => setTabKey(key || 'medical')}
          >
            <Tab eventKey="medical" title="Medical"></Tab>
            {voluntaryBenefits?.length ? (
              <Tab eventKey="voluntary" title="Voluntary"></Tab>
            ) : null}
          </Tabs>
        </PageContainer>
      </div>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          {error || memberError ? (
            <PageContainer size="md" className="py-5">
              <ErrorMessage />
            </PageContainer>
          ) : (
            <PlanContent
              data={data}
              member={member}
              tabKey={tabKey}
              voluntaryBenefits={voluntaryBenefits}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </HomeLayout>
  )
}

export default MyPlan
