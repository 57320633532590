import { SetStateAction, useContext, useState, Dispatch } from 'react'
import { createContext } from 'react'

export interface CreateAccountContextMemberData {
  ssn?: string
  date_of_birth?: string
  first_name?: string
  last_name?: string
  member_id?: string
}

export interface CreateAccountContextValues {
  memberData: CreateAccountContextMemberData
  setMemberData: Dispatch<SetStateAction<CreateAccountContextMemberData>>
  members: any[]
  setMembers: Dispatch<SetStateAction<any[]>>
}
export const CreateAccountContext = createContext<CreateAccountContextValues>({
  memberData: {},
  setMemberData: () => {},
  members: [],
  setMembers: () => {}
})

export const CreateAccountContextProvider: React.FC = ({ children }) => {
  const [memberData, setMemberData] = useState<CreateAccountContextMemberData>({
    ssn: '',
    date_of_birth: '',
    first_name: '',
    last_name: '',
    member_id: ''
  })

  const [members, setMembers] = useState<any[]>([])

  return (
    <CreateAccountContext.Provider
      value={{
        memberData,
        setMemberData,
        members,
        setMembers
      }}
    >
      {children}
    </CreateAccountContext.Provider>
  )
}

export const useMemberData = () => useContext(CreateAccountContext)
