export const identifyIterate = (data: any) => {
  const Iterate = (window as any).Iterate

  const iterateData = {
    email: data?.contact_info?.home_email || data?.contact_info?.work_email,
    external_id: data?.member_id,
    name: data?.name?.first_name + ' ' + data?.name?.last_name,
    groupName: data?.group_name,
    daysSinceJoined: data?.days_since_joined
  }

  Iterate('identify', iterateData)
}
