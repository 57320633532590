import { useQueryParams } from '@angle/hooks'
import React, { useContext } from 'react'
import {
  CenteredSpinner,
  ErrorPageLayout
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { AuthContext } from '../../context/Auth/Auth'
import { tempAuthService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'

// Move to a resusable component if we need to use temp auth w/o pw elsewhere
export const TempAuthRedirect = () => {
  const token = useQueryParams().get('code') || ''
  const { loginWithToken } = useContext(AuthContext)

  const { isLoading, isError } = useQuery(
    ['tempAuthNoPw', token],
    () => tempAuthService.tempAuthNoPW(token),
    {
      enabled: !!token,
      onSuccess: (data) => {
        loginWithToken(data)
      }
    }
  )

  if (isLoading) {
    return <CenteredSpinner />
  }
  if (!token || isError) {
    return (
      <HomeLayout noMenu noBreadcrumbs>
        {' '}
        <ErrorPageLayout
          errorTitle="Invalid Link"
          errorSubtitle={
            'This link is invalid. Please try again or contact member services to obtain a new link.'
          }
          button={<a href="tel://+18559371855">Contact</a>}
        />
      </HomeLayout>
    )
  }

  return <div />
}
