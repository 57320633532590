import React, { useEffect } from 'react'
import {
  CenteredSpinner,
  DrugProfileProvider,
  IDrugProfile,
  Row
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useParams, useHistory } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { drugService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'
import { AboutSection } from './AboutSection'
import BackButton from './BackButton'
import { HeaderSection } from './HeaderSection'
import { Header, LayoutContainer } from './style'

export const DrugProfile = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { data, isLoading, isError } = useQuery<IDrugProfile>(
    ['drugProfile', id],
    () => drugService.getById(id)
  )

  if (isError) {
    history.replace(Paths.notFound)
  }

  useEffect(() => {
    // Keeps this page from switching earlier than expected
    window.history.pushState(null, document.title, null)

    const myHandler = () => {
      history.push('/drugs', history.location.state)
    }

    window.addEventListener('popstate', myHandler)

    return () => {
      window.removeEventListener('popstate', myHandler)
    }
  }, [history])

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Pharmacy', link: '/drugs' },
        { title: data?.name || '...', link: `/drugs/${id}` }
      ]}
    >
      {isLoading || !data ? (
        <CenteredSpinner></CenteredSpinner>
      ) : (
        <DrugProfileProvider drugProfile={{ ...data }}>
          <Header className="bg-light pl-3">
            <LayoutContainer>
              <Row
                className="d-flex align-items-center"
                onClick={() => history.push('/drugs', history.location.state)}
              >
                <BackButton />
                <label
                  className="text-primary ml-2"
                  style={{ cursor: 'pointer', marginTop: '10px' }}
                >
                  Back to Search
                </label>
              </Row>
              <HeaderSection />
            </LayoutContainer>
          </Header>
          <AboutSection />
        </DrugProfileProvider>
      )}
    </HomeLayout>
  )
}

export default DrugProfile
