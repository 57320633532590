import { ApiService } from 'react-angle-dashboard-components'

export interface MemberNotFoundTicketDTO {
  contact: {
    email: string
    phone?: string
  }
  member: {
    angle_id?: string
    date_of_birth?: string
    first_name?: string
    last_name?: string
    ssn?: string
  }
}

export class TicketService extends ApiService {
  async sendMemberNotFoundTicket(data: MemberNotFoundTicketDTO) {
    return this.request({
      method: 'post',
      url: '/claim_search/v0/create_ticket/access',
      data
    })
  }
}
