import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { ReactComponent as AmeritasLogo } from '../../../../../../../assets/ameritas.svg'
import { BenefitData, BenefitLabel } from '../../style'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const VisionCard: React.FC<{
  plan: any
  onClickSelectPlan: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  return (
    <Card style={{ width: 400 }} className="mr-4 mb-3">
      <Card.Header className="py-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2">
              <AmeritasLogo />
            </div>
          </div>
          <PlanCardPremium premium={premium} />
          <SelectPlanButton
            isSelected={isSelected}
            onClick={() => onClickSelectPlan(plan.plan_id)}
          />
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100 ">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="px-3 py-2 font-weight-bold">Plan Coverage</th>
              <th className="px-3 py-2 font-weight-bold">In-Network</th>
              <th className="px-3 py-2 font-weight-bold">Out-of-Network</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-3">Annual Exam</BenefitLabel>
              <BenefitData className="fs-18 pt-3">
                {plan.inn_coverage_annual_exam_message}
              </BenefitData>
              <BenefitData className="fs-18 pt-3">
                {plan.oon_coverage_annual_exam_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>
                Lenses*<span className="d-block">Single Lens</span>
              </BenefitLabel>
              <BenefitData className="fs-18">
                {plan.inn_coverage_lens_single_vision_message}
              </BenefitData>
              <BenefitData className="fs-18">
                {plan.oon_coverage_lens_single_vision_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Frame Allowance</BenefitLabel>
              {/* Hard coded right now because it is the same for both plans */}
              <BenefitData className="fs-18">$180</BenefitData>
              <BenefitData className="fs-18">
                {plan.oon_coverage_frame_allowance_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Frequency</BenefitLabel>
              {/* Hard coded right now because it is the same for both plans */}
              <BenefitData className="fs-18">12/12/12</BenefitData>
              <BenefitData className="fs-18">12/12/12</BenefitData>
            </tr>
            <tr>
              {/* Hard coded right now because it is the same for both plans */}
              <BenefitLabel>Contact Lense Exam</BenefitLabel>
              <BenefitData className="fs-18">Up to $60</BenefitData>
              <BenefitData className="fs-18">No benefit</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="fs-12 text-center border-bottom py-1">
        *View More Details Below for Other Lens Options
      </div>
      <Card.Body className="border-bottom">
        <span style={{ fontWeight: 600 }} className="text-secondary fs-14">
          Plan Deductibles
        </span>

        <div className="d-flex pt-3 pb-3">
          <div className="w-50">
            <span className="fs-20 font-weight-bold">
              {plan.inn_exam_deductible_per_service_message}
            </span>
            <span className="fs-20 text-secondary">/exam</span>
          </div>
          <div className="w-50">
            <span className="fs-20 font-weight-bold">
              {plan.oon_exam_deductible_per_service_message}
            </span>
            <span className="fs-20 text-secondary">/exam</span>
          </div>
        </div>
      </Card.Body>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
