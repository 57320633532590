import * as React from 'react'
import { SVGProps } from 'react'

const MinusSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 3"
    fill="currentColor"
    {...props}
  >
    <g id="Minus">
      <path
        id="Minus_2"
        d="M1.2648 0.000124713C1.09955 -0.0022123 0.935489 0.0283169 0.782143 0.0899378C0.628797 0.151559 0.489226 0.243042 0.371544 0.359073C0.253861 0.475103 0.160413 0.613365 0.0966294 0.765825C0.0328461 0.918285 0 1.0819 0 1.24716C0 1.41243 0.0328461 1.57605 0.0966294 1.7285C0.160413 1.88096 0.253861 2.01923 0.371544 2.13526C0.489226 2.25129 0.628797 2.34277 0.782143 2.40439C0.935489 2.46601 1.09955 2.49654 1.2648 2.4942H13.7352C13.9004 2.49654 14.0645 2.46601 14.2179 2.40439C14.3712 2.34277 14.5108 2.25129 14.6285 2.13526C14.7461 2.01923 14.8396 1.88096 14.9034 1.7285C14.9672 1.57605 15 1.41243 15 1.24716C15 1.0819 14.9672 0.918285 14.9034 0.765825C14.8396 0.613365 14.7461 0.475103 14.6285 0.359073C14.5108 0.243042 14.3712 0.151559 14.2179 0.0899378C14.0645 0.0283169 13.9004 -0.0022123 13.7352 0.000124713H1.2648Z"
      />
    </g>
  </svg>
)

export default MinusSolid
