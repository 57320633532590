import React from 'react'
import { Dropdown, useToaster } from 'react-angle-dashboard-components'
import { useMutation } from 'react-query'
import { memberService } from '../../../../../services'
import { DependentWrapper, DependentInfo } from '../style'
import { CustomMenu } from './CustomMenu'
import { MenuToggle } from './MenuToggle'

interface DependentCardProps {
  name: string
  isSpouse: string
  memberId: string
  refetch: any
  setEditingDependent: any
  setShowFormModal: any
  dependent: any
}

export const DependentCard: React.FC<DependentCardProps> = ({
  name,
  isSpouse,
  memberId,
  refetch,
  setEditingDependent,
  setShowFormModal,
  dependent
}) => {
  const { showError, showSuccess } = useToaster()

  const deleteDependent = useMutation(
    () => memberService.deleteDependent(memberId),
    {
      onSuccess: () => {
        showSuccess(`${name} deleted successfully`)
        refetch()
      },
      onError: () => {
        showError(
          `An error occurred deleting ${name}. Please contact us if the issue continues`
        )
        refetch()
      }
    }
  )

  const handleDelete = async () => {
    await deleteDependent.mutateAsync()
  }
  const openEditForm = () => {
    setEditingDependent(dependent)
    setShowFormModal(true)
  }

  return (
    <DependentWrapper>
      <DependentInfo>
        <h6>{name}</h6>
        <span className="fs-14 text-secondary">
          {isSpouse === 'no' ? 'Child' : 'Spouse'}
        </span>
      </DependentInfo>
      <Dropdown>
        <Dropdown.Toggle as={MenuToggle} id="dropdown-custom-components" />
        <Dropdown.Menu as={CustomMenu} className="border py-0">
          <Dropdown.Item className="py-2" onClick={openEditForm}>
            <i
              style={{ fontSize: '11px' }}
              className="d-inline icon icon-pencil text-black mx-2"
            ></i>
            Edit
          </Dropdown.Item>
          <Dropdown.Item onClick={handleDelete} className="text-danger py-2">
            <i
              style={{ fontSize: '11px' }}
              className="d-inline icon icon-cancel text-black mx-2"
            ></i>
            Remove
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </DependentWrapper>
  )
}
