import React from 'react'
import { Button, Modal } from 'react-angle-dashboard-components'
import AppStore from '../../assets/app-store.svg'
import GooglePlay from '../../assets/google-play-badge.svg'
import { DownloadContent, DownloadHeader } from './style'

const DownloadAppModal = ({
  show,
  requestClose
}: {
  show: boolean
  requestClose: () => void
}) => {
  return (
    <Modal size="lg" show={show} onHide={requestClose}>
      <Modal.Header closeButton className="border pb-4 pt-4">
        <Modal.Title>Find a Pharmacy Near You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DownloadHeader>
          To find a pharmacy near you by downloading the Capital Rx app
        </DownloadHeader>
        <DownloadContent>
          Lookup nearby pharmacies and find the best price for your
          prescriptions by downloading the Capital Rx mobile app or visiting the
          Capital Rx web app and setting up an account.
        </DownloadContent>
        <div className="d-flex mb-3">
          <a
            href="https://apps.apple.com/us/app/capital-rx-pharmacy-benefits/id1508011365"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="mr-4"
              src={AppStore}
              alt="Download capRx app on the app store"
              height="52"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.capitalrx&hl=en_US&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={GooglePlay}
              alt="Download capRx app on google play"
              height="52"
            />
          </a>
        </div>
        <DownloadContent>
          To register for the app:
          <br />
          <br />
          <ol className="pl-3">
            <li>
              Download it or visit{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.cap-rx.com/register"
              >
                app.cap-rx.com/register.
              </a>
            </li>
            <li>
              Fill in your personal information, click “validate,” then complete
              the credentials form and click “create an account.”
            </li>
            <li>
              Check your email for the verification code and enter it to
              validate your account.
            </li>
            <li>
              Registration should be complete. Login and use their pharmacy
              search to find one near you!
            </li>
          </ol>
        </DownloadContent>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="outline-primary" onClick={requestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DownloadAppModal
