import React from 'react'
import { Alert, Button, Form } from 'react-angle-dashboard-components'
import { ILoginFormData } from 'react-angle-dashboard-components/src/hooks/use-login-form-handler'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useLang } from '../../../hooks/use-language'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../routes/paths'

interface LoginFormProps {
  control: Control<ILoginFormData>
  onSubmit: () => void
  onPasswordForgetHandler: () => void
  register: UseFormRegister<ILoginFormData>
  isSubmitting: boolean
  isValid: boolean
  errors: FieldErrors
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  register,
  errors,
  isSubmitting,
  onPasswordForgetHandler
}) => {
  const history = useHistory()
  const {
    messages: { login: messages }
  } = useLang()
  return (
    <Form onSubmit={onSubmit}>
      {errors.email?.message && (
        <Alert variant="danger">{errors.email?.message}</Alert>
      )}
      <Form.Group>
        <Form.Label className="fs-16">Email or Member ID</Form.Label>
        <Form.Control
          className="bg-white"
          {...register('email', {
            required: true
          })}
          placeholder="Email or Member ID"
        />
      </Form.Group>
      <Form.Group>
        <Form.Label className="fs-16">{messages.password}</Form.Label>
        <Form.Control
          {...register('password', { required: true })}
          type="password"
          placeholder={messages.password}
          className="bg-white"
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-between flex-wrap">
        <Form.Check
          {...register('remember')}
          type="checkbox"
          className="pr-2 fs-14"
          label={messages.remberMe}
        />
        <Button
          className="d-inline-block p-0 fs-14"
          variant="link"
          onClick={onPasswordForgetHandler}
        >
          Forgot Password?
        </Button>
      </Form.Group>
      <Button type="submit" block disabled={isSubmitting}>
        {!isSubmitting ? messages.buttons.signIn : messages.buttons.signingIn}
      </Button>
      <Button
        className="font-weight-normal"
        variant="link"
        block
        onClick={() => history.push(Paths.createAccount)}
      >
        Create an account
      </Button>
    </Form>
  )
}
