export const dependentPaths = {
  benefits: '/dependents/:id/benefits',
  idCard: '/dependents/:id/id-card',
  healthProfile: '/dependents/:id/health-profile',
  claims: '/dependents/:id/claims'
}

export const healthProfilePaths = {
  overview: '/dependents/:id/health-profile/overview',
  medications: '/dependents/:id/health-profile/medications',
  authorizations: '/dependents/:id/health-profile/authorizations',
  labs: '/dependents/:id/health-profile/labs',
  vaccines: '/dependents/:id/health-profile/vaccines',
  allergies: '/dependents/:id/health-profile/allergies',
  vitals: '/dependents/:id/health-profile/vitals'
}

export const getInterpolatedHealthProfilePath = (
  id: string,
  key: keyof typeof healthProfilePaths
) => {
  return healthProfilePaths[key].replace(':id', id)
}
