import React from 'react'
import { Paths } from '../../../../../../routes/paths'
import { Link } from 'react-router-dom'
import { onboardingPaths } from '../../../../Router'

export const GoBackLink = () => {
  return (
    <Link
      to={Paths.onboarding + onboardingPaths.voluntary}
      className="d-flex align-items-center"
    >
      <i
        className="icon icon-left-arrow"
        style={{ marginLeft: -15, marginRight: -5 }}
      />
      Go Back to Voluntary Benefits
    </Link>
  )
}
