import React, { useState } from 'react'
import {
  IDependentForm,
  PageHeaderLight,
  useToaster
} from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { Cont, FullScreenModal } from './style'
import { DependentRegistrationForm } from './DependentRegistrationForm/DependentRegistrationForm'
import { useMutation } from 'react-query'
import { memberService } from '../../../../services'
import { mapDependentToServer } from './mapper'
import moment from 'moment'
import { DisabledDependentModal } from './DisabledDependentModal'

// can't get error toasters to show up on this full screen modal even when messing with z-indexes. just using regular text error messages for now

interface AddDependentProps {
  show: boolean
  setShowFormModal: any
  refetch: any
  defaultValue?: any
  editingDependent: any
  setEditingDependent: any
  member: any
}

export const AddDependent = ({
  show,
  refetch,
  setEditingDependent,
  editingDependent,
  setShowFormModal,
  member
}: AddDependentProps) => {
  const { showSuccess } = useToaster()
  const [showAgeModal, setShowAgeModal] = useState(false)
  const [error, setError] = useState('')

  const {
    control,
    handleSubmit,
    formState,
    watch,
    getValues,
    trigger,
    setValue,
    unregister
  } = useForm<IDependentForm>({
    mode: 'onChange',
    defaultValues: editingDependent || {}
  })

  const addDependent = useMutation((data: any) =>
    memberService.addDependent(data)
  )

  const editDependent = useMutation((data: any) =>
    memberService.updateDependent(editingDependent?.memberId, data)
  )

  const onCancel = () => {
    setShowAgeModal(false)
    setShowFormModal(false)
    setEditingDependent(null)
  }

  const isSpouse = watch('personalInfo.isSpouse')

  const onSubmit = handleSubmit(async (formData: any) => {
    try {
      const age = moment().diff(
        formData?.personalInfo?.dateOfBirth,
        'years',
        true
      )
      if (age >= 26 && isSpouse !== 'yes') {
        setShowAgeModal(true)
      } else {
        addOrUpdateDependent(formData)
      }
    } catch (error: any) {
      setError(
        error?.message || 'Unknown error. Please contact customer support.'
      )
    }
  })

  const addOrUpdateDependent = async (formData: any) => {
    try {
      const mappedData = mapDependentToServer(formData, member)
      if (editingDependent) {
        await editDependent.mutateAsync(mappedData)
        showSuccess('Successfully updated dependent information')
      } else {
        await addDependent.mutateAsync(mappedData)
        showSuccess('Successfully added dependent')
      }

      refetch()
      setShowFormModal(false)
      setEditingDependent(null)
      setError('')
    } catch (error: any) {
      setError(
        error?.message || 'Unknown error. Please contact customer support.'
      )
    }
  }

  const onContinueDisabledDependent = async () => {
    try {
      const values = getValues()
      await addOrUpdateDependent({ ...values, isDisabled: true })
    } catch (error: any) {
      setShowAgeModal(false)
      setError(
        error?.message || 'Unknown error. Please contact customer support.'
      )
    }
  }

  const getErrors = (stage: keyof IDependentForm) => {
    const hasError = Object.keys(formState.errors[stage] || {}).length

    const message = (
      <span className="text-danger">Please fill the required fields</span>
    )

    return {
      message: hasError ? message : null,
      errors: formState.errors[stage]
    }
  }

  const healthInsurance = watch('healthInsurance')

  return (
    <>
      {showAgeModal && (
        <DisabledDependentModal
          show={showAgeModal}
          onCancel={onCancel}
          onContinue={onContinueDisabledDependent}
        />
      )}
      <FullScreenModal show={show}>
        <PageHeaderLight className="align-items-start">
          <Cont>
            <h1>{editingDependent ? 'Edit' : 'Add'} your dependent</h1>
          </Cont>
        </PageHeaderLight>

        <Cont className="pb-5">
          <DependentRegistrationForm
            onContinue={onSubmit}
            onBack={onCancel}
            control={control}
            isSpouse={isSpouse as string}
            healthInsurance={healthInsurance as boolean}
            getErrors={getErrors}
            formState={formState}
            getValues={getValues}
            trigger={trigger}
            unregister={unregister}
            setValue={setValue}
            error={error}
          />
        </Cont>
      </FullScreenModal>
    </>
  )
}

export default AddDependent
