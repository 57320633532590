import { useCallback, useEffect, useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { SessionExpired } from 'react-angle-dashboard-components'
import { useHistory, useLocation } from 'react-router-dom'
import { ApiService, authService, memberService } from '../../services'
import Cookies from 'js-cookie'

export enum AppStatus {
  temporary = 'temporary',
  onboarding = 'onboarding',
  full = 'full',
  public = 'public',
  loading = 'loading'
}

interface IUserData {
  authuser_id: number
  first_name: string
  last_name: string
  username: string
  temporary: boolean
  onboarding_status: string
  in_progress_state: number
}

export interface IAuthContext {
  login: Function
  logout: Function
  loginWithToken: Function
  loginWithPassedCookie: Function
  refreshUserData: Function
  userData: IUserData | null
  appStatus: AppStatus
  authLoading: boolean
  isRenewal: boolean
}
export const AuthContext = createContext<IAuthContext>({
  login: (username: string, password: string) => Promise.reject(),
  logout: () => {},
  loginWithToken: () => {},
  loginWithPassedCookie: () => {},
  userData: null,
  appStatus: AppStatus.public,
  refreshUserData: () => {},
  authLoading: false,
  isRenewal: false
})

const FS = (window as any).FS
export const AuthContextProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<IUserData | null>(null)
  const [appStatus, setAppStatus] = useState(AppStatus.loading)
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)
  const [isRenewal, setIsRenewal] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const refreshUserData = useCallback(async () => {
    setAuthLoading(true)
    return authService
      .getUserInfo()
      .then((response) => {
        FS.identify(response.authuser_id, {
          displayName: response.first_name + ' ' + response.last_name,
          username: response.username
        })

        setUserData(response)
      })
      .then(() => memberService.getMemberInfo())
      .then((data) => {
        setIsRenewal(data.in_renewal)
      })
      .catch((err) => {
        setUserData(null)
      })
      .finally(() => {
        setAuthLoading(false)
      })
  }, [])

  const onSessionExpiredButtonClicHandler = useCallback(() => {
    setShowSessionExpiredModal(false)
    setAppStatus(AppStatus.public)
    history.push('/login')
  }, [history])

  ApiService.onError = (error: any) => {
    if (
      error.response?.status === 401 &&
      error.config.url !== '/auth' &&
      localStorage.getItem('token')
    ) {
      setShowSessionExpiredModal(true)
      localStorage.removeItem('token')
      history.push('/login')
    }
    setAuthLoading(false)
    return {}
  }

  const login = async (username: string, password: string) => {
    setAuthLoading(true)

    const response = await authService.login(username, password)

    if (response.access_token) {
      ApiService._token = response.access_token
      localStorage.setItem('token', response.access_token)
      FS.identify(response.authuser_id, {
        displayName: response.first_name + ' ' + response.last_name,
        username: response.username
      })

      setUserData(response)
    }

    setAuthLoading(false)

    return response
  }

  const loginWithToken = async (response: any) => {
    setAuthLoading(true)

    if (response.access_token) {
      ApiService._token = response.access_token
      localStorage.setItem('token', response.access_token)
      FS.identify(response.authuser_id, {
        displayName: response.first_name + ' ' + response.last_name,
        username: response.username
      })
      setUserData(response)
    }

    setAuthLoading(false)

    return response
  }

  // used for mobile iframe auth
  const loginWithPassedCookie = async () => {
    setAuthLoading(true)

    const access_token = Cookies.get('passed_access_token')

    if (access_token) {
      ApiService._token = access_token
      localStorage.setItem('token', access_token)
      refreshUserData()
    }

    setAuthLoading(false)
  }

  const logout = async () => {
    localStorage.removeItem('token')
    // Use this instead of history.push() to prevent undesired calls to the getMember() endpoint which will return 401 error at this point
    window.location.assign('/login')
    await authService.logout()
    setUserData(null)
  }

  useEffect(() => {
    const isStandalonePage =
      location.pathname?.includes('survey/dependents-providers') ||
      location.pathname?.includes('survey/providers')

    const token = localStorage.getItem('token')
    if (token && !isStandalonePage) {
      ApiService._token = token
      refreshUserData()
    } else {
      setAuthLoading(false)
    }
  }, [refreshUserData, location])

  useEffect(() => {
    if (authLoading) return
    if (userData) {
      if (userData.temporary) {
        setAppStatus(AppStatus.temporary)
      } else {
        switch (userData.onboarding_status) {
          case 'pending_email':
          case 'pending':
          case 'in_progress':
            setAppStatus(AppStatus.onboarding)
            break
          case 'completed':
          case 'waived':
            setAppStatus(AppStatus.full)
        }
      }
    } else {
      setAppStatus(AppStatus.public)
    }
  }, [userData, authLoading, isRenewal])

  return (
    <AuthContext.Provider
      value={{
        userData,
        authLoading,
        login,
        logout,
        loginWithToken,
        loginWithPassedCookie,
        appStatus,
        refreshUserData,
        isRenewal
      }}
    >
      {showSessionExpiredModal && (
        <SessionExpired
          verticallyCentered
          onClick={onSessionExpiredButtonClicHandler}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}

export const useUserData = () => useContext(AuthContext).userData
export const useAppStatus = () => useContext(AuthContext).appStatus
