import React, { useRef } from 'react'
import {
  Button,
  Col,
  Form,
  Row,
  useToaster
} from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useLang } from '../../hooks/use-language'
import { useMutation } from 'react-query'
import { authService } from '../../services'

const emptyFormState = {
  newPassword: '',
  currentPassword: '',
  verifiedPassword: ''
}

export const SecurityTab: React.FC = () => {
  const {
    handleSubmit,
    register,
    watch,
    reset,
    clearErrors,
    formState: { errors, isSubmitted }
  } = useForm({ defaultValues: emptyFormState })
  const { show } = useToaster()
  const { security: messages } = useLang().messages.settings

  const changePassword = useMutation((data: any) =>
    authService.changePassword(data)
  )

  const password = useRef({})
  password.current = watch('newPassword', '')

  const missingInputData =
    errors.newPassword?.type === 'required' ||
    errors.verifiedPassword?.type === 'required' ||
    errors.currentPassword?.type === 'required'

  const onSubmit = async (data: any) => {
    const requestBody = {
      old_password: data.currentPassword,
      new_password: data.newPassword
    }
    try {
      await changePassword.mutateAsync(requestBody)

      show({
        message: messages.success,
        icon: 'icon-correct',
        type: 'success'
      })
      reset(emptyFormState)
      clearErrors()
    } catch (error) {
      show({
        message:
          'There was an error updating your password. Please reach out to your care team if the issue continues',
        icon: 'icon-error',
        type: 'danger'
      })
    }
  }

  return (
    <section>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <section>
          <h6>
            <b>{messages.title}</b>
          </h6>

          <Row>
            <Col>
              <Form.Group controlId="formCurrentPassword">
                <Form.Label>{messages.currentPassword}</Form.Label>
                <Form.Control
                  type="password"
                  {...register('currentPassword')}
                  name="currentPassword"
                  placeholder={messages.currentPassword}
                  className="bg-white"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formNewPassword">
                <Form.Label>{messages.newPassword}</Form.Label>
                <Form.Control
                  type="password"
                  {...register('newPassword', { required: true, minLength: 8 })}
                  placeholder={messages.newPassword}
                  className="bg-white"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="formVerifiedPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  className="bg-white"
                  {...register('verifiedPassword', {
                    required: true,
                    validate: (value: string) => value === password.current
                  })}
                />
              </Form.Group>
            </Col>
          </Row>
        </section>
        {isSubmitted && missingInputData && (
          <span className="text-danger">All inputs must be filled</span>
        )}
        {isSubmitted && errors.newPassword?.type === 'minLength' && (
          <span className="text-danger">
            Password length must be at least 8 characters
          </span>
        )}
        {isSubmitted && errors.verifiedPassword?.type === 'validate' && (
          <span className="text-danger">Passwords do not match</span>
        )}
        <hr />
        <div className="text-right">
          <Button type="submit">{messages.buttons.save}</Button>
        </div>
      </Form>
    </section>
  )
}

export default SecurityTab
