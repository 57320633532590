import { FormService } from 'react-angle-dashboard-components'

export interface IForm {
  title: string
  link: string
}

export class MemberFormService extends FormService {
  async getForms(): Promise<IForm[]> {
    return this.request({
      method: 'get',
      url: '/forms',
      credentials: true
    }).then((r: any) => r.data)
  }
}
