import { ApiService } from 'react-angle-dashboard-components'
import { INetworkResource } from '../../interfaces/network-resources'

export class NetworkResourcesService extends ApiService {
  async findDrugsNetworkResources(): Promise<INetworkResource[]> {
    return this.request({
      method: 'get',
      url: '/network-resources/drugs',
      credentials: true
    }).then((r: any) => r.drug_network_resources || [])
  }

  async findFacilitiesNetworkResources(): Promise<INetworkResource[]> {
    return this.request({
      method: 'get',
      url: '/network-resources/facilities',
      credentials: true
    }).then((r: any) => r.facility_network_resources || [])
  }
}
