import React, { CSSProperties, ReactNode } from 'react'
import { EllipsisCell } from './style'
import { OverlayTrigger, Tooltip } from 'react-angle-dashboard-components'

const sharedStyles: CSSProperties = {
  verticalAlign: 'middle',
  width: '1%',
  whiteSpace: 'nowrap'
}

const ClaimCell = ({
  children,
  ellipsis = false,
  className
}: {
  children: ReactNode
  ellipsis?: boolean
  className?: string
}) => {
  return ellipsis ? (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="cell-info">{children}</Tooltip>}
    >
      <EllipsisCell className={className} style={sharedStyles}>
        {children}
      </EllipsisCell>
    </OverlayTrigger>
  ) : (
    <td className={className} style={sharedStyles}>
      {children}
    </td>
  )
}

export default ClaimCell
