import React, { useContext } from 'react'
import { Paths } from '../../routes/paths'
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Col,
  IMemberEnrollment,
  Row,
  Spinner,
  Table
} from 'react-angle-dashboard-components'

import OrangeAvatar from '../../assets/avatar-orange.svg'
import BlueAvatar from '../../assets/avatar-blue.svg'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { acaService, memberService } from '../../services'
import { currencyFormatter, DateHandler } from '@angle/utils'
import { FaqDetails, FaqHeader, FaqTitle } from './style'

const CaretIcon = ({ myKey }: { myKey: string }) => {
  const currentEventKey = useContext(AccordionContext)
  return (
    <i
      className="icon fs-10 icon-dropdown"
      style={currentEventKey === myKey ? { transform: 'rotate(180deg)' } : {}}
    />
  )
}

const faqs = [
  {
    title: 'Who do I call regarding claims, authorizations, or eligibility?',
    details:
      'Any inquiries into your new plan after your Effective Date will be directed to your new health plan. Your employer will show you as an Access+ member in their system but you will coordinate care and administrative details with the new health plan on a go forward basis.'
  },
  {
    title:
      'What happens if I have a child or get married/bring someone onto my plan?',
    details:
      'This is referred to as a qualifying life event or QLE. If you have a QLE, make sure to communicate this to both your employer and new health plan.'
  },
  {
    title: 'What happens if I lose my job?',
    details:
      "You may continue the ACA plan just as you could continue with your employer's health plan under COBRA. That said, COBRA is not available for these plans, and you will be responsible for the total ACA premium upon termination."
  }
]

interface IAccessPlusInfoCardProps {
  hideHomeButton?: boolean
  memberAcaDataProp?: IMemberEnrollment
}

const AccessPlusInfoCard = ({
  hideHomeButton = false,
  memberAcaDataProp
}: IAccessPlusInfoCardProps) => {
  const history = useHistory()

  const { data: memberData, isLoading: memberDataIsLoading } = useQuery(
    'getMember',
    async () => {
      return memberService.getMemberInfo()
    }
  )

  const { data: memberAcaDataQueried, isLoading: memberAcaDataIsLoading } = 
    useQuery(
      'getMemberAca',
      async () => {
        return acaService.getMemberACAInfo(memberData?.member_id)
      },
      {
        enabled: !memberAcaDataProp && !!memberData?.member_id
      }
    )

  const memberAcaData = memberAcaDataQueried || memberAcaDataProp

  const accessPlusIsActive = memberData?.aca_status === 'active' // on aca plan

  const currentCoverage = memberData?.current_medical_coverage || null
  const pastCoverage =
    memberData?.past_medical_coverages[
      memberData?.past_medical_coverages?.length - 1
    ] || null

  // conditional display
  const acaData = memberAcaData?.acaData?.[0]
  let company = acaData?.companyName ? acaData?.companyName : ''
  let descriptionText = ''
  let effectiveDate = DateHandler.formatForClient(acaData?.startDate)
  let headerImg = ''
  let planName = acaData?.planName
  let title = ''
  let deductible
  let oop 

  if (accessPlusIsActive) {  
    title = `You transitioned to your new plan with ${company} on ${effectiveDate}`
    headerImg = BlueAvatar
    const termDate = pastCoverage?.term_date
      ? DateHandler.formatForClient(pastCoverage?.term_date)
      : null
    descriptionText = termDate
      ? `Your Angle Health plan coverage has ended on ${termDate}`
      : ''
  } else {
    title = `Your Angle Health plan coverage will end on ${DateHandler.formatForClient(
      currentCoverage?.term_date
    )}`
    headerImg = OrangeAvatar
    descriptionText = `You are going to be transitioning off of your Angle Health plan on ${ DateHandler.formatForClient(pastCoverage?.term_date) } to a ${company} plan that starts on ${ DateHandler.formatForClient(effectiveDate) }`
  }

  if (memberDataIsLoading || memberAcaDataIsLoading) {
    return <Spinner animation="border" />
  }

  if (acaData?.outOfPocketMax || acaData?.outOfPocketMax === 0) {
    oop = currencyFormatter.format(acaData.outOfPocketMax)
  }

  if (acaData?.deductible || acaData?.deductible === 0) {
    deductible = currencyFormatter.format(acaData.deductible)
  }

  return (
    <Card>
      {!hideHomeButton && (
        <div>
          <Button variant="link" onClick={() => history.push(Paths.home)}>
            Back to Home
          </Button>
        </div>
      )}
      <Card.Body className="px-8">
        <div className="d-flex flex-column align-items-center w-full mb-5">
          <img className="mb-3" src={headerImg} alt="" style={{ width: 88 }} />
          <h5>{title}</h5>
          <p
            style={{
              color: '#6C757D'
            }}
          >
            {descriptionText}
          </p>
          <a href="#faqHeader">
            <Button variant="outline-primary">Check FAQs</Button>
          </a>
        </div>
        <div
          style={{
            backgroundColor: '#F8F9FA'
          }}
          className="py-3 rounded"
        >
          <h6
            style={{
              marginLeft: '1.3125rem'
            }}
            className="font-weight-bold"
          >
            Plan Details
          </h6>
          <Table
            style={{
              backgroundColor: '#F8F9FA'
            }}
            className="border-0"
          >
            <tr>
              <td>Company</td>
              <td>{company}</td>
            </tr>
            <tr>
              <td>Plan</td>
              <td>{planName}</td>
            </tr>
            <tr>
              <td>Effective Date</td>
              <td>{effectiveDate}</td>
            </tr>
            <tr>
              <td>Deductible (In-Network)</td>
              <td>{deductible}</td>
            </tr>
            <tr>
              <td>Out-of-Pocket Max (In-Network)</td>
              <td>{oop}</td>
            </tr>
          </Table>
        </div>
        <div className="mt-5">
          <Row>
            <Col>
              <FaqHeader id="faqHeader">FAQs</FaqHeader>
            </Col>
            <Col className="col-auto">
              <a
                target="_blank"
                href="https://20488264.fs1.hubspotusercontent-na1.net/hubfs/20488264/Access%2B%20Collateral/Access%2B%20FAQ_Members_Enhanced%20Assistance.pdf"
                rel="noreferrer"
              >
                <Button variant="outline-primary">View all FAQs</Button>
              </a>
            </Col>
          </Row>
          <Accordion defaultActiveKey={faqs[0].title}>
            {faqs.map((faq) => (
              <div key={faq.title} className="border-bottom">
                <Accordion.Toggle
                  className="my-2"
                  as="div"
                  role="button"
                  eventKey={faq.title}
                >
                  <Row
                    style={{ minHeight: 60 }}
                    className="align-items-center justify-content-between"
                  >
                    <Col className="col-auto">
                      <div className="d-flex align-items-center">
                        <FaqTitle>{faq.title}</FaqTitle>
                        <CaretIcon myKey={faq.title} />
                      </div>
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse className="py-3 gy-2" eventKey={faq.title}>
                  <FaqDetails>{faq.details}</FaqDetails>
                </Accordion.Collapse>
              </div>
            ))}
          </Accordion>
        </div>
      </Card.Body>
    </Card>
  )
}

export default AccessPlusInfoCard
