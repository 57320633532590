import React from 'react'
import { CenteredSpinner } from 'react-angle-dashboard-components'
import { SelectDependentForPlan } from '../../../../../../../components/SelectDependentForPlan/SelectDependentForPlan'
import { useDependents } from '../../../../../hooks/useDependents'
import { GoBackLink } from '../../components/GoBackLink'
import { useGetAvailablePlans } from '../../../../../hooks/useGetAvailablePlans'
import { benefitsMap } from 'react-angle-dashboard-components'
import { VoluntaryPlansContainer } from '../../components/VoluntaryPlansContainer'
import { useSelectVoluntaryPlan } from '../../hooks/useSelectVoluntaryPlan'
import { VisionCard } from './VisionCard'
import { useQuery } from 'react-query'
import { coverageService } from '../../../../../../../services'
import { mapDependentsToSelectedArray } from '../../hooks/useSelectVoluntaryPlan'
import { findPremium } from '../../../../../utils/findPremium'

export const Vision = () => {
  const benefitKey = 'vision'

  const { data: selectedPlan, refetch: refetchSelectedPlan } = useQuery(
    ['getSelectedPlan', benefitKey],
    () => coverageService.getSelectedPlan(benefitKey)
  )

  const { availablePlans, isLoadingPlans, plansError } =
    useGetAvailablePlans(benefitKey)
  const {
    dependents,
    isLoadingDependents,
    dependentsError,
    selectedDependentsKey,
    setSelectedDependentsKey
  } = useDependents(selectedPlan?.family_coverage?.members)

  // Get premiums
  const { data: premiums } = useQuery(
    ['getAvailablePlanPremiums', selectedDependentsKey],
    () =>
      coverageService.getAvailablePlansPremiums(
        benefitKey,
        mapDependentsToSelectedArray(selectedDependentsKey)
      )
  )
  const { onClickSelectPlan } = useSelectVoluntaryPlan(
    benefitKey,
    selectedDependentsKey
  )

  const showDependents =
    dependents && dependents?.length && !isLoadingDependents && !dependentsError
  if (isLoadingDependents || isLoadingPlans) {
    return (
      <div className="p-3">
        <CenteredSpinner />
      </div>
    )
  }

  return (
    <div>
      {showDependents && (
        <SelectDependentForPlan
          dependents={dependents}
          selectedDependentsKey={selectedDependentsKey}
          setSelectedDependentsKey={setSelectedDependentsKey}
          refetchSelectedPlan={refetchSelectedPlan}
          apiKey={benefitKey}
        />
      )}
      <GoBackLink />
      <VoluntaryPlansContainer
        benefitDisplayName={benefitsMap[benefitKey]?.displayName}
        error={plansError}
      >
        {availablePlans?.map((plan: any) => {
          const premium = findPremium(plan.plan_id, premiums)
          return (
            <VisionCard
              key={plan.plan_id}
              plan={plan}
              onClickSelectPlan={onClickSelectPlan}
              premium={premium}
              isSelected={
                selectedPlan?.family_coverage?.plan?.plan_id === plan.plan_id
              }
            />
          )
        })}
      </VoluntaryPlansContainer>
    </div>
  )
}
