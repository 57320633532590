import styled from 'styled-components'

export const Title = styled.h1`
  font-family: 'sentinel';
  font-weight: bold;
`
export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const icon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="4"
      cy="4"
      r="3.46667"
      fill="white"
      stroke="#6D39D9"
      strokeWidth="1.06667"
    />
    <path
      d="M3.97328 2.54168C4.23518 2.54168 4.43756 2.33632 4.43756 2.0893C4.43756 1.84227 4.23518 1.63692 3.97328 1.63692C3.71137 1.63692 3.50899 1.84227 3.50899 2.0893C3.50899 2.33632 3.71137 2.54168 3.97328 2.54168ZM3.53875 6.14287H4.4078V2.95239H3.53875V6.14287Z"
      fill="#6D39D9"
    />
  </svg>
)
