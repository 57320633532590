import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { ReactComponent as AmeritasLogo } from '../../../../../../../assets/ameritas.svg'
import { BenefitData, BenefitLabel } from '../../style'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { dentalPlans } from './dentalPlansMap'
import { carrierLogoPaths } from '../../../../../utils/carrierLogoPaths'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const DentalCard: React.FC<{
  plan: any
  onClickSelectPlan: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  const maximumAmount = plan.maximum_message?.split('/')[0]

  const hasOrtho = plan.ortho_included

  const carrierName = plan?.carrier?.name
  return (
    <Card style={{ width: 380 }} className="mr-5 mb-5">
      <Card.Header className="py-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2" style={{ height: 60 }}>
              {plan?.carrier?.name === 'Ameritas' ? (
                <AmeritasLogo />
              ) : (
                <div style={{ height: '100%', marginTop: 14 }}>
                  <img
                    src={carrierLogoPaths[carrierName]}
                    alt={carrierName}
                    style={{
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '100%',
                      maxHeight: '20%'
                    }}
                  />
                </div>
              )}
            </div>
          </div>

          <PlanCardPremium premium={premium} />
          <SelectPlanButton
            isSelected={isSelected}
            onClick={() => onClickSelectPlan(plan.plan_id)}
          />
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100 ">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="px-3 py-2 font-weight-bold">Plan Coverage</th>
              <th className="px-3 py-2 font-weight-bold">In-Network</th>
              <th className="px-3 py-2 font-weight-bold">
                Out-of-Network
                <span className="d-block fs-10">(80th Percentile U&C)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-3">Preventive</BenefitLabel>
              <BenefitData className="fs-18 pt-3">
                {plan.inn_preventative_message}
              </BenefitData>
              <BenefitData className="fs-18 pt-3">
                {plan.oon_preventative_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Basic</BenefitLabel>
              <BenefitData className="fs-18">
                {plan.inn_basic_message}
              </BenefitData>
              <BenefitData className="fs-18">
                {plan.oon_basic_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Major</BenefitLabel>
              <BenefitData className="fs-18">
                {' '}
                {plan.inn_major_message}
              </BenefitData>
              <BenefitData className="fs-18">
                {' '}
                {plan.oon_major_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Orthodontia</BenefitLabel>
              <BenefitData>
                {plan.ortho_benefit_message ? (
                  <span className="fs-18">{plan.ortho_benefit_message}</span>
                ) : (
                  'Not Included'
                )}
              </BenefitData>
              <BenefitData>
                {plan.ortho_benefit_message ? (
                  <span className="fs-18">{plan.ortho_benefit_message}</span>
                ) : (
                  'Not Included'
                )}
              </BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <Card.Body className="border-bottom">
        <span style={{ fontWeight: 600 }} className="text-secondary fs-14">
          Plan Deductibles
        </span>
        <span className="text-secondary fs-12 d-block">
          {dentalPlans[plan.plan_id]?.deductibleText}
        </span>
        <div className="d-flex pt-3 pb-3">
          <div className="w-50">
            <span className="fs-20 font-weight-bold">$50</span>
            <span className="fs-20 text-secondary">/year</span>
            <span className="d-block fs-14">Individual</span>
          </div>
          <div className="w-50">
            <span className="fs-20 font-weight-bold">$150</span>
            <span className="fs-20 text-secondary">/year</span>
            <span className="d-block fs-14">Family</span>
          </div>
        </div>
        <span style={{ fontWeight: 600 }} className="text-secondary fs-14">
          Plan Maxes
        </span>
        <span className="fs-12 d-block text-secondary">
          Annual maximum applies diagnostics & preventive, basic services, and
          major services. Lifetime maximum applies to orthodontic services.
        </span>
        <div className="d-flex pt-3">
          <div className="w-50">
            <span className="fs-20 font-weight-bold">{maximumAmount}</span>
            <span className="fs-20 text-secondary">/year</span>
            <span className="d-block fs-14">Annual Max</span>
          </div>
          <div className="w-50 fs-20">
            {hasOrtho ? (
              <span className="font-weight-bold">
                {plan.ortho_lifetime_maximum_message}
              </span>
            ) : (
              <span className="font-weight-bold">Not Included</span>
            )}

            <span className="d-block fs-14">Ortho Lifetime Max</span>
          </div>
        </div>
      </Card.Body>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
