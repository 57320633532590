import React from 'react'
import { Nav } from 'react-angle-dashboard-components'
import { useHistory, useLocation, useParams } from 'react-router-dom'

export const DependentTabs: React.FC = () => {
  const activeLink = useLocation().pathname

  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  return (
    <Nav
      variant="tabs"
      activeKey={activeLink}
      onSelect={(key: string | null) => history.push(key || '')}
      style={{ border: 'none' }}
    >
      <Nav.Item>
        {/* <Nav.Link className="fs-16" eventKey={`/dependents/${id}/benefits`}>
          Benefits
        </Nav.Link> */}
      </Nav.Item>
      <Nav.Item>
        <Nav.Link className="fs-16" eventKey={`/dependents/${id}/id-card`}>
          ID Card
        </Nav.Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Nav.Link
          className="fs-16"
          eventKey={`/dependents/${id}/health-profile`}
        >
          Health Profile
        </Nav.Link>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Nav.Link className="fs-16" eventKey={`/dependents/${id}/claims`}>
          Claims
        </Nav.Link>
      </Nav.Item> */}
    </Nav>
  )
}

export default DependentTabs
