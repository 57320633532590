import * as React from 'react'
import { SVGProps } from 'react'

const DownloadSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 18"
    fill="currentColor"
    {...props}
  >
    <g id="Download">
      <path
        id="Vector"
        d="M7.5 0C7.12364 0 6.81818 0.305455 6.81818 0.681818V3.40909H8.18182V0.681818C8.18182 0.305455 7.87637 0 7.5 0ZM8.18182 3.40909V11.9904L9.74521 10.427C10.0118 10.1604 10.4428 10.1604 10.7093 10.427C10.9759 10.6936 10.9759 11.1246 10.7093 11.3912L7.98207 14.1184C7.84911 14.2514 7.67455 14.3182 7.5 14.3182C7.32546 14.3182 7.15089 14.2514 7.01793 14.1184L4.29066 11.3912C4.02407 11.1246 4.02407 10.6936 4.29066 10.427C4.55725 10.1604 4.9882 10.1604 5.2548 10.427L6.81818 11.9904V3.40909H1.36364C0.610227 3.40909 0 4.01932 0 4.77273V16.3636C0 17.117 0.610227 17.7273 1.36364 17.7273H13.6364C14.3898 17.7273 15 17.117 15 16.3636V4.77273C15 4.01932 14.3898 3.40909 13.6364 3.40909H8.18182Z"
      />
    </g>
  </svg>
)

export default DownloadSolid
