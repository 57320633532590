export const directoryUrls: Record<string, string> = {
  angle: 'https://providerlocator.firsthealth.com/LocateProvider/SelectNetworkType',
  sns: 'https://hcpdirectory.cigna.com/web/public/consumer/directory/search',
  mpcn: 'http://www.mpcn-ms.com/'
}

const ANGLE_WEBSITE_FIND_CARE_URL = 'https://www.anglehealth.com/network-directories'

export const getDirectoryUrlByIdCardType = (idCardType?: string): string => {
  if (idCardType && directoryUrls[idCardType]) {
    return directoryUrls[idCardType]
  }

  return ANGLE_WEBSITE_FIND_CARE_URL
}
