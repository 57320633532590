export const accidentalPlans = {
  lowPlan: {
    plan_name: 'Accident Benefit - Low Plan',
    price: 13.73,
    fracture: '$100-8,000',
    eyeInjury: '$200',
    ambulance: '$100 Ground',
    concussion: '$50',
    admission: '$750'
  },
  highPlan: {
    plan_name: 'Accident Benefit - High Plan',
    price: 20.92,
    fracture: '$200-10,000',
    eyeInjury: '$300',
    ambulance: '$200 Ground',
    concussion: '$100',
    admission: '$1,250'
  }
}
