import { DateHandler, currencyFormatter } from '@angle/utils'
import { IClaimDTOItem, IClaimListItem } from '../../../interfaces/claims'
import { IClaimDTO, IRxClaimDTO } from './claims'

// TODO clean up types for this data

const getName = (firstName: any, lastName: any) => {
  if (!firstName && !lastName) return '-'
  else return firstName + ' ' + lastName
}

export interface IUnifiedClaimDetail {
  billedAmount: string
  checkNumber?: string
  claimId: string
  claimNumber: string
  inNetwork: boolean
  memberId: string
  memberName: string
  paidAmount: string
  patientResponsibility: string
  paymentSource?: string
  paymentStatus: string
  providerName: string
  status: string
  type: string

  // RX
  allowedAmount?: string
  dateDaysSupply?: number
  dateRxWritten?: string
  dateSubmitted?: string
  dateOfService?: string
  drugName?: string
  pharmacyName?: string
  pharmacyAddress?: string
  refillNumber?: number
  rxNumber?: string

  // Medical
  copayAmount?: string
  deductibleAmount?: string
  facilityAddress?: string
  facilityName?: string
  hasEOB?: boolean
  notCoveredAmount?: string
  npi?: string
  processed_date?: string
  paidDate?: string
  processedDate?: string
  providerSpecialty?: string
  receivedDate?: string
  savedAmount?: string
  serviceEndDate?: string
  serviceStartDate?: string
  diagnosisCodes?: {
    code: string
    description: string
  }[]
  procedureCodes?: {
    code: string
    description: string
  }[]
}

export const mapDTOtoClaimDetail = (r: IClaimDTO) => {
  const { street_1, street_2, city, state, zip } = r?.provider?.address
  return {
    billedAmount: currencyFormatter.format(r.billed_price),
    checkNumber: r.check_number || '-',
    claimId: `${r.claim_id}`,
    claimNumber: r.claim_number,
    copayAmount: currencyFormatter.format(
      r.copay_amount + r.coinsurance_amount
    ),
    deductibleAmount: currencyFormatter.format(r.deductible_amount),
    diagnosisCodes: r.diagnostic_codes_details,
    facilityAddress: `${street_1?.toLowerCase()}${
      street_2 ? ` ${street_2}` : ''
    }, ${city?.toLowerCase()} ${state}, ${zip}`,
    facilityName: r.provider?.office_name?.toLowerCase() || '-',
    hasEOB: r.last_eob !== null && r.last_eob !== undefined,
    inNetwork: r.in_network,
    memberId: r.member?.member_id || '',
    memberName: getName(r.member?.name?.first_name, r.member?.name?.last_name),
    notCoveredAmount: currencyFormatter.format(r.not_covered_amount),
    npi: r.provider?.npi || r.provider?.provider_npi || r.provider?.rendering_npi,
    paidAmount: currencyFormatter.format(r.paid_amount),
    paidDate: r.angle_paid_date
      ? DateHandler.formatForClient(r.angle_paid_date)
      : '-',
    paymentSource: r.payment_source,
    paymentStatus: r.payment_status,
    patientResponsibility: currencyFormatter.format(r.patient_amount),
    procedureCodes: r.procedure_codes_details,
    processedDate: DateHandler.formatForClient(r.processed_date),
    providerName: r.provider_name || '-',
    providerSpecialty: r.provider.specialty || '-',
    receivedDate: DateHandler.formatForClient(r.received_date),
    savedAmount: currencyFormatter.format(r.saved_amount),
    serviceEndDate: DateHandler.formatForClient(r.service_thru),
    serviceStartDate: DateHandler.formatForClient(r.service_date),
    status: r.claim_status || 'error',
    type: "medical"
  }
}

export const mapDTOtoClaimList = (r: any) => {
  return {
    ...r,
    claims: r.claims?.map((claim: IClaimDTOItem) => {
      const associatedClaims: IClaimDTOItem[] = claim?.associated_claims || []

      const mappedClaim: IClaimListItem = mapClaimItemToList(claim)
      if (associatedClaims.length) {
        mappedClaim.associatedClaims = associatedClaims.map(
          (claim: IClaimDTOItem) => mapClaimItemToList(claim)
        )
      }
      return mappedClaim
    })
  }
}

const mapClaimItemToList = (claim: IClaimDTOItem) => {
  return {
    claimId: claim.claim_id,
    claimNumber: claim.claim_number,
    serviceDate: DateHandler.formatForClient(claim.service_date),
    serviceEndDate: DateHandler.formatForClient(claim.service_thru),
    paidDate: claim.paid_date
      ? DateHandler.formatForClient(claim.paid_date)
      : '-',
    paymentSource: claim.payment_source,
    providerName: claim.provider_name || '-',
    status: claim.claim_status || 'error',
    anglePaid: !!claim.angle_paid,
    amountBilled: currencyFormatter.format(claim.billed_price),
    memberName: getName(
      claim.member?.name?.first_name,
      claim.member?.name?.last_name
    ),
    patientResponsibility: currencyFormatter.format(claim.patient_amount),
    inNetwork: claim.in_network
  }
}

export const mapDTOtoRxClaimList = (r: any) => {
  return {
    ...r,
    claims: r.claims?.map((claim: any) => {
      const claimDetails = claim?.rx_claim_details[0]
      return {
        claimId: claim.mrx_claim_ref,
        claimNumber: claimDetails?.rx_number,
        paidDate: claim.date_of_service
          ? DateHandler.formatForClient(claim.date_of_service)
          : '-',
        providerName: getName(
          claim.prescriber_first_name,
          claim.prescriber_last_name
        ).toLowerCase(),
        memberName: getName(
          claim.patient_first_name,
          claim.patient_last_name
        ).toLowerCase(),
        patientResponsibility:
          claim?.rx_claim_details[0]?.approved_copay !== undefined
            ? currencyFormatter.format(
                claim?.rx_claim_details[0]?.approved_copay
              )
            : '-',
        status: claim?.rx_claim_details[0]?.transaction_status,
        copayAssistance: claim.copay_assistance
      }
    })
  }
}

export const mapDTOtoRXClaimDetail = (r: IRxClaimDTO) => {
  const { address_line_1, address_line_2, city, state, zip } = r?.pharmacy?.address
  const pharmacyAddress = `${address_line_1?.toLowerCase()}${
    address_line_2 ? ` ${address_line_2}` : ''
  }, ${city?.toLowerCase()} ${state}, ${zip}`

  return {
    allowedAmount: currencyFormatter.format(r.allowed_amount),
    billedAmount: currencyFormatter.format(r.retail_amount),
    claimId: `${r.claim_id}`,
    claimNumber: `${r.claim_id}`,
    dateDaysSupply: r?.days_supply,
    dateOfService: DateHandler.formatForClient(r?.date_of_service),
    dateRxWritten: DateHandler.formatForClient(r?.date_rx_written),
    dateSubmitted: DateHandler.formatForClient(r?.date_submitted),
    drugName: r?.label_name,
    inNetwork: true,
    memberId: r?.patient?.id || '',
    memberName: r?.patient?.name || '',
    paidAmount: currencyFormatter.format(r.paid_by_angle),
    patientResponsibility: currencyFormatter.format(r.patient_amount),
    providerName: r.prescriber.name,
    pharmacyName: r?.pharmacy?.name,
    refillNumber: r?.refill_number,
    rxNumber: r?.rx_number || '-',
    status: r.status || 'error',
    type: "pharmacy",
    pharmacyAddress: pharmacyAddress === ',  , ' ? '-' : pharmacyAddress,
  }
}
