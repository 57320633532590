import * as React from 'react'
import { SVGProps } from 'react'

const ChatSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    fill="currentColor"
    {...props}
  >
    <g id="Chat">
      <path
        id="Vector"
        d="M8.49873 0C13.1924 0 16.9975 3.80501 16.9975 8.49873C16.9975 13.1924 13.1924 16.9975 8.49873 16.9975C7.1046 16.9975 5.75803 16.6608 4.55174 16.0272L0.905438 16.9765C0.519358 17.0771 0.124846 16.8456 0.0242893 16.4595C-0.00680753 16.3402 -0.00681606 16.2148 0.0242638 16.0954L0.97302 12.4508C0.33757 11.2433 0 9.89491 0 8.49873C0 3.80501 3.80501 0 8.49873 0ZM9.56252 9.34861H5.73665L5.65015 9.35439C5.33903 9.39663 5.09924 9.66332 5.09924 9.98601C5.09924 10.3087 5.33903 10.5754 5.65015 10.6176L5.73665 10.6234H9.56252L9.64904 10.6176C9.96018 10.5754 10.1999 10.3087 10.1999 9.98601C10.1999 9.66332 9.96018 9.39663 9.64904 9.35439L9.56252 9.34861ZM11.2608 6.37405H5.73665L5.65015 6.37987C5.33903 6.42208 5.09924 6.68876 5.09924 7.01146C5.09924 7.33415 5.33903 7.60084 5.65015 7.64308L5.73665 7.64886H11.2608L11.3473 7.64308C11.6584 7.60084 11.8982 7.33415 11.8982 7.01146C11.8982 6.68876 11.6584 6.42208 11.3473 6.37987L11.2608 6.37405Z"
      />
    </g>
  </svg>
)

export default ChatSolid
