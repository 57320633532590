import * as React from 'react'
import { SVGProps } from 'react'

const ContactSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <g id="Contact">
      <path
        id="Union"
        fillRule="evenodd"
        d="M1.66698 14.7955C1.66698 15.8311 2.50648 16.6705 3.54198 16.6705L16.4586 16.6705C17.4942 16.6705 18.3336 15.8311 18.3336 14.7955L18.3336 5.20837C18.3336 4.17287 17.4942 3.33337 16.4586 3.33337L3.54198 3.33337C2.50648 3.33337 1.66698 4.17287 1.66698 5.20837L1.66698 14.7955ZM8.95673 9.17379L5.20865 9.17379L5.12381 9.16804C4.81881 9.12671 4.58365 8.86521 4.58365 8.54879C4.58365 8.23237 4.81881 7.97087 5.12381 7.92946L5.20865 7.92379L8.95673 7.92379L9.04157 7.92946C9.34665 7.97087 9.58173 8.23237 9.58173 8.54879C9.58173 8.86521 9.34665 9.12671 9.04157 9.16804L8.95673 9.17379ZM8.95673 12.0872L5.20865 12.0872L5.12381 12.0815C4.81881 12.0401 4.58365 11.7786 4.58365 11.4622C4.58365 11.1458 4.81881 10.8843 5.12381 10.8429L5.20865 10.8372L8.95673 10.8372L9.04157 10.8429C9.34665 10.8843 9.58173 11.1458 9.58173 11.4622C9.58173 11.7786 9.34665 12.0401 9.04157 12.0815L8.95673 12.0872Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)

export default ContactSolid
