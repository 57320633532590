export const Paths = Object.freeze({
  main: '/',
  login: '/login',
  temporaryLogin: '/temporary-login',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
  resetPassword: '/reset-password',
  facilityProfile: '/facilities/:id',
  facilities: '/facilities/map',
  providerProfile: '/providers/:id',
  providers: '/providers',
  drugProfile: '/drugs/:id',
  settings: '/settings',
  drugs: '/drugs',
  prescriptionsAtHome: '/pharmacy/prescriptions-at-home',
  care: '/care',
  claims: '/claims',
  comparePrices: '/compare-prices',
  comparePricesProviderSearch: '/compare-prices/provider-search',
  comparePricesResult: '/compare-prices/result',
  medicalClaims: '/medical/claims',
  rxClaims: '/rx/claims',
  claimsDetail: '/claims/:id',
  rxClaimsDetail: '/rxclaims/:id',
  documentsAndForms: '/documents',
  healthProfile: '/health-profile',
  healthProfileOverview: '/health-profile/overview',
  healthProfileMedications: '/health-profile/medications',
  healthProfileAuthorizations: '/health-profile/authorizations',
  healthProfileLabs: '/health-profile/labs',
  healthProfileVaccines: '/health-profile/vaccines',
  healthProfileAllergies: '/health-profile/allergies',
  healthProfileVitals: '/health-profile/vitals',
  healthProfileWellness: '/health-profile/wellness',
  networkResources: '/network-resources',
  myPlan: '/my-plan',
  myPlanMedical: '/my-plan/medical',
  myPlanDental: '/my-plan/dental',
  myPlanVision: '/my-plan/vision',
  myPlanPharmacy: '/my-plan/pharmacy',
  myPlanMemberIdCard: '/my-plan/member-id-card',
  dependentPlan: '/dependents/:id',
  newEmployeeSelectPassword: '/select-password',
  onboarding: '/onboarding',
  onboardingComplete: '/onboarding-complete',
  home: '/home',
  notFound: '/not-found',
  waiveCoverageSuccess: '/waive-coverage-success',
  waiveCoverage: '/waive-coverage',
  docusignSigned: '/docusign-signed',
  memberSurveys: '/survey',
  superuser: '/superuser',
  virtualCare: '/virtual-care',
  createAccount: '/create-account',
  pharmacy: '/pharmacy',
  accessPlusPlanDetails: '/access-plus/plan-details'
})
