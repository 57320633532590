import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Personal } from './Sections/Personal/Personal'
import { Contact } from './Sections/Contact/Contact'
import { CurrentCarrier } from './Sections/CurrentCarrier/CurrentCarrier'
import { Dependents } from './Sections/Dependents/Dependents'
import { Agreement } from './Sections/Agreement/Agreement'
import { Plan } from './Sections/Plan/Plan'
import { VoluntaryRouter } from './Sections/Voluntary/VoluntaryRouter'
import { Paths } from '../../routes/paths'
import {
  voluntarySteps,
  renewalSteps,
  voluntaryRenewalSteps,
  defaultSteps
} from './Sidebar/steps'
import { CenteredSpinner } from 'react-angle-dashboard-components'
import Review from './Sections/Review/Review'

export const onboardingPaths = {
  personal: '/personal',
  contact: '/contact',
  dependents: '/dependents',
  'current-carrier': '/current-carrier',
  plan: '/plan',
  voluntary: '/voluntary',
  agreement: '/agreement',
  emergencyContact: '/emergency',
  completed: '/completed/onboarding',
  waiveCoverage: '/onboarding/waive-coverage',
  review: '/review'
}

// const subSections: { [key: string]: JSX.Element } = {
//   personal: <Personal />,
//   contact: <Contact />,
//   dependents: <Dependents />,
//   'current-carrier': <CurrentCarrier />,
//   plan: <Plan />,
//   voluntary: <VoluntaryRouter />,
//   agreement: <Agreement />
// }

export const Router: React.FC<{
  memberData: any
  isLoading: boolean
  refetch: any
}> = ({ memberData, isLoading, refetch }) => {
  const params = useParams<{ activeStepName: string }>()

  if (isLoading) {
    return <CenteredSpinner />
  }

  const subSections: { [key: string]: JSX.Element } = {
    personal: <Personal refetch={refetch} />,
    contact: <Contact />,
    dependents: <Dependents />,
    'current-carrier': <CurrentCarrier />,
    plan: <Plan />,
    voluntary: <VoluntaryRouter />,
    agreement: <Agreement />,
    review: <Review />
  }

  const showVoluntary = memberData.showVoluntary

  let steps = defaultSteps

  if (memberData?.inRenewal && showVoluntary) {
    steps = voluntaryRenewalSteps
  } else if (memberData?.inRenewal) {
    steps = renewalSteps
  } else if (showVoluntary) {
    steps = voluntarySteps
  }

  const component: JSX.Element = subSections[params.activeStepName]

  const activeNameStep = steps.find(
    (step: any) => step.stepName === params.activeStepName
  )

  // redirect to agreement page if on the last step
  if (
    params.activeStepName === 'agreement' ||
    steps.length < (activeNameStep?.number || 1)
  ) {
    return subSections.agreement
  }

  const isAccessDenied =
    memberData?.serverStep < (activeNameStep?.number || 0) ||
    (!showVoluntary && params.activeStepName === 'voluntary')

  if (!component || isAccessDenied) {
    const redirectStep = steps[memberData?.serverStep - 1]

    if (!redirectStep) {
      return <Redirect to={Paths.onboarding + onboardingPaths.personal} />
    }

    const redirectPath =
      onboardingPaths[redirectStep?.stepName as keyof typeof onboardingPaths]

    return (
      <Redirect
        to={Paths.onboarding + (redirectPath || onboardingPaths.personal)}
      />
    )
  }
  return component
}
