import React, { ReactNode } from 'react'
import {
  Col,
  Row,
  Tooltip,
  OverlayTrigger,
  Card
} from 'react-angle-dashboard-components'
import Skeleton from 'react-loading-skeleton'

import { claimsStatusEnums, claimsTypeEnums } from '../../services/api/claims/claims'
import { IUnifiedClaimDetail } from '../../services/api/claims/mappers'

import { HelpIcon } from './style'
import { PaymentSource, isNomi, paymentSourceObject } from '@angle/utils'

interface InfoRowProps {
  label: ReactNode
  content: ReactNode | undefined
  isLoading?: boolean
  image?: string
  reverse?: boolean
}

const InfoRow = ({
  label: header,
  content,
  isLoading,
  image,
  reverse = false
}: InfoRowProps) => (
  <Row>
    {image && <i className={`icon ${image}`} />}
    <Col className={`d-flex flex-column ${reverse && 'flex-column-reverse'}`}>
      <Row className="text-muted fs-12">{header}</Row>
      <Row
        className="text-capitalize"
        style={{
          fontWeight: 500
        }}
      >
        {isLoading ? <Skeleton width={150} /> : content}
      </Row>
    </Col>
  </Row>
)

export const Details: React.FC<{
  data: IUnifiedClaimDetail | undefined
  isLoading: boolean
}> = ({ data, isLoading }) => {
  return (
    <Col>
      <Card className="mb-3">
        <Card.Header>
          <strong>Claim Info</strong>
        </Card.Header>
        <Card.Body className="px-5">
          <Row>
            <Col className="bg-light rounded">
              <Col className="d-flex flex-column justify-content-center h-100">
                <Row className="font-weight-bold">
                  You may owe (Patient Responsibility):
                </Row>
                <Row className="font-weight-bold fs-28">
                  {isLoading ? (
                    <Skeleton width={150} />
                  ) : (
                    data?.status === claimsStatusEnums.processing ? 'TBD' : data?.patientResponsibility
                  )}
                </Row>
                <Row className="text-muted fs-12">
                  { data?.status === claimsStatusEnums.processing ?
                  'The amount will be shown once the claim is processed' :
                  'Always confirm the amount you may owe directly with your provider.'}
                </Row>
              </Col>
            </Col>
            <Col>
              <Col>
                {data?.type === claimsTypeEnums.pharmacy && (<InfoRow
                  label="RX Number"
                  content={data?.rxNumber}
                  image="icon-hashtag"
                  isLoading={isLoading}
                />)}
                <InfoRow
                  label="Provider"
                  content={data?.providerName}
                  image="icon-user"
                  isLoading={isLoading}
                />
                {data?.type === claimsTypeEnums.pharmacy && (<InfoRow
                  label="Drug Name"
                  content={data?.drugName}
                  image="icon-drugs"
                  isLoading={isLoading}
                />)}
                {data?.type === claimsTypeEnums.medical && (<InfoRow
                  label="Provider Specialty"
                  content={data?.providerSpecialty}
                  image="icon-specialty"
                  isLoading={isLoading}
                />)}
                {data?.type === claimsTypeEnums.medical && (<InfoRow
                  label="Dates of Service"
                  content={data?.serviceStartDate === data?.serviceEndDate ? `${data?.serviceStartDate}` : `${data?.serviceStartDate} - ${data?.serviceEndDate}`}
                  image="icon-calendar"
                  isLoading={isLoading}
                />)}
                {data?.paymentSource && (<InfoRow
                  label={isNomi(data.paymentSource) ? "Payment Through" : "Check Number"}
                  content={isNomi(data.paymentSource) ? paymentSourceObject[data.paymentSource as PaymentSource] : data.checkNumber}
                  image={isNomi(data.paymentSource) ? "icon-bank" : "icon-hashtag"}
                  isLoading={isLoading}
                />)}
              </Col>
            </Col>
          </Row>
          { data?.type === claimsTypeEnums.medical && (<Row className="mt-5 gy-1">
            <Col>
              <InfoRow
                label="Claim Received Date"
                content={data?.receivedDate}
                isLoading={isLoading}
              />
            </Col>
            <Col>
              <InfoRow
                label={
                  <div>
                    Claim Processed Date{' '}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="process-date-tooltip">TODO</Tooltip>
                      }
                    >
                      <HelpIcon className="icon icon-question-mark fs-12" />
                    </OverlayTrigger>
                  </div>
                }
                content={data?.processedDate}
                isLoading={isLoading}
              />
            </Col>
            <Col>
              <InfoRow label="NPI" content={data?.npi} isLoading={isLoading} />
            </Col>
            <div className="w-100" />
            <Col>
              <InfoRow
                label="Facility Name"
                content={data?.facilityName}
                isLoading={isLoading}
              />
            </Col>
            <Col xs={8}>
              <InfoRow
                label="Service Address"
                content={data?.facilityAddress}
                isLoading={isLoading}
              />
            </Col>
          </Row>)}
          { data?.type === claimsTypeEnums.pharmacy && (<Row className="mt-5 gy-1">
            <Col>
              <InfoRow
                label="Pharmacy Name"
                content={data?.pharmacyName}
                isLoading={isLoading}
              />
            </Col>
            <Col xs={8}>
              <InfoRow
                label="Pharmacy Address"
                content={data?.pharmacyAddress}
                isLoading={isLoading}
              />
            </Col>
            <div className="w-100" />
            <Col>
              <InfoRow
                label="Date RX Written"
                content={data?.dateRxWritten}
                isLoading={isLoading}
              />
            </Col>
            <Col>
              <InfoRow
                label="Date Submitted"
                content={data?.dateSubmitted}
                isLoading={isLoading}
              />
            </Col>
            <Col>
              <InfoRow
                label="Date of Service (filled date)"
                content={data?.dateOfService}
                isLoading={isLoading} />
            </Col>
            <div className="w-100" />
            <Col>
              <InfoRow
                label="Refill #"
                content={data?.refillNumber}
                isLoading={isLoading}
              />
            </Col>
            <Col xs={8}>
              <InfoRow
                label="Date Days Supply"
                content={data?.dateDaysSupply}
                isLoading={isLoading}
              />
            </Col>
          </Row>)}
        </Card.Body>
      </Card>
      {data?.type === claimsTypeEnums.medical && (<strong>Services Breakdown</strong>)}
      {data?.type === claimsTypeEnums.medical && (<Card className="mt-3" style={!isLoading && !data?.diagnosisCodes?.length ? { border: 'none' } : {}}>
        {!isLoading && data?.status === claimsStatusEnums.processing ? (
          <>
          <Card.Header style={{
            border: 'none',
            display: 'flex',
            fontWeight: 600,
            fontSize: '20px',
            justifyContent: 'center'
            }}> TBD </Card.Header>
          <Card.Body className='text-muted fs-16' style={{
            border: 'none',
            display: 'flex',
            justifyContent: 'center'
             }}> Services will be shown once the claim is processed </Card.Body>
        </>
        ) : !isLoading && !data?.diagnosisCodes?.length ?
          (<>
            <Card.Header style={{
              border: 'none',
              display: 'flex',
              fontWeight: 600,
              fontSize: '20px',
              justifyContent: 'center'
              }}> No services to show </Card.Header>
            <Card.Body className='text-muted fs-16' style={{
              border: 'none',
              display: 'flex',
              justifyContent: 'center'
               }}> We do not have the data about the services for this claim </Card.Body>
          </>) : 
          (<Card.Body>
          <Col className="gy-1">
            <InfoRow
              reverse
              label={
                isLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <span className="fs-16">{data?.serviceStartDate}</span>
                )
              }
              content={data?.diagnosisCodes?.length && data.diagnosisCodes[0].description}
              isLoading={isLoading}
            />
            <Row>
              <Col>
                <InfoRow
                  reverse
                  label="Amount Billed"
                  content={data?.billedAmount}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="In-Network Savings"
                  content={data?.savedAmount}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="Paid by Angle"
                  content={data?.paidAmount}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="Non Covered"
                  content={data?.notCoveredAmount}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InfoRow
                  reverse
                  label="Applied to DED"
                  content={data?.deductibleAmount}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="Co-Pay/Co-Insurance"
                  content={data?.copayAmount}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="Total Patient Responsiblity"
                  content={data?.patientResponsibility}
                  isLoading={isLoading}
                />
              </Col>
              <Col />
            </Row>
            <hr style={{ marginLeft: '-15px', marginRight: '-15px' }} />
            <Row>
              <Col>
                <InfoRow
                  reverse
                  label="Diagnosis Code"
                  content={data?.diagnosisCodes?.length && data.diagnosisCodes[0].code}
                  isLoading={isLoading}
                />
              </Col>
              <Col>
                <InfoRow
                  reverse
                  label="Procedure Code"
                  content={data?.procedureCodes?.length && data?.procedureCodes[0].code}
                  isLoading={isLoading}
                />
              </Col>
            </Row>
          </Col>
        </Card.Body>)}
      </Card>)}
    </Col>
  )
}
