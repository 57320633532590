import React from 'react'
import { Col, Row } from 'react-angle-dashboard-components'
import { Link, useParams } from 'react-router-dom'
import { Paths } from '../../../routes/paths'
import { StepBadge, StepWrapper } from './style'
import { IStepProps } from './interfaces'

export const Step: React.FC<IStepProps> = ({
  title,
  stepNumber,
  stepName,
  serverStep
}) => {
  const { activeStepName } = useParams<{ activeStepName: string }>()

  let active = activeStepName === stepName

  const completed = serverStep > stepNumber

  let badgeContent = <span className="fs-12">{stepNumber}</span>

  let showEdit = serverStep >= stepNumber && !active

  if (
    activeStepName === 'agreement' &&
    (stepName === 'plan' || stepName === 'voluntary')
  ) {
    showEdit = true
  }

  if (completed && !active) {
    badgeContent = (
      <i
        className="icon icon-correct"
        style={{ fontSize: '0.7rem' }}
        data-testid="correct-icon"
      />
    )
  }

  return (
    <StepWrapper className="w-100">
      {/* <i className="icon icon-correct" /> */}
      <Row>
        <Col xs={1}>
          <StepBadge className="fs-12" active={active || Boolean(completed)}>
            {badgeContent}
          </StepBadge>
        </Col>
        <Col>
          <div className="d-flex flex-column">
            <h6 className="text-nowrap" style={{ maxWidth: 165 }}>
              {title}
            </h6>
          </div>
        </Col>
        <Col xs={3} className="pr-4">
          <div className="d-flex align-items-base">
            {showEdit && (
              <div className="d-flex">
                <i
                  className="icon icon-pencil text-primary fs-12"
                  style={{ height: '100%', width: '0.8rem', marginRight: 7 }}
                />

                <Link
                  to={Paths.onboarding + '/' + stepName}
                  className="fs-14 text-primary"
                >
                  Edit
                </Link>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </StepWrapper>
  )
}

export default Step
