import { currencyFormatter } from '@angle/utils'

export const formatCurrencyForNonIntegers = (amount: number) => {
  if (typeof amount !== 'number') {
    return ''
  }
  if (Number.isInteger(amount)) {
    return (
      '$' +
      amount?.toLocaleString('en-US', {
        currency: 'USD'
      })
    )
  } else {
    return currencyFormatter.format(amount)
  }
}
