import { SVGProps } from 'react'

export const DownloadArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={18}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.25 0V12.4219L2.28906 8.46094L1.21094 9.53906L6.46094 14.7891L7 15.3047L7.53906 14.7891L12.7891 9.53906L11.7109 8.46094L7.75 12.4219V0H6.25ZM0.25 16.5V18H13.75V16.5H0.25Z"
      fill="currentColor"
    />
  </svg>
)
