import React, { ReactNode } from 'react'
import { Col, Row } from 'react-angle-dashboard-components'
import { DisclaimerBody, DisclaimerHeader, HelpIcon } from './style'

interface IWarningBubble {
  header: string
  body: ReactNode
  className?: string
}

const WarningBubble = ({ className, header, body }: IWarningBubble) => (
  <Row
    className={`rounded py-1 px-3 d-flex flex-wrap ${className}`}
    style={{
      backgroundColor: '#EEEAF7'
    }}
  >
    <HelpIcon
      data-testid="about-tooltip-trigger"
      className="icon icon-question-mark"
    />
    <Col>
      <DisclaimerHeader>{header}</DisclaimerHeader>
      <DisclaimerBody>{body}</DisclaimerBody>
    </Col>
  </Row>
)

export default WarningBubble
