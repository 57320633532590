import styled from 'styled-components'

export const FaqHeader = styled.h2`
  color: var(--global-08-dark, #343a40);
  /* Body/Paragraph + Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
`

export const FaqTitle = styled.strong`
  color: #171725;
  /* Heading/H5 */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
`

export const FaqDetails = styled.span`
  color: var(--text-02-muted, #6c757d);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
