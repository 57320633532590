import React from 'react'
import { Modal, Button } from 'react-angle-dashboard-components'

export const DisabledDependentModal: React.FC<{
  onCancel: Function
  onContinue: Function
  show: boolean
}> = ({ onCancel, onContinue, show }) => {
  return (
    <Modal
      style={{ zIndex: 99999 }}
      show={show}
      contentClassName="border"
      size="lg"
    >
      <Modal.Header className="border-bottom pb-3">
        <h5>Confirmation for a child 26 years or older</h5>
      </Modal.Header>
      <Modal.Body>
        <h6 className="pb-3">
          You’ve entered information for a child over the age of 26. Please
          confirm the dependent is unmarried and meets the definition of
          “disabled” as that term is used in our policy.
        </h6>
        <h6 className="pb-3">That means the dependent is:</h6>
        <ul>
          <li className="pb-3">
            Unable to engage in substantial gainful employment to the degree
            they can achieve economic independence due to medically determinable
            physical or mental impairment which can be expected to last for a
            continuous period of not less than 12 months or result in death;
          </li>
          <li className="pb-3">
            Chiefly dependent upon you or your lawful spouse for support and
            maintenance since they reached age 26; and
          </li>
          <li>
            Have been continuously enrolled in some form of healthcare coverage,
            with no break in coverage of more than 63 days since the date they
            reached age 26.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between py-3">
        <Button onClick={onCancel} variant="outline-primary">
          Cancel
        </Button>
        <Button onClick={onContinue}>I understand</Button>
      </Modal.Footer>
    </Modal>
  )
}
