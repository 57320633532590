import React, { useEffect } from 'react'
import { Button } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { LoginPageContainer, StyledCard, Header } from '../../style'
import { memberService, ApiService } from '../../../../services'

export const CreateAccountSuccess = () => {
  const history = useHistory()
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    ApiService._token = token
  }, [token])

  const { data } = useQuery(['getMember', token], () =>
    memberService.getMemberInfo()
  )

  const loginEmail = data?.contact_info?.home_email
  const memberId = data?.member_id
  const onClick = () => {
    // clear token and member data
    sessionStorage.clear()
    localStorage.clear()
    history.push('/login')
  }

  return (
    <LoginPageContainer>
      <StyledCard className="p-5">
        <div className="text-success text-center">
          <i className="icon icon-correct" style={{ fontSize: 30 }} />
        </div>
        <Header>
          Congratulations, your password has been set and your account is
          created with{' '}
          <span className="text-secondary">{memberId?.substring(0, 10)}</span>
          {loginEmail && (
            <span>
              {' '}
              or <span className="text-secondary">{loginEmail}</span>
            </span>
          )}
        </Header>
        <Button className="mt-4" onClick={() => onClick()}>
          Log in to your member experience
        </Button>
      </StyledCard>
    </LoginPageContainer>
  )
}
