// TODO add interfaces
import { unformatPhoneNumber, DateHandler } from '@angle/utils'

const mapDependent = (dependent: any) => {
  return {
    memberId: dependent?.member_id,
    firstName: dependent?.name?.first_name,
    middleName: dependent?.name?.middle_name,
    lastName: dependent?.name?.last_name,
    suffix: dependent?.name?.suffix,
    isSpouse: dependent?.relationship === 'spouse' ? 'yes' : 'no',
    personalInfo: {
      memberId: dependent?.member_id,
      firstName: dependent?.name?.first_name,
      middleName: dependent?.name?.middle_name,
      lastName: dependent?.name?.last_name,
      suffix: dependent?.name?.suffix,
      dateOfBirth: DateHandler.formatForClient(dependent?.date_of_birth),
      workEmail: dependent?.contact_info.work_email,
      personalEmail: dependent?.contact_info.home_email,
      personalPhoneNumber: dependent?.contact_info.home_phone,
      gender:
        dependent?.gender && dependent?.gender !== 'unknown'
          ? dependent?.gender
          : '',
      identifiedGender:
        dependent?.identified_gender &&
        dependent?.identified_gender !== 'unknown'
          ? dependent?.identified_gender
          : '',
      id: dependent?.member_id,
      isSpouse: dependent?.relationship === 'spouse' ? 'yes' : 'no',
      workPhoneNumber: dependent?.contact_info.work_phone,
      relationship: dependent?.relationship,
      ssn: dependent?.ssn,
      state: dependent?.home_address.state,
      streetAddress: dependent?.home_address.street_1,
      streetAddress2: dependent?.home_address.street_2,
      zipCode: dependent?.home_address.zip,
      city: dependent?.home_address.city
    },
    carrierInfo: {
      coverageContinue: dependent?.current_carrier?.status === 'active',
      dateOfCoverage: {
        endAt: dependent?.current_carrier?.medical_term_date,
        startAt: dependent?.current_carrier?.medical_start_date
      },
      insurer: dependent?.current_carrier?.medical_carrier,
      policyNumber: dependent?.current_carrier?.medical_policy_number,
      typesOfCoverage: dependent?.current_carrier?.medical_coverage_types || []
    }
  }
}

export const mapDependentsToList = (data: any) => {
  return data?.dependents?.map((d: any) => mapDependent(d))
}

export const mapMemberToDependentsList = (data: any) => {
  return {
    city: data?.home_address?.city,
    streetAddress: data?.home_address?.street_1,
    streetAddressLine2: data.home_address.street_2,
    state: data.home_address.state,
    zipCode: data.home_address.zip,
    personalEmail: data.contact_info.home_email || '',
    personalPhoneNumber: unformatPhoneNumber(
      data.contact_info.home_phone as string
    ),
    serverStep: data.in_progress_state,
    inRenewal: data?.in_renewal
  }
}

export const mapDependentToServer = (data: any, member: any) => {
  let home_address = {}
  if (data?.personalInfo?.streetAddress) {
    home_address = {
      state: data.personalInfo.state,
      street_1: data.personalInfo.streetAddress,
      street_2: data.personalInfo.streetAddress2,
      city: data.personalInfo.city,
      zip: data.personalInfo.zipCode
    }
  } else if (member) {
    home_address = {
      state: member?.state,
      street_1: member?.streetAddress,
      street_2: member?.streetAddressLine2,
      city: member?.city,
      zip: member?.zipCode
    }
  }

  return {
    name: {
      first_name: data.personalInfo.firstName,
      middle_name: data.personalInfo.middleName,
      last_name: data.personalInfo.lastName,
      suffix: data.personalInfo.suffix
    },
    has_disabilities: data?.isDisabled ? true : null,
    ssn: unformatPhoneNumber(data.personalInfo.ssn),
    gender: data.personalInfo.gender,
    identified_gender: data.personalInfo.identifiedGender,
    contact_info: {
      work_phone: unformatPhoneNumber(data.personalInfo.workPhoneNumber),
      work_email: data.personalInfo.workEmail,
      home_email: data.personalInfo.personalEmail,
      home_phone: unformatPhoneNumber(data.personalInfo.personalPhoneNumber)
    },
    relationship: data.personalInfo.isSpouse === 'yes' ? 'spouse' : 'child',
    date_of_birth: DateHandler.formatForServer(
      Array.isArray(data.personalInfo.dateOfBirth)
        ? (data.personalInfo.dateOfBirth[0] as string)
        : data.personalInfo.dateOfBirth
    ),
    home_address,
    current_carrier: data.healthInsurance
      ? {
          status: data.carrierInfo?.coverageContinue ? 'active' : 'terminated',
          medical_coverage_types: data.carrierInfo?.typesOfCoverage,
          medical_carrier: data.carrierInfo?.insurer || '',
          medical_policy_number: data.carrierInfo?.policyNumber || '',
          medical_start_date: DateHandler.formatForServer(
            Array.isArray(data.carrierInfo?.dateOfCoverage?.startAt)
              ? data.carrierInfo?.dateOfCoverage?.startAt[0]
              : data.carrierInfo?.dateOfCoverage?.startAt
          ),
          medical_term_date: DateHandler.formatForServer(
            Array.isArray(data.carrierInfo?.dateOfCoverage?.endAt)
              ? data.carrierInfo?.dateOfCoverage?.endAt[0]
              : data.carrierInfo?.dateOfCoverage?.endAt
          )
        }
      : undefined
  }
}
