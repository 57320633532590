import { DateHandler } from '@angle/utils'

export const mapWaiveCoverageToDTO = (data: any) => {
  const DTO: any = {
    waived: true,
    waive_reason: data.reason_other || data.reason,
    waive_policy_holder_name: data.name,
    waive_insurer_name: data.insurer,
    waive_phone_number: data.phone.split('-').join(''),
    waive_coverage_continue: data.will_coverage_continue === 'Yes',
    waive_date: DateHandler.formatForServer(new Date()),
    dependents: []
  }
  return DTO
}
