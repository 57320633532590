import React from 'react'
import { Button, useQueryParams } from 'react-angle-dashboard-components'
import { Paths } from '../../../../../routes/paths'
import { useHistory, Redirect } from 'react-router-dom'
import { Footer } from '../../../style'
import { onboardingPaths } from '../../../Router'
import { Vision } from './Benefits/Vision/Vision'
import { benefitsMap } from 'react-angle-dashboard-components'
import { Dental } from './Benefits/Dental/Dental'
import { Accidental } from './Benefits/Accidental/Accidental'
import { Identity } from './Benefits/Identity/Identity'
import { BasicLife } from './Benefits/BasicLife/BasicLife'
import { Critical } from './Benefits/Critical/Critical'
import { Hospital } from './Benefits/Hospital/Hospital'

export const BenefitDetails = () => {
  const history = useHistory()

  const voluntaryPath = Paths.onboarding + onboardingPaths.voluntary

  const params = useQueryParams()
  const benefitKey = params.get('benefit')

  if (!benefitsMap[benefitKey || '']) {
    return <Redirect to={voluntaryPath} />
  }

  const handleBack = () => {
    history.push(voluntaryPath)
  }

  return (
    <div>
      {benefitKey === 'vision' && <Vision />}
      {benefitKey === 'dental' && <Dental />}
      {benefitKey === 'accidental' && <Accidental />}
      {benefitKey === 'identity' && <Identity />}
      {benefitKey === 'basic_life_add' && <BasicLife />}
      {benefitKey === 'critical_illness' && <Critical />}
      {benefitKey === 'hospital_indemnity' && <Hospital />}
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </Footer>
    </div>
  )
}
