import React, { useEffect, useState } from 'react'

import { HomeLayout } from '../layouts/HomeLayout'
import {
  FormContainer,
  HeaderText,
  Inline,
  InlineSpace,
  PhotoBackground,
  ResultHeader,
  SearchBarLabel,
  SubHeaderText
} from './style'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import {
  Button,
  CenteredSpinner,
  Dropdown,
  Form
} from 'react-angle-dashboard-components'
import ListItem from './ListItem'

import avatarIcon from './avatar.png'
import facilityIcon from './facility.png'

import { Redirect, useHistory } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { useComparePrices } from '../../context/ComparePrices'
import { useQuery } from 'react-query'
import { priceTransparencyService } from '../../services'
import { IProviderTransparency } from '../../services/api/priceTransparency'

const costSort = (a: any, b: any) =>
  a.cost_before_benefits - b.cost_before_benefits

const distanceSort = (a: any, b: any) => a.dist - b.dist

export const ComparePricesProviderSearch = () => {
  const history = useHistory()
  const {
    service,
    provider,
    setProvider,
    placeOfService,
    coordsData: { lat, lon } = {}
  } = useComparePrices()

  const [providerSearchString, setProviderSearchString] = useState('')

  const baseQueryParams = {
    cpt: service?.['Code (1)'],
    pos: placeOfService?.value
  }

  const { data: providerList = [], isLoading: isProviderListLoading } =
    useQuery(['inNetworkProviders'], () => {
      if (lat && lon) {
        return priceTransparencyService.getInNetworkProviders({
          ...baseQueryParams,
          lat,
          lon
        })
      }
    })

  const { data: searchResults = [], isLoading: isSearchResultsLoading } =
    useQuery(['inNetworkProvidersSearch', providerSearchString], () => {
      if (lat && lon && providerSearchString.length > 0) {
        return priceTransparencyService
          .getInNetworkProviders({
            ...baseQueryParams,
            lat,
            lon,
            provider: providerSearchString
          })
          .then((res) => res.map((r) => ({ ...r, id: r.npi })))
      }
    })

  const [sortedResults, setSortedResults] = useState(
    [...providerList].sort(costSort)
  )

  useEffect(() => {
    if (providerList.length > 0) {
      setSortedResults([...providerList].sort(costSort))
    }
  }, [providerList])

  const formatResult = (itm: IProviderTransparency) => {
    return (
      <Inline>
        <img src={avatarIcon} alt="avatar" />
        <p>{itm.full_name}</p>
      </Inline>
    )
  }

  const canContinue = () => {
    return !!provider
  }

  const onSearch = (input: string) => {
    setProviderSearchString(input)
  }

  const onSearchResultSelect = (result: IProviderTransparency) => {
    setProvider(result)
  }

  const onSortByPrice = () => {
    setSortedResults([...providerList].sort(costSort))
  }

  const onSortByDistance = () => {
    setSortedResults([...providerList].sort(distanceSort))
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()

    history.push(Paths.comparePricesResult)
  }

  if (!service) {
    return <Redirect to={Paths.comparePrices} />
  }

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Select Service', link: Paths.comparePrices },
        { title: 'Select Provider', link: Paths.comparePricesProviderSearch }
      ]}
    >
      <PhotoBackground>
        <HeaderText>Get a price estimate for your care</HeaderText>
        <SubHeaderText>
          Search for the provider who will perform the service below to get a
          personalized price estimate.
        </SubHeaderText>
        <div className="w-50">
          <SearchBarLabel>Provider</SearchBarLabel>
          <ReactSearchAutocomplete
            key={searchResults.toString()}
            items={searchResults}
            fuseOptions={{
              keys: ['full_name']
            }}
            resultStringKeyName="full_name"
            formatResult={formatResult}
            styling={{
              fontFamily: 'SF-Pro',
              fontSize: '18px',
              placeholderColor: '#6c757d',
              iconColor: '#6c757d',
              borderRadius: '0.3125rem',
              clearIconMargin: '-5px 16px 0 0',
              zIndex: 10
            }}
            autoFocus
            onSearch={onSearch}
            inputDebounce={500}
            placeholder={'Search providers'}
            onSelect={onSearchResultSelect}
            inputSearchString={providerSearchString}
            showNoResultsText={
              isSearchResultsLoading ? 'Loading...' : 'No results'
            }
          />
        </div>
      </PhotoBackground>
      <FormContainer
        style={{
          paddingBottom: 200
        }}
      >
        <Form onSubmit={onFormSubmit}>
          {isProviderListLoading || providerList.length === 0 ? (
            <CenteredSpinner />
          ) : (
            <div>
              <InlineSpace>
                <ResultHeader>20 Providers for your search</ResultHeader>
                <Dropdown>
                  <Dropdown.Toggle>Sort</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={onSortByPrice}>
                      Sort by price
                    </Dropdown.Item>
                    <Dropdown.Item onClick={onSortByDistance}>
                      Sort by distance
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </InlineSpace>
              <div className="mt-3">
                {sortedResults.map((res) => (
                  <div onClick={() => setProvider(res)}>
                    <ListItem
                      title={
                        res.is_provider ? res.full_name : res.facility_name
                      }
                      description={`${res.address}\n${res.truncDist} miles away`}
                      cost={res.cost_before_benefits}
                      selected={provider?.npi === res.npi}
                      icon={res.is_provider ? avatarIcon : facilityIcon}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <div
            style={{
              position: 'fixed',
              bottom: 0,
              left: -4,
              right: 0,
              backgroundColor: 'white',
              borderTop: '2px solid #E7EAF3',
              padding: 10,
              paddingLeft: '20%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <div>
              <Button type="submit" disabled={!canContinue()}>
                Continue
              </Button>
            </div>
            {provider && (
              <ListItem
                title={
                  provider.is_provider
                    ? provider.full_name
                    : provider.facility_name
                }
                description={`${provider.address}\n${provider.truncDist} miles away`}
                cost={provider.cost_before_benefits}
                icon={provider.is_provider ? avatarIcon : facilityIcon}
              />
            )}
          </div>
        </Form>
      </FormContainer>
    </HomeLayout>
  )
}

export default ComparePricesProviderSearch
