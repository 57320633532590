export const hospitalPlans = {
  lowPlan: {
    admission_benefit: '$1,000',
    icu_admission_supplemental: '$1,000',
    confinement_benefit: 100,
    health_screening_benefit: 100
  },
  highPlan: {
    admission_benefit: '$1,500',
    icu_admission_supplemental: '$1,500',
    confinement_benefit: 100,
    health_screening_benefit: 150
  }
}
