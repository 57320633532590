import React from 'react'
import { PageContainer, Row } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { claimsService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'
import { ClaimSummary } from './ClaimSummary'
import { Header } from './Header'
import { Details } from './Details'

export const RxClaimsDetail: React.FC = () => {
  const history = useHistory()

  const { id } = useParams<{ id: string }>()

  const { data, isLoading, isError } = useQuery(['getRxClaimById', id], () => {
    return claimsService.getRXClaimById(id)
  })

  if (isError) {
    history.replace(Paths.notFound)
  }

  const oopmMet = false // not MVP
  const dedMet = false // not MVP

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Claims', link: '/claims' },
        { title: data?.claimNumber || '', link: `/rxclaims/${id}` }
      ]}
    >
      <Header
        data={data}
        isLoading={isLoading}
        oopmMet={oopmMet}
        dedMet={dedMet}
      />
      <PageContainer size="xl" className="p-3 pb-5">
        <Row>
          <Details data={data} isLoading={isLoading} />
          <ClaimSummary
            data={data}
            isLoading={isLoading}
          />
        </Row>
      </PageContainer>
    </HomeLayout>
  )
}