import React, { useState } from 'react'
import { Col, Container, Row } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { HomeLayout } from '../layouts/HomeLayout'
import DownloadAppModal from './DownloadAppModal'
import PharmCard from './PharmCard'
import {
  AdditionalResourcesHeader,
  CheckIcon,
  Header,
  MajorPharmHeader,
  MajorPharmInfo,
  MajorPharmText
} from './style'

const PharmacyBenefits = () => {
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Pharmacy', link: '/drugs' }
      ]}
    >
      <Container className="d-flex justify-content-sm-center">
        <DownloadAppModal
          show={showModal}
          requestClose={() => setShowModal(false)}
        />
        <Col sm={12}>
          <Header>Your Pharmacy Benefits</Header>
          <Row>
            <PharmCard
              image="/pill.png"
              title="Explore my Drug Coverage"
              text="Visit the formulary website to discover which drugs are covered by Angle"
              buttonText="Prescription Search"
              onButtonClick={() => {
                window.open(
                  'https://caprx.adaptiverx.com/webSearch/index?key=8F02B26A288102C27BAC82D14C006C6FC54D480F80409B6860374AA7ED985B45',
                  '_blank'
                )
              }}
            />
            <PharmCard
              image="/pin.png"
              title="Find a Pharmacy Near me"
              text="Download the Capital Rx application to explore pharmacies nearby"
              buttonText="Download the Capital Rx mobile app"
              buttonVariant="outline-primary"
              onButtonClick={() => setShowModal(true)}
            />
            <PharmCard
              image="/house.png"
              title="Receive Prescriptions at Home"
              text="Follow these instructions to have prescriptions delivered to your home"
              buttonText="Sign up to receive prescriptions at home"
              buttonVariant="outline-primary"
              onButtonClick={() => history.push(Paths.prescriptionsAtHome)}
            />
          </Row>
          <Col
            className="px-4 pt-4 pb-2 my-5"
            style={{ backgroundColor: '#F8F9FA' }}
          >
            <Col>
              <Row className="mb-2">
                <CheckIcon src="/check-circle.png" />
                <MajorPharmHeader>
                  Major pharmacies in your network
                </MajorPharmHeader>
              </Row>
            </Col>
            <MajorPharmText>
              As a Angle Health member, you have access to your prescriptions
              through{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://assets.website-files.com/62a8d34ea6c525655dc2b65b/62c5ddf88dea0b4bc35f0440_NationalPharmacyChainListing_20220124.pdf"
                style={{ textDecoration: 'underline' }}
              >
                a number of popular pharmacies.
              </a>
              <br />
              These include but are not limited to:
            </MajorPharmText>
            <Col>
              <Row className="py-5 justify-content-between col-auto">
                <Col>
                  <img
                    src="/walmart.png"
                    style={{
                      width: 102,
                      height: 24
                    }}
                    alt="walmart"
                  />
                </Col>
                <Col>
                  <img
                    src="/walgreens.png"
                    style={{
                      width: 105,
                      height: 29
                    }}
                    alt="walgreens"
                  />
                </Col>
                <Col>
                  <img
                    src="/cvs.png"
                    style={{
                      width: 68,
                      height: 33
                    }}
                    alt="cvs"
                  />
                </Col>
                <Col>
                  <img
                    src="/costco.png"
                    style={{
                      width: 98,
                      height: 34
                    }}
                    alt="walgreens"
                  />
                </Col>
              </Row>
            </Col>
            <MajorPharmInfo>
              If you don't have your ID card, show the pharmacy this
              information:
            </MajorPharmInfo>
            <MajorPharmInfo>
              RX BIN: 610852
              <br />
              RX PCN: CHM
              <br />
              RX Group: ANGLE
            </MajorPharmInfo>
          </Col>
          <AdditionalResourcesHeader>
            Additional Resources
          </AdditionalResourcesHeader>
          <ul className="pl-4">
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://assets.website-files.com/62a8d34ea6c525655dc2b65b/637d288dacb252a86d7e37b2_CapitalRx_Judi_General_PA_Form_11.22.pdf"
                style={{ textDecoration: 'underline' }}
              >
                Prescription Drug Prior Authorization Form
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://assets.website-files.com/62a8d34ea6c525655dc2b65b/63a1dd3856f8a5c79631b136_CL_PrescriptionDrugClaimForm_20221018_fillable.pdf"
                style={{ textDecoration: 'underline' }}
              >
                Prescription Drug Reimbursement Form
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://angle-public-documents.s3.amazonaws.com/cap-rx/Capital+Rx+Welcome+Flyer.pdf"
                style={{ textDecoration: 'underline' }}
              >
                Capital Rx Welcome Flyer
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://angle-public-documents.s3.amazonaws.com/cap-rx/Capital+Rx+FAQs.pdf"
                style={{ textDecoration: 'underline' }}
              >
                Capital Rx FAQ
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://angle-public-documents.s3.amazonaws.com/cap-rx/Captial+Rx+Digital+Tools.pdf"
                style={{ textDecoration: 'underline' }}
              >
                Capital Rx Digital Tools
              </a>
            </li>
          </ul>
        </Col>
      </Container>
    </HomeLayout>
  )
}

export default PharmacyBenefits
