import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import {
  CompletedPage,
  DependentAndProviderForm,
  ProviderFormPage
} from './DependentProviders'

export default function MemberSurvey() {
  return (
    <Switch>
      <Route
        exact
        path={Paths.memberSurveys + '/dependents-providers'}
        component={DependentAndProviderForm}
      />
      <Route
        exact
        path={Paths.memberSurveys + '/providers'}
        component={ProviderFormPage}
      />
      <Route
        exact
        path={Paths.memberSurveys + '/dependents-providers/completed'}
        component={CompletedPage}
      />

      <Route
        exact
        path={Paths.memberSurveys + '/providers/completed'}
        component={CompletedPage}
      />

      <Redirect to={Paths.memberSurveys + '/dependents-providers'} />
    </Switch>
  )
}
