export const dentalPlans: { [key: string]: { deductibleText: string } } = {
  ADPPOH0001: {
    deductibleText: 'This deductible is waived for Type I services.'
  },
  ADPPOH0002: {
    deductibleText: 'This deductible is waived for Type I services.'
  },
  ADPPOL0001: {
    deductibleText: 'This deductible is waived for preventive services.'
  },
  ADPPOL0002: {
    deductibleText: 'This deductible is waived for preventive services.'
  }
}
