import React from 'react'

export const MenuToggle = React.forwardRef<any, any>((props, ref) => (
  <i
    role="button"
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      props.onClick(e)
    }}
    className="icon icon-more text-secondary fs-14"
  />
))
