import { ApiService } from 'react-angle-dashboard-components'

export class SurveyService extends ApiService {
  async getDependents() {
    return this.request({
      method: 'get',
      url: '/surveys/dependents'
    })
  }

  async updateDependents(dependents: any) {
    return this.request({
      method: 'put',
      url: '/surveys/dependents',
      data: {
        dependents
      }
    })
  }

  async getProviders() {
    return this.request({
      method: 'get',
      url: '/surveys/providers-medications'
    })
  }

  async updateProviders(providers: any) {
    return this.request({
      method: 'put',
      url: '/surveys/providers-medications',
      data: {
        providers
      }
    })
  }
}
