import React from 'react'
import {
  Col
} from 'react-angle-dashboard-components'
import PlanWidget from './PlanWidget'
import { mapDTOtoPlans } from '../../services/api/coverage/mappers'
import { Updates } from './Updates'



export interface IUpdatesProps {
  isRenewal: boolean
  isLoading: boolean
  cardType: string
  formularyIndicator: string | undefined | null
}

interface IPlanWidgetProps {
  hidePlanInfo: boolean
  metalTier?: string
  planName?: string
  memberSinceDate?: string
  memberId: string
  isMobile?: boolean
}

interface IMainContentContainerProps {
  updatesProps: IUpdatesProps
  planWidgetProps: IPlanWidgetProps
  planData?: ReturnType<typeof mapDTOtoPlans>
}


const MainContentContainer = ({ updatesProps, planWidgetProps, planData }: IMainContentContainerProps) => {

  return (
    <Col md="8">
      {planData && <PlanWidget {...{...planWidgetProps, planData: planData} } />}
      
      <Updates
        {...updatesProps}
      />
    </Col>
  )
}

export default MainContentContainer