import styled from 'styled-components'

export const PageHeader = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  /* identical to box height, or 120% */

  /* Text/01. Body */
  color: #212529;

  margin-top: 20px;
`

export const PageContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  /* Text/02. Muted */
  color: #6c757d;
`

export const AccordionHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* or 120% */

  /* Text/01. Body */
  color: #212529;

  padding: 0px;
  margin: 0px;

  user-select: none;
`

export const AccordionContent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  /* or 136% */
  letter-spacing: -0.01em;

  /* Text/02. Muted */
  color: #6c757d;
`

export const NumberContainer = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;

  background: #eeeaf7;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Global/01. Primary */
  color: #6d39d9;
`

export const StepTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  /* Text/01. Body */
  color: #212529;
`

export const StepContent = styled.div`
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  /* Text/01. Body */
  color: #6c757d;
`
