import styled from 'styled-components'
import { Button, Card } from 'react-angle-dashboard-components'

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 480px;
`

export const LogoWrapper = styled.div`
  max-width: 307px;
  margin: 0 auto;
`

// not used on initial login page
export const LoginPageContainer = styled.div`
  background: #f6f2ec;
  display: flex;
  flex-direction: column;
  padding: 40px;
  padding-bottom: 200px;
  align-items: center;
  min-height: 100vh;
`

export const GoBackButton = styled(Button)`
  font-weight: 400;
`

export const OutlineButton = styled(Button)`
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
`

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  min-width: 200px;
`

export const Header = styled.h1`
  font-size: 26px;
  text-align: center;
  margin-top: 10px;
`

export const Subheader = styled.span`
  color: var(--secondary);
  text-align: center;
  display: block;
  padding: 5px 15px 25px 15px;
`
