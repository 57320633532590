import * as React from 'react'
import { SVGProps } from 'react'

const UserSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 14"
    fill="currentColor"
    {...props}
  >
    <g id="User">
      <path
        id="User_2"
        d="M6.50058 0C4.54221 0 2.95496 1.63496 2.95496 3.65217V4.26087C2.95496 6.27809 4.54221 7.91304 6.50058 7.91304C8.45894 7.91304 10.0462 6.27809 10.0462 4.26087V3.65217C10.0462 1.63496 8.45894 0 6.50058 0ZM6.49942 9.73913C4.13213 9.73913 1.09472 11.0581 0.220725 12.2286C-0.31939 12.9523 0.19477 14 1.08058 14H11.9194C12.8052 14 13.3194 12.9523 12.7793 12.2286C11.9053 11.0587 8.86671 9.73913 6.49942 9.73913Z"
      />
    </g>
  </svg>
)

export default UserSolid
