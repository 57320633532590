import React, { useContext, useEffect, useState } from 'react'
import {
  CenteredSpinner,
  Button,
  Card,
  Col,
  Form,
  PageContainer,
  Row,
  useServerPagination
} from 'react-angle-dashboard-components'
import Cookies from 'js-cookie'

import { useQuery } from 'react-query'
import { currencyFormatter, paymentSourceObject, DateHandler, PaymentSource } from '@angle/utils'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { AuthContext } from '../../context/Auth/Auth'
import { claimsService } from '../../services'
import { GetUnifiedClaimsParams, IProvidersMembersDTO } from '../../interfaces/claims'
import { HomeLayout } from '../layouts/HomeLayout'
import { useQueryParams } from '@angle/hooks'
import ClaimsTable from './ClaimsTable/ClaimsTable'

import { FilterSection, FilterSectionHeader } from './style'

import 'react-datepicker/dist/react-datepicker.css'
import './Claims.css'

export const Claims = () => {
  const [claimTypeFilter, setClaimTypeFilter] = useState<string | null>(null)
  const [dateRangeFilter, setDateRangeFilter] = useState<string | null>(null)
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null)
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null)
  const [providerIdFilter, setProviderIdFilter] = useState<string>('')
  const [memberIdFilter, setMemberIdFilter] = useState<string>('')
  const [networkFilter, setNetworkFilter] = useState<string | null>(null)
  const [paymentSourceFilter, setPaymentSourceFilter] = useState<string>('')
  const [filtersActiveFlag, setFiltersActiveFlag] = useState<boolean>(false)
  const [members, setMembers] = useState<IProvidersMembersDTO[]>([])
  const [providers, setProviders] = useState<IProvidersMembersDTO[]>([])
  const [width, setWidth] = useState<number>(window.innerWidth)

  const { loginWithPassedCookie } = useContext(AuthContext)

  const params = useQueryParams()
  const token = params.get('token') || ''

  const { isLoading: isTempAuthLoading, isError } = useQuery(
    ['loginWithToken', token],
    () =>  {
      Cookies.set("passed_access_token", token)
      loginWithPassedCookie()
    },
    {
      enabled: !!token,
    }
  )

  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, [])

  const isMobile = width <= 768;

  const {
    paginationConfig,
    queryResult: { data: claimData, isLoading: isClaimsLoading }
  } = useServerPagination<ReturnType<typeof claimsService.getUnifiedClaims>>(
    [
      'getUnifiedClaims',
      claimTypeFilter,
      dateRangeFilter,
      startDateFilter,
      endDateFilter,
      providerIdFilter,
      memberIdFilter,
      networkFilter,
      paymentSourceFilter,
    ],
    ({ pageLimit, pageNumber }) => {
      const params: GetUnifiedClaimsParams = {
        provider_name: providerIdFilter,
        member_id: memberIdFilter,
        type: claimTypeFilter,
        payment_source: paymentSourceFilter
      }

      if (dateRangeFilter !== 'custom') {
        params.date_filter = dateRangeFilter
      } else if (startDateFilter && endDateFilter) {
        params.start_date = moment(startDateFilter).format('YYYY-MM-DD')
        params.end_date = moment(endDateFilter).format('YYYY-MM-DD')
      }

      if (networkFilter === 'inn') {
        params.in_network = 1
      } else if (networkFilter === 'oon') {
        params.in_network = 0
      }
      return claimsService.getUnifiedClaims(params)
    },
    {
      queryOpts: { enabled: !isError && ((!!token && !isTempAuthLoading) || !token ) }
    }
  )

  useEffect(() => {
    const areFiltersActive = () => {
      if (claimTypeFilter !== null) {
        return true;
      }
  
      if (dateRangeFilter !== null) {
        return true;
      }
  
      if (providerIdFilter !== '') {
        return true;
      }
  
      if (memberIdFilter !== '') {
        return true;
      }
  
      if (networkFilter !== null) {
        return true;
      }
  
      return false
    }

    if (areFiltersActive() && !filtersActiveFlag) {
      setFiltersActiveFlag(true)
    } else if (!areFiltersActive() && filtersActiveFlag) {
      setFiltersActiveFlag(false)
    }
  }, [claimTypeFilter, dateRangeFilter, providerIdFilter, memberIdFilter, networkFilter, filtersActiveFlag])

  useEffect(() => {
    if (claimData?.filters.members.length && members.length === 0) {
      setMembers(claimData.filters.members)
    }

    if (claimData?.filters.providers.length && providers.length === 0) {
      setProviders(claimData.filters.providers)
    }
  }, [claimData, providers, members])

  const onClearAll = () => {
    setClaimTypeFilter(null)
    setDateRangeFilter(null)
    setStartDateFilter(null)
    setEndDateFilter(null)
    setProviderIdFilter('')
    setMemberIdFilter('')
    setNetworkFilter(null)
  }

  if (isTempAuthLoading) {
    return (
      <div style={{ marginTop: 100 }}>
        <CenteredSpinner />
      </div>
    )
  }

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Claims', link: '/claims/medical' }
      ]}
    >
      <div
        style={{
          height: '100%',
          overflow: 'auto',
          backgroundColor: '#F9F9FC'
        }}
      >
        <div
          className="border-bottom py-3 bg-white"
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
        >
          <PageContainer size="xl">
            <h1>Claims</h1>
            <h6 className="text-muted">
              Claims History and Explanation of Benefits
            </h6>
          </PageContainer>
        </div>
        <PageContainer size="xl" className="py-5 gy-3">
          <Row className="nowrap flex-nowrap">
            {!isMobile && (<Col xs={3}>
              <Card>
                <Card.Header style={{
                  paddingLeft: '16px',
                  paddingRight: '16px'
                }}>
                  <FilterSectionHeader style={{ marginBottom: 0 }}>Filters</FilterSectionHeader>
                  <Button variant="link" onClick={onClearAll} style={{ padding: 0 }}>
                    Clear All
                  </Button>
                </Card.Header>
                <Card.Body className="p-0">
                  <FilterSection>
                    <FilterSectionHeader>Claim Type</FilterSectionHeader>
                    <Form.Check
                      inline
                      className="mr-7"
                      type="radio"
                      label="Medical"
                      value="medical"
                      checked={claimTypeFilter === "medical"}
                      onChange={(e) => setClaimTypeFilter(e.target.value)}
                    />
                    <Form.Check
                      inline
                      className="mr-7"
                      type="radio"
                      label="Rx"
                      value="pharmacy"
                      checked={claimTypeFilter === "pharmacy"}
                      onChange={(e) => setClaimTypeFilter(e.target.value)}
                    />
                  </FilterSection>
                  <FilterSection>
                    <FilterSectionHeader>Date Range</FilterSectionHeader>
                    <Form.Check
                      className="mr-7"
                      type="radio"
                      label="Year-to-date"
                      value="ytd"
                      checked={dateRangeFilter === "ytd"}
                      onChange={(e) => setDateRangeFilter(e.target.value)}
                    />
                    <Form.Check
                      className="mr-7"
                      type="radio"
                      label="Plan Year"
                      value="py"
                      checked={dateRangeFilter === "py"}
                      onChange={(e) => setDateRangeFilter(e.target.value)}
                    />
                    <Form.Check
                      className="mr-7"
                      type="radio"
                      label="Custom Range"
                      value="custom"
                      checked={dateRangeFilter === "custom"}
                      onChange={(e) => setDateRangeFilter(e.target.value)}
                    />
                    {dateRangeFilter === 'custom' && (
                      <div className="mt-2">
                        <Form.Group>
                          <DatePicker
                            selected={startDateFilter}
                            onChange={(date) => setStartDateFilter(date)}
                            selectsStart
                            startDate={startDateFilter}
                            endDate={endDateFilter}
                            placeholderText="Start Date"
                          />
                          <DatePicker
                            className="datepicker-marginTop"
                            selected={endDateFilter}
                            onChange={(date) => setEndDateFilter(date)}
                            selectsEnd
                            startDate={startDateFilter}
                            endDate={endDateFilter}
                            minDate={startDateFilter}
                            placeholderText="End Date"
                          />
                        </Form.Group>
                      </div>
                    )}
                  </FilterSection>
                  <FilterSection>
                    <FilterSectionHeader>Provider</FilterSectionHeader>
                    <Form.Control
                      as="select"
                      onChange={(e) => setProviderIdFilter(e.target.value)}
                      value={providerIdFilter}
                      >
                      <option value="" disabled hidden>
                        Search by name
                      </option>
                      {providers.map((filterKV) => {
                        const key = Object.keys(filterKV)[0]

                        return (
                          <option value={filterKV[key]}>{key}</option>
                        )
                      })}
                    </Form.Control>
                  </FilterSection>
                  <FilterSection>
                    <FilterSectionHeader>Member</FilterSectionHeader>
                    <Form.Control
                      as="select"
                      onChange={(e) => setMemberIdFilter(e.target.value)}
                      value={memberIdFilter}
                      >
                      <option value="">All</option>
                      {members.map((filterKV) => {
                        const key = Object.keys(filterKV)[0]

                        return (
                          <option value={key}>{filterKV[key]}</option>
                        )
                      })}
                    </Form.Control>
                  </FilterSection>
                  <FilterSection noBorder>
                    <FilterSectionHeader>Network</FilterSectionHeader>
                    <Form.Check
                      className="mr-7"
                      type="radio"
                      label="In-Network"
                      value="inn"
                      checked={networkFilter === "inn"}
                      onChange={(e) => setNetworkFilter(e.target.value)}
                    />
                    <Form.Check
                      className="mr-7"
                      type="radio"
                      label="Out-of-Network"
                      value="oon"
                      checked={networkFilter === "oon"}
                      onChange={(e) => setNetworkFilter(e.target.value)}
                    />
                  </FilterSection>
                  <FilterSection>
                    <FilterSectionHeader>Payment Type</FilterSectionHeader>
                    <Form.Control
                      as="select"
                      onChange={(e) => setPaymentSourceFilter(e.target.value)}
                      value={paymentSourceFilter}
                    >
                      <option value="">All</option>
                      {Object.keys(paymentSourceObject).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {paymentSourceObject[key as PaymentSource]}
                          </option>
                        )
                      })}
                    </Form.Control>
                  </FilterSection>
                </Card.Body>
              </Card>
            </Col>)}
            <Col
              style={{
                width: 0
              }}
            >
              <ClaimsTable
                data={claimData?.claims.map((claim) => {
                  return {
                    claimId: claim.pk,
                    dateOfService: DateHandler.formatForClient(
                      claim?.date_of_service || claim?.service_date
                    ),
                    status: claim.status,
                    providerName: claim.provider_name,
                    providerSpecialty: claim.provider_specialty,
                    memberName: claim.member_name,
                    amountBilled: currencyFormatter.format(
                      claim.billed_price
                    ),
                    patientResponsibility: currencyFormatter.format(claim.patient_responsibility),
                    type: claim.type,
                    isDeductibleMet: false,
                    paymentSource: claim.payment_source ? claim.payment_source : "lob_check"
                  }
                })}
                isLoading={isClaimsLoading}
                paginationConfig={paginationConfig}
                onClearAllFilters={onClearAll}
                filtersActive={filtersActiveFlag}
                isMobile={isMobile}
              />
            </Col>
          </Row>
        </PageContainer>
      </div>
    </HomeLayout>
  )
}

export default Claims