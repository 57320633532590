import React from 'react'
import { Nav, Tab } from 'react-angle-dashboard-components'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import styled from 'styled-components'
import { useLang } from '../../hooks/use-language'
import { HomeLayout } from '../layouts/HomeLayout'
import { AccountDetailsTab } from './AccountDetailsTab'
import { CommunicationTab } from './CommunicationTab'
import { SecurityTab } from './SecurityTab'

const InnerContainer = styled.div`
  max-width: 800px;
  width: 100%;
`

export const Settings: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const key = pathname.slice(`${path}/`.length)
  const { settings: messages } = useLang().messages

  const onSelect = (k: any) => {
    history.push(`${path}/${k}`)
  }

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Settings', link: '/settings' }
      ]}
    >
      <Tab.Container id="left-tabs-example" activeKey={key} onSelect={onSelect}>
        <section className="bg-light px-3">
          <InnerContainer className="mt-5 pt-3 mx-auto">
            <h1>{messages.title}</h1>
            <Nav variant="tabs" className="mt-5">
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="account-details">
                  {messages.tabs.accountDetails}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="security">
                  {messages.tabs.security}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="communication">
                  {messages.tabs.communication}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </InnerContainer>
        </section>
        <InnerContainer className="mt-5 mx-auto pb-5 px-3">
          <Tab.Content style={{ paddingBottom: 200 }}>
            <Switch>
              <Route
                exact
                path={`${path}/account-details`}
                component={AccountDetailsTab}
              />
              <Route exact path={`${path}/security`} component={SecurityTab} />
              <Route
                exact
                path={`${path}/communication`}
                component={CommunicationTab}
              />
              <Redirect to={`${path}/account-details`}></Redirect>
            </Switch>
          </Tab.Content>
        </InnerContainer>
      </Tab.Container>
    </HomeLayout>
  )
}

export default Settings
