import { DateHandler } from '@angle/utils'
import React, { useState } from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  Form,
  Modal,
  PlaceAutocomplete,
  Row,
  useRuntimeConfig,
  useToaster
} from 'react-angle-dashboard-components'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { ErrorMessage } from '../../components/ErrorMessage'
import { useLang } from '../../hooks/use-language'
import { memberService } from '../../services'
import { MutedLabel } from './style'

const emptyFormState = {
  contact_info: {
    home_email: '',
    work_phone: '',
    home_phone: ''
  },
  home_address: {
    street_1: '',
    street_2: '',
    city: '',
    state: '',
    zip: ''
  },
  mailing_address: {
    street_1: '',
    street_2: '',
    city: '',
    state: '',
    zip: ''
  }
}

const tenDigitTest = (value: string) => {
  const length = value.length
  return /^-?\d+$/.test(value) && length === 10
}

const validEmailTest = (value: string) => {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  )
}

export const AccountDetailsTab: React.FC = () => {
  const config = useRuntimeConfig()
  const googleApiKey = config?.googleApiKey || '****'

  const { show } = useToaster()

  const [showModal, setShowModal] = useState(false)

  const [isEditingContactInfo, setIsEditingContactInfo] = useState(false)
  const [isEditingHomeAddress, setIsEditingHomeAddress] = useState(false)
  const [isEditingMailingAddress, setIsEditingMailingAddress] = useState(false)

  const {
    handleSubmit,
    reset,
    register,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: { ...emptyFormState }
  })

  const { accountDetails: messages } = useLang().messages.settings

  const {
    data: currentMemberData,
    error,
    isLoading,
    refetch
  } = useQuery('getMember', () => memberService.getMemberInfo(), {
    onSuccess: (data) => {
      const { contact_info, home_address, mailing_address } = data

      const defaultValues = {
        contact_info,
        home_address,
        mailing_address
      }

      reset(defaultValues)
    }
  })

  const updateMember = useMutation((memberInfo: any) =>
    memberService.updateMemberInfo(memberInfo)
  )

  const onSubmit = async (data: typeof emptyFormState) => {
    setIsEditingContactInfo(false)
    setIsEditingHomeAddress(false)
    setIsEditingMailingAddress(false)
    setShowModal(false)

    //TODO: not sending address for now
    const requestBody = {
      contact_info: data.contact_info
    }

    try {
      await updateMember.mutateAsync(requestBody)
      show({
        message: 'Update successful',
        icon: 'icon-correct',
        type: 'success'
      })
      refetch()
    } catch (error) {
      show({
        message:
          'There was an unexpected error updating your settings. Please reach out to your care team if the issue continues',
        icon: 'icon-error',
        type: 'danger'
      })
      const { contact_info, home_address, mailing_address } =
        currentMemberData ?? {}

      const defaultValues = {
        contact_info,
        home_address,
        mailing_address
      }

      reset(currentMemberData ? defaultValues : emptyFormState)
    }
  }

  const onInvalidSubmission = () => {
    setShowModal(false)

    show({
      message: 'One or more of your inputs are invalid.',
      icon: 'icon-error',
      type: 'danger'
    })
  }

  if (isLoading) {
    return <CenteredSpinner />
  }

  if (error) {
    return (
      <ErrorMessage errorText="An error occurred loading your account information." />
    )
  }
  return (
    <section>
      <Form>
        <Modal show={showModal}>
          <Modal.Header className="border pb-3" closeButton>
            Save Changes made to your Account
          </Modal.Header>
          <Modal.Body>
            You are about to save changes to your account information. Please
            confirm to proceed or cancel to discard changes made.
          </Modal.Body>
          <Modal.Footer className="border d-flex justify-content-between">
            <Button
              onClick={() => setShowModal(false)}
              variant="outline-primary"
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit, onInvalidSubmission)}>
              Save Account Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <section>
          <h6 className="mb-0">
            <b>{messages.title}</b>
          </h6>
          <MutedLabel className="mb-4">
            To change this information, please reach out to your care team by
            sessions on your mobile app.
          </MutedLabel>
          <Row>
            <Col>
              <MutedLabel>{messages.firstName}</MutedLabel>
              <p>{currentMemberData?.name.first_name}</p>
            </Col>

            <Col>
              <MutedLabel>{messages.lastName}</MutedLabel>
              <p>{currentMemberData?.name.last_name}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <MutedLabel>{messages.memberId}</MutedLabel>
              <p>{currentMemberData?.member_id}</p>
            </Col>

            <Col>
              <MutedLabel>{messages.dob}</MutedLabel>
              <p>
                {DateHandler.formatForClient(currentMemberData?.date_of_birth)}
              </p>
            </Col>
          </Row>
        </section>
        <hr />
        <section>
          <h6 className="d-flex justify-content-between align-items-center">
            <b>Personal Information</b>
            {!isEditingContactInfo && (
              <Button
                variant="link"
                onClick={() => setIsEditingContactInfo(true)}
              >
                Edit
              </Button>
            )}
          </h6>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formEmailAddress">
                <MutedLabel>E-mail address</MutedLabel>
                {isEditingContactInfo ? (
                  <>
                    <Form.Control
                      type="email"
                      placeholder="Personal Email"
                      {...register('contact_info.home_email', {
                        validate: {
                          validEmail: validEmailTest
                        }
                      })}
                    />
                    {errors?.contact_info?.home_email?.type ===
                      'validEmail' && (
                      <span className="text-danger">Email must be valid</span>
                    )}
                  </>
                ) : (
                  <p>{watch().contact_info.home_email}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formPhoneNumber">
                <MutedLabel>Work Phone</MutedLabel>
                {isEditingContactInfo ? (
                  <>
                    <Form.Control
                      type="tel"
                      required
                      {...register('contact_info.work_phone', {
                        required: true,
                        validate: {
                          tenDigits: tenDigitTest
                        }
                      })}
                      placeholder="Phone number"
                    />
                    {errors?.contact_info?.work_phone?.type === 'tenDigits' && (
                      <span className="text-danger">
                        Phone number must be ten digits.
                      </span>
                    )}
                  </>
                ) : (
                  <p>
                    {watch().contact_info.work_phone.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      '($1) $2-$3'
                    )}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formPhoneNumber">
                <MutedLabel>Personal Phone</MutedLabel>
                {isEditingContactInfo ? (
                  <>
                    <Form.Control
                      type="tel"
                      required
                      {...register('contact_info.home_phone', {
                        required: true,
                        validate: {
                          tenDigits: tenDigitTest
                        }
                      })}
                      placeholder="Phone number"
                    />
                    {errors?.contact_info?.home_phone?.type === 'tenDigits' && (
                      <span className="text-danger">
                        Phone number must be ten digits.
                      </span>
                    )}
                  </>
                ) : (
                  <p>
                    {watch().contact_info.home_phone.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      '($1) $2-$3'
                    )}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          {isEditingContactInfo && (
            <Row className="justify-content-end mt-4 mr-1">
              <Button
                variant="outline-primary mr-3"
                onClick={() => {
                  setIsEditingContactInfo(false)
                  if (currentMemberData) {
                    setValue(
                      'contact_info',
                      currentMemberData.contact_info as any
                    )
                  }
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => setShowModal(true)}>
                {messages.buttons.save}
              </Button>
            </Row>
          )}
        </section>
        <hr />
        <section>
          <h6 className="d-flex justify-content-between align-items-center mb-0">
            <b>Mailing Address</b>
            {/* {!isEditingMailingAddress && (
              <Button
                variant="link"
                onClick={() => setIsEditingMailingAddress(true)}
              >
                Edit
              </Button>
            )} */}
          </h6>
          <MutedLabel className="mb-4">
            To change your mailing address, please reach out to your company
            administrator
          </MutedLabel>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formMailingAddress">
                <MutedLabel>Address Line 1</MutedLabel>
                {isEditingMailingAddress ? (
                  <Controller
                    name="mailing_address.street_1"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <PlaceAutocomplete
                        ref={field.ref}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        className="form-control"
                        placeholder="Address Line 1"
                        defaultValue={field.value}
                        apiKey={googleApiKey}
                        onPlaceSelection={({
                          address,
                          city,
                          zip,
                          stateShort
                        }) => {
                          setValue('mailing_address.street_1', address || '')
                          setValue('mailing_address.state', stateShort || '')
                          setValue('mailing_address.city', city || '')
                          setValue('mailing_address.zip', zip || '')
                        }}
                      />
                    )}
                  />
                ) : (
                  <p>{watch().mailing_address.street_1}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formMailingAddress">
                <MutedLabel>Address Line 2</MutedLabel>
                {isEditingMailingAddress ? (
                  <Form.Control {...register('mailing_address.street_2')} />
                ) : (
                  <p>{watch().mailing_address.street_2}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formMailingAddress">
                <MutedLabel>City</MutedLabel>
                {isEditingMailingAddress ? (
                  <Form.Control {...register('mailing_address.city')} />
                ) : (
                  <p>{watch().mailing_address.city}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formMailingAddress">
                <MutedLabel>State</MutedLabel>
                {isEditingMailingAddress ? (
                  <Form.Control {...register('mailing_address.state')} />
                ) : (
                  <p>{watch().mailing_address.state}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formMailingAddress">
                <MutedLabel>Zip Code</MutedLabel>
                {isEditingMailingAddress ? (
                  <Form.Control {...register('mailing_address.zip')} />
                ) : (
                  <p>{watch().mailing_address.zip}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          {isEditingMailingAddress && (
            <Row className="justify-content-end mt-4 mr-1">
              <Button
                variant="outline-primary mr-3"
                onClick={() => {
                  setIsEditingMailingAddress(false)
                  if (currentMemberData) {
                    setValue(
                      'mailing_address',
                      currentMemberData.mailing_address
                    )
                  }
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => setShowModal(true)}>
                {messages.buttons.save}
              </Button>
            </Row>
          )}
        </section>
        <hr />
        <section>
          <h6 className="d-flex justify-content-between align-items-center mb-0">
            <b>Resident Address</b>
            {/* {!isEditingHomeAddress && (
              <Button
                variant="link"
                onClick={() => setIsEditingHomeAddress(true)}
              >
                Edit
              </Button>
            )} */}
          </h6>
          <MutedLabel className="mb-4">
            To change your resident address, please reach out to your care team
            by sessions on your mobile app or by phone. Please note that address
            changes may affect your rates.
          </MutedLabel>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formHomeAddress">
                <MutedLabel>Address Line 1</MutedLabel>
                {isEditingHomeAddress ? (
                  <Controller
                    name="home_address.street_1"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <PlaceAutocomplete
                        ref={field.ref}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        className="form-control"
                        placeholder="Address Line 1"
                        defaultValue={field.value}
                        apiKey={googleApiKey}
                        onPlaceSelection={({
                          address,
                          city,
                          zip,
                          stateShort
                        }) => {
                          setValue('home_address.street_1', address || '')
                          setValue('home_address.state', stateShort || '')
                          setValue('home_address.city', city || '')
                          setValue('home_address.zip', zip || '')
                        }}
                      />
                    )}
                  />
                ) : (
                  <p>{watch().home_address.street_1}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formHomeAddress">
                <MutedLabel>Address Line 2</MutedLabel>
                {isEditingHomeAddress ? (
                  <Form.Control {...register('home_address.street_2')} />
                ) : (
                  <p>{watch().home_address.street_2}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formHomeAddress">
                <MutedLabel>City</MutedLabel>
                {isEditingHomeAddress ? (
                  <Form.Control {...register('home_address.city')} />
                ) : (
                  <p>{watch().home_address.city}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formHomeAddress">
                <MutedLabel>State</MutedLabel>
                {isEditingHomeAddress ? (
                  <Form.Control {...register('home_address.state')} />
                ) : (
                  <p>{watch().home_address.state}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="row-cols-1 row-cols-md-2">
            <Col>
              <Form.Group controlId="formHomeAddress">
                <MutedLabel>Zip Code</MutedLabel>
                {isEditingHomeAddress ? (
                  <Form.Control {...register('home_address.zip')} />
                ) : (
                  <p>{watch().home_address.zip}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          {isEditingHomeAddress && (
            <Row className="justify-content-end mt-4 mr-1">
              <Button
                variant="outline-primary mr-3"
                onClick={() => {
                  setIsEditingHomeAddress(false)
                  if (currentMemberData) {
                    setValue('home_address', currentMemberData.home_address)
                  }
                }}
              >
                Cancel
              </Button>
              <Button onClick={() => setShowModal(true)}>
                {messages.buttons.save}
              </Button>
            </Row>
          )}
        </section>
      </Form>
    </section>
  )
}

export default AccountDetailsTab
