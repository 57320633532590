import React from 'react'
import {
  Col,
  Row,
  Form,
  SectionTitle,
  Button,
  useToaster,
  CenteredSpinner,
  DatePicker
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import classNames from 'classnames'
import InputMask from 'react-input-mask'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { onboardingPaths } from '../../Router'
import { memberService } from '../../../../services'
import { Footer } from '../../style'
import { DateHandler } from '@angle/utils'
import { mapper } from './mapper'
import { Paths } from '../../../../routes/paths'
import { useUpdateMember } from '../../hooks/useUpdateMember'

export const mapPersonalToServer = () => {
  return
}
export const Personal: React.FC<{ refetch: any }> = () => {
  const history = useHistory()
  const { showError } = useToaster()

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading } = useQuery(
    'getPersonal',
    () => memberService.getMemberInfo(mapper),
    {
      onSuccess: (data) => {
        reset({ ...data })
      }
    }
  )

  const updateMember = useUpdateMember()

  const handleContinue = handleSubmit(async (formData) => {
    try {
      const mappedData = {
        date_of_birth: DateHandler.formatForServer(formData.dob),
        name: {
          first_name: formData.firstName,
          middle_name: formData.middleName,
          last_name: formData.lastName,
          suffix: formData.suffix
        },
        preferred_name: formData.preferredName,
        language: formData.preferredLanguage,
        ssn: formData.ssn.replace(/[^\w]/g, ''),
        identified_gender: formData.selfIdentify,
        gender: formData.gender,
        in_progress_state: data?.serverStep > 2 ? data?.serverStep : 2,
        onboarding_status: 'in_progress'
      }

      await updateMember.mutateAsync(mappedData)

      history.push(Paths.onboarding + onboardingPaths.contact)
    } catch (e: any) {
      showError(e?.message)
    }
  })

  const getLabelStyles = (key: keyof typeof formState.errors) => {
    return {
      className: classNames({
        'text-danger':
          Boolean(formState.errors) &&
          Boolean(formState.errors[key]) &&
          formState.isSubmitted
      })
    }
  }

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <div>
      <SectionTitle>Personal information</SectionTitle>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Controller
                name="firstName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="First Name"
                    readOnly
                    style={{ backgroundColor: '#f8fafd' }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Middle Name (Optional)</Form.Label>
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Middle Name"
                    className="bg-white"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('selfIdentify')}>
                What gender do you identify as?
              </Form.Label>
              <Controller
                name="selfIdentify"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    isInvalid={
                      Boolean(formState.errors.gender) && formState.isSubmitted
                    }
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                    as="select"
                    placeholder="Select Gender"
                    className={`${!field.value ? 'text-muted' : ''} bg-white`}
                  >
                    <option value="" disabled hidden>
                      Select option
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non binary">Non-Binary</option>
                    <option value="non conforming">Non-Conforming</option>
                  </Form.Control>
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('preferredName')}>
                Preferred Name
              </Form.Label>
              <Controller
                name="preferredName"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    className="bg-white"
                    type="text"
                    placeholder="Preferred Name"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('dob')}>Date of Birth</Form.Label>
              <Controller
                name="dob"
                rules={{
                  required: true,
                  validate: {
                    notFuture: (value) => {
                      if (moment(value).isSameOrBefore(new Date())) return true
                      else return false
                    }
                  }
                }}
                control={control}
                render={({ field }) => <DatePicker {...field} />}
              />
            </Form.Group>

            {formState.errors?.dob &&
              formState.errors?.dob?.type === 'notFuture' &&
              formState.isSubmitted && (
                <span className="text-danger">
                  Date of birth cannot be in the future.
                </span>
              )}
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Controller
                name="lastName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Last Name"
                    isInvalid={
                      Boolean(formState.errors.lastName) &&
                      formState.isSubmitted
                    }
                    readOnly
                    style={{ backgroundColor: '#f8fafd' }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Suffix (Optional)</Form.Label>
              <Controller
                name="suffix"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Suffix"
                    className="bg-white"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('selfIdentify')}>
                Sex (Gender at Birth)
              </Form.Label>
              <Controller
                name="gender"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    isInvalid={
                      Boolean(formState.errors.gender) && formState.isSubmitted
                    }
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                    as="select"
                    placeholder="Select Sex"
                    className={`bg-white ${!field.value ? 'text-muted' : ''}`}
                  >
                    <option value="" disabled hidden>
                      Select option
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('ssn')}>
                Social Security Number
              </Form.Label>
              <Controller
                name="ssn"
                rules={{
                  required: true,
                  validate: {
                    correctPattern: (value) => {
                      if (String(value).match(/[a-zA-Z]+/g)) return false
                      if (
                        (String(value)
                          ?.match(/[0-9]+/g)
                          ?.join('')?.length as any) !== 9
                      )
                        return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    isInvalid={
                      Boolean(formState.errors.ssn) && formState.isSubmitted
                    }
                    as={InputMask}
                    mask="999-99-9999"
                    type="text"
                    placeholder="Enter your SSN"
                    className="bg-white"
                  />
                )}
              />
              {formState.errors?.ssn &&
                formState.errors?.ssn?.type === 'correctPattern' &&
                formState.isSubmitted && (
                  <span className="text-danger">SSN must be 9 digits.</span>
                )}
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('preferredLanguage')}>
                Preferred Language
              </Form.Label>
              <Controller
                name="preferredLanguage"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    isInvalid={
                      Boolean(formState.errors.preferredLanguage) &&
                      formState.isSubmitted
                    }
                    onChange={(e) => {
                      onChange(e.target.value)
                    }}
                    as="select"
                    className={`bg-white ${!field.value ? 'text-muted' : ''}`}
                  >
                    <option value="" disabled hidden>
                      Select preferred language
                    </option>
                    <option value="english">English</option>
                    <option value="spanish">Spanish</option>
                  </Form.Control>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Footer>
        <div className="text-right w-100">
          <Button disabled={formState.isSubmitting} onClick={handleContinue}>
            Continue
          </Button>
        </div>
      </Footer>
    </div>
  )
}
