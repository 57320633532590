import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { BenefitData, BenefitLabel } from '../../style'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const IdentityCard: React.FC<{
  plan?: any
  onClickSelectPlan?: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  return (
    <Card style={{ width: 345 }} className="mr-4 mb-3">
      <Card.Header className="px-3 pb-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2">
              <img
                src="/lifelock.png"
                style={{ width: 50, height: 50 }}
                alt="lifelock-logo"
              />
            </div>
          </div>

          <PlanCardPremium premium={premium} />
          <SelectPlanButton
            onClick={() => onClickSelectPlan(plan.plan_id)}
            isSelected={isSelected}
          />
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="font-weight-bold" style={{ padding: '12px 15px' }}>
                Plan Coverage
              </th>
              <th className="px-3 font-weight-bold">Included</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-4">Dark Web Monitoring</BenefitLabel>
              <BenefitData className="pt-4">
                {plan.dark_web_monitoring_included ? '100%' : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Monthly Credit Score Tracking</BenefitLabel>
              <BenefitData>
                {plan.monthly_credit_score_tracking_included
                  ? '100%'
                  : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Parent Control</BenefitLabel>
              <BenefitData>
                {plan.parental_control_included ? '100%' : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Device protection</BenefitLabel>
              <BenefitData className="text-capitalize">
                {plan.device_security_message}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Online Threat Protection</BenefitLabel>
              <BenefitData>
                {plan.online_threat_protection_included
                  ? '100%'
                  : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Password Manager</BenefitLabel>
              <BenefitData>
                {plan.password_manager_included ? '100%' : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Smart Firewall</BenefitLabel>
              <BenefitData>
                {plan.smart_firewall_included ? '100%' : 'Not Included'}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Cloud Backup</BenefitLabel>
              <BenefitData>{plan.cloud_backup_size_message}</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
