import { Card } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const Floating = styled.div`
  position: absolute;
  top: -185px;
  width: 100%;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 83%;
  max-width: 350px;

  @media (max-width: 576px) {
    position: relative;
    padding-left: 0px;
    top: 0;
    height: 100%;
    max-width: none;
  }
`

export const BoldTitle = styled.strong<{ leftOnMobile?: boolean }>`
  margin-bottom: 1.25rem;
  display: block;

  ${(props) =>
    props.leftOnMobile &&
    `@media (max-width: 576px) {
      text-align: left;
      width: 100%;
    }
  `}
`

export const ScrollableList = styled.div`
  /* overflow-y: auto; */
  /* max-height: 350px; */
  width: 100%;
`

export const PlanGlanceInnerCard = styled(Card)`
  background-color: #f8f9fa;
  padding: 16px;
  height: 100%;
`

export const InnerCardStepNumber = styled.div`
  background-color: #dbdee280;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 13px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const HelpIcon = styled.i`
  color: var(--primary);
  height: 16px;
  width: 16px;
  position: relative;
  top: 2px;
  left: 4px;
`
