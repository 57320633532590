import * as React from 'react'
import { SVGProps } from 'react'

const CancelSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    fill="currentColor"
    {...props}
  >
    <g id="Cancel">
      <path
        id="Vector"
        fillRule="evenodd"
        d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
)

export default CancelSolid
