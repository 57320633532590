import React from 'react'
import { Row, Col, IDCardContainer } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useParams, useLocation } from 'react-router-dom'
import { memberService } from '../../../services'
import { OtherDependents } from '../Components/OtherDependents'

export const IDCard: React.FC<{ otherDependents: any[] }> = ({
  otherDependents
}) => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()

  const isDependent = location.pathname.includes('dependent')

  const { data, isLoading, refetch, isRefetching } = useQuery(['memberInfo', id, isDependent], () => {
    return id && isDependent
      ? memberService.getDependentById(id)
      : memberService.getMemberInfo()
  })

  return (
    <Row>
      <Col xs={8}>
        <IDCardContainer
          data={data}
          isLoading={isLoading}
          employeeService={memberService}
          isAdmin={false}
          refetchMemberData={refetch}
          isRefetching={isRefetching}
        />
      </Col>
      <Col xs={4}>
        <OtherDependents dependents={otherDependents} activeTab="id-card" />
      </Col>
    </Row>
  )
}

export default IDCard
