import { useState } from 'react'
import { IPlanDTO } from 'react-angle-dashboard-components'
import { planService } from '../../../../services'
import { CurrentSBCData } from './types'

export const useSbc = () => {
  const [showSbcModal, setShowSbcModal] = useState(false)
  const [currentSBCData, setSBCData] = useState<CurrentSBCData>(null)
  const [isLoadingSbc, setIsLoadingSbc] = useState(false)

  const openSBCPDF = (plan: IPlanDTO) => {
    window.open(plan.sbc_url, '_blank')
  }

  const openSBCModal = async (plan: IPlanDTO) => {
    setIsLoadingSbc(true)
    setShowSbcModal(true)
    const { events, faqs } = await planService.getSBC(
      plan.plan_id,
      'time_type=latest'
    )
    setSBCData({
      benefits: events,
      faqs,
      plan
    })
    setIsLoadingSbc(false)
  }

  return {
    openSBCModal,
    currentSBCData,
    showSbcModal,
    setShowSbcModal,
    isLoadingSbc,
    openSBCPDF
  }
}
