import React, { SetStateAction, Dispatch } from 'react'
import { Dropdown } from 'react-angle-dashboard-components'
import { DropdownButton, ArrowDownIcon } from './style'
import { formatCurrencyWithoutDecimals } from '../../../../../utils/formatCurrency'

interface CustomToggleProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
  disabled: boolean
  children?: React.ReactNode
}

export const CustomToggle = React.forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLButtonElement>) => (
    <DropdownButton
      ref={ref}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        props.onClick(e)
      }}
      disabled={props.disabled}
    >
      {props.children}
    </DropdownButton>
  )
)

export const DropdownComponent: React.FC<{
  currentAmount: number
  setCurrentAmount: Dispatch<SetStateAction<number>>
  amountList: number[]
}> = ({ currentAmount, setCurrentAmount, amountList }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        disabled={false}
        id="dropdown-custom-components"
      >
        {formatCurrencyWithoutDecimals(currentAmount)}
        <ArrowDownIcon className="icon icon-down-arrow fs-14 ml-2" />
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          border: '0.5px solid gray',
          padding: 0
        }}
      >
        {amountList.map((amount: number, i) => (
          <Dropdown.Item
            style={{ fontSize: 18 }}
            key={i}
            onClick={() => setCurrentAmount(amount)}
          >
            {formatCurrencyWithoutDecimals(amount)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
