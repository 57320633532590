import { useToaster } from 'react-angle-dashboard-components'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { SelectedDependentsKey } from '../../../../../../components/SelectDependentForPlan/SelectDependentForPlan'
import { coverageService } from '../../../../../../services'
import { onboardingPaths } from '../../../../Router'
import { Paths } from '../../../../../../routes/paths'

// todo safegaurd against selecting plan before dependents or dependents key loads

export const mapDependentsToSelectedArray = (
  selectedDependentsKey: SelectedDependentsKey
) => {
  const selectedDependents = []
  for (const dependentId in selectedDependentsKey) {
    if (selectedDependentsKey[dependentId] === true) {
      selectedDependents.push(dependentId)
    }
  }

  return selectedDependents
}

export const useSelectVoluntaryPlan = (
  benefitKey: string,
  selectedDependentsKey: SelectedDependentsKey,
  coverageAmount?: number
) => {
  const history = useHistory()
  const { showError } = useToaster()

  const selectPlan = useMutation((data: any) =>
    coverageService.selectPlan(data, benefitKey)
  )
  const onClickSelectPlan = async (planId: string) => {
    const selectedDependents = mapDependentsToSelectedArray(
      selectedDependentsKey
    )

    try {
      const data: any = {
        waived: false,
        plan_id: planId,
        dependents: selectedDependents
      }
      if (coverageAmount) {
        data.coverage_amount = coverageAmount
      }
      await selectPlan.mutateAsync(data)
      history.push(Paths.onboarding + onboardingPaths.voluntary)
    } catch (error: any) {
      showError(error.message)
    }
  }

  return { onClickSelectPlan }
}
