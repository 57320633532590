import React from 'react'
import { Button, Card } from 'react-angle-dashboard-components'
import { HomeLayout } from '../layouts/HomeLayout'
import { StyledBackground, StyledCard, StyledUl } from './style'

export const VirtualCare: React.FC = () => {
  // const doctorOnDemandUrls = {
  //   test: 'https://patient-demo.doctorondemand.com/sso/oidc/web/start/?idp_connection_id=angle-health-test&app_type=3',
  //   prod: 'https://patient.doctorondemand.com/sso/oidc/web/start/?idp_connection_id=angle-health&app_type=3&status_response_url=https://doctorondemand.onelink.me/doctorondemand/f4854b1e'
  // }

  const tempDoDRedirect = 'https://doctorondemand.com/microsite/anglehealth/'

  const doctorOnDemandUrl = window.location.origin.includes('test')
    ? tempDoDRedirect
    : tempDoDRedirect

  return (
    <HomeLayout
      noSearchBar
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Get Virtual Care', link: '/virtual-care' }
      ]}
    >
      <StyledBackground>
        <img
          src="/virtual-care-background.png"
          alt="virtual care background"
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%'
          }}
        />
      </StyledBackground>

      <StyledCard className="w-100 w-sm-75 w-lg-50 mx-auto">
        <Card.Body className="d-flex flex-column">
          <div>
            <div className="d-flex justify-content-start align-items-center">
              <img src="/dr-plus.png" alt="doctor on demand" width={100} />
              <h4 className="ml-2">
                Angle Telehealth brought to you by Doctor on Demand
              </h4>
            </div>
            <h3 className="mb-3">Get access to care anytime</h3>
            <p className="mb-0">
              We partner with Doctor on Demand to bring you access to telehealth
              24/7. Get access to top-rated doctors who can help you receive
              care wherever you are, anytime. You can use your Angle credentials
              to log in and get started.
            </p>
            <Button
              size="md"
              className="my-5"
              onClick={() =>
                window.open(doctorOnDemandUrl, '_blank  noreferrer')
              }
            >
              Get Virtual Care
            </Button>

            <p className="font-weight-bold">
              Things that virtual care can help you treat:
            </p>
            <StyledUl>
              <li>Sinus Infection</li>
              <li>Anxiety</li>
              <li>Urinary tract infection</li>
              <li>Cold & Flu</li>
              <li>Depression</li>
              <li>Prescription refill</li>
              <li>Rash</li>
              <li>Yeast infection</li>
              <li>Covid-19 concerns</li>
              <li>Hypertension</li>
            </StyledUl>
          </div>
          <div style={{ marginTop: 150 }}>
            <small>Questions about Angle Telehealth?</small>
            <small className="d-block">
              Chat with your care team directly from your mobile app.
            </small>
          </div>
        </Card.Body>
      </StyledCard>
    </HomeLayout>
  )
}

export default VirtualCare
