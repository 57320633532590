import React from 'react'
import { Button, Card } from 'react-angle-dashboard-components'
import { ReactComponent as MetlifeLogo } from '../../../../../../../assets/metlife.svg'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { BenefitData, BenefitLabel } from '../../style'
import { accidentalPlans } from './accidentalPlans'
import { PlanCardPremium } from '../../components/PlanCardPremium'

export const AccidentCard: React.FC<{
  plan?: any
  onClickSelectPlan?: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  const planDetails =
    plan?.plan_id === 'MLAC0001'
      ? accidentalPlans.lowPlan
      : accidentalPlans.highPlan
  return (
    <Card style={{ width: 345 }} className="mr-4 mb-3">
      <Card.Header className="px-3 pb-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2">
              <MetlifeLogo />
            </div>
          </div>
          <PlanCardPremium premium={premium} />

          <div className="pt-3">
            {isSelected ? (
              <div className="d-flex align-items-center flex-nowrap">
                <div>
                  <i className="icon icon-correct text-success"></i>
                </div>

                <span className="d-block">Selected</span>
              </div>
            ) : (
              <Button
                className="w-100"
                onClick={() => onClickSelectPlan(plan.plan_id)}
              >
                Select this plan
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="font-weight-bold" style={{ padding: '12px 15px' }}>
                Plan Coverage
              </th>
              <th className="px-3 font-weight-bold">Benefit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-4">Fracture</BenefitLabel>
              <BenefitData className="pt-4">{planDetails.fracture}</BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Concussion</BenefitLabel>
              <BenefitData>{planDetails.concussion}</BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Eye Injury</BenefitLabel>
              <BenefitData>
                <span className="font-weight-bold">
                  {planDetails.eyeInjury}
                </span>
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Ambulance</BenefitLabel>
              <BenefitData>{planDetails.ambulance}</BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Hospital Admission</BenefitLabel>
              <BenefitData>{planDetails.admission}</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
