import React, { CSSProperties } from 'react'
import { claimsStatusEnums } from '../../services/api/claims/claims'

import { StatusCircle } from './style'

export interface StatusIndicatorProps {
  status: string
  style?: CSSProperties
}

const StatusIndicator = ({status, style}: StatusIndicatorProps) => {
  let statusText = 'Processing'
  let statusBgColor: 'success' | 'danger' | 'warning' = 'warning'
  switch (status) {
    case claimsStatusEnums.approved:
      statusText = 'Approved'
      statusBgColor = 'success'
      break
    case claimsStatusEnums.denied:
      statusText = 'Denied'
      statusBgColor = 'danger'
      break
    case claimsStatusEnums.denied_partial:
      statusText = 'Partially Denied'
      statusBgColor = 'danger'
      break
    case claimsStatusEnums.processing:
      statusText = 'Processing'
      statusBgColor = 'warning'
      break
    default:
      break
  }

  return (
    <div className="d-flex align-items-center" style={style}>
      <StatusCircle bgColor={`var(--${statusBgColor})`} />
      {statusText}
    </div>
  )
}

export default StatusIndicator
