import React from 'react'
import {
  CenteredSpinner,
  PageContainer
} from 'react-angle-dashboard-components'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import { PageHeaderLight } from '../../components/PageHeaderLight/PageHeaderLight'
import { HomeLayout } from '../layouts/HomeLayout'
import DependentTabs from './DependentTabs'
import IDCard from './Sections/IDCard'
import { dependentPaths } from './paths'
import { useQuery } from 'react-query'
import { memberService } from '../../services'

export const DependentPlan = () => {
  const { id } = useParams<{ id: string }>()

  const { data: dependentsData } = useQuery(['getDependents'], () =>
    memberService.getDependents()
  )

  const { data: dependentData } = useQuery(['getDependent', id], () =>
    memberService.getDependentById(id)
  )

  let otherDependents: any[] = []

  if (dependentsData) {
    otherDependents = dependentsData.dependents.filter(
      (d: any) => d.member_id !== id
    )
  }

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Dependent Plan', link: '/dependent-plan' }
      ]}
    >
      {dependentData && dependentsData ? (
        <>
          <PageHeaderLight
            style={{ alignItems: 'flex-start' }}
            className="pb-0"
          >
            <PageContainer size="xl">
              <h1 className="mb-3">
                {dependentData.name.first_name} {dependentData.name.last_name}
              </h1>
              <DependentTabs />
            </PageContainer>
          </PageHeaderLight>
          <PageContainer size="xl" className="pb-5">
            <Switch>
              {/* <Route
                path={dependentPaths.benefits}
                render={() => (
                  <Benefits
                    otherDependents={otherDependents}
                    coverages={dependentData.coverages}
                  />
                )}
              /> */}
              <Route
                path={dependentPaths.idCard}
                render={() => <IDCard otherDependents={otherDependents} />}
              />
              {/* <Route
                path={dependentPaths.healthProfile}
                render={() => (
                  <HealthProfile otherDependents={otherDependents} />
                )}
              /> */}
              {/* <Route path={dependentPaths.claims} component={Claims} /> */}
              <Redirect to={dependentPaths.idCard.replace(':id', id)} />
            </Switch>
          </PageContainer>
        </>
      ) : (
        <CenteredSpinner />
      )}
    </HomeLayout>
  )
}

export default DependentPlan
