import React from 'react'
import {
  Button,
  CenteredSpinner,
  Container,
  PortalLayout,
  useQueryParams,
  useToaster
} from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useTempAuth } from '../../../hooks/use-temp-auth'
import { surveyService } from '../../../services'
import { IDependentProviderForm } from './interfaces'
import { ProviderForm } from './ProviderForm'
import { ErrorPage } from './ErrorPage'
import { getEmptyDependent, getEmptyProvider, validateFields } from './shared'

// only

export const ProviderFormPage = () => {
  const query = useQueryParams()
  const history = useHistory()
  const location = useLocation()
  const code = query.get('code')
  const toaster = useToaster()

  const { isLoading, error } = useTempAuth(code, surveyService)

  const form = useForm<IDependentProviderForm>({
    mode: 'onChange',
    defaultValues: {
      dependents: [getEmptyDependent()],
      providers: [getEmptyProvider()]
    }
  })

  const submit = form.handleSubmit(async (data: IDependentProviderForm) => {
    try {
      const cleanData = validateFields(data, false)
      await surveyService.updateProviders(cleanData.providers)
      history.push(location.pathname + '/completed')
    } catch (e: any) {
      toaster.showError(e.message)
    }
  })

  return isLoading ? (
    <div style={{ height: '100vh' }}>
      <CenteredSpinner />
    </div>
  ) : (
    <PortalLayout breadcrumbs={[]} noBreadcrumbs>
      {error ? (
        <ErrorPage />
      ) : (
        <Container className="pt-5">
          <ProviderForm {...form} title="Current Provider Details" />
          <div className="text-right mt-5 mb-3">
            <Button onClick={submit}>Submit Form</Button>
          </div>
        </Container>
      )}
    </PortalLayout>
  )
}

export default ProviderFormPage
