import React from 'react'
import { Button, Form, DatePicker } from 'react-angle-dashboard-components'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { LoginPageCard } from '../../../LoginPageCard'
import { GoBackButton } from '../../../style'
import { Controller } from 'react-hook-form'
import { unformatPhoneNumber } from '@angle/utils'
import { useMemberLookup } from './useMemberLookup'
import { FormInputs } from './FormInputs'
import { VerificationType } from '../../types'

export const CreateAccountLookup = () => {
  const history = useHistory()
  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const { handleSubmit, formState, register, control, lookupMember } =
    useMemberLookup()

  const onContinue = handleSubmit(async (formData: any) => {
    const reqBody = { ...formData }
    if (formData.member_id) {
      reqBody.member_id = 'ANG' + formData.member_id
    }
    if (formData.ssn) {
      reqBody.ssn = unformatPhoneNumber(formData.ssn)
    }

    await lookupMember.mutateAsync(reqBody)
  })

  return (
    <LoginPageCard
      headerText="Create your account"
      subheaderText="Please provide us with:"
    >
      <div>
        <FormInputs
          formState={formState}
          register={register}
          verificationType={verificationType}
        />
        <div className="d-flex justify-content-between">
          <Form.Group style={{ width: '48%' }}>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              className="bg-white"
              placeholder="First Name"
              isInvalid={
                !!formState?.errors?.first_name && formState?.isSubmitted
              }
              {...register('first_name', {
                required: true
              })}
            />
          </Form.Group>
          <Form.Group style={{ width: '48%' }}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              className="bg-white"
              placeholder="Last Name"
              isInvalid={
                !!formState?.errors?.last_name && formState?.isSubmitted
              }
              {...register('last_name', {
                required: true
              })}
            />
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label>Date of Birth</Form.Label>
          <Controller
            name="date_of_birth"
            rules={{
              required: true
            }}
            control={control}
            render={({ field }) => (
              <DatePicker {...field} backgroundColor="white" />
            )}
          />
        </Form.Group>
        {formState?.errors?.date_of_birth && formState?.isSubmitted && (
          <span className="text-danger d-inline-block mb-2">
            Please enter a valid date
          </span>
        )}
      </div>
      <div className="text-center">
        <Button block onClick={() => onContinue()}>
          Continue
        </Button>

        <GoBackButton
          className="mt-3"
          variant="link"
          onClick={() => history.push(Paths.createAccount)}
        >
          Go Back
        </GoBackButton>
      </div>
    </LoginPageCard>
  )
}
