import React from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  Row
} from 'react-angle-dashboard-components'
import { Paths } from '../../../../routes/paths'
import { useHistory } from 'react-router-dom'
import { Footer } from '../../style'
import { onboardingPaths } from '../../Router'
import { SummaryCard } from './SummaryCard'
import { useGetSelectedBenefits } from '../Voluntary/useGetSelectedPlans'
import { benefitsMap } from 'react-angle-dashboard-components'

export const Summary = () => {
  const history = useHistory()

  const { selectedBenefits, isLoading } = useGetSelectedBenefits()

  const handleBack = () => {
    history.push(Paths.onboarding + onboardingPaths.voluntary)
  }

  const handleContinue = () => {
    history.push(Paths.onboarding + onboardingPaths.agreement)
  }

  return (
    <div>
      <div>
        <h3>Summary</h3>
        <p>Here is a summary view of your benefit elections:</p>
      </div>
      <Row className="pb-5">
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <Col className="d-flex flex-wrap ">
            {selectedBenefits.map((benefit: any) => {
              if (benefit && benefit?.selectedPlanName) {
                const key = benefit.key
                return (
                  <SummaryCard
                    benefitType={benefitsMap[key]?.displayName || ''}
                    planName={benefit.selectedPlanName}
                    key={key}
                    benefitKey={key}
                    members={benefit.members}
                    carrierName={benefit.carrierName || ''}
                  />
                )
              }
              return null
            })}
          </Col>
        )}
      </Row>
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </Footer>
    </div>
  )
}
