import { DateHandler } from '@angle/utils'
import moment from 'moment'
import { Alert } from 'react-angle-dashboard-components'
import { Link } from 'react-router-dom'
import { Paths } from '../../../routes/paths'
import styled from 'styled-components'
import { ReactNode } from 'react'

const AlertLink = styled(Link)`
  text-decoration: underline;
  color: var(--dark);

  :hover {
    color: black;
    text-decoration: underline;
  }
`

interface Options {
  acaUpcomingMessage: ReactNode
  acaActiveMessage: ReactNode
  angleRxMessage: ReactNode
}

export const getAlert = (
  member: any,
  { acaUpcomingMessage, acaActiveMessage, angleRxMessage }: Options
) => {
  // ACA

  const accessPlusIsEnrolled = member?.aca_status === 'enrolled' // not started aca yet; TODO: switch back to 'enrolled'
  const accessPlusIsActive = member?.aca_status === 'active' // on aca plan; TODO: switch back to 'active'

  // ACA IS ACTIVE
  if (accessPlusIsActive) {
    return {
      type: 'warning',
      message: acaActiveMessage,
      style: {
        backgroundColor: '#17A2B8'
      },
      className:
        'w-full text-center text-white py-2 flex align-items-center border-0'
    }
  }

  // ACA PLAN WILL BEGIN

  if (accessPlusIsEnrolled) {
    return {
      type: 'warning',
      message: acaUpcomingMessage,
      style: {
        backgroundColor: '#F2994A'
      },
      className:
        'w-full text-center text-white py-2 flex align-items-center border-0'
    }
  }

  // ANGLE COVERAGE

  const currentCoverage = member?.current_medical_coverage || null
  const futureCoverage = member?.future_medical_coverages[0] || null
  const pastCoverage =
    member?.past_medical_coverages[
      member?.past_medical_coverages?.length - 1
    ] || null

  const today = new Date()

  // COVERAGE WILL END

  const currentTermDate = currentCoverage?.term_date
  const termDateCopy = moment(currentTermDate)
  const isToday30daysBeforeTerm = DateHandler.use(today).isSameOrAfter(
    termDateCopy.subtract(31, 'days')
  )

  const showWillTermAlert =
    currentTermDate &&
    DateHandler.use(currentCoverage?.term_date).isSameOrAfter(today) &&
    isToday30daysBeforeTerm &&
    futureCoverage === null

  if (showWillTermAlert) {
    const inRenewal =
      member.in_renewal && member.onboarding_status === 'in_progress'
    const messageNode = (
      <Alert.Heading>
        Your coverage will end on{' '}
        {DateHandler.formatForClient(currentCoverage?.term_date)}.
        {inRenewal && (
          <span className="pl-2">
            {`Complete  `}
            <AlertLink to={Paths.onboarding}>open enrollment</AlertLink>
            {`  today.`}
          </span>
        )}
      </Alert.Heading>
    )

    return {
      message: messageNode,
      type: 'warning'
    }
  }

  // COVERAGE WILL BEGIN / RENEW

  if (
    futureCoverage &&
    DateHandler.use(today).isBefore(futureCoverage.effective_date)
  ) {
    const action = pastCoverage || currentCoverage ? 'renew' : 'begin'
    return {
      message: `Your coverage will ${action} on ${DateHandler.formatForClient(
        futureCoverage?.effective_date
      )}. `,
      type: 'success'
    }
  }

  // COVERAGE HAS ENDED

  const showHasTermedAlert =
    pastCoverage &&
    DateHandler.use(pastCoverage?.term_date).isSameOrBefore(today) &&
    futureCoverage === null &&
    currentCoverage === null

  if (showHasTermedAlert) {
    return {
      message: `Your coverage ended on ${DateHandler.formatForClient(
        pastCoverage?.term_date
      )}. `,
      type: 'danger'
    }
  }

  // Angle Rx info
  const isAngleRx = member?.formulary_indicator === 'angle_rx'

  if (
    isAngleRx &&
    member.past_medical_coverages[0]?.plan &&
    member.current_medical_coverage.plan.rx_group !==
      member.past_medical_coverages[0].plan.rx_group
  ) {
    return {
      type: 'warning',
      message: angleRxMessage,
      style: {
        backgroundColor: '#F2994A'
      },
      className:
        'w-full text-center text-white py-2 flex align-items-center border-0'
    }
  }

  return undefined
}
