import * as React from 'react'
import { SVGProps } from 'react'

const RightArrowSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <g id="RightArrow">
      <path
        id="RightArrow_2"
        d="M1.02679 8.8227H12.4961L7.48532 13.8335C7.08487 14.2339 7.08487 14.891 7.48532 15.2915C7.88577 15.6919 8.53265 15.6919 8.9331 15.2915L15.6997 8.52493C16.1001 8.12448 16.1001 7.4776 15.6997 7.07715L8.94336 0.300322C8.75153 0.108052 8.49108 0 8.21947 0C7.94787 0 7.68742 0.108052 7.49559 0.300322C7.09514 0.700771 7.09514 1.34765 7.49559 1.7481L12.4961 6.76912H1.02679C0.462057 6.76912 0 7.23117 0 7.79591C0 8.36065 0.462057 8.8227 1.02679 8.8227Z"
      />
    </g>
  </svg>
)

export default RightArrowSolid
