import { DateHandler } from '@angle/utils'

export const mapCurrentCarrierToForm = (data: any) => {
  return {
    healthInsurance: data?.current_carrier?.medical_continue,
    insurer: data?.current_carrier?.medical_carrier,
    policyNumber: data?.current_carrier?.medical_policy_number,
    dateOfCoverage: {
      endAt: DateHandler.formatForClient(
        data.current_carrier?.medical_term_date
      ),
      startAt: DateHandler.formatForClient(
        data.current_carrier?.medical_start_date
      )
    },
    typesOfCoverage: data.current_carrier?.medical_coverage_types,
    coverageContinue: data.current_carrier?.medical_continue,
    medicareElegible: data.medicare_eligible,
    cobra: data.on_cobra_scb,
    serverStep: data.in_progress_state,
    inRenewal: data?.in_renewal
  }
}

export const mapCurrentCarrierToServer = (
  formData: any,
  serverStep: number
) => {
  const hasCurrentCarrier = formData.healthInsurance

  const data: any = {
    in_progress_state: serverStep > 4 ? serverStep : 4,
    on_cobra_scb: formData.cobra,
    medicare_eligible: formData.medicareElegible
  }

  let currentCarrier = {}

  if (hasCurrentCarrier) {
    currentCarrier = {
      medical_continue: formData.healthInsurance,
      medical_carrier: formData.insurer,
      medical_policy_number: formData.policyNumber,
      medical_start_date: DateHandler.formatForServer(
        formData.dateOfCoverage?.startAt as any
      ),
      medical_term_date: DateHandler.formatForServer(
        formData.dateOfCoverage?.endAt as any
      ),
      medical_coverage_types: formData.typesOfCoverage
    }
  } else {
    currentCarrier = {
      medical_continue: false,
      medical_carrier: '',
      medical_policy_number: '',
      medical_start_date: undefined,
      medical_term_date: undefined,
      medical_coverage_types: []
    }
  }
  data.current_carrier = currentCarrier

  return data
}
