import * as React from 'react'
import { SVGProps } from 'react'

const MenuSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    {...props}
  >
    <g id="Menu">
      <path
        id="Vector"
        d="M7.99997 12.3077C9.02017 12.3077 9.84727 13.1343 9.84727 14.1538C9.84727 15.1734 9.02017 16 7.99997 16C6.97977 16 6.15267 15.1734 6.15267 14.1538C6.15267 13.1343 6.97977 12.3077 7.99997 12.3077ZM14.1527 12.3077C15.1729 12.3077 16 13.1343 16 14.1538C16 15.1734 15.1729 16 14.1527 16C13.1325 16 12.3054 15.1734 12.3054 14.1538C12.3054 13.1343 13.1325 12.3077 14.1527 12.3077ZM1.8473 12.3077C2.86754 12.3077 3.6946 13.1343 3.6946 14.1538C3.6946 15.1734 2.86754 16 1.8473 16C0.82706 16 0 15.1734 0 14.1538C0 13.1343 0.82706 12.3077 1.8473 12.3077ZM7.99997 6.15385C9.02017 6.15385 9.84727 6.98039 9.84727 8C9.84727 9.01957 9.02017 9.84615 7.99997 9.84615C6.97977 9.84615 6.15267 9.01957 6.15267 8C6.15267 6.98039 6.97977 6.15385 7.99997 6.15385ZM14.1527 6.15385C15.1729 6.15385 16 6.98039 16 8C16 9.01957 15.1729 9.84615 14.1527 9.84615C13.1325 9.84615 12.3054 9.01957 12.3054 8C12.3054 6.98039 13.1325 6.15385 14.1527 6.15385ZM1.8473 6.15385C2.86754 6.15385 3.6946 6.98039 3.6946 8C3.6946 9.01957 2.86754 9.84615 1.8473 9.84615C0.82706 9.84615 0 9.01957 0 8C0 6.98039 0.82706 6.15385 1.8473 6.15385ZM7.99997 0C9.02017 0 9.84727 0.826548 9.84727 1.84615C9.84727 2.86576 9.02017 3.69231 7.99997 3.69231C6.97977 3.69231 6.15267 2.86576 6.15267 1.84615C6.15267 0.826548 6.97977 0 7.99997 0ZM14.1527 0C15.1729 0 16 0.826548 16 1.84615C16 2.86576 15.1729 3.69231 14.1527 3.69231C13.1325 3.69231 12.3054 2.86576 12.3054 1.84615C12.3054 0.826548 13.1325 0 14.1527 0ZM1.8473 0C2.86754 0 3.6946 0.826548 3.6946 1.84615C3.6946 2.86576 2.86754 3.69231 1.8473 3.69231C0.82706 3.69231 0 2.86576 0 1.84615C0 0.826548 0.82706 0 1.8473 0Z"
      />
    </g>
  </svg>
)

export default MenuSolid
