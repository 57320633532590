import styled from 'styled-components'

export const SearchTitle = styled.h1`
  margin-bottom: 3rem;
`

export const FilterSection = styled.div`
  padding: 16px;

  ${({ noBorder = false }: { noBorder?: boolean }) =>
    !noBorder && `border-bottom: solid 1px #e7eaf3;`}
`

export const FilterSectionHeader = styled.div`
  color: #343a40;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 8px;
`

export const StatusCircle = styled.div<{ bgColor: string }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 0.8rem;
  background: ${(props) => props.bgColor};
`
