import React from 'react'
import {
  Col,
  PageContainer,
  PageHeaderLight,
  Row
} from 'react-angle-dashboard-components'
import Skeleton from 'react-loading-skeleton'

import { IUnifiedClaimDetail } from '../../services/api/claims/mappers'
import { NoWrapDiv } from './style'
import { ReactComponent as StethoscopeIcon } from '../../assets/stethoscope.svg'
import StatusIndicator from '../Claims/StatusIndicator'

// const status: Record<string, { text: string; variant: string }> = {
//   P: { text: 'Payable', variant: 'success' },
//   A: { text: 'Reversed', variant: 'warning' },
//   payable: { text: 'Payable', variant: 'success' },
//   denied_partial: { text: 'Denied Partial', variant: 'danger' },
//   denied: { text: 'Denied', variant: 'danger' },
//   suspended: { text: 'Suspended', variant: 'warning' },
//   pended: { text: 'Pended', variant: 'info' },
//   error: { text: 'Error', variant: 'danger' }
// }

const SeparatorDot = () => (
  <Col className="col-auto d-flex align-items-center">
    <div
      style={{
        width: 5,
        height: 5,
        backgroundColor: 'gray',
        borderRadius: '100%'
      }}
    />
  </Col>
)

const InfoCol = ({
  title,
  content,
  isLoading = false
}: {
  title: string
  content: string | undefined
  isLoading?: boolean
}) => (
  <Col>
    <Row className="mx-0 text-muted">{title}</Row>
    <Row className="mx-0 text-capitalize">
      <div
        style={{
          fontWeight: 500
        }}
      >
        {isLoading ? <Skeleton width={150} /> : content}
      </div>
    </Row>
  </Col>
)

export const Header: React.FC<{
  data: IUnifiedClaimDetail | undefined
  isLoading: boolean
  oopmMet: boolean
  dedMet: boolean
}> = ({ data, isLoading, oopmMet, dedMet }) => {
  return (
    <PageHeaderLight style={{ alignItems: 'flex-start' }}>
      <PageContainer size="xl">
        <Row>
          <Col>
            <Row noGutters className="d-flex flex-row">
              <Col className="flex-grow-0">
                {isLoading ? (
                  <Skeleton width={80} height={80} circle />
                ) : (
                  <div
                    className="rounded-circle text-primary d-flex align-items-center justify-content-center"
                    style={{
                      background: '#EEEAF7',
                      fontSize: 40,
                      height: 80,
                      width: 80
                    }}
                  >
                    <StethoscopeIcon />
                  </div>
                )}
              </Col>
              <Col className="ml-3">
                <Row className="mx-0 fs-22 d-flex flex-row justify-content-between align-items-center">
                  <span className="font-weight-bold">
                    {isLoading ? <Skeleton width={150} /> : data?.claimId}
                  </span>
                </Row>
                <Row>
                  <Col className="col-auto">
                    {isLoading ? (
                      <Skeleton width={120} />
                    ) : (
                      <StatusIndicator status={data?.status ?? "pended"} />
                    )}
                  </Col>
                  {oopmMet && (
                    <>
                      <SeparatorDot />
                      <Col className="col-auto">
                        {/* TODO */}
                        <NoWrapDiv>OOPM met</NoWrapDiv>
                      </Col>
                    </>
                  )}
                  {dedMet && (
                    <>
                      <SeparatorDot />
                      <Col className="col-auto">
                        {/* TODO */}
                        <NoWrapDiv>DED met</NoWrapDiv>
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="mt-3">
                  <InfoCol
                    title="Member Name"
                    content={data?.memberName}
                    isLoading={isLoading}
                  />
                  <InfoCol
                    title="Member ID"
                    content={data?.memberId}
                    isLoading={isLoading}
                  />
                  <InfoCol
                    title="Network"
                    content={data?.inNetwork ? 'In-Network' : 'Out-of-Network'}
                    isLoading={isLoading}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
      </PageContainer>
    </PageHeaderLight>
  )
}
