import React from 'react'
import { Button, Card } from 'react-angle-dashboard-components'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { BenefitData, BenefitLabel } from '../../style'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { ReactComponent as MetlifeLogo } from '../../../../../../../assets/metlife.svg'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const BasicLifeCard: React.FC<{
  plan?: any
  onClickSelectPlan?: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  return (
    <Card style={{ width: 375 }} className="mr-4 mb-3">
      <Card.Header className="px-3 pb-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2">
              <MetlifeLogo />
            </div>
          </div>

          <PlanCardPremium premium={premium} />
          <SelectPlanButton
            isSelected={isSelected}
            onClick={() => onClickSelectPlan(plan.plan_id)}
          />
          <div className="pt-3">
            <Button
              className="w-100"
              onClick={() => onClickSelectPlan(plan.plan_id)}
            >
              Select this plan
            </Button>
          </div>
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="font-weight-bold" style={{ padding: '12px 15px' }}>
                Plan Coverage
              </th>
              <th className="px-3 font-weight-bold">Benefit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-4">Coverage Amount</BenefitLabel>
              <BenefitData className="pt-4">$25,000</BenefitData>
            </tr>
            <tr>
              <BenefitLabel style={{ verticalAlign: 'top' }}>
                Age Reduction Schedule
              </BenefitLabel>
              <BenefitData>
                35% at age 65
                <div>50% at age 70</div>
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Non Medical Max Amount</BenefitLabel>
              <BenefitData>Full Amount</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
