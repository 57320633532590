import React from 'react'
import {
  Logo,
  Row,
  useRememberAccount,
  useLoginFormHandler,
  useQueryParams,
  Card
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { LoginForm } from './LoginForm'
import { Paths } from '../../routes/paths'
import { LoginLayout } from '../layouts'
import { FormWrapper, LogoWrapper } from './style'
import { AuthContext } from '../../context/Auth/Auth'
import { ApiService } from '../../services'

export interface LoginFormData {
  email: string
  password: string
  remember: boolean
}

// need to tighten up this redirect logic to claims, etc

export const Login = () => {
  const history = useHistory()
  const params = useQueryParams()
  const renewal = params.get('renewal')
  const page = params.get('page')

  const isRenewalLink = renewal === 'true'

  // if user is already logged in on renewal link, push to onboarding
  const token = localStorage.getItem('token')
  if (token && isRenewalLink) {
    ApiService._token = token
    history.push(Paths.onboarding)
  }

  const onSuccess = () => {
    if (isRenewalLink) {
      history.push(Paths.onboarding)
    } else if (page === 'claims') {
      history.push('/claims')
    } else {
      history.push('/')
    }
  }

  const {
    onSubmit,
    control,
    register,
    formState: { isSubmitting, isValid, errors },
    setValue,
    watch,
    setError
  } = useLoginFormHandler(
    {
      onSuccess,
      onError: () => {
        setError('email', {
          type: 'manual',
          message: 'Invalid username/password'
        })
      }
    },
    AuthContext
  )

  useRememberAccount(setValue, watch)
  return (
    <LoginLayout>
      <FormWrapper className="pb-5">
        <Row className="mr-auto ml-auto mb-6 mt-5">
          <LogoWrapper>
            <Logo variant="default" color="primary" />
          </LogoWrapper>
        </Row>
        <Card style={{ borderRadius: 10, padding: 40 }}>
          <LoginForm
            register={register}
            onSubmit={onSubmit}
            errors={errors}
            isSubmitting={isSubmitting}
            isValid={isValid}
            onPasswordForgetHandler={() => {
              history.push(Paths.forgotPassword)
            }}
            control={control}
          />
        </Card>
      </FormWrapper>
    </LoginLayout>
  )
}

export default Login
