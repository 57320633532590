import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { useMemberData } from '../../context/CreateAccountContext'
import { tempAuthService, ApiService } from '../../../../../services'
import { useMutation } from 'react-query'
import { VerificationType } from '../../types'
import { identifyFullstoryUser } from '../../utils/identifyFullstoryUser'

export const useConfirmMember = () => {
  const history = useHistory()
  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const [error, setError] = useState<any>(null)

  // user inputted data
  const { memberData, members } = useMemberData()

  // if date of birth is missing that means page has refereshed and/or we no longer have the necessary member data to authenticate
  useEffect(() => {
    if (!memberData?.date_of_birth) {
      history.push(Paths.createAccount)
    }
  }, [memberData, history])

  const confirmMember = useMutation(
    (data: any) => tempAuthService.confirmMemberLookup(data),
    {
      onSuccess: (responseData: any) => {
        const userInfo = responseData?.token
        if (userInfo && userInfo?.access_token) {
          // identify user on fullstory
          identifyFullstoryUser(userInfo, memberData?.member_id)

          // store temp pw for use on the change password section
          sessionStorage.setItem(
            'temporary_password',
            responseData?.temporary_password
          )

          sessionStorage.setItem('authuser_id', userInfo.authuser_id)

          sessionStorage.setItem('token', userInfo?.access_token)

          ApiService._token = userInfo?.access_token
        }

        history.push(`${Paths.createAccount}/${verificationType}/email`)
      },
      onError: (error: any) => {
        setError(error)
      }
    }
  )
  return { memberData, members, error, confirmMember }
}
