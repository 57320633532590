import React from 'react'

import {
  Button,
  Card,
  Col,
  Row,
  SectionTitle,
  Table
} from 'react-angle-dashboard-components'
import { currencyFormatter, DateHandler } from '@angle/utils'

import { claimsStatusEnums } from '../../services/api/claims/claims'
import { IUnifiedClaimListItem } from '../../interfaces/claims'
import { Link } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import StatusIndicator from '../Claims/StatusIndicator'


interface ClaimsRowProps {
  billedPrice: number
  claimId: string | number
  dateOfService: string
  memberName: string
  providerName: string
  status: string
  type: string
}

const ClaimsRow = ({ billedPrice, claimId, dateOfService, memberName, providerName, status, type }: ClaimsRowProps ) => {
  const amountBilled = status === claimsStatusEnums.processing ? 'TBD' : currencyFormatter.format(billedPrice)
  const amountBilledCN = `${status === claimsStatusEnums.processing && 'text-muted'}`

  return (
    <tr>
      <td style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row className='m-0'>
          <Col style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', overflow: 'hidden', paddingLeft: 0 }}>
            { type === "pharmacy" ? (
              <Link to={`/rxclaims/${claimId}`}>{claimId}</Link>
              ) : (
              <Link to={`/claims/${claimId}`}>{claimId}</Link>
              )}
            <div className="fs-14 text-muted">{dateOfService}</div>
            <div style={{ overflow: 'hidden', paddingTop: '3px' }}>{providerName}</div>
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', overflow: 'hidden', paddingLeft: '5px', paddingRight: 0, textAlign: 'right' }}>
            <div className={amountBilledCN}>
              {amountBilled}
            </div>
            <StatusIndicator status={status} style={{ float: 'right', justifyContent: 'flex-end' }} />
            <div className='text-muted' style={{ float: 'right', width: '100%' }}>
              {memberName}
            </div>
          </Col>
        </Row>
      </td>
      </tr>
  )}

interface ClaimsWidgetProps {
  claims: IUnifiedClaimListItem[]
}

export const ClaimsWidget = ({ claims }: ClaimsWidgetProps) => {

  const recentThreeClaims = claims.slice(0, 3)

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="m-0">
            <SectionTitle>Recent Claims</SectionTitle>
            <Col className="text-right p-0">
              <Link to={Paths.claims}>
                <Button className="p-0" variant="link" size="md">
                  View All
                </Button>
              </Link>
            </Col>
          </Row>
          <Table
            className="border-0 table-sm"
            headerClassName="thead-light"
          >
            <tbody>
              {recentThreeClaims.length === 0 && (
                <div style={{ fontSize: '16px' }}>
                  No recent claims. Your recent claims will be shown here once they are submitted.
                </div>
              )}
              {recentThreeClaims[0] && (
                <ClaimsRow
                  billedPrice={recentThreeClaims[0].billed_price}
                  claimId={recentThreeClaims[0].pk}
                  dateOfService={DateHandler.formatForClient(
                    recentThreeClaims[0]?.date_of_service || recentThreeClaims[0]?.service_date
                  )}
                  memberName={recentThreeClaims[0].member_name}
                  providerName={recentThreeClaims[0].provider_name}
                  status={recentThreeClaims[0].status}
                  type={recentThreeClaims[0].type}
                  />)}
              {recentThreeClaims[1] && (
                <ClaimsRow
                  billedPrice={recentThreeClaims[1].billed_price}
                  claimId={recentThreeClaims[1].pk}
                  dateOfService={DateHandler.formatForClient(
                    recentThreeClaims[1]?.date_of_service || recentThreeClaims[1]?.service_date
                  )}
                  memberName={recentThreeClaims[1].member_name}
                  providerName={recentThreeClaims[1].provider_name}
                  status={recentThreeClaims[1].status}
                  type={recentThreeClaims[1].type} />)}
              {recentThreeClaims[2] && (
                <ClaimsRow
                  billedPrice={recentThreeClaims[2].billed_price}
                  claimId={recentThreeClaims[2].pk}
                  dateOfService={DateHandler.formatForClient(
                    recentThreeClaims[2]?.date_of_service || recentThreeClaims[0]?.service_date
                  )}
                  memberName={recentThreeClaims[2].member_name}
                  providerName={recentThreeClaims[2].provider_name}
                  status={recentThreeClaims[2].status}
                  type={recentThreeClaims[2].type} />)}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimsWidget
