import React, { useMemo } from 'react'
import { Card, AnglePlanBadge } from 'react-angle-dashboard-components'
import { Paths } from '../../../../routes/paths'
import { Link } from 'react-router-dom'
import { onboardingPaths } from '../../Router'
import { SpouseIcon } from './SpouseIcon'
import { ChildIcon } from './ChildIcon'
import { carrierLogoPaths } from '../../utils/carrierLogoPaths'

export const SummaryPlanBadge: React.FC<{
  carrierName: string
  planName: string
}> = ({ carrierName, planName }) => {
  const carrierPath = carrierLogoPaths[carrierName]

  return (
    <React.Fragment>
      {carrierPath && (
        <div className="d-inline mr-2 mb-1">
          <img
            src={carrierPath}
            alt={`${carrierName}`}
            width="30"
            height="30"
          />
        </div>
      )}

      {planName && <span>{planName}</span>}
    </React.Fragment>
  )
}

export const sortMembersToDisplay = (members: any[]) => {
  const employee: any[] = []
  const spouse: any[] = []
  const children: any[] = []

  members.forEach((member: any) => {
    const relationship = member.relationship
    switch (relationship) {
      case 'employee':
        employee.push(member)
        break
      case 'spouse':
        spouse.push(member)
        break
      case 'child':
        children.push(member)
        break
      default:
        break
    }
  })
  return [...employee, ...spouse, ...children]
}

export const SummaryCard: React.FC<{
  benefitType: string
  planName: string
  benefitKey: string
  members?: any
  carrierName?: string
}> = ({ benefitType, planName, benefitKey, members, carrierName }) => {
  const sortedMembers = useMemo(() => {
    return sortMembersToDisplay(members)
  }, [members])

  const medicalPath = `${Paths.onboarding}${onboardingPaths.plan}`
  const voluntaryPath = `${Paths.onboarding}${onboardingPaths.voluntary}?benefit=${benefitKey}`
  const editPath = benefitKey === 'medical' ? medicalPath : voluntaryPath

  return (
    <Card style={{ width: 245 }} className="mb-3 mr-3">
      <Card.Body>
        <div className="border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="m-0 p-0">{benefitType}</h6>
            <div className="d-flex align-items-center">
              <i
                className="icon icon-pencil text-primary fs-12"
                style={{ height: '100%', width: '0.8rem', marginRight: 7 }}
              />

              <Link to={editPath} className="text-primary">
                Edit
              </Link>
            </div>
          </div>
          <div className="py-3">
            {carrierName ? (
              <SummaryPlanBadge carrierName={carrierName} planName={planName} />
            ) : (
              <AnglePlanBadge
                metalTier="gold"
                planName={planName}
                style={{ display: 'block' }}
                textColor="black"
              />
            )}
          </div>
        </div>
        <div className="pt-3 fs-14">
          <ul className="my-0 px-3">
            {sortedMembers?.map((member: any) => {
              return (
                <li className="pb-2">
                  {member?.name?.first_name} {member?.name?.last_name}
                  <span className="px-1">
                    {' '}
                    {member.relationship === 'spouse' && <SpouseIcon />}
                    {member.relationship === 'child' && <ChildIcon />}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </Card.Body>
    </Card>
  )
}
