import { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { tempAuthService } from '../../../../../services'
import { CreateAccountContext } from '../../context/CreateAccountContext'
import { unformatPhoneNumber } from '@angle/utils'
import { VerificationType } from '../../types'

export const useMemberLookup = () => {
  const history = useHistory()
  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const { memberData, setMemberData, setMembers } =
    useContext(CreateAccountContext)

  const defaultValues: any = {
    date_of_birth: memberData?.date_of_birth || '',
    first_name: memberData?.first_name || '',
    last_name: memberData?.last_name || ''
  }

  if (verificationType === 'angle') {
    defaultValues.member_id = memberData?.member_id || ''
  }
  const { handleSubmit, formState, register, control, getValues } = useForm({
    mode: 'onChange',
    defaultValues
  })

  const updateMemberContext = (responseData?: { data: any[] }) => {
    // save the member's inputs so that we can use them on the next screen
    const formData = getValues()
    setMemberData((prev: any) => {
      const newState = { ...prev }
      for (const val in formData) {
        val === 'ssn'
          ? (newState[val] = unformatPhoneNumber(formData.ssn))
          : (newState[val] = formData[val])
      }
      return newState
    })

    // save list of members in case the user wants to go back
    if (responseData) {
      setMembers(responseData?.data)
    }
  }

  const onSuccess = (responseData: { data: any[] }) => {
    updateMemberContext(responseData)

    history.push({
      pathname: `${Paths.createAccount}/${verificationType}/confirm`,
      state: responseData
    })
  }

  const onError = () => {
    updateMemberContext()

    history.push({
      pathname: `${Paths.createAccount}/${verificationType}/not-found`
    })
  }
  const lookupMember = useMutation(
    (data: any) => tempAuthService.memberLookup(data),
    {
      onSuccess,
      onError
    }
  )

  return {
    handleSubmit,
    formState,
    register,
    control,
    updateMemberContext,
    lookupMember
  }
}
