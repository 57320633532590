import React, { useEffect, useState } from 'react'

import { HomeLayout } from '../layouts/HomeLayout'
import {
  FormContainer,
  FormLabel,
  HeaderText,
  PhotoBackground,
  SearchBarLabel,
  SubHeaderText,
  ZipCodeControl
} from './style'

import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import {
  Button,
  Col,
  Form,
  FormGroup,
  Row,
  Spinner
} from 'react-angle-dashboard-components'
import ListItem from './ListItem'

import { useHistory } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { SearchResult, useComparePrices } from '../../context/ComparePrices'

import serviceItems from './services.json'
import placeOfServiceList from './placeOfServiceList'
import CPTMap from '../ComparePricesResult/CPTMap.json'
import InfoContainer from './InfoContainer'
import WarningBubble from './WarningBubble'
import { useQuery } from 'react-query'
import { memberService } from '../../services'
import { getDirectoryUrlByIdCardType } from '../../utils/directoryUrls'

export const ComparePrices = () => {
  const history = useHistory()
  const {
    INN,
    setINN,
    zipCode,
    setZipCode,
    placeOfService,
    setPlaceOfService,
    service,
    setService,
    setProvider,
    coordsData,
    isCoordsLoading,
    clearAllComparePrices
  } = useComparePrices()

  const [isZipCodeValid, setIsZipCodeValid] = useState(false)
  const [showCantFindWarning, setShowCantFindWarning] = useState(false)

  useEffect(() => {
    setIsZipCodeValid(
      !!coordsData && (zipCode?.length ?? 0) === 5 && !isCoordsLoading
    )
  }, [coordsData, isCoordsLoading, zipCode])

  const items = React.useMemo<SearchResult[]>(
    () => serviceItems.map((itm) => ({ id: itm['Code (1)'], ...itm })),
    []
  )

  const formatResult = (itm: SearchResult) => {
    return (
      <div key={itm['Code (1)']}>
        <span>{itm['Short description']}</span>
        <br />
        <span>{itm['Plain text description']}</span>
      </div>
    )
  }

  const canContinue = () => {
    if (INN) {
      return isZipCodeValid && !!placeOfService && !!coordsData
    } else {
      return !!placeOfService
    }
  }

  const onSearchResultSelect = (result: SearchResult) => {
    setService(result)
    setShowCantFindWarning(false)
  }

  const onSearchClear = () => {
    clearAllComparePrices()
    setIsZipCodeValid(false)
    setShowCantFindWarning(false)
  }

  const onSearchInput = () => {
    setShowCantFindWarning(true)
  }

  const onFormSubmit = (e: any) => {
    e.preventDefault()

    if (INN) {
      history.push(Paths.comparePricesProviderSearch)
    } else {
      history.push(Paths.comparePricesResult)
    }
  }

  const onPlaceOfServiceSelect = (value: string) =>
    setPlaceOfService(placeOfServiceList.find((pos) => pos.value === value))

  const filterPOSByService = (pos: typeof placeOfServiceList[0]) => {
    let serviceCPT = service?.['CPT Benefit Category']
    if (serviceCPT) {
      const mappedPOS = (CPTMap as any)[serviceCPT] as any
      if (!mappedPOS) return true
      const mappedVals = mappedPOS[pos.value]
      if (mappedVals && typeof mappedVals === 'object') {
        return !!mappedVals['Facility Fee'] && !!mappedVals['Physician Fee']
      }
      return !!mappedVals
    }
    return true
  }

  const { data } = useQuery('getMember', async () => {
    return memberService.getMemberInfo()
  })


  const directoriesUrl = getDirectoryUrlByIdCardType(data?.id_card_type)
  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Select Service', link: Paths.comparePrices }
      ]}
    >
      <div
        style={{
          minHeight: 650
        }}
      >
        <PhotoBackground>
          <HeaderText>Get a price estimate for your care</HeaderText>
          <SubHeaderText>
            Shoppable Services removes the guesswork from how much a visit to
            the doctor will cost you
          </SubHeaderText>
          <div className="w-60 bg-white px-5 py-3 rounded">
            {showCantFindWarning && (
              <WarningBubble
                className="mb-3"
                header="Can't find what you're looking for?"
                body="We currently allow cost estimates for 500 shoppable services. By January 1st, 2024 we'll expand cost estimates to all available services."
              />
            )}
            <SearchBarLabel>Reason for visit</SearchBarLabel>
            <ReactSearchAutocomplete<SearchResult>
              items={items}
              fuseOptions={{
                keys: [
                  'Short description',
                  'Plain text description',
                  'Code (1)'
                ]
              }}
              resultStringKeyName="Short description"
              formatResult={formatResult}
              styling={{
                fontFamily: 'SF-Pro',
                fontSize: '18px',
                placeholderColor: '#6c757d',
                iconColor: '#6c757d',
                borderRadius: '0.3125rem',
                clearIconMargin: '-5px 16px 0 0',
                zIndex: 10
              }}
              autoFocus
              placeholder={service?.['Short description'] || 'Search services'}
              onSelect={onSearchResultSelect}
              onClear={onSearchClear}
              onSearch={onSearchInput}
            />
            {!service && (
              <>
                <Row className="mt-5">
                  <InfoContainer
                    step="1."
                    header="Search for a service"
                    body="Search for a service by name or CPT code, choose between in-network and out-of-network, provide your zip code, and select the facility type you'd like to receive care at."
                  />
                  <InfoContainer
                    step="2."
                    header="Select a provider or facility"
                    body="We'll provide a list of 20 low-cost options near you to choose from. You'll also be able to search for any other provider or facility that you'd like to receive care from."
                  />
                  <InfoContainer
                    step="3."
                    header="Get a cost estimate"
                    body="Input your cost-sharing liability by reviewing your Schedule of Benefits and we'll provide a cost estimate for your selected service and provider or facility."
                  />
                </Row>
                <WarningBubble
                  className="mt-5"
                  header="Please remember that these estimates are not final"
                  body="Factors like service location, provider qualifications, and care requirements may cause discrepancies with the final price estimate."
                />
              </>
            )}
          </div>
        </PhotoBackground>
        <FormContainer
          style={{
            paddingBottom: 100
          }}
        >
          <Form onSubmit={onFormSubmit}>
            {!!service && (
              <>
                <Row className="justify-content-between">
                  <Col>
                    <FormLabel>Provider Network Status</FormLabel>
                    <div>
                      <Form.Check
                        inline
                        className="mr-7"
                        type="radio"
                        label="In Network"
                        name="inNetwork"
                        defaultChecked={INN}
                        onChange={() => setINN(true)}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Out of Network"
                        name="inNetwork"
                        defaultChecked={INN === false}
                        onChange={() => {
                          setINN(false)
                          setZipCode(undefined)
                          setProvider(undefined)
                        }}
                      />
                    </div>
                  </Col>
                  {INN === undefined && (
                    <Col sm={4}>
                      <WarningBubble
                        header="Not sure if your provider or facility is in-network?"
                        body={
                          <div>
                            You can use our{' '}
                            <a
                              href={directoriesUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Directories Tool
                            </a>{' '}
                            to easily find out. Remember, going to an
                            out-of-network provider or facility can raise the
                            cost of your care.
                          </div>
                        }
                      />
                    </Col>
                  )}
                </Row>
                {INN && (
                  <Row className="justify-content-between">
                    <Col>
                      <FormGroup className="mb-0" controlId="zipCode">
                        <FormLabel>ZIP Code</FormLabel>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ZipCodeControl
                            className="mr-2"
                            type="text"
                            maxLength="5"
                            value={zipCode}
                            onChange={(e: any) => {
                              const value = e.target.value
                              setZipCode(value)
                            }}
                          />
                          {isCoordsLoading && <Spinner animation={'border'} />}
                          {!coordsData &&
                            zipCode?.length === 5 &&
                            !isCoordsLoading &&
                            'Zipcode invalid!'}
                        </div>
                      </FormGroup>
                    </Col>
                    {!isZipCodeValid && (
                      <Col sm={4}>
                        <WarningBubble
                          header="Want to get care at a convenient location?"
                          body="Input the area code where you'd like to visit your provider or facility and we'll provide a list nearest to that location."
                        />
                      </Col>
                    )}
                  </Row>
                )}
                {(isZipCodeValid || INN === false) && (
                  <Row>
                    <Col>
                      <FormLabel>Place of Service</FormLabel>
                      <Row>
                        <Col>
                          <Form.Group controlId="placeOfService">
                            {placeOfServiceList
                              .filter(filterPOSByService)
                              .map((pos) => (
                                <ListItem
                                  key={pos.value}
                                  image={pos.image}
                                  title={pos.title}
                                  value={pos.value}
                                  description={pos.description}
                                  selected={placeOfService?.value === pos.value}
                                  onClick={onPlaceOfServiceSelect}
                                />
                              ))}
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <WarningBubble
                            header="Not sure which option to pick?"
                            body="Call your provider or facility to confirm what type of facility you'll be receiving care at. This can have a big impact on your cost estimate, so it's always a good idea to double-check!"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                left: -4,
                right: 0,
                backgroundColor: 'white',
                borderTop: '2px solid #E7EAF3',
                padding: 10,
                paddingLeft: '20%'
              }}
            >
              <Button type="submit" disabled={!canContinue()}>
                Continue
              </Button>
            </div>
          </Form>
        </FormContainer>
      </div>
    </HomeLayout>
  )
}

export default ComparePrices
