import { ApiService } from 'react-angle-dashboard-components'
import { Settings, SettingsDTO } from '../../interfaces/settings'

export class SettingsService extends ApiService {
  async getSettings(): Promise<Settings> {
    return this.request({
      method: 'get',
      url: '/settings'
    }).then((r) => ({
      paperlessEOB: !!r.paperless_eob,
      paperlessEOBEmail: !!r.paperless_eob_email
    }))
  }

  async modifySettings(data: SettingsDTO): Promise<any> {
    return this.request({
      method: 'patch',
      url: '/settings',
      data
    })
  }
}
