import React from 'react'
import {
  Button,
  Card,
  CenteredSpinner,
  Col,
  DrugSearch,
  Row
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { drugService, memberService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'
import PharmacyBenefits from '../PharmacyBenefits/PharmacyBenefits'
import { SmallTitle } from './style'
import {
  ANGLE_RX_MEMBERPORTAL_URL,
  SMITH_RX_MEMBERPORTAL_URL
} from '../../constants/pharmacy-portals'

export const DrugSearchPage: React.FC = () => {
  const { data: memberData, isLoading: isMemberLoading } = useQuery(
    'getMember',
    () => memberService.getMemberInfo()
  )

  const isCapRx = memberData?.formulary_indicator === 'cap_rx'
  const isSmithRx = memberData?.formulary_indicator === 'smith_rx'
  const isAngleRx = memberData?.formulary_indicator === 'angle_rx'

  let redirectName = ''
  let redirectLink = ''

  if (isCapRx) {
    return <PharmacyBenefits />
  } else if (isSmithRx) {
    redirectName = 'SmithRx'
    redirectLink = SMITH_RX_MEMBERPORTAL_URL
  } else if (isAngleRx) {
    redirectName = 'AngleRx'
    redirectLink = ANGLE_RX_MEMBERPORTAL_URL
  }

  const onButtonClick = () => {
    window.open(redirectLink, '_blank')
  }

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Pharmacy', link: '/drugs' }
      ]}
    >
      {isMemberLoading ? (
        <CenteredSpinner />
      ) : isCapRx || isSmithRx || isAngleRx ? (
        <Row className="justify-content-sm-center">
          <Col className="col-sm-10 col-lg-8">
            <Card>
              <Card.Body>
                <SmallTitle>Your Pharmacy Benefits have a new home!</SmallTitle>
                <Card.Text className="pt-3">
                  Use {redirectName} to confirm the coverage of a medication -
                  including prior authorization, step therapy, or quantity limit
                  information.
                  <br />
                  <br />
                  {isAngleRx ? (
                    <>
                      If it’s your first visit to {redirectName}, create an
                      account using your {redirectName} ID card. Click “register
                      your account”, enter your member ID as shown on the ID
                      card, and proceed with completing the required personal
                      information.
                      <br />
                      <br />
                      If you have any questions or issues getting registered,
                      please call member services at{' '}
                      <a href="tel:+9452240872">(945) 224-0872</a> for
                      assistance.
                    </>
                  ) : (
                    <>
                      If it's your first visit to {redirectName}, create an
                      account using your Angle Health coverage information.
                    </>
                  )}
                </Card.Text>
                <div style={{ paddingTop: 16 }}>
                  <Button onClick={onButtonClick}>Access Here</Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <DrugSearch drugService={drugService} />
      )}
    </HomeLayout>
  )
}

export default DrugSearchPage
