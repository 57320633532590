import React, { MouseEventHandler, useEffect, useState } from 'react'
import {
  Button,
  Col,
  EmptySearch,
  OverlayTrigger,
  Tooltip
} from 'react-angle-dashboard-components'
import {
  BreakdownCost,
  SubHeader,
  BreakdownLabel,
  BreakdownLine,
  EstimatedCost,
  GrayText,
  TipsText,
  BoldText,
  TipsContainer,
  RightSideContainer,
  HelpIcon,
  Inline,
  InlineSpace,
  InputCostHeader
} from './style'
import { Paths } from '../../routes/paths'
import { Link } from 'react-router-dom'
import InputCost from './InputCost'
import { useComparePrices } from '../../context/ComparePrices'
import { useQuery } from 'react-query'
import { claimsService, memberService } from '../../services'
import CPTMap from '../ComparePricesResult/CPTMap.json'
import { getAccumsValues } from './utils/getAccumsValues'

export const ResultMainSection = ({
  currentCardMemberId
}: {
  currentCardMemberId: string
}) => {
  const { provider, service, placeOfService } = useComparePrices()

  const [costBeforeBenefits, setCostBeforeBenefits] = useState(0)

  const [line1Title, setLine1Title] = useState(undefined)
  const [line2Title, setLine2Title] = useState(undefined)
  const [line1Copay, setLine1Copay] = useState(0)
  const [line1Coinsurance, setLine1Coinsurance] = useState(0)
  const [line2Copay, setLine2Copay] = useState(0)
  const [line2Coinsurance, setLine2Coinsurance] = useState(0)

  const [estimatedCost, setEstimatedCost] = useState(0)

  const [costAfterBenefits, setCostAfterBenefits] = useState(0)
  const [copayCost, setCopayCost] = useState(0)
  const [coinsurnaceCost, setCoinsuranceCost] = useState(0)

  const { data: memberData } = useQuery(['getPlan'], () =>
    memberService.getMemberInfo()
  )

  const { data: accumsData } = useQuery(['getAccums'], () =>
    claimsService.getAccums()
  )

  const indData = accumsData?.members?.find(
    (member: any) => member.memberId === currentCardMemberId
  )

  const { remaining: oopRemainingFamily } = getAccumsValues(
    accumsData?.familyOOP
  )

  const { remaining: deductibleRemainingFamily } = getAccumsValues(
    accumsData?.familyDeductible
  )

  const { remaining: oopRemainingInd } = getAccumsValues(indData?.oop)

  const { remaining: deductibleRemainingInd } = getAccumsValues(
    indData?.deductible
  )

  useEffect(() => {
    const getCoinsurance = (computeCost: number) =>
      (line1Coinsurance / 100) * computeCost +
      (line2Coinsurance / 100) * computeCost

    let negotiatedRate = provider?.cost_before_benefits ?? 0
    let isDoubled = false

    switch (placeOfService?.value) {
      case 'inpatient-hospital':
      case 'outpatient-hospital':
      case 'ambulatory-surgery':
        isDoubled = true
        negotiatedRate = negotiatedRate * 2
        setCostBeforeBenefits(negotiatedRate)
        break
      default:
        setCostBeforeBenefits(negotiatedRate)
    }

    const copayTotal = line1Copay + line2Copay

    const smallerRemainingDed = Math.min(
      deductibleRemainingFamily,
      deductibleRemainingInd
    )
    const deductibleExceeded = smallerRemainingDed - negotiatedRate < 0

    let estCost

    if (deductibleExceeded) {
      const costMinusRemainingDed = negotiatedRate - smallerRemainingDed
      const coinsuranceTotal = getCoinsurance(
        isDoubled ? costMinusRemainingDed / 2 : costMinusRemainingDed
      )
      const cab = copayTotal + coinsuranceTotal + smallerRemainingDed

      setCostAfterBenefits(cab)
      setCopayCost(copayTotal)
      setCoinsuranceCost(coinsuranceTotal)

      const smallerRemainingOop = Math.min(oopRemainingFamily, oopRemainingInd)
      const OopExceeded = smallerRemainingOop - cab < 0
      if (OopExceeded) {
        estCost = smallerRemainingOop
      } else {
        estCost = cab
      }
    } else {
      // oop not exceeded (oop always higher than deductible)
      estCost = negotiatedRate
    }

    setEstimatedCost(Math.min(estCost, negotiatedRate))
  }, [
    placeOfService,
    deductibleRemainingFamily,
    deductibleRemainingInd,
    line1Coinsurance,
    line1Copay,
    line2Coinsurance,
    line2Copay,
    oopRemainingFamily,
    oopRemainingInd,
    provider?.cost_before_benefits
  ])

  useEffect(() => {
    let serviceCPT = service?.['CPT Benefit Category']
    if (serviceCPT) {
      const mappedPOS = (CPTMap as any)[serviceCPT] as any
      if (!mappedPOS) return
      const mappedVals = mappedPOS[placeOfService?.value ?? '']
      if (mappedVals && typeof mappedVals === 'object') {
        setLine1Title(mappedVals['Facility Fee'])
        setLine2Title(mappedVals['Physician Fee'])
      } else if (mappedVals) {
        setLine1Title(mappedVals)
      }
    }
  }, [placeOfService?.value, service])

  const benefitsUrl = memberData?.current_medical_coverage?.plan?.sob_url

  return (
    <InlineSpace>
      <Col className="p-0 pb-10 pr-7 pt-5">
        <InputCostHeader>Input Cost-Sharing Liability Benefits</InputCostHeader>
        {line1Title && (
          <InputCost
            label={line1Title!}
            copay={line1Copay}
            setCopay={setLine1Copay}
            coinsurance={line1Coinsurance}
            setCoinsurance={setLine1Coinsurance}
          />
        )}
        {line2Title && (
          <InputCost
            label={line2Title!}
            copay={line2Copay}
            setCopay={setLine2Copay}
            coinsurance={line2Coinsurance}
            setCoinsurance={setLine2Coinsurance}
          />
        )}
        <Button
          href={benefitsUrl}
          target="_blank"
          rel="nofollow noreferrer noopener"
          disabled={!benefitsUrl}
          className="mt-4"
        >
          View Benefits
        </Button>
        <div className="pt-5">
          <EstimatedCost>
            Your estimated cost is{' '}
            {estimatedCost?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}
          </EstimatedCost>
          <GrayText>
            Your estimate takes into account the amount you have already paid
            towards your deductible and out-of-pocket max (excluding any pending
            claims).
          </GrayText>
        </div>
        <div className="pt-5">
          <SubHeader>Price Breakdown</SubHeader>
          <BreakdownLine>
            <BreakdownLabel>Cost before benefits</BreakdownLabel>
            <BreakdownCost>
              {costBeforeBenefits?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </BreakdownCost>
          </BreakdownLine>
          <BreakdownLine>
            <BreakdownLabel>Cost after benefits</BreakdownLabel>
            <BreakdownCost>
              {costAfterBenefits?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </BreakdownCost>
          </BreakdownLine>
          <BreakdownLine>
            <Inline>
              <BreakdownLabel className="pl-4">Copay Amount</BreakdownLabel>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip data-testid="about-tooltip" id="copay assistance">
                    A Copay is a flat amount of money that you are required to
                    pay to receive this service regardless of the cost of the
                    service. Look on your insurance plan's Summary of Benefits
                    and coverage to learn more.
                  </Tooltip>
                }
              >
                <HelpIcon
                  data-testid="about-tooltip-trigger"
                  className="icon icon-question-mark fs-12"
                />
              </OverlayTrigger>
            </Inline>
            <BreakdownCost>
              {copayCost?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </BreakdownCost>
          </BreakdownLine>
          <BreakdownLine>
            <Inline>
              <BreakdownLabel className="pl-4">Coinsurance</BreakdownLabel>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip data-testid="about-tooltip" id="copay assistance">
                    Coinsurance is a percentage of the cost of the service that
                    you may be required to pay. Look on your insurance plan's
                    Summary of Benefits and coverage to learn more.
                  </Tooltip>
                }
              >
                <HelpIcon
                  data-testid="about-tooltip-trigger"
                  className="icon icon-question-mark fs-12"
                />
              </OverlayTrigger>
            </Inline>
            <BreakdownCost>
              {coinsurnaceCost?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })}
            </BreakdownCost>
          </BreakdownLine>
        </div>
        <TipsContainer>
          <SubHeader>Tips to save on cost</SubHeader>
          <br />
          <TipsText>
            <BoldText>Make sure your provider is in-network</BoldText>
            Going to an out-of-network provider can raise the cost of your care.
            Keep in mind that there may be multiple providers involved in your
            services and you should make sure that all of them are in network.
            You can check a provider's in-network status by searching for them
            on the Angle Health website or app. You can also always ask your
            Care Team for help.
            <br />
            <br />
            <BoldText>Choose the right facility</BoldText>
            Different types of facilities can charge drastically different
            amounts. For example, it's often cheaper to get things done at a
            provider's office than at an "Ambulatory Surgery Center" or an
            "Outpatient Hospital". See if your provider has options for where
            you can get your procedure done and use our tool to compare the
            costs.
          </TipsText>
        </TipsContainer>
      </Col>
      <RightSideContainer></RightSideContainer>
    </InlineSpace>
  )
}

export const PricingNotFound: React.FC<{
  onClickLink: MouseEventHandler<HTMLAnchorElement>
}> = ({ onClickLink }) => {
  return (
    <div className="mt-10" style={{ marginRight: 400 }}>
      <EmptySearch title={`No search results found`}>
        Pricing is not available for the requested service at this time. Please{' '}
        <Link to={Paths.comparePrices} onClick={onClickLink}>
          search for a price estimate using an In-Network Provider.
        </Link>
      </EmptySearch>
    </div>
  )
}
