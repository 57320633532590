import {
  ApiService,
  AuthService,
  DocumentService,
  DrugService,
  FacilityService,
  ProviderService,
  MemberService
} from 'react-angle-dashboard-components'
import { AcaService } from './api/aca'
import { CareService } from './api/care'
import { ClaimsService } from './api/claims/claims'
import { HealthProfileService } from './api/health-profile'
import { PlanService } from './api/plan'
import { DocusignService } from './api/docusign'
import { NetworkResourcesService } from './api/network-resources'
import { SurveyService } from './api/survey'
import { TempAuthService } from './api/temp-auth'
import { SettingsService } from './api/settings'
import { CoverageService } from './api/coverage/coverage'
import { PriceTransparencyService } from './api/priceTransparency'
import { MemberFormService } from './api/forms'
import { TicketService } from './api/tickets'

const BASE_URL = (window as any).runtimeConfig?.apiURL

const apiUrl = `${BASE_URL}/memberportal`

export const acaService = new AcaService(apiUrl)
export const authService = new AuthService(apiUrl)
export const careService = new CareService(apiUrl)
export const claimsService = new ClaimsService(apiUrl)
export const coverageService = new CoverageService(apiUrl)
export const documentService = new DocumentService(apiUrl)
export const docusignService = new DocusignService(apiUrl)
export const drugService = new DrugService(apiUrl)
export const facilityService = new FacilityService(apiUrl)
export const formService = new MemberFormService(apiUrl)
export const healthProfileService = new HealthProfileService(apiUrl)
export const memberService = new MemberService(apiUrl)
export const networkResourcesService = new NetworkResourcesService(apiUrl)
export const planService = new PlanService(apiUrl)
export const priceTransparencyService = new PriceTransparencyService(apiUrl)
export const providerService = new ProviderService(apiUrl)
export const settingsService = new SettingsService(apiUrl)
export const surveyService = new SurveyService(apiUrl)
export const tempAuthService = new TempAuthService(apiUrl)
export const ticketService = new TicketService(BASE_URL)

export { ApiService }
