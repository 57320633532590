import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Col,
  Row,
  Form,
  PageHeaderLight,
  PageContainer,
  useToaster
} from 'react-angle-dashboard-components'
import ReactInputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { HomeLayout } from '../layouts/HomeLayout'
import { Paths } from '../../routes/paths'
import { useUserData } from '../../context/Auth/Auth'
import { docusignService, coverageService } from '../../services'
import { mapWaiveCoverageToDTO } from './mapper'
import { unformatPhoneNumber } from '@angle/utils'

const CheckRow = styled(Row)`
  .form-check {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
`

export const WaiveCoverageForm = () => {
  const userData = useUserData()
  const isTemporary = userData?.temporary === true

  const history = useHistory()
  const { show } = useToaster()
  const [signed, setSigned] = useState(false)
  const [showIframe, setShowIframe] = useState(false)
  const { data } = useQuery('getWaiveCoverageDocusign', () =>
    docusignService.getWaiveCoverageDocusignUrl()
  )

  window.addEventListener('message', (e: any) => {
    if (e.data.message === 'docusign-complete') {
      setShowIframe(false)
      setSigned(true)
    }
  })

  const { handleSubmit, register, formState, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      reason: '',
      reason_other: '',
      will_coverage_continue: '',
      name: '',
      insurer: '',
      phone: '',
      date: '',
      personalPhone: '',
      middleInitial: '',
      firstName: userData?.first_name || '',
      lastName: userData?.last_name || ''
    }
  })

  const onFormSubmit = handleSubmit((data) => {
    const DTO = mapWaiveCoverageToDTO(data)

    coverageService
      .waiveCoverage(DTO)
      .then(() => {
        history.push(Paths.waiveCoverageSuccess)
      })
      .catch((e: any) => {
        show({
          message: e.message || 'An unknown error occurred.',
          icon: 'icon-danger',
          type: 'danger'
        })
      })
  })

  const required = { value: true, message: 'Input required' }

  return (
    <HomeLayout
      noMenu
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Onboarding', link: '/onboarding' }
      ]}
    >
      <PageHeaderLight>
        <PageContainer size="md">
          <h1 className="mt-3 mb-5">Waive Coverage</h1>
        </PageContainer>
      </PageHeaderLight>
      <PageContainer className="bg-white" size="md">
        {isTemporary && (
          <Row className="my-5">
            <Col>
              <h6 className="mb-3">
                <b>Employee Information</b>
              </h6>
              <Row>
                <Col>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    {...register('firstName', { required })}
                    type="text"
                    placeholder="First name"
                    className="bg-white"
                  />

                  <span className="text-danger">
                    {formState.isSubmitted &&
                      formState.errors?.firstName?.message}
                  </span>
                </Col>
                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    {...register('lastName', { required })}
                    type="text"
                    placeholder="Last name"
                    className="bg-white"
                  />
                  <span className="text-danger">
                    {formState.isSubmitted &&
                      formState.errors?.firstName?.message}
                  </span>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Label>Middle Initial</Form.Label>
                  <Form.Control
                    {...register('middleInitial')}
                    type="text"
                    placeholder="Middle initial"
                    className="bg-white"
                  />
                </Col>
                <Col>
                  <Form.Label>Personal Phone Number</Form.Label>
                  <Controller
                    name="personalPhone"
                    rules={{
                      required,
                      validate: {
                        noLessThan10: (value) => {
                          if (
                            String(value.match(/[0-9]+/g)?.join('')).length < 10
                          )
                            return 'Phone number is invalid.'
                          return true
                        }
                      }
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        as={ReactInputMask}
                        mask="999-999-9999"
                        type="tel"
                        placeholder="Personal Phone Number"
                        className="bg-white"
                      />
                    )}
                  />

                  <span className="text-danger">
                    {formState.isSubmitted &&
                      formState.errors?.personalPhone?.message}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <CheckRow>
          <Col>
            <h6 className="mb-3">
              <b>Reason for waiving coverage:</b>
            </h6>
            <Form.Check
              {...register('reason', { required })}
              type="radio"
              value="Other employer group"
              label="Other employer group"
            />
            <Form.Check
              {...register('reason')}
              type="radio"
              value="Individual coverage"
              label="Individual coverage"
            />
            <Form.Check
              {...register('reason')}
              type="radio"
              value="Governmental (Medicare, Medicaid, Tricare, etc.)"
              label="Governmental (Medicare, Medicaid, Tricare, etc.)"
            />
            <Form.Check
              {...register('reason')}
              type="radio"
              value="Other"
              label={
                <Form.Control
                  {...register('reason_other')}
                  type="text"
                  placeholder="Other"
                  className="bg-white"
                />
              }
            />
            <span className="text-danger">
              {formState.isSubmitted && formState.errors?.reason?.message}
            </span>
          </Col>
        </CheckRow>
        <Row className="mt-5">
          <Col>
            <h6 className="mb-3">
              <b>Insurer (Optional)</b>
            </h6>
            <Row>
              <Col>
                <Form.Label>Policyholder Name</Form.Label>
                <Form.Control
                  {...register('name')}
                  type="text"
                  placeholder="Policyholder name"
                  className="bg-white"
                />
              </Col>
              <Col>
                <Form.Label>Insurer Name</Form.Label>
                <Form.Control
                  {...register('insurer')}
                  type="text"
                  placeholder="Insurer name"
                  className="bg-white"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <Form.Label>Phone Number</Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    validate: {
                      noLessThan10: (value) => {
                        const plainStr = unformatPhoneNumber(value)
                        if (!plainStr) return true
                        if (plainStr.length < 10) {
                          return 'Phone number is invalid.'
                        }
                        return true
                      }
                    }
                  }}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      as={ReactInputMask}
                      mask="999-999-9999"
                      type="tel"
                      placeholder="Phone Number"
                      className="bg-white"
                    />
                  )}
                />

                <span className="text-danger">
                  {formState.isSubmitted && formState.errors?.phone?.message}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h6 className="mb-3">
              <b>Will Coverage Continue:</b>
            </h6>
            <Form.Check
              {...register('will_coverage_continue')}
              type="radio"
              value="Yes"
              label="Yes"
            />
            <Form.Check
              {...register('will_coverage_continue')}
              type="radio"
              value="No"
              label="No"
            />
          </Col>
        </Row>
        {!signed && (
          <Row className="my-5">
            <Col>
              <Row>
                <Col>
                  <Form.Label>Employee Signature</Form.Label>

                  <Button
                    className="d-block"
                    variant="primary"
                    ghost
                    onClick={() => setShowIframe(true)}
                  >
                    Attach Your Signature
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {showIframe && (
          <iframe
            src={data?.redirect_url}
            title="waive-coverage-docusign"
            style={{ width: '100%', height: 600 }}
          />
        )}
        <hr />
        <div className="d-flex justify-content-between my-5">
          <Button
            variant="primary"
            ghost
            onClick={() => history.push(Paths.onboarding + '/plan')}
            style={{ visibility: isTemporary ? 'hidden' : 'visible' }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onFormSubmit} disabled={!signed}>
            Continue
          </Button>
        </div>
      </PageContainer>
    </HomeLayout>
  )
}

export default WaiveCoverageForm
