import { useQuery } from 'react-query'

import { networkResourcesService } from '../services'

export const useDrugsNetworkResources = (
  query = 'network-resources-drugs',
  retry = 0
) =>
  useQuery(
    query,
    networkResourcesService.findDrugsNetworkResources.bind(
      networkResourcesService
    ),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )

export const useFacilitiesNetworkResources = (
  query = 'network-resources-facilities',
  retry = 0
) =>
  useQuery(
    query,
    networkResourcesService.findFacilitiesNetworkResources.bind(
      networkResourcesService
    ),
    {
      retry,
      refetchOnWindowFocus: false
    }
  )
