import { useState } from 'react'
import { useQuery } from 'react-query'
import { memberService } from '../../../services'
import { SelectedDependentsKey } from '../../../components/SelectDependentForPlan/SelectDependentForPlan'

export const mapDependentsMinInfo = (data: any) => {
  return data?.dependents?.map((dependent: any) => ({
    name: dependent.name?.first_name + ' ' + dependent.name?.last_name,
    id: dependent.member_id
  }))
}

export const useDependents = (previouslySelectedDependents?: any[]) => {
  const [selectedDependentsKey, setSelectedDependentsKey] =
    useState<SelectedDependentsKey>({})
  const {
    data: dependents,
    isLoading: isLoadingDependents,
    error: dependentsError
  } = useQuery(
    'getDependentsMinInfo',
    () => memberService.getDependents(mapDependentsMinInfo),
    {
      onSuccess: (data) => {
        const updatedSelectedDependentsKey: SelectedDependentsKey = {}
        data.forEach((dependent: any) => {
          updatedSelectedDependentsKey[dependent.id] = true
        })

        previouslySelectedDependents?.forEach((dep: any) => {
          if (updatedSelectedDependentsKey[dep.member_id] !== undefined) {
            updatedSelectedDependentsKey[dep.member_id] = true
          }
        })

        setSelectedDependentsKey(updatedSelectedDependentsKey)
      }
    }
  )

  return {
    dependents,
    isLoadingDependents,
    dependentsError,
    selectedDependentsKey,
    setSelectedDependentsKey
  }
}
