import React from 'react'
import {
  Button,
  Form,
  Logo,
  useToaster
} from 'react-angle-dashboard-components'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { Paths } from '../../../routes/paths'
import { useLang } from '../../../hooks/use-language'
import { authService as auth } from '../../../services'
import { LoginPageContainer, LogoWrapper, GoBackButton } from '../style'
import { LoginPageCard } from '../LoginPageCard'

export const ForgotPassword = () => {
  const { showError, showSuccess } = useToaster()
  const history = useHistory()
  const { forgotPassword: messages } = useLang().messages
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  })

  const onChangesSuccess = async (response: any) => {
    showSuccess(
      'An email has been sent with instructions for resetting your password.'
    )
  }

  const onChangesFail = (error: any) => {
    showError(error.message || '')
  }

  const forgotPassword = useMutation(
    (data: { email: string }) => auth.forgotPassword(data),
    {
      onSuccess: onChangesSuccess,
      onError: onChangesFail
    }
  )

  const onSubmit = handleSubmit(async (data: { email: string }) => {
    forgotPassword.mutate(data)
  })

  return (
    <LoginPageContainer>
      <LogoWrapper className="py-5">
        <Logo variant="default" color="primary" />
      </LogoWrapper>
      <LoginPageCard headerText={messages.title} subheaderText={messages.info}>
        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              {...register('email', {
                required: true
              })}
              type="email"
              placeholder="Email Address"
            />
          </Form.Group>
          <Button type="submit" block>
            Send Recovery Link
          </Button>
          <GoBackButton
            variant="link"
            block
            onClick={() => history.push(Paths.login)}
          >
            Go Back
          </GoBackButton>
        </Form>
      </LoginPageCard>
    </LoginPageContainer>
  )
}

export default ForgotPassword
