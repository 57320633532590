import styled from 'styled-components'

export const BenefitLabel = styled.td`
  padding: 0px 15px 20px 15px;
`

export const BenefitData = styled.td`
  padding: 0px 15px 20px 15px;
  font-weight: 600;
`
