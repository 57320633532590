import React from 'react'

export const ErrorMessage = () => {
  return (
    <div className="text-danger py-2">
      Something went wrong obtaining your available plans. Please reach out to
      our customer service team to resolve this issue.
    </div>
  )
}
