import React from 'react'
import { Button, Col, Row } from 'react-angle-dashboard-components'
import { Paths } from '../../../../../routes/paths'
import { PlanCard } from './PlanCard'
import { DateHandler } from '@angle/utils'
import { findPremium } from '../../../utils/findPremium'
import { PremiumData } from '../../../types'

export const PlanContent: React.FC<{
  availablePlans: any[]
  coverageEffectiveDate: string
  openSBCModal: Function
  onClickSelectPlan: (planId: string) => void
  premiums: PremiumData[]
}> = ({ availablePlans, coverageEffectiveDate, openSBCModal, onClickSelectPlan, premiums }) => {
  const handleWaiveCoverage = () => {
    window.location.assign(window.location.origin + Paths.waiveCoverage)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center pb-3">
        <h3>
          Coverage will begin on{' '}
          {DateHandler.formatForClient(coverageEffectiveDate)}
        </h3>
        <Button
          variant="primary"
          ghost
          size="sm"
          className="fs-16"
          onClick={handleWaiveCoverage}
        >
          Waive coverage
        </Button>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p>
          This is an estimate of your payment based on the enrollment
          information you and your employer have provided.
        </p>
      </div>
      <Row className="gx-4 pb-3">
        {availablePlans?.map((plan: any) => {
          const premium = findPremium(plan.plan_id, premiums)

          return (
            <Col sm={6} md={4} key={plan.plan_id}>
              <PlanCard
                plan={plan}
                onClickSelectPlan={() => onClickSelectPlan(plan.plan_id)}
                onMoreDetails={() => openSBCModal(plan)}
                premium={premium}
              />
            </Col>
          )
        })}
      </Row>
    </>
  )
}
