import React, { SetStateAction, Dispatch } from 'react'
import { Card } from 'react-angle-dashboard-components'
import { ReactComponent as MetlifeLogo } from '../../../../../../../assets/metlife.svg'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { BenefitData, BenefitLabel } from '../../style'
import { DropdownComponent } from './Dropdown'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const CriticalCard: React.FC<{
  plan?: any
  onClickSelectPlan?: any
  currentAmount: number
  setCurrentAmount: Dispatch<SetStateAction<number>>
  premium?: number
  isSelected: boolean
}> = ({
  plan,
  onClickSelectPlan,
  currentAmount,
  setCurrentAmount,
  premium,
  isSelected
}) => {
  const employee_benefit_amounts = plan?.employee_benefit_amounts || []

  return (
    <Card style={{ width: 350 }} className="mr-4 mb-3">
      <Card.Header className="px-3 pb-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Critical Illness</h5>
            <div className="ml-2">
              <MetlifeLogo />
            </div>
          </div>
          <div className="d-flex pt-2">
            <div className="w-50">
              {currentAmount && (
                <DropdownComponent
                  currentAmount={currentAmount}
                  setCurrentAmount={setCurrentAmount}
                  amountList={employee_benefit_amounts}
                />
              )}

              <span className="d-block">Coverage Amount</span>
            </div>
            <PlanCardPremium premium={premium} />
          </div>
          <SelectPlanButton
            isSelected={isSelected}
            onClick={() => onClickSelectPlan(plan.plan_id)}
          />
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="font-weight-bold" style={{ padding: '0px 15px' }}>
                Condition
              </th>
              <th
                style={{ padding: 15 }}
                className="font-weight-bold text-nowrap"
              >
                Initial Benefit
              </th>
              <th style={{ padding: '10px 15px' }} className="font-weight-bold">
                Recurrence Benefit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel>Heart Attack</BenefitLabel>
              <BenefitData>100%</BenefitData>
              <BenefitData className="pt-3">50% of initial benefit</BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Skin Cancer</BenefitLabel>
              <BenefitData>5%</BenefitData>
              <BenefitData>None</BenefitData>
            </tr>
            <tr>
              <BenefitLabel className="text-nowrap">
                Kidney Failure
              </BenefitLabel>
              <BenefitData>100%</BenefitData>
              <BenefitData>50% of initial benefit</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="px-3 py-4 fs-20 font-weight-bold border-bottom">
        <div className="d-flex">
          <div className="w-50">Spouse</div>
          <div className="w-50">
            100%
            <span className="fs-14 font-weight-normal d-block">
              Of employees initial benefit
            </span>
          </div>
        </div>
        <div className="d-flex pt-4">
          <div className="w-50">Child</div>
          <div className="w-50">
            50%
            <span className="fs-14 font-weight-normal d-block">
              Of employees initial benefit
            </span>
          </div>
        </div>
      </div>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
