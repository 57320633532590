import * as React from 'react'
import { SVGProps } from 'react'

const SearchOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g id="Search">
      <g id="Icons/iOS-Glyph/Search">
        <path
          id="Vector"
          fill="black"
          d="M8.66673 2C4.99273 2 2.00006 4.99266 2.00006 8.66667C2.00006 12.3407 4.99273 15.3333 8.66673 15.3333C10.2644 15.3333 11.7317 14.766 12.8816 13.8242L16.862 17.8047C16.9235 17.8687 16.9971 17.9198 17.0785 17.9549C17.1599 17.9901 17.2475 18.0087 17.3362 18.0096C17.4249 18.0105 17.5129 17.9937 17.595 17.9602C17.6771 17.9267 17.7517 17.8771 17.8144 17.8144C17.8772 17.7517 17.9267 17.6771 17.9603 17.5949C17.9938 17.5128 18.0106 17.4248 18.0097 17.3361C18.0088 17.2474 17.9902 17.1598 17.955 17.0784C17.9198 16.997 17.8687 16.9234 17.8048 16.862L13.8243 12.8815C14.7661 11.7316 15.3334 10.2643 15.3334 8.66667C15.3334 4.99266 12.3407 2 8.66673 2ZM8.66673 3.33333C11.6201 3.33333 14.0001 5.71325 14.0001 8.66667C14.0001 11.6201 11.6201 14 8.66673 14C5.71332 14 3.3334 11.6201 3.3334 8.66667C3.3334 5.71325 5.71332 3.33333 8.66673 3.33333Z"
        />
      </g>
    </g>
  </svg>
)

export default SearchOutline
