import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { Alert, Card, Col, Form, Row } from 'react-angle-dashboard-components'
import { DateHandler } from '@angle/utils'
import { IAccums, IPlan } from '../../../../interfaces/coverage'
import { PlansAccumMember } from '../PlanContent'
import { ReactComponent as InfoCircle } from '../../../../assets/infoCircle.svg'
import { ReactComponent as RestartIcon } from '../../../../assets/restart.svg'
import InnerCard from './InnerCard'
import AccumsCard from './AccumsCard'
import AccumDetails from './AccumDetails'


const networkSelectOptions = [
  {
    display: 'In-Network',
    value: 'inn'
  },
  {
    display: 'Out-of-Network',
    value: 'oon'
  }
]

export const getRelevantDedFields = (accum?: IAccums, planDetails?: IPlan, selectedNetwork?: string) => {

  if (planDetails && accum && selectedNetwork) {
    return {
      indNum: selectedNetwork === 'inn' ? accum?.indInnDed || 0 : accum?.indOonDed || 0,
      indDen: selectedNetwork === 'inn' ? accum?.indInnDedMax || 1 : accum?.indOonDedMax || 1,
      indMed: selectedNetwork === 'inn' ? accum?.indInnDedMed || 0 : accum?.indOonDedMed || 0,
      indRx: selectedNetwork === 'inn' ? accum?.indInnDedRx || 0 : accum?.indOonDedRx || 0,
      famNum: selectedNetwork === 'inn' ? accum?.famInnDed || 0 : accum?.famOonDed || 0,
      famDen: selectedNetwork === 'inn' ? accum?.famInnDedMax || 1 : accum?.famOonDedMax || 1,
      famMed: selectedNetwork === 'inn' ? accum?.famInnDedMed || 0 : accum?.famOonDedMed || 0,
      famRx: selectedNetwork === 'inn' ? accum?.famInnDedRx || 0 : accum?.famOonDedRx || 0,
      showIndFlag: 
        planDetails.coverageTier === 'single' || planDetails.coverageTier === 'employee' ? true :
        planDetails.planType === 'aggregate' ?
          false :
          selectedNetwork === 'inn' ?
            !!(accum.indInnDedMax) ? true : false
            : !!(accum.indOonDedMax) ? true : false,
      showFamFlag:
        planDetails.coverageTier === 'single' || planDetails.coverageTier === 'employee' ? false :
        planDetails.planType === 'embedded' && planDetails.coveredMembers.length === 1 ?
          false : 
          selectedNetwork === 'inn' ?
            !!(accum.famInnDedMax) ? true : false
            : !!(accum.famOonDedMax) ? true : false
    }
  } else {
    return {
      indNum: 0,
      indDen: 1,
      indMed: 0,
      indRx: 0,
      famNum: 0,
      famDen: 1,
      famMed: 0,
      famRx: 0,
      showIndFlag: false,
      showFamFlag: false
    }
  }
}

export const getRelevantOopFields = (accum?: IAccums, planDetails?: IPlan, selectedNetwork?: string) => {
  if (planDetails && accum && selectedNetwork) {
    let isUnlimited = false

    let showIndFlag =
      planDetails.coverageTier === 'single' || planDetails.coverageTier === 'employee' ? true :
      planDetails.planType === 'aggregate' ?
        false :
        selectedNetwork === 'inn' ?
          !!(accum.indInnOopMax) ? true : false
          : !!(accum.indOonOopMax) ? true : false
    let indDen = selectedNetwork === 'inn' ? accum?.indInnOopMax : accum?.indOonOopMax

    let showFamFlag =
      planDetails.coverageTier === 'single' || planDetails.coverageTier === 'employee' ? false :
      planDetails.planType === 'embedded' && planDetails.coveredMembers.length === 1 ?
        false : 
        selectedNetwork === 'inn' ?
          !!(accum.famInnOopMax) ? true : false
          : !!(accum.famOonOopMax) ? true : false
    let famDen = selectedNetwork === 'inn' ? accum?.famInnOopMax : accum?.famOonOopMax

    if (indDen === null || indDen === 999999999) {
      showIndFlag = true
      isUnlimited = true
    } else if (indDen === undefined) {
      indDen = 1
    }

    if (famDen === null || famDen === 999999999) {
      showFamFlag = true
      isUnlimited = true
    } else if (famDen === undefined) {
      famDen = 1
    }

    return {
      indNum: selectedNetwork === 'inn' ? accum?.indInnOop || 0 : accum?.indOonOop || 0,
      indDen: indDen,
      indMed: selectedNetwork === 'inn' ? accum?.indInnOopMed || 0 : accum?.indOonOopMed || 0,
      indRx: selectedNetwork === 'inn' ? accum?.indInnOopRx || 0 : accum?.indOonOopRx || 0,
      famNum: selectedNetwork === 'inn' ? accum?.famInnOop || 0 : accum?.famOonOop || 0,
      famDen: famDen,
      famMed: selectedNetwork === 'inn' ? accum?.famInnOopMed || 0 : accum?.famOonOopMed || 0,
      famRx: selectedNetwork === 'inn' ? accum?.famInnOopRx || 0 : accum?.famOonOopRx || 0,
      isUnlimited: isUnlimited,
      showIndFlag: showIndFlag,
      showFamFlag: showFamFlag   
    }
  } else {
    return {
      indNum: 0,
      indDen: 1,
      indMed: 0,
      indRx: 0,
      famNum: 0,
      famDen: 1,
      famMed: 0,
      famRx: 0,
      isUnlimited: false,
      showIndFlag: false,
      showFamFlag: false
    }
  }
}

interface PlanGlanceCardProps {
  accum: IAccums | undefined
  isLoading?: boolean
  planDetails: IPlan | undefined
  selectedMemberId: string | undefined
  selectedMemberName: string | undefined
  selectedPlansAccumMembers: PlansAccumMember[]
  setSelectedMemberId: (memberId: string) => void
}

const PlanGlanceCard = ({ accum, isLoading, planDetails, selectedMemberId, selectedMemberName, selectedPlansAccumMembers, setSelectedMemberId }: PlanGlanceCardProps) => {
  const planRestartDate = planDetails?.planRestartDate || ''
  
  const [selectedNetwork, setSelectedNetwork] = useState<string>('inn')
  const [memberSelect, setMemberSelect] = useState<{ display: string, value: string}[]>([])
  const [memberPlanInnPhase, setMemberPlanInnPhase] = useState<number>(0)
  const [memberPlanOonPhase, setMemberPlanOonPhase] = useState<number>(0)
  const [isExpiredPlan, setIsExpiredPlan] = useState<boolean>(false)

  useEffect(() => {
    if (selectedPlansAccumMembers.length) {

      const coveredMembersSelect = selectedPlansAccumMembers.map((member) => {
        return {
          display: member.relationship === 'employee' ? `${member.name} (Subscriber)` : member.name,
          value: member.memberId
        }
      })

      setMemberSelect(coveredMembersSelect)
    }
  }, [selectedPlansAccumMembers])
  
  useEffect(() => {
    if (planDetails && accum) {
      setMemberPlanInnPhase(accum.innCurrentStep)
      setMemberPlanOonPhase(accum.oonCurrentStep)

      const endDate = moment(planDetails.endDate, 'MM/DD/YYYY')
      const isCurrentPlan = endDate.isAfter(moment())
      setIsExpiredPlan(!isCurrentPlan)
    }
  }, [planDetails, accum])

  const getInnerCardOne = () => {
    const famTitle = planDetails?.planType === 'aggregate' && planDetails.coveredMembers.length === 1 ? `${selectedMemberName}'s Deductible` : 'Family Deductible'
    
    const { showIndFlag, showFamFlag, indNum, indDen, indMed, indRx, famNum, famDen, famMed, famRx } = getRelevantDedFields(accum, planDetails, selectedNetwork)
      
    return (
      <InnerCard
        isLoading={isLoading}
        isSelected={isExpiredPlan ? false : selectedNetwork === 'inn' ? memberPlanInnPhase === 1 : memberPlanOonPhase === 1}
        learnMore={{
          mode: planDetails?.planType === 'aggregate' && planDetails.coveredMembers.length > 1 ? 'dedAggFam' : planDetails?.planType === 'embedded' && planDetails.coveredMembers.length > 1 ? 'dedEmbFam' : 'ded',
          tooltipText: 'Learn more about deductible'
        }}
        memberName={selectedMemberName}
        step={1}
        subTitle="When you visit a provider you pay for all covered costs until the
  deductible is met"
        title="Plan Deductible"
      >
        {showIndFlag && (<AccumsCard
          title={`${selectedMemberName}'s Deductible`}
          numerator={indNum}
          denominator={indDen}
          isLoading={isLoading}
          seeMoreContent={
            <AccumDetails
              numerator={indNum}
              denominator={indDen}
              medClaimsCost={indMed}
              rxClaimsCost={indRx}
            />
          }
        />)}
        {showFamFlag && (<AccumsCard
          title={famTitle}
          numerator={famNum}
          denominator={famDen}
          isLoading={isLoading}
          seeMoreContent={
            <AccumDetails
              numerator={famNum}
              denominator={famDen}
              medClaimsCost={famMed}
              rxClaimsCost={famRx}
            />
          }
        />)}
        {!showIndFlag && !showFamFlag && (
          <Alert
            className={`my-0 rounded-0 text-center w-full py-2 flex align-items-center border-0`}
            style={{
              backgroundColor: '#17A2B833'
            }}
            variant={'warning'}
            show={true}
          >
            <InfoCircle style={{ height: '1rem'}} />
            <span className="mr-3">
              The plan you are on has no deductible.
            </span>
          </Alert>
        )}
      </InnerCard>
    )
  }

  const getInnerCardThree = () => {
    const famTitle = planDetails?.planType === 'aggregate' && planDetails.coveredMembers.length === 1 ? `${selectedMemberName}'s Out-of-Pocket Max` : 'Family Out-of-Pocket Max'
    
    const { showIndFlag, showFamFlag, indNum, indDen, indMed, indRx, famNum, famDen, famMed, famRx, isUnlimited } = getRelevantOopFields(accum, planDetails, selectedNetwork)
    

    return (
      <InnerCard
        isSelected={isExpiredPlan ? false : selectedNetwork === 'inn' ? memberPlanInnPhase === 3 : memberPlanOonPhase === 3}
        step={3}
        title={ isUnlimited ? "Unlimited Out-of-Pocket Max" : "Plan Covers 100%" }
        subTitle={ isUnlimited ? `This plan has an unlimited out-of-pocket maximum. Your ${ selectedNetwork === 'inn' ? 'in-network' : 'out-of-network' } out-of-pocket expenses include your deductible, copay and coinsurance for covered medical and pharmacy services` : "Once the out-of-pocket maximum is met, the plan pays 100% for your covered services until the next benefit period begins" }
        learnMore={ isUnlimited ? undefined : {
          mode: 'oop',
          tooltipText: 'Learn more about 100% coverage'
        }}
        memberName={selectedMemberName}
        isLoading={isLoading}
      >
        {showIndFlag && (<AccumsCard
          title={`${selectedMemberName}'s Out-of-Pocket Max`}
          numerator={indNum}
          denominator={indDen}
          isLoading={isLoading}
          isUnlimited={isUnlimited}
          seeMoreContent={
            <AccumDetails
              isUnlimited={isUnlimited}
              numerator={indNum}
              denominator={indDen}
              medClaimsCost={indMed}
              rxClaimsCost={indRx}
            />
          }
        />)}
        {showFamFlag && (<AccumsCard
          title={famTitle}
          numerator={famNum}
          denominator={famDen}
          isLoading={isLoading}
          isUnlimited={isUnlimited}
          seeMoreContent={
            <AccumDetails
              isUnlimited={isUnlimited}
              numerator={famNum}
              denominator={famDen}
              medClaimsCost={famMed}
              rxClaimsCost={famRx}
            />
          }
        />)}
      </InnerCard>
    )
  }

  return (
    <Card>
      <Card.Body className="py-3">
        <Row>
          <Col className="d-flex flex-column justify-content-center">
            <h6 className="mb-0">Your Plan at a Glance</h6>
            {planRestartDate && (
              <span className="d-flex flex-row align-items-center">
                <RestartIcon className="mr-1" />
                <span className="text-secondary fs-12">
                  Restarts on {DateHandler.formatForClient(planRestartDate)}
                </span>
              </span>
            )}
          </Col>
          <Col className="col-auto">
            <Form.Control
              as="select"
              className="w-auto"
              disabled={memberSelect?.length === 1}
              onChange={(e) => setSelectedMemberId(e.target.value)}
              value={selectedMemberId}>
              {memberSelect?.map(({ display, value }) => (
                <option value={value}>{display}</option>
              ))}
            </Form.Control>
          </Col>
          <Col className="col-auto">
            <Form.Control
              as="select"
              className="w-auto"
              onChange={(e) => setSelectedNetwork(e.target.value)}
              value={selectedNetwork}>
              {networkSelectOptions.map(({ display, value }) => (
                <option value={value}>{display}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            {getInnerCardOne()}
          </Col>
          <Col>
            <InnerCard
              isSelected={isExpiredPlan ? false : selectedNetwork === 'inn' ? memberPlanInnPhase === 2 : memberPlanOonPhase === 2}
              memberName={selectedMemberName}
              step={2}
              title="Member pays for a percentage of costs"
              subTitle="After your deductible is met, both you and your plan pay a predetermined percentage of health care costs for covered services"
              isLoading={isLoading}
            />
          </Col>
          <Col>
            {getInnerCardThree()}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PlanGlanceCard