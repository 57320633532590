import * as React from 'react'
import { SVGProps } from 'react'

const DocumentSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 45"
    fill="currentColor"
    {...props}
  >
    <g id="Document" clipPath="url(#clip0_109_11)">
      <path
        id="Vector"
        d="M30.7687 1.62881C30.478 1.33729 30.1325 1.10602 29.7522 0.948263C29.3719 0.790504 28.9642 0.709356 28.5525 0.709473H3.44614C2.61426 0.710091 1.8167 1.041 1.22871 1.62947C0.640729 2.21794 0.31048 3.0158 0.310547 3.84768V41.5065C0.310458 42.3384 0.640676 43.1362 1.22866 43.7247C1.81665 44.3132 2.61425 44.6441 3.44614 44.6448H28.5521C29.3842 44.6432 30.1817 44.3121 30.7704 43.724C31.359 43.1359 31.6909 42.3386 31.6932 41.5065V3.84768C31.6932 3.43512 31.6115 3.02662 31.4529 2.64579C31.2942 2.26495 31.0617 1.91931 30.7687 1.62881ZM20.8919 38.9174H6.29868V36.4853H20.8919V38.9174ZM26.5667 34.0533H6.29868V31.6212H26.5664L26.5667 34.0533ZM26.5667 29.1887H6.29868V26.7565H26.5664L26.5667 29.1887ZM26.5667 24.324H6.29868V21.8919H26.5664L26.5667 24.324ZM26.5667 19.4594H6.29868V17.0272H26.5664L26.5667 19.4594ZM26.5667 14.5947H6.29868V7.29791H26.5664L26.5667 14.5947Z"
      />
      <path
        id="Vector_2"
        d="M30.7302 1.59619L26.0442 7.3002H26.5663V14.597H20.0502L18.0518 17.0295H26.567V19.4617H16.0533L14.0549 21.8946H26.5663V24.3267H12.0569L10.0585 26.7597H26.5663V29.1918H8.06062L0.310547 38.6255V41.5096C0.310458 42.3415 0.640726 43.1394 1.22871 43.7279C1.8167 44.3163 2.6143 44.6473 3.44619 44.6479H28.5521C29.3842 44.6463 30.1817 44.3152 30.7704 43.7271C31.359 43.139 31.6909 42.3417 31.6932 41.5096V3.84997C31.6933 3.43742 31.6116 3.02894 31.453 2.64811C31.2944 2.26727 31.0619 1.92162 30.769 1.6311C30.7567 1.61869 30.7427 1.60827 30.7302 1.59619ZM20.8917 38.9197H6.29853V36.4876H20.8917V38.9197ZM26.5666 34.0556H6.29853V31.6235H26.5663L26.5666 34.0556Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_109_11">
        <rect width={32} height={45} />
      </clipPath>
    </defs>
  </svg>
)

export default DocumentSolid
