import { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import { coverageService } from '../../../services'
import { benefitsMap } from 'react-angle-dashboard-components'
import { formatPhoneNumber } from '@angle/utils'

export interface VoluntaryBenefitsListItem {
  carrier: string
  benefitType: string
  phone: string
}
interface VoluntaryBenefitsList {
  [key: string]: null | any
}

interface options {
  disabled?: boolean
}

export const useGetVoluntaryCoverage = (options?: options) => {
  const disabled = options?.disabled
  
  const [allVoluntaryBenefits, setVoluntaryBenefits] =
    useState<VoluntaryBenefitsList>({
      dental: null,
      vision: null,
      accidental: null
    })

  const handleSuccess = (benefitKey: string, data: any) => {
    const carrier = data?.family_coverage?.plan?.carrier
    if (carrier) {
      setVoluntaryBenefits((prev) => ({
        ...prev,
        [benefitKey]: {
          carrier: carrier?.name || '',
          benefitType: benefitsMap[benefitKey]?.displayName || '',
          phone: formatPhoneNumber(carrier?.phone) || ''
        }
      }))
    }
  }

  const { isLoading: isLoadingDental } = useQuery(
    'getDisplayDental',
    () => coverageService.getDisplayPlan('dental'),
    {
      onSuccess: (data) => {
        handleSuccess('dental', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingAccidental } = useQuery(
    'getDisplayAccidental',
    () => coverageService.getDisplayPlan('accidental'),
    {
      onSuccess: (data) => {
        handleSuccess('accidental', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingBasicLife } = useQuery(
    'getDisplayBasicLife',
    () => coverageService.getDisplayPlan('basic-life-add'),
    {
      onSuccess: (data) => {
        handleSuccess('basic_life_add', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingHospital } = useQuery(
    'getDisplayHospital',
    () => coverageService.getDisplayPlan('hospital-indemnity'),
    {
      onSuccess: (data) => {
        handleSuccess('hospital_indemnity', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingIdentity } = useQuery(
    'getDisplayIdentity',
    () => coverageService.getDisplayPlan('identity'),
    {
      onSuccess: (data) => {
        handleSuccess('identity', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingVision } = useQuery(
    'getDisplayVision',
    () => coverageService.getDisplayPlan('vision'),
    {
      onSuccess: (data) => {
        handleSuccess('vision', data)
      },
      enabled: !disabled
    }
  )

  const { isLoading: isLoadingCritical } = useQuery(
    'getDisplayCritical',
    () => coverageService.getDisplayPlan('critical-illness'),
    {
      onSuccess: (data) => {
        handleSuccess('critical_illness', data)
      },
      enabled: !disabled
    }
  )

  const isLoading =
    isLoadingDental ||
    isLoadingCritical ||
    isLoadingAccidental ||
    isLoadingBasicLife ||
    isLoadingHospital ||
    isLoadingVision ||
    isLoadingBasicLife ||
    isLoadingIdentity

  const voluntaryBenefits = useMemo(() => {
    return Object.values(allVoluntaryBenefits).filter(
      (benefit: any) => !!benefit
    )
  }, [allVoluntaryBenefits])

  return { isLoading, voluntaryBenefits }
}
