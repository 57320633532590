import { DateHandler } from '@angle/utils'

export const mapper = (data: any) => {
  return {
    firstName: data?.name?.first_name || '',
    middleName: data?.name?.middle_name || '',
    lastName: data?.name?.last_name || '',
    suffix: data?.name?.suffix || '',
    selfIdentify:
      data.identified_gender !== 'unknown' && data.identified_gender
        ? data?.identified_gender
        : '',
    preferredName: data?.preferred_name || '',
    dob: DateHandler.formatForClient(data.date_of_birth),
    gender: data.gender && data.gender !== 'unknown' ? data?.gender : '',
    ssn: data?.ssn,
    preferredLanguage: data?.language,
    serverStep: data?.in_progress_state,
    inRenewal: data?.in_renewal
  }
}
