import React from 'react'

import { currencyFormatter } from '@angle/utils'
import { ProgressBar } from 'react-angle-dashboard-components'
import styled from 'styled-components'

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`

interface AccumDetailsProps {
  numerator: number
  denominator: number
  medClaimsCost: number
  rxClaimsCost: number
  isUnlimited?: boolean
  widgetMode?: boolean
  isMobile?: boolean
}

const AccumDetails = ({
  numerator,
  denominator,
  medClaimsCost,
  rxClaimsCost,
  isUnlimited = false,
  widgetMode = false,
  isMobile = false
}: AccumDetailsProps) => {
  return (
    <div>
      { !isUnlimited && widgetMode && (
        <>
          <h4 className="my-0 mb-1 fs-24" style={{
            position: 'relative'
          }}>
            {currencyFormatter.format(numerator)}
            <span
              style={{
                color: '#9AA6B8',
                fontSize: 16
              }}
            >
              /{ isUnlimited ? 'Unlimited' : currencyFormatter.format(denominator) }
            </span>
            {!isMobile && (<small className="text-secondary" style={{
              position: 'absolute',
              bottom: 0,
              right: 0
            }}>
              {`${currencyFormatter.format(denominator - numerator)} In-Network remaining`}
            </small>)}
          </h4>
          {isMobile && (<small className="text-secondary">
            {`${currencyFormatter.format(denominator - numerator)} In-Network remaining`}
          </small>)}
        </>
      ) }
      { !isUnlimited && (<ProgressBar now={(numerator/denominator) * 100} className="mb-1 mt-2" />)}
      { !isUnlimited && !widgetMode && (<small className="text-secondary">{`${currencyFormatter.format(denominator - numerator)} remaining`}</small>)}
      {!widgetMode && (<>
        <Line>
          <div className="fs-13">Medical Claims</div>
          <div className="fs-13 text-muted">${medClaimsCost.toFixed(2)}</div>
        </Line>
        <div className="border-bottom" />
        <Line>
          <div className="fs-13">RX Claims</div>
          <div className="fs-13 text-muted">${rxClaimsCost.toFixed(2)}</div>
        </Line>
      </>)}
    </div>
  )
}

export default AccumDetails
