import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Card,
  IMemberInfo
} from 'react-angle-dashboard-components'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { LoginPageCard } from '../../../LoginPageCard'
import { GoBackButton } from '../../../style'
import { useForm } from 'react-hook-form'
import { validateEmail } from '@angle/utils'
import { NoEmailModal } from './NoEmailModal'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { memberService, ApiService } from '../../../../../services'
import { VerificationType } from '../../types'
import { FullstoryIdentifiers } from '../../utils/identifyFullstoryUser'

export const CreateAccountEmail = () => {
  const history = useHistory()

  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const [showModal, setShowModal] = useState(false)

  const token = sessionStorage.getItem('token')
  const authuserId = sessionStorage.getItem('authuser_id')

  useEffect(() => {
    ApiService._token = token
  }, [token])

  const queryClient = useQueryClient()

  const { data: memberData } = useQuery(
    ['getMember', token],
    () => memberService.getMemberInfo(),
    { enabled: !!token, onSuccess: (data: any) => {} }
  )

  const homeEmail = memberData?.contact_info.home_email
  const workEmail = memberData?.contact_info.work_email

  const currentEmail = homeEmail || workEmail || ''

  const hasEmail = !!currentEmail

  const headerText = hasEmail ? 'Verify email address' : 'Add an email address'
  const subheaderText = hasEmail
    ? 'This is the email address we received and have on file for you. Is this correct? It is recommended to use your personal email as this will be used as part of your log in credentials and ongoing communication.'
    : 'Our records indicate we don’t have an email address on file for you, would you like to add one?'

  const { handleSubmit, formState, register, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { email: currentEmail, emailConfirm: '', newEmail: '' }
  })

  useEffect(() => {
    setValue('email', currentEmail)
  }, [currentEmail, setValue])

  const email = watch('email')
  const newEmail = watch('newEmail')

  const pushToNextPage = () => {
    history.push(`${Paths.createAccount}/${verificationType}/set-pw`)
  }

  const updateEmail = useMutation(
    (email: string) =>
      memberService.updateMemberInfo({ contact_info: { home_email: email } }),
    {
      onSuccess: (data: IMemberInfo) => {
        if (typeof authuserId === 'number') {
          const fullstoryIdentifiers: FullstoryIdentifiers = {
            displayName: data?.name?.first_name + ' ' + data?.name?.last_name,
            angleId: data?.member_id,
            username: data?.contact_info?.home_email,
            email: data?.contact_info?.home_email,
            groupName: data?.group_name
          }
          const fullstoryId = authuserId
          const FS = (window as any).FS
          FS.identify(fullstoryId, fullstoryIdentifiers)
        }

        sessionStorage.setItem('member_id', data?.member_id)
        queryClient.setQueryData<IMemberInfo | undefined>(
          ['getMember', token],
          (prev) => {
            if (prev) {
              const copy: IMemberInfo = { ...prev }
              copy.contact_info = {
                ...data.contact_info,
                home_email: data.contact_info.home_email
              }
              return copy
            }
            return undefined
          }
        )
        queryClient.invalidateQueries(['getMember', token])
        pushToNextPage()
      }
    }
  )
  const onContinue = handleSubmit(async () => {
    const confirmedEmail = newEmail || email

    await updateEmail.mutateAsync(confirmedEmail)
  })

  return (
    <>
      <NoEmailModal
        show={showModal}
        setShow={setShowModal}
        onContinue={() => pushToNextPage()}
      />
      <LoginPageCard headerText={headerText} subheaderText={subheaderText}>
        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          {hasEmail ? (
            <Form.Control
              readOnly
              className="bg-light"
              {...register('email', {
                required: true
              })}
            />
          ) : (
            <Form.Control
              className="bg-white"
              placeholder="Email Address"
              isInvalid={formState?.errors?.email && formState?.isSubmitted}
              {...register('email', {
                required: true,
                validate: validateEmail
              })}
            />
          )}
          {formState?.errors?.email && formState?.isSubmitted && (
            <span className="fs-14 text-danger d-inline-block mt-2">
              Please enter a valid email address.
            </span>
          )}
        </Form.Group>
        {hasEmail && (
          <Form.Group>
            <Form.Label>Not the right email address? Change it here</Form.Label>
            <Form.Control
              placeholder="Email Address"
              className="bg-white"
              {...register('newEmail', {
                validate: validateEmail
              })}
            />
            {formState?.errors?.newEmail && formState?.isSubmitted && (
              <span className="fs-14 text-danger d-inline-block mt-2">
                Please enter a valid email address.
              </span>
            )}

            <span className="fs-14 text-secondary d-inline-block mt-2">
              In addition to login, your email address is used for important
              member communications such as explanation of benefits and updates
              to plan benefits.
            </span>
          </Form.Group>
        )}

        <Form.Group>
          <Form.Label>Confirm Email Address</Form.Label>
          <Form.Control
            placeholder="Email Address"
            className="bg-white"
            isInvalid={
              formState?.errors?.emailConfirm && formState?.isSubmitted
            }
            {...register('emailConfirm', {
              required: true,
              validate: {
                matchingPassword: (value: string) =>
                  newEmail ? value === newEmail : value === email
              }
            })}
          />
          {formState?.errors?.emailConfirm &&
            formState?.errors?.emailConfirm?.type === 'matchingPassword' &&
            formState?.isSubmitted && (
              <span className="fs-14 text-danger d-inline-block mt-2">
                Email addresses do not match. Please try again.
              </span>
            )}
        </Form.Group>
        {!hasEmail && (
          <div className="text-center py-3">
            <span>Don't have an email? </span>
            <Button
              variant="link-primary"
              className="text-primary px-0 font-weight-bold"
              style={{ marginBottom: 3 }}
              onClick={() => setShowModal(true)}
            >
              Use Member ID to log in
            </Button>
          </div>
        )}

        <div className="text-center pt-2">
          <Button className="mb-3" block onClick={() => onContinue()}>
            Continue
          </Button>

          <GoBackButton
            className="mt-4"
            variant="link"
            block
            onClick={() =>
              history.push(`${Paths.createAccount}/${verificationType}/confirm`)
            }
          >
            Go Back
          </GoBackButton>
        </div>
      </LoginPageCard>
      {!hasEmail && (
        <Card
          style={{ borderRadius: 10, maxWidth: 600, width: '100%' }}
          className="text-center p-3 mt-5"
        >
          <span className="text-secondary">
            Want to create your own email address?
          </span>
          <a
            href="https://support.google.com/mail/answer/56256?hl=en"
            target="_blank noreferrer noopener"
          >
            Here's how to create an email.
          </a>
        </Card>
      )}
    </>
  )
}
