import React from 'react'
import {
  Table,
  TableHeader,
  Container,
  CenteredSpinner,
  InfoWithIcon
} from 'react-angle-dashboard-components'
// import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { memberService } from '../../services'
import { getCigna } from '../../utils/cigna'
import { useLang } from '../../hooks/use-language'
import { HomeLayout } from '../layouts/HomeLayout'
import {
  useDrugsNetworkResources,
  useFacilitiesNetworkResources
} from '../../hooks/use-network-resources'

const UnableToLoad: React.FC = () => (
  <InfoWithIcon
    iconEl={
      <i
        className="icon icon-error text-danger"
        style={{ fontSize: '35px', marginBottom: '0.625rem' }}
      />
    }
  >
    We were unable to load network resources. If your issue continues please{' '}
    <a style={{ textDecoration: 'underline' }} href="tel://+18559371855">
      contact member services.
    </a>
  </InfoWithIcon>
)

export const NetworkResources: React.FC = () => {
  const {
    messages: { networkResources }
  } = useLang()
  const { data, isLoading, isError } = useDrugsNetworkResources()
  const { data: facilities } = useFacilitiesNetworkResources()

  const { data: memberData } = useQuery('getMember', async () => {
    return memberService.getMemberInfo()
  })

  const { isCigna } = getCigna(memberData)

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: networkResources.title, link: '/network-resources' }
      ]}
    >
      <section className="bg-light">
        <Container className="my-5 py-3 px-5 mx-auto">
          <h1>{networkResources.title}</h1>
        </Container>
      </section>
      <Container className="mt-5 mx-auto px-5 pb-5">
        <section>
          {isLoading && <CenteredSpinner style={{ height: 'auto' }} />}
          {isError && <UnableToLoad />}
          {data && (
            <div className="my-5">
              <h6 className="mb-3">
                <b>Drugs</b>
              </h6>
              <Table>
                <thead className="thead-light">
                  <tr>
                    <TableHeader className="py-2" wide>
                      {networkResources.tableHeaders.documentName}
                    </TableHeader>
                    <TableHeader className="py-2" />
                  </tr>
                </thead>
                <tbody>
                  {data.map((resource, i) => (
                    <tr key={i}>
                      <td>{resource.name}</td>
                      <td>
                        <a
                          rel="nofollow noreferrer"
                          target="_blank"
                          href={resource.link}
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </section>
        <section>
          {facilities && !isCigna && (
            <div className="my-5">
              <h6 className="mb-3">
                <b>Facilities</b>
              </h6>
              <Table>
                <thead className="thead-light">
                  <tr>
                    <TableHeader className="py-2" wide>
                      {networkResources.tableHeaders.documentName}
                    </TableHeader>
                    <TableHeader className="py-2" />
                  </tr>
                </thead>
                <tbody>
                  {facilities.map((resource, i) => (
                    <tr key={i}>
                      <td>{resource.name}</td>
                      <td>
                        <a
                          rel="nofollow noreferrer"
                          target="_blank"
                          href={resource.link}
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </section>
      </Container>
    </HomeLayout>
  )
}

export default NetworkResources
