import * as React from 'react'
import { SVGProps } from 'react'

const PlusSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 15"
    fill="currentColor"
    {...props}
  >
    <g id="Plus">
      <path
        id="Vector"
        d="M7.48173 0.000133681C7.15121 0.0049775 6.83613 0.140839 6.60573 0.377867C6.37532 0.614894 6.24844 0.933696 6.25296 1.26422V6.25238H1.2648C1.09955 6.25005 0.935489 6.28058 0.782143 6.3422C0.628797 6.40382 0.489226 6.4953 0.371544 6.61133C0.253861 6.72736 0.160413 6.86562 0.0966294 7.01808C0.0328461 7.17054 0 7.33416 0 7.49942C0 7.66469 0.0328461 7.8283 0.0966294 7.98076C0.160413 8.13322 0.253861 8.27149 0.371544 8.38752C0.489226 8.50355 0.628797 8.59503 0.782143 8.65665C0.935489 8.71827 1.09955 8.7488 1.2648 8.74646H6.25296V13.7346C6.25062 13.8999 6.28115 14.0639 6.34277 14.2173C6.40439 14.3706 6.49588 14.5102 6.61191 14.6279C6.72794 14.7456 6.8662 14.839 7.01866 14.9028C7.17112 14.9666 7.33474 14.9994 7.5 14.9994C7.66526 14.9994 7.82888 14.9666 7.98134 14.9028C8.1338 14.839 8.27206 14.7456 8.38809 14.6279C8.50412 14.5102 8.59561 14.3706 8.65723 14.2173C8.71885 14.0639 8.74938 13.8999 8.74704 13.7346V8.74646H13.7352C13.9004 8.7488 14.0645 8.71827 14.2179 8.65665C14.3712 8.59503 14.5108 8.50355 14.6285 8.38752C14.7461 8.27149 14.8396 8.13322 14.9034 7.98076C14.9672 7.8283 15 7.66469 15 7.49942C15 7.33416 14.9672 7.17054 14.9034 7.01808C14.8396 6.86562 14.7461 6.72736 14.6285 6.61133C14.5108 6.4953 14.3712 6.40382 14.2179 6.3422C14.0645 6.28058 13.9004 6.25005 13.7352 6.25238H8.74704V1.26422C8.74932 1.09749 8.71814 0.931985 8.65535 0.777509C8.59255 0.623032 8.49941 0.482716 8.38145 0.364862C8.26348 0.247007 8.12307 0.154006 7.96854 0.0913589C7.814 0.0287122 7.64847 -0.00230767 7.48173 0.000133681Z"
      />
    </g>
  </svg>
)

export default PlusSolid
