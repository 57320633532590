import React from 'react'
import {
  PageContainer,
  PageHeaderLight,
  Row,
  Col,
  CenteredSpinner
} from 'react-angle-dashboard-components'

import { memberService } from '../../services'
import { Router } from './Router'
import { useQuery } from 'react-query'
import { HomeLayout } from '../layouts/HomeLayout'
import { Sidebar } from './Sidebar/Sidebar'
import { memberDataMapper } from './utils/memberDataMapper'

export const Onboarding = () => {
  const { data, isLoading, refetch } = useQuery('getOnboardingGeneral', () =>
    memberService.getMemberInfo(memberDataMapper)
  )

  return (
    <HomeLayout
      noSearchBar
      noMenu
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Onboarding', link: '/onboarding' }
      ]}
    >
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <div style={{ minWidth: 325 }}>
          <PageHeaderLight className="align-items-start">
            <PageContainer size="xl">
              <h1>Angle Health Member Onboarding</h1>
            </PageContainer>
          </PageHeaderLight>
          <PageContainer size="xl" className="pb-4 mb-4">
            <Row className="pb-5">
              <Sidebar memberData={data} isLoading={isLoading} />
              <Col id="content-container">
                <Router
                  memberData={data}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </Col>
            </Row>
          </PageContainer>
        </div>
      )}
    </HomeLayout>
  )
}

export default Onboarding
