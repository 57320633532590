import React from 'react'
import {
  Avatar,
  Badge,
  Col,
  Row,
  useDrugProfile
} from 'react-angle-dashboard-components'
import { ProfileDetail, ProfileHeader } from './style'

export const HeaderSection = () => {
  const {
    name,
    //  description,
    is_covered
  } = useDrugProfile() as any

  /* const {
    messages: { drugsProfile }
  } = useLang() */
  return (
    <Row className="py-5">
      <Col className="pl-0 mb-3" xs="12" md="12">
        <ProfileHeader>
          <Avatar
            circle
            size="xxl"
            centered
            fallbackContent={
              <i className="display-3 text-white icon icon-drugs"></i>
            }
          />
          <ProfileDetail className="align-self-center">
            <div className="mb-3 flex-column align-items-start">
              <h2 className="mb-1 text-capitalize">{name}</h2>
              {/* <p className="text-muted text-capitalize">{name}</p> */}
              <Badge
                variant={is_covered ? 'success' : 'danger'}
                className="text-white text-capitalize py-2"
              >
                {is_covered ? 'Covered' : 'Not Covered'}
              </Badge>
              {/*   <Badge
                variant={
                  state.toLowerCase() === 'covered' ? 'success' : 'danger'
                }
                className="text-white p-1"
                style={{ fontSize: '16px' }}
              >
                {state}
              </Badge> */}
            </div>
            {/* <SaveForLaterButton /> */}
          </ProfileDetail>
        </ProfileHeader>
      </Col>
      {/* 
      <Col xs="12" md="4">
        <FloatingContainer>
          <Accordion className="d-block d-sm-none">
            <Card className="shadow-sm">
              <Accordion.Toggle as="div" role="button" eventKey="0">
                <InfoRow style={{ margin: 0, padding: 0 }}>
                  <strong>{drugsProfile.cost.title}</strong>
                </InfoRow>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div style={{ marginTop: '27px' }}>
                  <InfoRow borderBottom>
                    <h6>Before deductible</h6>
                    <h6 className="text-muted">{cost.beforeDeductible}</h6>
                  </InfoRow>
                  <InfoRow>
                    <h6>Deductible met</h6>
                    <h6 className="text-muted">${cost.deductibleMet}</h6>
                  </InfoRow>
                  <InfoRow>
                    <h6>Out-of-pocket max met</h6>
                    <h6 className="text-muted">${cost.outOfPocketMaxMet}</h6>
                  </InfoRow>
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Card className="shadow-sm d-none d-sm-flex">
            <InfoRow style={{ marginBottom: '27px' }}>
              <strong>{drugsProfile.cost.title}</strong>
            </InfoRow>
            <InfoRow>
              <h6>Before deductible</h6>
              <h6 className="text-muted">{cost.beforeDeductible}</h6>
            </InfoRow>
            <InfoRow>
              <h6>Deductible met</h6>
              <h6 className="text-muted">${cost.deductibleMet}</h6>
            </InfoRow>
            <InfoRow>
              <h6>Out-of-pocket max met</h6>
              <h6 className="text-muted">${cost.outOfPocketMaxMet}</h6>
            </InfoRow>
          </Card>
        </FloatingContainer>
      </Col>
     */}
    </Row>
  )
}
export default HeaderSection
