import * as React from 'react'
import { SVGProps } from 'react'

const CaretSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 7"
    fill="currentColor"
    {...props}
  >
    <g id="Caret">
      <path id="Vector" d="M14 0L0 0L7 7L14 0Z" />
    </g>
  </svg>
)

export default CaretSolid
