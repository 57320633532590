import React from 'react'
import {
  CostContainer,
  CostDescription,
  CostText,
  ListItemDescription,
  ListItemTextContainer,
  ListItemTitle
} from './style'

import styled from 'styled-components'

interface IListItemProps {
  title: string
  description: string
  cost: number
  selected?: boolean
  icon: string
}

const ListItem = ({
  title,
  description,
  cost,
  selected = false,
  icon
}: IListItemProps) => {
  const ListItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    height: 90px;
    padding-left: 20px;
    padding-right: 20px;

    cursor: pointer;

    ${selected &&
    `
    border: 1px solid #6D39D9;
    border-radius: 8px;
    `}

    &:hover {
      background-color: #f2f5f9;
    }
  `

  return (
    <>
      <ListItemContainer>
        <img style={{ objectFit: 'contain' }} src={icon} alt={`${title}`} />
        <ListItemTextContainer>
          <ListItemTitle>{title}</ListItemTitle>
          <ListItemDescription>{description}</ListItemDescription>
        </ListItemTextContainer>
        <CostContainer>
          <CostText>
            {cost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD'
            })}
          </CostText>
          <CostDescription>Negotiated Rate</CostDescription>
        </CostContainer>
      </ListItemContainer>
      <hr style={{ margin: 0 }} />
    </>
  )
}

export default ListItem
