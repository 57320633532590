import React from 'react'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'
import { LoginPageCard } from '../../LoginPageCard'
import { OutlineButton, GoBackButton } from '../../style'

export const CreateAccountInitialPage = () => {
  const history = useHistory()
  return (
    <LoginPageCard
      headerText="Create your account"
      subheaderText="Select a way for us to look you up"
    >
      <div className="text-center">
        <OutlineButton
          variant="outline-primary"
          block
          onClick={() => history.push(Paths.createAccount + '/angle')}
        >
          Continue with your Member ID
        </OutlineButton>
        <div className="text-secondary py-4">or</div>
        <OutlineButton
          variant="outline-primary"
          block
          onClick={() => history.push(Paths.createAccount + '/id')}
        >
          Continue with your Social Security No.
        </OutlineButton>

        <GoBackButton
          className="mt-2"
          variant="link"
          onClick={() => history.push(Paths.login)}
        >
          Go Back
        </GoBackButton>
      </div>
    </LoginPageCard>
  )
}
