import React, { useState } from 'react'
import {
  Button,
  CenteredSpinner,
  useToaster
} from 'react-angle-dashboard-components'
import { Form } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { AgreementContainer } from './style'
import { Footer } from '../../style'
import { memberService } from '../../../../services'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'
import { onboardingPaths } from '../../Router'
import { memberDataMapper } from '../../utils/memberDataMapper'
import { useUpdateMember } from '../../hooks/useUpdateMember'

export const Agreement = () => {
  const history = useHistory()
  const { showError } = useToaster()

  const [agree, setAgree] = useState(false)

  const { data } = useQuery('getOnboardingGeneral', () =>
    memberService.getMemberInfo(memberDataMapper)
  )

  const handleBack = () => {
    let path = Paths.onboarding
    if (data?.showVoluntary) {
      path += onboardingPaths.voluntary + '?summary=true'
    } else {
      path += onboardingPaths.plan
    }
    history.push(path)
  }

  const updateMember = useUpdateMember()

  const accept = async () => {
    try {
      await updateMember.mutateAsync({
        onboarding_status: 'completed',
        statement_of_understanding: true
      })

      history.push(Paths.onboardingComplete)
    } catch (error: any) {
      showError(error?.message)
    }
  }

  return (
    <div>
      <h3>Certify and submit your Angle Health application</h3>
      <AgreementContainer>
        By signing and submitting I certify the following: I understand that
        Eligible Employee means: An active employee of the Employer who works
        the number of hours per week to be eligible for benefits as defined by
        the Employer, who meets the definition of “employee” under Utah State
        and Federal laws, and approved by Angle, as of the effective date.
        Employment must be verifiable from state or federal wage tax. An
        employee, as defined above, who enters into employment after the
        coverage effective date and who completes the group imposed waiting
        period for eligibility (if any) and applies for coverage within 30 days;
        Any other class of persons identified by the Employer, provided that
        written approval of their eligibility is obtained from the Company(ies);
        or An Employee, who is eligible for continued coverage under Utah State
        or Federal laws. I understand that Eligible Dependent means: My spouse,
        or child age 26 or younger, including a newborn, natural child, or a
        child placed with me for adoption, a stepchild or any other child for
        whom I have legal guardianship or court ordered custody. The age limit
        for coverage of a child (1) age 26 unless the Employer has chosen to
        extend dependent coverage and dependent qualifies, or (2) I or the
        dependent have purchased a rider to extend coverage for young adults
        through age 29 and my dependent is eligible. In the case of (1) or (2),
        the dependent age limit for coverage is age 30. Coverage for children
        willl end on the last day of the month in which the children reach age
        26 or age 30 if applicable.
      </AgreementContainer>
      <Form.Group>
        <Form.Check
          id="agree"
          type="checkbox"
          label="I attest that I have reviewed, understand, and agree to comply with the above and that I am authorized to
submit this application."
          checked={agree}
          onChange={(e) => setAgree(e.target.checked)}
        />
      </Form.Group>
      {updateMember.isLoading ? (
        <CenteredSpinner />
      ) : (
        <Footer>
          <div className="text-right w-100">
            <Button
              ghost
              variant="primary"
              style={{
                borderColor: 'var(--card-border)',
                marginRight: '1rem'
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button disabled={!agree} onClick={accept}>
              Continue
            </Button>
          </div>
        </Footer>
      )}
    </div>
  )
}
