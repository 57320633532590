import * as React from 'react'
import { SVGProps } from 'react'

const LocationPinSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 18"
    fill="currentColor"
    {...props}
  >
    <g id="LocationPin">
      <path
        id="LocationPin_2"
        d="M7.33524 0C3.28418 0 0 3.28418 0 7.33523C0 12.1592 4.76924 14.4684 5.36673 15.0059C5.97688 15.5547 6.48101 16.785 6.6844 17.4825C6.78309 17.8213 7.0605 17.9927 7.33524 18C7.61064 17.992 7.88738 17.8206 7.98607 17.4825C8.18946 16.785 8.69359 15.5554 9.30375 15.0059C9.90123 14.4684 14.6705 12.1592 14.6705 7.33523C14.6705 3.28418 11.3863 0 7.33524 0ZM7.33524 9.33575C6.23028 9.33575 5.33472 8.44019 5.33472 7.33523C5.33472 6.23028 6.23028 5.33472 7.33524 5.33472C8.44019 5.33472 9.33575 6.23028 9.33575 7.33523C9.33575 8.44019 8.44019 9.33575 7.33524 9.33575Z"
      />
    </g>
  </svg>
)

export default LocationPinSolid
