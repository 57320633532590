import buildingImage from './Building.png'
import coatImage from './Coat.png'
import mriImage from './MRI.png'
import scaleImage from './Scale.png'
import avatarImage from './avatar.png'
import avatarPlusImage from './avatarPlus.png'
import beakerImage from './beaker.png'
import groupImage from './group.png'
import houseImage from './house.png'
import medCrossImage from './medCross.png'
import moonImage from './moon.png'

const placeOfServiceList = [
  {
    title: 'Virtual Care',
    value: 'virtual-care',
    description: 'Select this for care provided virtually.',
    image: avatarImage
  },
  {
    title: 'Primary Care Office',
    value: 'primary-care',
    description: "Select this for care at a primary care provider's office. ",
    image: coatImage
  },
  {
    title: 'Specialist Office',
    value: 'specialist',
    description: "Select this for care at a specialist's office.",
    image: avatarPlusImage
  },
  {
    title: 'Urgent Care Center',
    value: 'urgent-care',
    description: 'Select this for care at an urgent care center.',
    image: medCrossImage
  },
  {
    title: 'Laboratory Services',
    value: 'laboratory',
    description:
      'Select this for diagnostic testing, reference testing, and disease surveillance.',
    image: beakerImage
  },
  {
    title: 'Imaging Center',
    value: 'imaging',
    description:
      'Select this for care at a facility with the primary purpose of diagnosing and monitoring diseases and injuries using medical imaging techniques like x-rays, CT scans, and MRIs.',
    image: mriImage
  },
  {
    title: 'Ambulatory Surgery Center',
    value: 'ambulatory-surgery',
    description:
      "Select this for care at a non-hospital facility that offers same-day treatment and procedures. Note that this is different from a provider's office.",
    image: scaleImage
  },
  {
    title: 'Home Health',
    value: 'home',
    description:
      'Select this for care provided at your home by qualified medical professionals.',
    image: houseImage
  },
  {
    title: 'Skilled Nursing Facility',
    value: 'skilled-nursing',
    description:
      'Select this for care at a skilled nursing facility. Note that this is different from a hospital.',
    image: groupImage
  },
  {
    title: 'Outpatient Hospital',
    value: 'outpatient-hospital',
    description:
      'Select this for same-day care at a hospital. Note that this does not include overnight care.',
    image: buildingImage
  },
  {
    title: 'Inpatient Hospital',
    value: 'inpatient-hospital',
    description:
      'Select this if you plan to be admitted to a hospital for at least one night.',
    image: moonImage
  }
]

export default placeOfServiceList
