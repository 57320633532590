import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-angle-dashboard-components'
import { PaymentSource, paymentSourceObject } from '@angle/utils'

import { claimsStatusEnums } from '../../../services/api/claims/claims'
import ClaimCell from './ClaimCell'
import StatusIndicator from '../StatusIndicator'

import { InnerCellEllipsis } from './style'

export interface ClaimRowProps {
  amountBilled: string
  claimId: string | number
  dateOfService: string
  isDeductibleMet: boolean
  memberName: string
  patientResponsibility: string
  providerName: string
  providerSpecialty: string
  status: string
  type: string
  paymentSource: string
}

const ClaimRow = ({
  amountBilled,
  claimId,
  dateOfService,
  isDeductibleMet,
  memberName,
  patientResponsibility,
  providerName,
  providerSpecialty,
  status,
  type,
  paymentSource
}: ClaimRowProps) => {
  const amountBilledRenderer = () => {
    if (isDeductibleMet) {
      return (
        <div className="text-muted d-flex align-items-center gx-1">
          Deductible met
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip data-testid="about-tooltip" id="deductible">
                You met your deductible on this claim
              </Tooltip>
            }
          >
            <i className="icon-question-mark fs-13" />
          </OverlayTrigger>
        </div>
      )
    }
    return amountBilled
  }

  const amountBilledCN = `${status === claimsStatusEnums.processing && 'text-muted'}`
  const patientResponsibilityCN = `${status === claimsStatusEnums.processing && 'text-muted'}`

  return (
    <tr>
      <ClaimCell>
        <div></div>
        { type === "pharmacy" ? (
          <Link to={`/rxclaims/${claimId}`}>{claimId}</Link>
          ) : (
          <Link to={`/claims/${claimId}`}>{claimId}</Link>
          )}
        <div className="fs-14 text-muted">{dateOfService}</div>
      </ClaimCell>
      <ClaimCell>
        <StatusIndicator status={status} />
      </ClaimCell>
      <ClaimCell ellipsis>
        <InnerCellEllipsis>{providerName}</InnerCellEllipsis>
        <InnerCellEllipsis className="text-muted">
          {providerSpecialty}
        </InnerCellEllipsis>
      </ClaimCell>
      <ClaimCell ellipsis>{memberName}</ClaimCell>
      <ClaimCell className={amountBilledCN}>{amountBilledRenderer()}</ClaimCell>
      <ClaimCell className={patientResponsibilityCN}>
        {patientResponsibility}
      </ClaimCell>
      <ClaimCell>{paymentSourceObject[paymentSource as PaymentSource]}</ClaimCell>
    </tr>
  )
}

export default ClaimRow