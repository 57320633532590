import styled from 'styled-components'

export const NoWrapDiv = styled.div`
  white-space: nowrap;
`

export const FloatingCard = styled.div`
  position: absolute;
  top: -210px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 83%;
  max-width: 400px;
  @media (max-width: 900px) {
    position: relative;
    top: 0;
    height: 100%;
    max-width: none;
  }
`

export const HelpIcon = styled.i`
  color: var(--gray-400);
  height: 10px;
  width: 10px;
`
