import { useMutation, useQueryClient } from 'react-query'
import { memberService } from '../../../services'
import { memberDataMapper } from '../utils/memberDataMapper'

// can remove inRenewal once ziyu adds to patch response
export const useUpdateMember = () => {
  const queryClient = useQueryClient()

  const updateMember = useMutation(
    (data: any) => memberService.updateMemberInfo(data),
    {
      onSuccess: (mutationData: any) => {
        queryClient.setQueryData(
          ['getOnboardingGeneral'],
          memberDataMapper(mutationData)
        )
      }
    }
  )
  return updateMember
}
