import React from 'react'
import { Col, PageContainer } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const Header = styled.section`
  box-shadow: inset 0 2px 8px -6px rgba(0, 0, 0, 0.35);
`
export const FloatingContainer = styled.div`
  position: absolute;
  width: 100%;
  @media (max-width: 767px) {
    position: relative;
  }
`

export const AboutCol = styled(Col)`
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    flex-grow: 1;
  }
  > div + div {
    margin-left: 1rem;
  }
  .avatar {
    flex-grow: 0;
    flex-shrink: 0;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    .avatar {
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
    > div + div {
      margin-left: 0;
    }
  }
`

export const ProfileDetail = styled.div`
  p {
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.25rem;
    }
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    h2 {
      margin-bottom: 0;
    }
    .badge {
      font-weight: normal;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 1.25rem;
  }
`

export const InfoRow = styled.div<{ borderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.825rem;
  border-bottom: 1px solid var(--gray);
  &:last-child {
    border-bottom: ${(props) =>
      props.borderBottom ? '1px solid var(--gray);' : 'none'};
  }
  &:first-child {
    border-bottom: ${(props) =>
      props.borderBottom ? '1px solid var(--gray);' : 'none'};
  }
`

export const LayoutContainer: React.FC = ({ children }) => (
  <PageContainer size="md">{children}</PageContainer>
)
