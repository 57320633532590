import styled from 'styled-components'

export const NavButton = styled.button`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  border-radius: 8px;
  border: 1px solid var(--Components-Card-border, #e7eaf3);

  background: none;
  color: #343a40;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  aspect-ratio: 1;
`
