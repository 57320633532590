import React from 'react'
import { Paths } from '../../../../../routes/paths'
import { Link } from 'react-router-dom'
import { onboardingPaths } from '../../../Router'
import styled from 'styled-components'
import { Benefit } from '../types'
import { benefitsMap } from 'react-angle-dashboard-components'

const BenefitContainer = styled(Link)`
  &:not(:last-of-type) {
    border-bottom: 1px solid #e7eaf3;
  }

  color: var(--dark);
  &:hover {
    background-color: var(--light);
    cursor: pointer;
    color: var(--dark);
  }
`

export const BenefitItem: React.FC<{ benefit: Benefit }> = ({ benefit }) => {
  return (
    <BenefitContainer
      to={`${Paths.onboarding}${onboardingPaths.voluntary}?benefit=${benefit.key}`}
      className="d-flex align-items-center justify-content-between px-3 pt-2 flex-nowrap"
    >
      <h6>{benefitsMap[benefit?.key || '']?.displayName || ''}</h6>
      <div className="d-flex align-items-center pb-1 flex-nowrap">
        {benefit.selectedPlanName && (
          <div className="d-flex align-items-center flex-nowrap">
            <div>
              <i className="icon icon-correct text-success"></i>
            </div>

            <span className="d-block">{benefit.selectedPlanName}</span>
          </div>
        )}
        <div className="">
          <i className="icon icon-right-arrow" />
        </div>
      </div>
    </BenefitContainer>
  )
}
