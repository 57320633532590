import React from 'react'
import { Router } from './routes/Router'

import './App.scss'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  return <Router />
}

export default App
