import styled from 'styled-components'
import { Form } from 'react-angle-dashboard-components'

export const ResultHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;

  color: #212529;
`

export const ResultProvider = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  width: 700px;

  color: #6c757d;

  margin-top: 8px;
`

export const ResultDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  width: 660px;

  color: black;

  margin-top: 8px;
`

export const EstimatedCost = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;

  color: #212529;
`

export const GrayText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #6c757d;
`

export const AbsoluteContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 35px;
`

export const CostCard = styled.div`
  border: 1px solid #e7eaf3;
  border-radius: 6px;
  background-color: white;
  width: 370px;
`

export const CostCardContent = styled.div`
  padding: 12px;
`

export const CardTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;

  color: #6c757d;
`

export const SubHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #343a40;
`

export const BreakdownLine = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e7eaf3;
  padding-top: 8px;
  padding-bottom: 8px;
`

export const BreakdownLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #6c757d;
`

export const BreakdownCost = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #343a40;
`

export const TipsContainer = styled.div`
  margin-top: 30px;
  background: #f8f9fa;
  border-radius: 6px;
  padding: 16px;
`

export const TipsText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #343a40;
`
export const BoldText = styled.div`
  font-weight: 700;
`

export const DisclaimerContainer = styled.div`
  padding-left: 23px;
  padding-right: 23px;

  margin-top: 17px;

  font-style: normal;
  font-weight: 400;
  font-size: 12.8px;
  line-height: 19px;

  color: #6c757d;
`

export const RightSideContainer = styled.div`
  padding-top: 330px;
  width: 368px;
`

export const HelpIcon = styled.i`
  color: var(--gray-400);
  height: 10px;
  width: 10px;
  position: relative;
  top: 2px;
  left: 4px;
`

export const Inline = styled.span`
  display: flex;
  align-items: center;
`

export const InlineTop = styled.span`
  display: flex;
  align-items: flex-start;
`

export const InlineSpace = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const FamilyDropdown = styled(Form.Control)`
  width: auto;
  border: none;

  :hover {
    cursor: pointer;
    border: none;
  }
`

export const InputCostHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #343a40;
`

export const InputCostLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: #6d39d9;
`

export const InputCostInputHeader = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #212529;
`
