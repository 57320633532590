
import { UserInfoDTO } from "@angle/types"
const FS = (window as any).FS

export type FullstoryIdentifiers = {
    displayName: string
    username?: string
    angleId?: string
    email?: string
    groupName?: string
}

export const identifyFullstoryUser = (
    userInfo: Partial<UserInfoDTO>,
    memberId?: string
  ) => {
    const fullstoryId = userInfo.authuser_id
  
    const fullstoryIdentifiers: FullstoryIdentifiers =  {
      displayName: userInfo.first_name + ' ' + userInfo.last_name,
      username: userInfo.username
    }
  
    let email = ''
    let angleId = ''
    
    if (userInfo?.username?.includes('@')) {
      email = userInfo?.username
    } else {
      angleId = userInfo?.username || ""
    }
    if (!angleId && memberId) {
      angleId = 'ANG' + memberId
    }
  
    if (angleId) {
      fullstoryIdentifiers.angleId = angleId
    }
  
    if (email) {
      fullstoryIdentifiers.email = email
    }
  
    FS.identify(fullstoryId, fullstoryIdentifiers)
  }
  