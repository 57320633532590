import { PremiumData } from '../types'

export const findPremium = (
  planId: string,
  premiums: PremiumData[] | undefined
) => {
  const premium = premiums?.find(
    (premiumData: PremiumData) => premiumData.plan_id === planId
  )?.employee_contrib
  return premium
}
