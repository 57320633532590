import React from 'react'
import {
  AnglePlanBadge,
  Button,
  Col,
  Row,
  Card
} from 'react-angle-dashboard-components'

interface PlanCardProps {
  plan: { [key: string]: any }
  onClickSelectPlan: (planId: string) => void
  onMoreDetails: () => void
  premium?: number
}

export const PlanCard: React.FC<PlanCardProps> = ({
  onClickSelectPlan,
  onMoreDetails,
  plan,
  premium
}) => {
  const copayType = plan.doctor_visit_inn_copay_type

  return (
    <Card style={{ minWidth: 250 }}>
      <Card.Body>
        <div>
          <AnglePlanBadge
            metalTier={plan.plan?.metal_tier || ''}
            planName={plan.plan_name}
            style={{ display: 'block' }}
            textColor="black"
          />
        </div>

        <div
          className="mt-3"
          style={{ visibility: premium === undefined ? 'hidden' : 'visible' }}
        >
          <h3 className="d-inline font-weight-bold">${premium}</h3>
          <h4 className="text-muted d-inline">/mo</h4>
        </div>
        <div className="justify-content-center mt-3 pb-2">
          <Button onClick={onClickSelectPlan} className="w-100  px-2">
            Select Plan
          </Button>
        </div>
        <hr />
        <div>
          <div className="fs-12" style={{ minHeight: 143 }}>
            <p className="fs-14 text-muted mb-3">In-Network Costs</p>
            <Row className="pb-2">
              <Col className="font-weight-bold col-4">
                ${plan.deductible_individual_inn}
              </Col>
              <Col className="col-8 text-muted text-right">Deductible</Col>
            </Row>
            <Row className="pb-2">
              <Col className="font-weight-bold col-4">
                {copayType === 'copay'
                  ? `$${plan.doctor_visit_inn_copay}`
                  : `${plan.doctor_visit_inn_coinsurance}%`}
              </Col>
              <Col className="col-8 text-muted text-right text-capitalize">
                Primary Doctor {copayType}
              </Col>
            </Row>
            <Row className="pb-2">
              <Col className="font-weight-bold col-4">
                ${plan.oop_max_individual_inn}
              </Col>
              <Col className="col-8 text-muted text-right">
                Max Out-of-pocket
              </Col>
            </Row>
          </div>
          <hr />
          <div className="d-flex pt-1">
            <Button
              variant="primary"
              ghost
              className="w-100"
              style={{ borderColor: 'var(--card-border)', width: '100%' }}
              onClick={onMoreDetails}
            >
              More details
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default PlanCard
