import React, { useContext } from 'react'
import { Switch, Redirect, Route, BrowserRouter } from 'react-router-dom'
import { CenteredSpinner } from 'react-angle-dashboard-components'

import { Paths } from './paths'
import { AppStatus, AuthContext } from '../context/Auth/Auth'

// Public
import Login from '../pages/Login'

import ResetPassword from '../pages/Login/ResetPassword'

// Full

import DocumentsAndForms from '../pages/Documents'
import DrugProfile from '../pages/DrugProfile'
import DrugSearch from '../pages/DrugSearch'
/* import EmployeeOnboarding from '../pages/EmployeeOnboarding/EmployeeOnboarding' */
// import FacilitiesDirectory from '../pages/FacilitiesDirectory'
// import FacilityProfile from '../pages/FacilityProfile'
// import EmployeeOnboarding from '../pages/EmployeeOnboarding/EmployeeOnboarding'
import ForgotPassword from '../pages/Login/ForgotPassword'
// import HealthProfile from '../pages/HealthProfile'
import Home from '../pages/Home/Home'
import Claims from '../pages/Claims'
import MemberIDCard from '../pages/MemberIDCard'
import MyPlan from '../pages/MyPlan'
import NetworkResources from '../pages/NetworkResources'
import Settings from '../pages/Settings'
import NotFound from '../pages/NotFound'
import DependentPlan from '../pages/DependentPlan/DependentPlan'
import VirtualCare from '../pages/VirtualCare/VirtualCare'
import OnboardingCompleted from '../pages/OnboardingCompleted/OnboardingCompleted'
import ClaimsRoutingError from '../pages/ClaimsRoutingError'
import {
  WaiveCoverageForm,
  WaiveCoverageSuccess,
  TempAuthRedirect
} from '../pages/WaiveCoverage'
import { ClaimsDetail, RxClaimsDetail } from '../pages/ClaimsDetail'
import MemberSurvey from '../pages/Surveys/Survey'
import Onboarding from '../pages/MemberOnboarding/Onboarding'
import { TempAuth } from '../pages/TempAuth'
import ComparePrices from '../pages/ComparePrices'
import ComparePricesResult from '../pages/ComparePricesResult/ComparePricesResult'
import ComparePricesProviderSearch from '../pages/ComparePricesProviderSearch'
import { ComparePricesContextProvider } from '../context/ComparePrices'
import PrescriptionsAtHome from '../pages/PrescriptionsAtHome/PrescriptionsAtHome'
import { CreateAccountRouter } from '../pages/Login/CreateAccount/router/CreateAccountRouter'
import AccessPlusPlanDetails from '../pages/AccessPlus/AccessPlusPlanDetails'

const Fallbacks = {
  [AppStatus.public]: Paths.login,
  [AppStatus.onboarding]: Paths.onboarding,
  [AppStatus.temporary]: Paths.changePassword,
  [AppStatus.full]: Paths.home
}

const DocusignComplete = () => {
  window.parent.postMessage({ message: 'docusign-complete' }, '*')

  return <CenteredSpinner />
}

const PublicApp = () => {
  return (
    <Switch>
      <Route path={Paths.waiveCoverage} component={TempAuthRedirect} />
      <Route path={Paths.login} component={Login} />
      <Route path={Paths.createAccount} component={CreateAccountRouter} />
      <Route path={Paths.claims} component={Claims} />
      <Route path={Paths.myPlan} component={MyPlan} />
      <Route path={Paths.virtualCare}>
        <Redirect to={Paths.login} />
      </Route>
      <Route path={Paths.forgotPassword} component={ForgotPassword} />
      <Route path={Paths.resetPassword} component={ResetPassword} />
      <Route path={Paths.temporaryLogin}>
        <Redirect to={Paths.createAccount} />
      </Route>
      <Route path={Paths.memberSurveys} component={MemberSurvey} />
      <Route path={Paths.superuser} component={TempAuth} />
      <Route exact path="/">
        <Redirect to={Fallbacks[AppStatus.public]} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

const TemporaryLoginApp = () => {
  return (
    <Switch>
      <Route path={Paths.temporaryLogin}>
        <Redirect to={Paths.createAccount} />
      </Route>
      <Route path={Paths.createAccount} component={CreateAccountRouter} />
      <Route path={Paths.changePassword}>
        <Redirect to={Paths.createAccount} />
      </Route>
      <Route path={Paths.waiveCoverage} component={WaiveCoverageForm} />
      <Route
        path={Paths.waiveCoverageSuccess}
        component={WaiveCoverageSuccess}
      />
      <Route path={Paths.resetPassword} component={ResetPassword} />
      <Route path={Paths.docusignSigned} component={DocusignComplete} />
      <Route path={Paths.claims} component={ClaimsRoutingError} />
      <Route path={Paths.virtualCare}>
        <Redirect to={Fallbacks[AppStatus.temporary]} />
      </Route>
      <Route path={Paths.superuser}>
        {' '}
        <Redirect to={Fallbacks[AppStatus.temporary]} />
      </Route>
      {/* <Route path={Paths.superuser} component={TempAuth} /> */}
      <Route exact path="/">
        <Redirect to={Fallbacks[AppStatus.temporary]} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

const OnboardingApp = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Paths.createAccount} component={CreateAccountRouter} />
        <Route path={Paths.waiveCoverage} component={WaiveCoverageForm} />
        <Route
          path={Paths.waiveCoverageSuccess}
          component={WaiveCoverageSuccess}
        />
        <Route path={Paths.login} component={Login} />
        <Route path={Paths.docusignSigned} component={DocusignComplete} />
        <Route path={'/onboarding/:activeStepName'} component={Onboarding} />
        <Route path={Paths.onboarding} component={Onboarding} />
        <Route path={Paths.resetPassword} component={ResetPassword} />
        <Route
          path={Paths.onboardingComplete}
          component={OnboardingCompleted}
        />
        <Route path={Paths.claims} component={ClaimsRoutingError} />
        <Route path={Paths.memberSurveys} component={MemberSurvey} />
        <Route path={Paths.superuser}>
          <Redirect to={Fallbacks[AppStatus.onboarding]} />
        </Route>
        {/* <Route path={Paths.superuser} component={TempAuth} /> */}
        <Route path={Paths.virtualCare}>
          <Redirect to={Fallbacks[AppStatus.onboarding]} />
        </Route>
        <Route exact path="/">
          <Redirect to={Fallbacks[AppStatus.onboarding]} />
        </Route>
        <Redirect to={Fallbacks[AppStatus.onboarding]} />
      </Switch>
    </BrowserRouter>
  )
}

const FullApp = () => {
  return (
    <Switch>
      <Route path={Paths.home} component={Home} />
      <Route path={Paths.login} component={Login} />
      <Route path={Paths.virtualCare} component={VirtualCare} />
      <Route path={Paths.createAccount} component={CreateAccountRouter} />
      <Route path={Paths.resetPassword} component={ResetPassword} />
      <Route path={Paths.settings} component={Settings} />
      <Route path={Paths.drugProfile} component={DrugProfile} />
      <Route
        path={Paths.drugs}
        component={() => <Redirect to={Paths.pharmacy} />}
      />
      <Route exact path={Paths.pharmacy} component={DrugSearch} />
      <Route path={Paths.prescriptionsAtHome} component={PrescriptionsAtHome} />
      <Route exact path={Paths.claims} component={Claims} />
      <Route path={Paths.claimsDetail} component={ClaimsDetail} />
      <Route path={Paths.rxClaimsDetail} component={RxClaimsDetail} />
      <Route path={Paths.comparePrices}>
        <ComparePricesContextProvider>
          <Route exact path={Paths.comparePrices} component={ComparePrices} />
          <Route
            exact
            path={Paths.comparePricesProviderSearch}
            component={ComparePricesProviderSearch}
          />
          <Route
            path={Paths.comparePricesResult}
            component={ComparePricesResult}
          />
        </ComparePricesContextProvider>
      </Route>
      <Route path={Paths.documentsAndForms} component={DocumentsAndForms} />
      {/* <Route path={Paths.healthProfile} component={HealthProfile} /> */}
      <Route path={Paths.networkResources} component={NetworkResources} />
      <Route exact path={Paths.myPlanMemberIdCard} component={MemberIDCard} />
      <Route
        exact
        path={Paths.myPlanMemberIdCard + '/dependent/:id'}
        component={MemberIDCard}
      />
      <Route path={Paths.myPlan} component={MyPlan} />
      <Route path={Paths.dependentPlan} component={DependentPlan} />
      <Route path={Paths.myPlan} component={MyPlan} />
      <Route path={Paths.onboardingComplete} component={OnboardingCompleted} />
      <Route path={Paths.memberSurveys} component={MemberSurvey} />
      <Route
        path={Paths.accessPlusPlanDetails}
        component={AccessPlusPlanDetails}
      />
      <Route path={Paths.notFound} component={NotFound} />
      <Route path={Paths.superuser}>
        <Redirect to={Fallbacks[AppStatus.full]} />
      </Route>
      {/* <Route path={Paths.superuser} component={TempAuth} /> */}

      <Route exact path="/">
        <Redirect to={Fallbacks[AppStatus.full]} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

const RenewalApp = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={Paths.home} component={Home} />
        <Route path={Paths.createAccount} component={CreateAccountRouter} />
        <Route path={Paths.virtualCare} component={VirtualCare} />
        <Route path={Paths.resetPassword} component={ResetPassword} />
        <Route path={Paths.login} component={Login} />
        <Route path={Paths.settings} component={Settings} />
        <Route path={Paths.drugProfile} component={DrugProfile} />
        <Route exact path={Paths.drugs} component={DrugSearch} />
        <Route exact path={Paths.claims} component={Claims} />
        <Route path={Paths.medicalClaims}>
          <Redirect to={Paths.claims} />
        </Route>
        <Route path={Paths.rxClaims}>
          <Redirect to={Paths.claims} />
        </Route>
        <Route path={Paths.rxClaimsDetail} component={RxClaimsDetail} />
        <Route path={Paths.claimsDetail} component={ClaimsDetail} />
        <Route path={Paths.documentsAndForms} component={DocumentsAndForms} />
        {/* <Route path={Paths.healthProfile} component={HealthProfile} /> */}
        <Route path={Paths.networkResources} component={NetworkResources} />
        <Route exact path={Paths.myPlanMemberIdCard} component={MemberIDCard} />
        <Route
          exact
          path={Paths.myPlanMemberIdCard + '/dependent/:id'}
          component={MemberIDCard}
        />
        <Route path={Paths.myPlan} component={MyPlan} />
        <Route path={Paths.dependentPlan} component={DependentPlan} />
        <Route path={Paths.myPlan} component={MyPlan} />
        <Route
          path={Paths.onboardingComplete}
          component={OnboardingCompleted}
        />
        <Route path={Paths.memberSurveys} component={MemberSurvey} />
        <Route path={Paths.notFound} component={NotFound} />
        <Route path={Paths.waiveCoverage} component={WaiveCoverageForm} />
        <Route
          path={Paths.waiveCoverageSuccess}
          component={WaiveCoverageSuccess}
        />
        <Route path={Paths.docusignSigned} component={DocusignComplete} />
        <Route
          path={Paths.onboarding + '/:activeStepName'}
          component={Onboarding}
        />
        <Route path={Paths.onboarding} component={Onboarding} />

        <Route path={Paths.resetPassword} component={ResetPassword} />
        <Route
          path={Paths.onboardingComplete}
          component={OnboardingCompleted}
        />
        <Route path={Paths.memberSurveys} component={MemberSurvey} />
        <Route path={Paths.superuser}>
          {' '}
          <Redirect to={Fallbacks[AppStatus.full]} />
        </Route>
        {/* <Route path={Paths.superuser} component={TempAuth} /> */}

        <Route exact path="/">
          <Redirect to={Fallbacks[AppStatus.full]} />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export const Router = () => {
  const { appStatus, isRenewal } = useContext(AuthContext)
  return (
    <>
      {appStatus !== AppStatus.public && isRenewal && <RenewalApp />}
      {appStatus === AppStatus.public && <PublicApp />}
      {appStatus === AppStatus.temporary && <TemporaryLoginApp />}
      {appStatus === AppStatus.onboarding && !isRenewal && <OnboardingApp />}
      {appStatus === AppStatus.full && !isRenewal && <FullApp />}
    </>
  )
}
