import React, { SetStateAction, Dispatch } from 'react'
import {
  CenteredSpinner,
  Modal,
  SummaryOfBenefitsCoverage
} from 'react-angle-dashboard-components'
import { capitalize } from '@angle/utils'
import { CurrentSBCData } from '../types'

export const SBCModal: React.FC<{
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  sbcData: CurrentSBCData
}> = ({ show, setShow, isLoading, sbcData }) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="w-100 m-0 mw-100"
      className="px-0"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ minHeight: '95vh' }}>
        {isLoading && <CenteredSpinner />}
        {sbcData && !isLoading && (
          <SummaryOfBenefitsCoverage
            coverageInfo={{
              plan: {
                name: sbcData.plan?.plan_name,
                metalTier: sbcData.plan?.metal_tier,
                type: sbcData.plan?.plan_type
              },
              coveragePeriod: {
                from: sbcData.plan?.start_date,
                to: sbcData.plan?.end_date
              },
              coverageFor: sbcData.plan?.eligible_member_types
                ?.map(capitalize)
                .join('/') //'Single/Family'
            }}
            benefits={sbcData.benefits}
            faqs={sbcData.faqs}
          />
        )}
      </Modal.Body>
    </Modal>
  )
}
