export const ENGLISH_CODES = [
  'en',
  'es',
  'en-EN',
  'es-es',
  'en-en',
  'en-us',
  'de-de'
]
export const LANGUAGE_KEY = '_appct_lang'
