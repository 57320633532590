import React from 'react'
import { Button, Card, Col } from 'react-angle-dashboard-components'
import { PharmCardText, PharmCardTitle } from './style'

interface IPharmCardProps {
  image: string
  title: string
  text: string
  buttonText: string
  onButtonClick: () => void
  buttonVariant?: 'primary' | 'outline-primary'
}

const PharmCard = ({
  image,
  title,
  text,
  buttonText,
  buttonVariant = 'primary',
  onButtonClick
}: IPharmCardProps) => (
  <Col>
    <Card>
      <Card.Body>
        <img src={image} alt="card" />
        <PharmCardTitle>{title}</PharmCardTitle>
        <PharmCardText>{text}</PharmCardText>
        <Button variant={buttonVariant} onClick={onButtonClick}>
          {buttonText}
        </Button>
      </Card.Body>
    </Card>
  </Col>
)

export default PharmCard
