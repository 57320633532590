import React from 'react'
import { Button } from 'react-angle-dashboard-components'

export const SelectPlanButton: React.FC<{
  isSelected: boolean
  onClick: any
}> = ({ isSelected, onClick }) => {
  return (
    <div className="pt-3">
      {isSelected ? (
        <div className="d-flex align-items-center flex-nowrap">
          <div>
            <i className="icon icon-correct text-success"></i>
          </div>

          <span className="d-block">Selected</span>
        </div>
      ) : (
        <Button className="w-100" onClick={onClick}>
          Select this plan
        </Button>
      )}
    </div>
  )
}
