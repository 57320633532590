import React from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorPageLayout, Button } from 'react-angle-dashboard-components'
import { useLang } from '../../hooks/use-language'
import { HomeLayout } from '../layouts/HomeLayout'
import { useAppStatus, AppStatus } from '../../context/Auth/Auth'

// TODO refactor this page and ErrorPage because there is repetitive code

const NavButton: React.FC = ({ children }) => {
  const history = useHistory()
  return <Button onClick={() => history.push('/')}>{children}</Button>
}

export const NotFound = () => {
  const { messages } = useLang()
  const appStatus = useAppStatus()

  let buttonText = 'Go back to my member portal'

  let errorText = 'The page you were looking for could not be found.'
  if (appStatus === AppStatus.public) {
    buttonText = 'Log in'
    errorText = 'Please log in and try again.'
  } else if (
    appStatus === AppStatus.onboarding ||
    appStatus === AppStatus.temporary
  ) {
    buttonText = 'Go back to onboarding'
    errorText =
      'You may need to complete your member onboarding to access this page.'
  }

  return (
    <HomeLayout noSearchBar noMenu noBreadcrumbs>
      <div style={{ marginTop: -75 }}>
        <ErrorPageLayout
          errorTitle={messages.notFoundPage.title}
          errorSubtitle={errorText}
          button={<NavButton>{buttonText}</NavButton>}
        />
      </div>
    </HomeLayout>
  )
}

export default NotFound
