import { useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { useQuery } from 'react-query'
import { priceTransparencyService } from '../services'
import { IProviderTransparency } from '../services/api/priceTransparency'
import serviceItems from '../pages/ComparePrices/services.json'
import placeOfServiceList from '../pages/ComparePrices/placeOfServiceList'

interface ICoords {
  lat: number
  lon: number
}

export type SearchResult = typeof serviceItems[0] & { id: string | number }

interface IComparePrices {
  INN: boolean | undefined
  setINN: any
  zipCode: string | undefined
  setZipCode: any
  placeOfService: typeof placeOfServiceList[0] | undefined
  setPlaceOfService: any | undefined
  service: SearchResult | undefined
  setService: any
  provider: IProviderTransparency | undefined
  setProvider: any
  coordsData: ICoords | undefined
  isCoordsLoading: boolean
  clearAllComparePrices: () => void
}

export const ComparePricesContext = createContext<IComparePrices>({
  INN: undefined,
  setINN: undefined,
  zipCode: undefined,
  setZipCode: undefined,
  placeOfService: undefined,
  setPlaceOfService: undefined,
  service: undefined,
  setService: undefined,
  provider: undefined,
  setProvider: undefined,
  coordsData: undefined,
  isCoordsLoading: false,
  clearAllComparePrices: () => {}
})

export const ComparePricesContextProvider: React.FC = ({ children }) => {
  const [INN, setINN] = useState(undefined)
  const [zipCode, setZipCode] = useState<string | undefined>(undefined)
  const [placeOfService, setPlaceOfService] = useState(undefined)
  const [service, setService] = useState(undefined)
  const [provider, setProvider] = useState(undefined)

  const { data: coordsData, isLoading: isCoordsLoading } = useQuery(
    ['getCoordinates', zipCode],
    (): Promise<ICoords> =>
      priceTransparencyService
        .getCoordinates(zipCode ?? '')
        .then(({ results: [res] }) => ({
          lat: res.geometry.location.lat,
          lon: res.geometry.location.lng
        })),
    {
      // Refetch every time the length is 5
      enabled: zipCode?.length === 5
    }
  )

  const clearAllComparePrices = () => {
    setINN(undefined)
    setZipCode(undefined)
    setPlaceOfService(undefined)
    setService(undefined)
    setProvider(undefined)
  }

  return (
    <ComparePricesContext.Provider
      value={{
        INN,
        setINN,
        zipCode,
        setZipCode,
        placeOfService,
        setPlaceOfService,
        service,
        setService,
        provider,
        setProvider,
        coordsData,
        isCoordsLoading,
        clearAllComparePrices
      }}
    >
      {children}
    </ComparePricesContext.Provider>
  )
}

export const useComparePrices = () => useContext(ComparePricesContext)
