import * as React from 'react'
import { SVGProps } from 'react'

const SortSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 10"
    fill="currentColor"
    {...props}
  >
    <g id="Sort">
      <path
        id="Vector"
        d="M3.75003 0C3.64347 0 3.53689 0.0406119 3.45543 0.12207L0.122099 3.4554C0.00293254 3.57457 -0.0328161 3.75367 0.0317672 3.9095C0.0959339 4.06534 0.248362 4.16667 0.416696 4.16667H7.08336C7.2517 4.16667 7.40412 4.06534 7.46829 3.9095C7.53287 3.75367 7.49712 3.57457 7.37796 3.4554L4.04463 0.12207C3.96317 0.0406119 3.85659 0 3.75003 0ZM0.416696 5.83333C0.248362 5.83333 0.0959339 5.93466 0.0317672 6.09049C-0.0328161 6.24633 0.00293257 6.42543 0.122099 6.5446L3.45543 9.87793C3.53668 9.95918 3.64336 10 3.75003 10C3.8567 10 3.96338 9.95918 4.04463 9.87793L7.37796 6.5446C7.49712 6.42543 7.53287 6.24633 7.46829 6.09049C7.40412 5.93466 7.2517 5.83333 7.08336 5.83333H0.416696Z"
      />
    </g>
  </svg>
)

export default SortSolid
