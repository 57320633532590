import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { NavButton } from './style'

import { ReactComponent as InsuranceCard } from '../../../../assets/insurance-card.svg'
import { ReactComponent as MemberId } from '../../../../assets/member-id.svg'
import { ReactComponent as Folder } from '../../../../assets/folder.svg'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'

const NavigateCard = () => {
  const history = useHistory()

  return (
    <Card className="p-3 h-100">
      <h5>Navigate to</h5>
      <div
        className="d-flex flex-row justify-content-between"
        style={{
          gap: 16
        }}
      >
        <NavButton onClick={() => history.push(Paths.myPlanMemberIdCard)}>
          <MemberId />
          Member ID Card
        </NavButton>
        <NavButton onClick={() => history.push(Paths.claims)}>
          <InsuranceCard />
          Claims
        </NavButton>
        <NavButton onClick={() => history.push(Paths.documentsAndForms)}>
          <Folder />
          Docs/Forms
        </NavButton>
      </div>
    </Card>
  )
}

export default NavigateCard
