import * as React from 'react'
import { SVGProps } from 'react'

const LocationSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <g id="Location">
      <path
        id="Vector"
        d="M9 0C5.94138 0 3.46154 2.47985 3.46154 5.53846C3.46154 9.69231 8.30769 11.0769 8.30769 13.8462C8.30769 14.2283 8.61785 14.5385 9 14.5385C9.38215 14.5385 9.69231 14.2283 9.69231 13.8462C9.69231 11.0769 14.5385 9.69231 14.5385 5.53846C14.5385 2.47985 12.0586 0 9 0ZM9 4.15385C9.765 4.15385 10.3846 4.77346 10.3846 5.53846C10.3846 6.30346 9.765 6.92308 9 6.92308C8.235 6.92308 7.61538 6.30346 7.61538 5.53846C7.61538 4.77346 8.235 4.15385 9 4.15385ZM5.10983 11.3933C2.23329 11.8849 0 12.9434 0 14.5385C0 16.8106 4.52769 18 9 18C13.4723 18 18 16.8106 18 14.5385C18 12.9434 15.7667 11.8849 12.8902 11.3933C12.8341 11.4494 12.7772 11.505 12.7212 11.5596C12.3425 11.9328 11.9902 12.2834 11.707 12.6157C14.9 12.9916 16.6154 13.9763 16.6154 14.5385C16.6154 15.2439 13.9272 16.6154 9 16.6154C4.07285 16.6154 1.38462 15.2439 1.38462 14.5385C1.38462 13.9763 3.10005 12.9916 6.29297 12.6157C6.00912 12.2834 5.65685 11.9328 5.27885 11.5596C5.22346 11.505 5.1666 11.4494 5.10983 11.3933Z"
      />
    </g>
  </svg>
)

export default LocationSolid
