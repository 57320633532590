import { Form } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const PhotoBackground = styled.div`
  background-image: url('/compare-prices-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 296px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 45px;
`

export const HeaderText = styled.h1`
  font-family: 'Sentinel';
  font-style: normal;
  font-weight: 375;
  font-size: 48px;
  line-height: 48px;
  color: white;
`

export const SubHeaderText = styled.h2`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
`

export const SearchBarLabel = styled.p`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #343a40;
  margin-bottom: 12px;
`

export const FormContainer = styled.div`
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 12px;
`

export const FormLabel = styled(Form.Label)`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #343a40;
  margin-bottom: 12px;
  margin-top: 12px;
`

export const ZipCodeControl = styled(Form.Control)`
  width: 110px;
`

export const ListItemTitle = styled.div`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #343a40;
`

export const ListItemDescription = styled.div`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #6c757d;
`

export const NumberContainer = styled.div`
  width: 36px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;

  background: #eeeaf7;

  font-family: 'sentinel';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Global/01. Primary */
  color: #6d39d9;
`

export const InfoLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Text/01. Body */
  color: #212529;
`

export const BodyText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  /* Text/02. Muted */
  color: #6c757d;
`

export const HelpIcon = styled.i`
  color: #6d39d9;
  height: 24px;
  width: 24px;
`

export const DisclaimerHeader = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  /* Global/08. Dark */
  color: #343a40;
`

export const DisclaimerBody = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  /* or 150% */
  display: flex;
  align-items: center;

  /* Global/02. Secondary */
  color: #6c757d;
`
