import React, { useEffect } from 'react'
import {
  Button,
  Form,
  IChangePasswordDTO
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { authService, memberService, ApiService } from '../../../../../services'
import { LoginPageCard } from '../../../LoginPageCard'
import { GoBackButton } from '../../../style'
import { VerificationType } from '../../types'

interface PasswordForm {
  password: string
  confirmPassword: string
}

export const CreateAccountSetPw = () => {
  const history = useHistory()

  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const token = sessionStorage.getItem('token')
  const tempPassword = sessionStorage.getItem('temporary_password')

  useEffect(() => {
    ApiService._token = token
  }, [token])

  const { data: memberData } = useQuery(['getMember', token], () =>
    memberService.getMemberInfo()
  )

  const email = memberData?.contact_info?.home_email
  const memberId = memberData?.member_id

  const headerText = 'Set your password'
  const subheaderText =
    'Choose a strong password that you will log into Angle Health with.'

  const { handleSubmit, formState, register, watch } = useForm({
    mode: 'onChange'
  })

  const passwordFormValue = watch('password')

  const updatePassword = useMutation(
    (passwordDTO: IChangePasswordDTO) =>
      authService.changePassword(passwordDTO),
    {
      onSuccess: () => {
        history.push(Paths.createAccount + '/success')
      },
      onError: () => {}
    }
  )
  const onContinue = handleSubmit(async (formData: PasswordForm) => {
    const DTO = {
      new_password: formData?.password,
      old_password: tempPassword || ''
    }
    await updatePassword.mutateAsync(DTO)
  })

  const pushToEmailPage = () => {
    history.push(`${Paths.createAccount}/${verificationType}/email`)
  }
  return (
    <>
      <LoginPageCard headerText={headerText} subheaderText={subheaderText}>
        <div className="mb-3">
          <span className="text-secondary">
            Use your email or Member ID as a username to login
          </span>
          <div className="my-2">
            <span className="text-secondary mr-2">Member ID:</span>
            <span>{memberId?.substring(0, 10)}</span>
          </div>
          <div>
            <span className="text-secondary mr-2">Email Address:</span>
            {email ? (
              <span>{email}</span>
            ) : (
              <span>
                No email added
                <Button
                  variant="link-primary py-0 px-1 ml-2 text-primary font-weight-bold"
                  onClick={() => pushToEmailPage()}
                >
                  Add
                </Button>
              </span>
            )}
          </div>
        </div>

        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            className="bg-white"
            {...register('password', {
              required: true,
              minLength: 8
            })}
            placeholder="Password"
            isInvalid={!!formState.errors?.password && formState.isSubmitted}
            type="password"
          />
          {formState.errors?.password &&
            formState.errors?.password?.type === 'minLength' &&
            formState.isSubmitted && (
              <span className="text-danger mb-1 d-inline-block py-2">
                Password must be 8 characters or more
              </span>
            )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            className="bg-white"
            placeholder="Confirm Password"
            {...register('confirmPassword', {
              required: true,
              validate: {
                matchingPassword: (val) => val === passwordFormValue
              }
            })}
            type="password"
            isInvalid={
              !!formState.errors?.confirmPassword && formState.isSubmitted
            }
          />
          {formState.errors?.confirmPassword && formState.isSubmitted && (
            <span className="text-danger mb-1 d-inline-block py-2">
              Passwords must match
            </span>
          )}
        </Form.Group>

        <div className="text-center pt-2">
          <Button className="mb-3" block onClick={() => onContinue()}>
            Submit
          </Button>

          <GoBackButton
            className="mt-4"
            variant="link"
            block
            onClick={() => pushToEmailPage()}
          >
            Go Back
          </GoBackButton>
        </div>
      </LoginPageCard>
    </>
  )
}
