import React, { useState } from 'react'
import {
  useToaster,
  Button,
  SectionTitle,
  CenteredSpinner
} from 'react-angle-dashboard-components'
import { memberService } from '../../../../services'
import { useQuery } from 'react-query'
import { Footer } from '../../style'
import { useHistory } from 'react-router-dom'
import { DependentList } from './DependentList/DependentList'
import { mapDependentsToList, mapMemberToDependentsList } from './mapper'
import { DependentsContainer } from './style'
import { AddDependent } from './AddDependent'
import { Paths } from '../../../../routes/paths'
import { onboardingPaths } from '../../Router'
import { useUpdateMember } from '../../hooks/useUpdateMember'

export const Dependents = () => {
  const history = useHistory()
  const { showError } = useToaster()

  const [showFormModal, setShowFormModal] = useState(false)
  const [editingDependent, setEditingDependent] = useState<any>(null)

  const {
    data: dependents,
    refetch,
    isLoading
  } = useQuery('getDependentsInfo', () =>
    memberService.getDependents(mapDependentsToList)
  )

  // get member info in case user checks "address same as my address"
  const { data: member, isLoading: isLoadingMember } = useQuery(
    'getMemberForDependents',
    () => memberService.getMemberInfo(mapMemberToDependentsList)
  )

  // const updateMember = useMutation((data: any) =>
  //   memberService.updateMemberInfo(data)
  // )
  const updateMember = useUpdateMember()

  const handleContinue = async () => {
    try {
      const step = member?.inRenewal ? 4 : 5
      await updateMember.mutateAsync({
        in_progress_state: member?.serverStep > step ? member?.serverStep : step
      })
      history.push(Paths.onboarding + onboardingPaths.plan)
    } catch (error) {
      showError()
    }
  }

  const handleBack = () => {
    if (member?.inRenewal) {
      history.push(Paths.onboarding + onboardingPaths.contact)
    } else {
      history.push(Paths.onboarding + onboardingPaths['current-carrier'])
    }
  }

  if (isLoading || isLoadingMember) {
    return <CenteredSpinner />
  }

  return (
    <div>
      <DependentsContainer>
        <SectionTitle className="mb-4">Dependents</SectionTitle>
        <div className="d-flex flex-column align-items-center">
          <DependentList
            dependents={dependents}
            refetch={refetch}
            setEditingDependent={setEditingDependent}
            setShowFormModal={setShowFormModal}
          />

          <Button variant="primary mt-4" onClick={() => setShowFormModal(true)}>
            Add dependent
          </Button>
        </div>
      </DependentsContainer>
      {showFormModal && (
        <AddDependent
          show={showFormModal}
          refetch={refetch}
          editingDependent={editingDependent}
          setShowFormModal={setShowFormModal}
          setEditingDependent={setEditingDependent}
          member={member}
        />
      )}
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            onClick={handleBack}
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
          >
            Back
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </Footer>
    </div>
  )
}
