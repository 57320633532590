import React, { useRef, useState } from 'react'
import {
  Badge,
  Card,
  PageContainer,
  ProgressBar
} from 'react-angle-dashboard-components'
import { HomeLayout } from '../layouts/HomeLayout'
import {
  GrayText,
  ResultHeader,
  ResultProvider,
  Inline,
  InlineSpace,
  ResultDescription
} from './style'
import infoIcon from './info.png'
import { Paths } from '../../routes/paths'
import { useComparePrices } from '../../context/ComparePrices'
import { AccumsCol } from './AccumsCol/AccumsCol'
import { Redirect } from 'react-router-dom'
import { ResultMainSection, PricingNotFound } from './ResultMainSection'
// import { priceTransparencyService } from '../../services'
// import { useQuery } from 'react-query'

export const ComparePricesResult = () => {
  const printRef = useRef<any>()

  const { provider, service, placeOfService, setINN } = useComparePrices()

  const [currentCardMemberId, setCurrentCardMemberId] = useState('')

  // Aren't using OON data yet
  //
  // const { data: oonData } = useQuery(
  //   ['OONProviders'],
  //   () =>
  //     priceTransparencyService.getOutNetworkProviders({
  //       cpt: service?.['Code (1)'],
  //       pos: placeOfService?.value
  //     }),
  //   {
  //     enabled: !INN
  //   }
  // )

  const isResultAvailable = provider?.cost_before_benefits
  const requiresPriorAuth = provider?.procedure.prior_auth
  const showBenefitLimits = false

  const currentSessionsRemaining = 4
  const maxSessionLimit = 20
  const sessionLimitPercentage = Math.trunc(
    ((maxSessionLimit - currentSessionsRemaining) * 100) / maxSessionLimit
  )

  const breadcrumbs = [
    { title: 'Home', link: '/' },
    { title: 'Compare Prices', link: Paths.comparePrices }
  ]

  if (provider) {
    breadcrumbs.push({
      title: 'Provider Search',
      link: Paths.comparePricesProviderSearch
    })
  }

  breadcrumbs.push({ title: 'Result', link: Paths.comparePricesResult })

  if (!service) {
    return <Redirect to={Paths.comparePrices} />
  }

  return (
    <HomeLayout breadcrumbs={breadcrumbs}>
      <div ref={printRef}>
        <div className="bg-light py-5">
          <PageContainer size="lg">
            <InlineSpace>
              <div>
                <InlineSpace>
                  <ResultHeader>{service['Short description']}</ResultHeader>
                </InlineSpace>
                <ResultDescription>
                  {service['Plain text description']}
                </ResultDescription>
                <ResultProvider>
                  {provider?.is_provider
                    ? provider?.full_name
                    : provider?.facility_name ?? 'Out of network'}{' '}
                  · {provider?.address ?? placeOfService?.title}{' '}
                  {`· ${provider?.truncDist ?? 'N/A'} miles away`}
                </ResultProvider>
                {requiresPriorAuth && (
                  <Badge className="mt-2" variant="danger">
                    Requires Prior Authorization
                  </Badge>
                )}
                {showBenefitLimits && (
                  <Card className="p-2 mt-3" style={{ width: 475 }}>
                    <Inline>
                      <img
                        style={{ width: 16 }}
                        className="mr-2"
                        src={infoIcon}
                        alt="info"
                      />
                      <span
                        className="mr-1"
                        style={{ fontWeight: 700, fontSize: 14 }}
                      >
                        {currentSessionsRemaining}
                      </span>
                      <GrayText style={{ whiteSpace: 'nowrap' }}>
                        out of {maxSessionLimit} sessions left this year
                      </GrayText>
                      <ProgressBar
                        now={sessionLimitPercentage}
                        className="ml-3"
                        variant={
                          currentSessionsRemaining < 4 ? 'danger' : 'warning'
                        }
                        style={{
                          width: 207
                        }}
                      />
                    </Inline>
                  </Card>
                )}
              </div>
              <AccumsCol
                printRef={printRef}
                currentCardMemberId={currentCardMemberId}
                setCurrentCardMemberId={setCurrentCardMemberId}
              />
            </InlineSpace>
          </PageContainer>
        </div>
        <PageContainer size="lg">
          {isResultAvailable ? (
            <ResultMainSection currentCardMemberId={currentCardMemberId} />
          ) : (
            <PricingNotFound onClickLink={() => setINN(true)} />
          )}
        </PageContainer>
      </div>
    </HomeLayout>
  )
}

export default ComparePricesResult
