import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from 'react-angle-dashboard-components'
import { Paths } from '../../../../routes/paths'

interface MemberListItemProps {
  name: string
  age: string
  relationship?: string
  memberId: string
}

const MemberListItem = ({ age, memberId, name, relationship }: MemberListItemProps) => {
  const history = useHistory()
  
  return (
    <div>
      <div className="rounded border d-flex justify-content-between align-items-center">
        <div className="p-3">
          <p
            style={{
              fontWeight: 500
            }}
            className="m-0 p-0"
          >
            {name} ({age})
          </p>
          <p
            className="m-0 p-0 text-capitalize"
            style={{
              color: '#6C757D'
            }}
          >
            {relationship === 'employee' ? 'Subscriber' : (relationship || '').charAt(0).toUpperCase() + (relationship || '').slice(1)}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <Button
            className="p-0"
            variant="link"
            onClick={() => history.push(Paths.myPlanMemberIdCard + (relationship === 'employee' ? '' : `/dependent/${memberId}`))}
            style={{
              fontWeight: 500
            }}
          >
            Member ID Card
          </Button>
          <div onClick={() => {}} className="p-2">
            <i className="icon-more" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MemberListItem
