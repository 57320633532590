import React from 'react'
import { Form, InputGroup } from 'react-angle-dashboard-components'
import InputMask from 'react-input-mask'
import { VerificationType } from '../../types'

export const FormInputs: React.FC<{
  formState: any
  register: any
  verificationType: VerificationType
}> = ({ formState, register, verificationType }) => {
  // verify by SSN
  if (verificationType === 'id') {
    return (
      <>
        <Form.Group>
          <Form.Label>Social Security Number</Form.Label>

          <Form.Control
            as={InputMask}
            className="bg-white"
            mask="999-99-9999"
            placeholder="Social Security Number"
            isInvalid={!!formState?.errors?.ssn && formState?.isSubmitted}
            {...register('ssn', {
              required: true
            })}
          />
        </Form.Group>
      </>
    )
  }

  // verify by angle member id
  return (
    <Form.Group>
      <Form.Label>Angle Health Member ID</Form.Label>
      <InputGroup>
        <InputGroup.Text
          style={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0
          }}
        >
          ANG
        </InputGroup.Text>
        <Form.Control
          className="bg-white"
          placeholder="Member ID"
          isInvalid={!!formState?.errors?.member_id && formState?.isSubmitted}
          {...register('member_id', {
            required: true,
            validate: {
              length: (val: string) => ( val?.length === 7 || val?.length === 9 )
            }
          })}
        />
      </InputGroup>
      {!!formState?.errors?.member_id && formState?.isSubmitted && (
        <span className="mt-3 text-danger d-inline-block">
          Please enter the member ID number.
        </span>
      )}
    </Form.Group>
  )
}
