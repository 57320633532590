import React from 'react'
import { Card } from 'react-angle-dashboard-components'

export const MoreDetailsFooter: React.FC<{
  link?: string
}> = ({ link }) => {
  if (!link) {
    return null
  }
  return (
    <Card.Body className="text-center py-2">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: 'underline', margin: 0, padding: 0 }}
        className="font-weight-light"
      >
        More Details
      </a>
    </Card.Body>
  )
}
