import { ApiService } from 'react-angle-dashboard-components'
// import { IClaimsDTO } from '../../../interfaces/claims'
import { Accum, IClaimsList, GetClaimsParams, GetUnifiedClaimsParams, IUnifiedClaimsList } from '../../../interfaces/claims'
import {
  mapDTOtoClaimDetail,
  mapDTOtoClaimList,
  mapDTOtoRXClaimDetail,
  mapDTOtoRxClaimList
} from './mappers'

export interface IClaimDTO {
  angle_paid_date: string
  angle_paid: boolean
  associated_claims: IClaimDTO[]
  batch_claim: number
  billed_price: number
  check_number: string
  claim_details: {
    benefit_info: string
    billed_price: number
    claim_detail_status: string
    cob_amount: number
    coinsurance_amount: number
    copay_amount: number
    deductible_amount: number
    not_covered_amount: number
    paid_amount: number
    plan_price: number
    saved_amount: number
    service_date: string
    service_thru: string
    total_cost: number
  }[]
  claim_id: number
  claim_number: string
  claim_status: string
  claim_type: string
  cob_amount: number
  coinsurance_amount: number
  copay_amount: number
  current_illness_date: string | null
  deductible_amount: number
  diagnostic_codes: string[]
  diagnostic_codes_details: {
    code: string
    description: string
  }[]
  ex_codes: string[]
  funded_status: string
  in_network: boolean
  last_eob: {
    bucket: string
    cancelled: boolean
    claimeob_id: number
    content_type: string
    created_at: string
    key: string
    lob_id: string
    paperless_email: boolean
    paperless: boolean
  }
  member: {
    member_id: string
    name: {
      first_name: string
      last_name: string
      middle_name: string
      suffix: string
      title: string
    }
  }
  network: string
  not_covered_amount: number
  onset_similar_illness_date: string | null
  other_insurance: boolean
  paid_amount: number
  paid_date: string
  payment_source: string
  payment_status: string
  patient_account: string
  patient_amount: number
  procedure_codes_details: {
    code: string
    description: string
  }[]
  processed_date: string
  provider: {
    address: {
      city: string
      state: string
      street_1: string
      street_2: string
      zip: string
    }
    facility_name: string
    first_name: string
    last_name: string
    office_name: string
    npi: string
    provider_npi: string
    rendering_npi: string
    specialty?: string
  }
  provider_name: string
  received_date: string
  referred_by: string
  saved_amount: number
  service_date: string
  service_thru: string
  subscriber_signature: true
  total_cost: number
  vba_claim_number: number
  provider_npi: string
  billing_npi: string
  rendering_provider_npi: string
  new_provider_npi: string
}

export interface IRxClaimDTO {
  allowed_amount: number
  claim_id: number
  date_of_service: string
  date_rx_written: string
  date_submitted: string
  days_supply: number
  label_name: string
  paid_by_angle: number
  patient_amount: number
  refill_number: number
  retail_amount: number
  rx_number: string
  savings_amount: number
  status: string
  patient: {
    id: string
    name: string
  }
  pharmacy: {
    name: string
    address: {
      address_line_1: string
      address_line_2: string
      city:string
      state: string
      zip: string
    }
  }
  prescriber: {
    name: string
  }
  
}

export const claimsStatusEnums = {
  denied_partial: 'denied_partial',
  denied: 'denied',
  approved: 'approved',
  processing: 'processing'
}

export const claimsTypeEnums = {
  medical: 'medical',
  pharmacy: 'pharmacy'
}

export class ClaimsService extends ApiService {
  async getUnifiedClaims(params?: GetUnifiedClaimsParams): Promise<IUnifiedClaimsList> {
    return this.request({
      method: 'get',
      url: '/v2/claims',
      credentials: true,
      params
    })
  }

  async getMedicalClaims(params?: GetClaimsParams): Promise<IClaimsList> {
    return this.request({
      method: 'get',
      url: '/claims',
      credentials: true,
      params
    }).then((r) => mapDTOtoClaimList(r))
  }

  async getClaimById(id: string) {
    return this.request({
      method: 'get',
      url: `/v2/claims/medical/${id}`,
      credentials: true
    }).then((r: IClaimDTO) => mapDTOtoClaimDetail(r))
  }

  async getRXClaims(params?: GetClaimsParams): Promise<any> {
    return this.request({
      method: 'get',
      url: '/rx-claims',
      credentials: true,
      params
    }).then((r) => mapDTOtoRxClaimList(r))
  }

  async getRXClaimById(id: string): Promise<any> {
    return this.request({
      method: 'get',
      url: `/v2/claims/pharmacy/${id}`,
      credentials: true
    }).then((r) => mapDTOtoRXClaimDetail(r))
  }

  async getAccums(): Promise<any> {
    const INN = 'INN'
    const OON = 'OON'
    const DEDUCTIBLE = 'DED'
    const OOP = 'OOP'

    return this.request({
      method: 'get',
      url: `/accums`,
      credentials: true
    }).then((r) => {
      const familyAccums = r?.family?.accums
      const familyDeductible = familyAccums.find(
        (accum: Accum) =>
          accum.accum_type === DEDUCTIBLE && accum.network_type === INN
      )
      const familyDeductibleOON = familyAccums.find(
        (accum: Accum) =>
          accum.accum_type === DEDUCTIBLE && accum.network_type === OON
      )
      const familyOOP = familyAccums.find(
        (accum: Accum) => accum.accum_type === OOP && accum.network_type === INN
      )
      const familyOOPOON = familyAccums.find(
        (accum: Accum) => accum.accum_type === OOP && accum.network_type === OON
      )
      if (!familyDeductible || !familyOOP) {
        throw new Error('An error occurred retrieving claims information.')
      }

      return {
        familyDeductible,
        familyDeductibleOON,
        familyOOP,
        familyOOPOON,
        members: r.members?.map((member: any) => {
          const accums = member?.accums
          const oop = accums.find(
            (accum: Accum) =>
              accum.accum_type === OOP && accum.network_type === INN
          )
          const oopoon = accums.find(
            (accum: Accum) =>
              accum.accum_type === OOP && accum.network_type === OON
          )
          const deductible = accums.find(
            (accum: Accum) =>
              accum.accum_type === DEDUCTIBLE && accum.network_type === INN
          )
          const deductibleoon = accums.find(
            (accum: Accum) =>
              accum.accum_type === DEDUCTIBLE && accum.network_type === OON
          )
          if (!oop || !deductible) {
            throw new Error('An error occurred retrieving claims information.')
          }
          return {
            memberId: member.member_id,
            oop,
            oopoon,
            deductible,
            deductibleoon
          }
        })
      }
    })
  }
}
