import { HealthProfileService as BaseHealthProfileService } from 'react-angle-dashboard-components'
import {
  IVaccine,
  IVital,
  IWellnessItem
} from '../../interfaces/health-profile'

export class HealthProfileService extends BaseHealthProfileService {
  getWelnessChecklist() {
    return this.request({
      method: 'post',
      url: '/health-profile/wellness-checklist',
      credentials: true
    }).then(
      (r: { wellness_checklist: IWellnessItem[] }) => r.wellness_checklist
    )
  }

  getVaccines() {
    return this.request({
      method: 'post',
      url: '/health-profile/vaccines',
      credentials: true
    }).then((r: { vaccines: IVaccine[] }) => r.vaccines)
  }

  getVitals() {
    return this.request({
      method: 'post',
      url: '/health-profile/vitals',
      credentials: true
    }).then((r: { vitals: IVital[] }) => r.vitals)
  }
}
