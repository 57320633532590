import { ReactElement, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Accordion, Alert, Col, Row } from 'react-angle-dashboard-components'
import { currencyFormatter } from '@angle/utils'
import { ReactComponent as InfoCircle } from '../../../../assets/infoCircleBrown.svg'

interface AccumsCardProps {
  title?: string
  numerator: number
  denominator: number
  seeMoreContent?: ReactElement
  isLoading?: boolean
  isUnlimited?: boolean
}

const AccumsCard = ({
  title,
  numerator,
  denominator,
  seeMoreContent,
  isUnlimited = false,
  isLoading = false
}: AccumsCardProps) => {
  const [isOpen, setisOpen] = useState<boolean>(false)
  const [isOverAccum, setIsOverAccum] = useState<boolean>(false)

  useEffect(() => {
    if (numerator > denominator && !isOverAccum) {
      setIsOverAccum(true)
    } else if (numerator <= denominator && isOverAccum) {
      setIsOverAccum(false)
    }
  }, [numerator, denominator, isOverAccum])

  return (
    <div className="border p-2 rounded mb-3">
      <h5 className="my-0 mb-2 fs-16">
        {isLoading ? <Skeleton width={200} /> : title}
      </h5>
      {isLoading ? (
        <Skeleton width={100} height={30} />
      ) : (
        <h4 className="my-0 mb-1 fs-24">
          {currencyFormatter.format(numerator)}
          <span
            style={{
              color: '#9AA6B8',
              fontSize: 16
            }}
          >
            /{ isUnlimited ? 'Unlimited' : currencyFormatter.format(denominator) }
          </span>
        </h4>
      )}
      {isLoading ? (
        <Skeleton height={10} />
      ) : (
        seeMoreContent && (
          <Accordion>
            <Accordion.Toggle
              className="fs-12 text-primary"
              as="div"
              role="button"
              eventKey={'seeMore'}
              onClick={() => setisOpen((prev) => !prev)}
            >
              See {isOpen ? 'less' : 'more'}{' '}
              <img
                src="/carot.png"
                alt="carot"
                style={{
                  transform: isOpen ? 'rotate(180deg)' : '',
                  transition: 'transform 400ms',
                  width: 10
                }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse className="pt-3 gy-2" eventKey={'seeMore'}>
              {seeMoreContent}
            </Accordion.Collapse>
            { isOverAccum && (
              <Alert
                className={`my-0 rounded-0 text-center w-full py-2 flex align-items-center border-0`}
                style={{
                  backgroundColor: '#FFF3CD',
                  marginTop: '5px'
                }}
                variant={'warning'}
                show={true}
              >
                <Row>
                    <InfoCircle color={'#856404'} style={{ height: '1rem', marginTop: '6px'}} />
                  <Col style={{ textAlign: 'left' }}>
                    <span className="mr-3">
                      Our claims team has been notified of an overaccumulation. We will be reviewing all claims and will provide an updated EOB once this has been resolved. 
                    </span>
                  </Col>
                </Row>
              </Alert>
            )}
          </Accordion>
        )
      )}
    </div>
  )
}

export default AccumsCard
