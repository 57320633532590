import { ApiService } from 'react-angle-dashboard-components'

export class DocusignService extends ApiService {
  async getWaiveCoverageDocusignUrl(): Promise<any> {
    return this.request({
      method: 'post',
      url: '/sign/waive-coverage',
      data: { redirect_url: window.location.origin + '/docusign-signed' },
      credentials: true
    }).then((r) => r)
  }
}
