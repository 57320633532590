import React from 'react'
import {
  CenteredSpinner,
  Col,
  Form,
  Row,
  useToaster
} from 'react-angle-dashboard-components'
import { useMutation, useQuery } from 'react-query'
import { settingsService } from '../../services'
import { ErrorMessage } from '../../components/ErrorMessage'
import { Label, Header } from './style'
import { SettingsDTO } from '../../interfaces/settings'

export const CommunicationTab: React.FC = () => {
  const { showError, showSuccess } = useToaster()

  const { data, isLoading, isError, refetch } = useQuery('memberSettings', () =>
    settingsService.getSettings()
  )

  const updateCommunicationSetttings = useMutation(
    (data: SettingsDTO) => settingsService.modifySettings(data),
    {
      onSuccess: () => {
        refetch()
        showSuccess('Updated Successfully')
      },
      onError: (error: any) => {
        showError(
          'An error occurred: ' + error.message ||
            error.detail ||
            'Unknown Error'
        )
      }
    }
  )

  const onChange = async (e: any) => {
    if (e.target.name === 'paperlessEOB') {
      await updateCommunicationSetttings.mutateAsync({
        paperless_eob: e.target.checked
      })
    } else if (e.target.name === 'eobEmail') {
      await updateCommunicationSetttings.mutateAsync({
        paperless_eob_email: e.target.checked
      })
    } else return
  }

  if (isError) {
    return (
      <ErrorMessage errorText="An error occurred loading your communication preferences." />
    )
  }

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <section>
      <Header>Paperless Updates</Header>
      <section>
        <Form>
          <section>
            <Row>
              <Col md="10">
                <Label>Enroll in Paperless</Label>
                <p className="text-muted">
                  Skip the paper mail and view your Explanation of Benefits
                  online.
                </p>
              </Col>
              <Col className="text-right">
                <Form.Check
                  type="switch"
                  id="paperlessEOB"
                  name="paperlessEOB"
                  onChange={onChange}
                  defaultChecked={data?.paperlessEOB}
                />
              </Col>
            </Row>
          </section>
          <hr />
          <Header>Email</Header>
          <section>
            <Row>
              <Col md="10">
                <Label>Claims</Label>
                <p className="text-muted">
                  Receive an email whenever a new Explanation of Benefits
                  becomes available to view.
                </p>
              </Col>
              <Col className="text-right">
                <Form.Check
                  type="switch"
                  id="eobEmail"
                  name="eobEmail"
                  onChange={onChange}
                  defaultChecked={data?.paperlessEOBEmail}
                />
              </Col>
            </Row>
          </section>
        </Form>
      </section>
    </section>
  )
}

export default CommunicationTab
