import React from 'react'
import { ProgressBar } from 'react-angle-dashboard-components'
import { CardTitle } from '../style'
import { formatCurrencyForNonIntegers } from '../../../utils/formatCurrencyForNonIntegers'

export const AccumsCard: React.FC<{
  title: string
  current: number
  max: number
  percentage: number
  remaining: number
}> = ({ title, current, max, percentage, remaining }) => {
  return (
    <>
      <CardTitle>{title}</CardTitle>
      <div className="fs-28 mt-2">
        <span>{formatCurrencyForNonIntegers(current)} / </span>
        <span className="text-secondary">
          {formatCurrencyForNonIntegers(max)}
        </span>
      </div>
      <ProgressBar now={percentage} className="mb-1 mt-2" />
      <small className="text-secondary">
        {formatCurrencyForNonIntegers(remaining)} remaining
      </small>
    </>
  )
}
