import React from 'react'
import { useQueryParams } from 'react-angle-dashboard-components'
import { VoluntarySelection } from './VoluntarySelection/VoluntarySelection'
import { BenefitDetails } from './BenefitDetails/BenefitDetails'
import { Summary } from '../Summary/Summary'

export const VoluntaryRouter = () => {
  const params = useQueryParams()

  if (params.get('benefit')) {
    return <BenefitDetails />
  }

  if (params.get('summary')) {
    return <Summary />
  }

  return <VoluntarySelection />
}
