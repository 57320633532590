import React from 'react'
// import { IDependentForm } from 'react-angle-dashboard-components'
import { DependentCard } from './DependentCard'
import { DependentListContainer } from '../style'

const getNameString = (
  firstName: string,
  lastName: string,
  middleName?: string,
  suffix?: string
) => {
  let nameStr = `${firstName} ${lastName}`
  if (middleName) {
    nameStr = `${firstName} ${middleName} ${lastName}`
  }

  if (suffix) {
    nameStr += ' ' + suffix
  }
  return nameStr
}

interface DependentListProps {
  dependents: any[]
  refetch: any
  setEditingDependent: any
  setShowFormModal: any
}

export const DependentList: React.FC<DependentListProps> = ({
  dependents,
  refetch,
  setEditingDependent,
  setShowFormModal
}) => {
  if (dependents.length <= 0)
    return (
      <>
        <h5>No dependents added</h5>
      </>
    )

  return (
    <DependentListContainer>
      {dependents?.map((d: any) => (
        <DependentCard
          key={d.memberId}
          name={getNameString(d.firstName, d.lastName, d.middleName, d.suffix)}
          memberId={d.memberId}
          isSpouse={d.isSpouse}
          refetch={refetch}
          setEditingDependent={setEditingDependent}
          setShowFormModal={setShowFormModal}
          dependent={d}
        />
      ))}
    </DependentListContainer>
  )
}
