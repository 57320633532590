import { Form } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const PhotoBackground = styled.div`
  background-image: url('/compare-prices-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 296px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 45px;
  padding-bottom: 45px;
`

export const HeaderText = styled.h1`
  font-family: 'Sentinel';
  font-style: normal;
  font-weight: 375;
  font-size: 48px;
  line-height: 48px;
  color: white;
`

export const SubHeaderText = styled.h2`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
`

export const SearchBarLabel = styled.p`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-bottom: 12px;
`

export const FormContainer = styled.div`
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 12px;
`

export const FormLabel = styled(Form.Label)`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #343a40;
  margin-bottom: 12px;
  margin-top: 12px;
`

export const ListItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ListItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #6d39d9;
`

export const ListItemDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;

  white-space: pre-wrap;

  color: #6c757d;
`

export const Inline = styled.span`
  display: flex;
  align-items: center;
`

export const InlineSpace = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ResultHeader = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #343a40;
`

export const CostContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
`

export const CostText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #212529;
`

export const CostDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #6c757d;
`
