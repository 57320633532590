import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'react-angle-dashboard-components'
import { DocumentSolid } from '@angle/icons'
import { Paths } from '../../routes/paths'

const Container = styled.div`
  background: #eeeaf7;
  padding: 1.5rem;
  border: 1px solid #e7eaf3;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Circle = styled.div`
  background: var(--primary);
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`

const Text = styled.p`
  font-weight: 500;
  text-align: center;
`

interface RenewalsCTAProps {
  [key: string]: any
}

export const RenewalsCTA: React.FC<RenewalsCTAProps> = ({ ...rest }) => {
  const history = useHistory()

  return (
    <Container {...rest}>
      <Circle>
        <DocumentSolid color="#fff" width="60%" height="60%" />
      </Circle>
      <h3 className="mb-3">Open Enrollment is Here</h3>
      <Text>
        Access your onboarding experience to make your benefit elections for
        upcoming plan year.
      </Text>
      <Button
        className="w-100 mt-4"
        onClick={() => history.push(Paths.onboarding)}
      >
        Start Enrollment
      </Button>
    </Container>
  )
}
