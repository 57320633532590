import { Accum } from '../../../interfaces/claims'

export const getAccumsValues = (accum: Accum) => {
  const max = accum?.limit
  const current = accum?.coverage

  const remaining = max - current
  const percentage = Math.trunc((current * 100) / max)
  return { max, current, remaining, percentage }
}
