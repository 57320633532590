import React from 'react'
import {
  CenteredSpinner,
  Col,
  SectionTitle
} from 'react-angle-dashboard-components'
import { Wrapper } from './style'
import { Step } from './Step'
import {
  defaultSteps,
  renewalSteps,
  voluntaryRenewalSteps,
  voluntarySteps
} from './steps'
import { IStep } from './interfaces'

export const Sidebar: React.FC<{ memberData: any; isLoading: boolean }> = ({
  memberData,
  isLoading
}) => {
  const showVoluntary = memberData?.showVoluntary
  let steps = defaultSteps

  if (memberData?.inRenewal && showVoluntary) {
    steps = voluntaryRenewalSteps
  } else if (memberData?.inRenewal) {
    steps = renewalSteps
  } else if (showVoluntary) {
    steps = voluntarySteps
  }

  return (
    <Col id="summery-container" xs={12} md={4} xl={3} style={{ minWidth: 315 }}>
      <Wrapper className="mb-3 mb-lg-0">
        <SectionTitle>Summary</SectionTitle>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <>
            {steps.map((step: IStep) => (
              <Step
                stepNumber={step.number}
                serverStep={memberData?.serverStep}
                key={step.number}
                title={step.title}
                stepName={step.stepName}
              />
            ))}
          </>
        )}
      </Wrapper>
    </Col>
  )
}
