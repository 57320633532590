import React from 'react'
import {
  Button,
  CenteredSpinner,
  useToaster
} from 'react-angle-dashboard-components'
import { Paths } from '../../../../../routes/paths'
import { useHistory } from 'react-router-dom'
import { Footer } from '../../../style'
import { onboardingPaths } from '../../../Router'
import { BenefitItem } from './BenefitItem'
import { useQuery } from 'react-query'
import { memberService } from '../../../../../services'
import { useGetSelectedBenefits } from '../useGetSelectedPlans'
import { useUpdateMember } from '../../../hooks/useUpdateMember'

export const VoluntarySelection = () => {
  const history = useHistory()
  const { showError } = useToaster()

  const { selectedBenefits, isLoading: isLoadingSelectedBenefits } =
    useGetSelectedBenefits()

  const { data: availableBenefitsData, isLoading: isLoadingTypes } = useQuery(
    'getAvailableBenefitTypes',
    () => memberService.getAvailableBenefitTypes()
  )

  const updateMember = useUpdateMember()
  const handleBack = () => {
    history.push(Paths.onboarding + onboardingPaths.plan)
  }

  const handleContinue = async () => {
    try {
      await updateMember.mutateAsync({
        in_progress_state: availableBenefitsData?.inRenewal ? 6 : 7
      })
      history.push(
        Paths.onboarding + onboardingPaths.voluntary + '?summary=true'
      )
    } catch (error: any) {
      showError(error?.message)
    }
  }

  const isLoading = isLoadingSelectedBenefits || isLoadingTypes

  return (
    <div>
      <div>
        <h3>Voluntary Benefits</h3>
        <p>
          Voluntary benefits can further complement your healthcare offerings
          and help offset unexpected and large expenses that medical insurance
          may not cover. The following voluntary benefits are available to you:
        </p>
      </div>
      <div>
        {isLoading ? (
          <div className="pb-5">
            <CenteredSpinner />
          </div>
        ) : (
          selectedBenefits
            .filter((benefit: any) => {
              const key = benefit.key
              if (
                availableBenefitsData &&
                availableBenefitsData.availableBenefitTypes[key] &&
                !!benefit
              ) {
                return true
              } else return false
            })
            .map((benefit: any) => {
              return <BenefitItem benefit={benefit} key={benefit.key} />
            })
        )}
      </div>
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </Footer>
    </div>
  )
}
