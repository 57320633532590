import styled from 'styled-components'

export const EllipsisCell = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 0;
`

export const InnerCellEllipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`