import React, { useContext } from 'react'
import { Button } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { memberService } from '../../services'
import { AuthContext } from '../../context/Auth/Auth'
import { HomeLayout } from '../layouts/HomeLayout'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../routes/paths'

const OnboardingCompleted = () => {
  const history = useHistory()

  const { userData: auth } = useContext(AuthContext)
  const { data } = useQuery('getMember', async () => {
    return memberService.getMemberInfo()
  })

  const onClick = async () => {
    history.replace(Paths.home)
  }

  const Iterate = (window as any).Iterate

  const iterateData = {
    email: auth?.username,
    external_id: data?.member_id,
    name: auth?.first_name + ' ' + auth?.last_name,
    groupName: data?.group_name,
    daysSinceJoined: data?.days_since_joined
  }

  Iterate('identify', iterateData)

  return (
    <HomeLayout
      noSearchBar
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Onboarding', link: '/onboarding' }
      ]}
    >
      <section
        className="d-flex justify-content-center mx-3 mt-5 pb-5"
        style={{
          height: '70vh'
        }}
      >
        <div className="align-self-center">
          <svg
            width="89"
            height="89"
            viewBox="0 0 89 89"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M80.1761 29.7233C82.6831 17.29 71.7104 6.31732 59.2772 8.82432C52.2576 -1.74141 36.7396 -1.74141 29.7236 8.82432C17.2904 6.31732 6.31769 17.29 8.82469 29.7233C-1.74104 36.7429 -1.74104 52.2608 8.82469 59.2768C6.31769 71.7101 17.2904 82.6827 29.7236 80.1757C36.7432 90.7415 52.2612 90.7415 59.2772 80.1757C71.7104 82.6827 82.6831 71.7101 80.1761 59.2768C90.7418 52.2572 90.7418 36.7429 80.1761 29.7233ZM68.8692 32.5355L41.4012 60.0035C40.7181 60.6865 39.7952 61.068 38.8324 61.068C37.8696 61.068 36.9431 60.6865 36.2636 60.0035L23.7177 47.4576C22.2971 46.0369 22.2971 43.7407 23.7177 42.32C25.1384 40.8994 27.4346 40.8994 28.8553 42.32L38.8324 52.2972L63.7316 27.3979C65.1523 25.9773 67.4485 25.9773 68.8692 27.3979C70.2898 28.8186 70.2898 31.1148 68.8692 32.5355Z"
              fill="#7DD591"
            />
          </svg>

          <h1 className="mb-5 mt-3">
            Congratulations, {auth?.first_name + ' ' + auth?.last_name}
          </h1>

          <div className="my-5 py-3">
            <p> You've completed your insurance enrollment!</p>
            <p>We're excited to start this journey with you!</p>
            <p>
              You'll be receiving a welcome packet as part of the next step on
              your healthcare journey. Don't forget you can access your member
              portal to view plan details, find a doctor, contact your care team
              and more!
            </p>
          </div>
          <p className="my-5">The Angle Health Team</p>
          <Button onClick={onClick}>View your member portal</Button>
        </div>
      </section>
    </HomeLayout>
  )
}

export default OnboardingCompleted
