import styled from 'styled-components'
import { Modal } from 'react-angle-dashboard-components'

export const DependentsContainer = styled.div`
  margin-bottom: 2rem;
  padding-bottom: 1rem;
`
export const DependentListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

export const DependentWrapper = styled.div`
  height: 68px;
  width: 215px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--card-border);
  border-radius: 6px;
  margin-right: 1rem;
  margin-bottom: 1rem;
`

export const DependentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Cont = styled.div`
  max-width: 45.625rem;
  margin: 0 auto;
`

export const FullScreenModal = styled(Modal)`
  position: fixed;
  top: 75px;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 2em;
  padding-left: 0 !important;

  & > .modal-dialog {
    max-width: 100% !important;
    margin-top: 0 !important;
  }
`
