import React, { useState, ReactNode } from 'react'
import Skeleton from 'react-loading-skeleton'

import { OverlayTrigger, Tooltip } from 'react-angle-dashboard-components'
import { HelpIcon, InnerCardStepNumber, PlanGlanceInnerCard } from '../../style'
import MoreInfoModal from '../MoreInfoModal/MoreInfoModal'

interface InnerCardProps {
  children?: ReactNode
  isLoading?: boolean
  isSelected?: boolean
  learnMore?: {
    mode?: string
    tooltipText: string
  }
  memberName?: string
  mode?: string
  step: number
  subTitle: string
  title: string
}

const InnerCard = ({
  children,
  isLoading = false,
  isSelected = false,
  learnMore,
  memberName,
  step,
  subTitle,
  title,
}: InnerCardProps) => {

  const [isMoreInfoModalOpen, setMoreInfoModalOpen] = useState<boolean>(false)


  return (
    <>
      {isMoreInfoModalOpen && (
        <MoreInfoModal
          show={isMoreInfoModalOpen}
          requestClose={() => setMoreInfoModalOpen(false)}
          mode={learnMore?.mode}
        />
      )}
      <PlanGlanceInnerCard
        className={isSelected && !isLoading ? 'border-primary bg-white' : undefined}
      >
        <div className="d-flex align-items-center mb-3">
          {isLoading ? (
            <Skeleton circle height={26} width={26} />
          ) : (
            <InnerCardStepNumber
              className={isSelected ? 'bg-primary text-white' : undefined}
            >
              {step}
            </InnerCardStepNumber>
          )}
          {isSelected && !isLoading && (
            <p className="text-primary ml-2 fs-16 p-0 m-0">{`${memberName} is here`}</p>
          )}
        </div>
        <h6 className="text-nowrap fs-18">{isLoading ? <Skeleton /> : title}</h6>
        <span className="text-secondary fs-12">
          {isLoading ? <Skeleton count={2} /> : subTitle}
        </span>
        {!!learnMore ? (
          <div className="my-3">
            {isLoading ? (
              <Skeleton width={150} />
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="inner-card-tooltip">
                    {learnMore.tooltipText}
                  </Tooltip>
                }
              >
                <div
                  className="text-primary d-flex align-items-center"
                  style={{
                    width: 'fit-content'
                  }}
                >
                  <HelpIcon className="icon icon-question-mark fs-14 mr-2 mb-1" />
                  <span
                    onClick={() => setMoreInfoModalOpen(true)}
                    style={{cursor: 'pointer' }}>Learn More</span>
                </div>
              </OverlayTrigger>
            )}
          </div>
        ) : (
          <div className="my-3" />
        )}
        {children}
      </PlanGlanceInnerCard>
    </>
  )
}

export default InnerCard
