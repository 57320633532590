import React, { useState } from 'react'
import { Alert, Button, Card, Col, Row, Table } from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import { ClaimRowProps } from './ClaimRow'
import ClaimsTableBody from './ClaimsTableBody'
import { Paths } from '../../../routes/paths'
import { ReactComponent as InfoCircle } from '../../../assets/infoCircle.svg'
import { HelpIcon } from '../../MyPlan/style'
import MoreInfoModal from '../../MyPlan/PlanContent/MoreInfoModal/MoreInfoModal'

const headers = [
  { title: 'Claim No. & DOS' },
  { title: 'Status' },
  { title: 'Provider' },
  { title: 'Member' },
  { title: 'Amount Billed' },
  { title: 'Patient Responsibility' },
  { title: 'Payment Type' }
]

interface ClaimsTableProps {
  data: ClaimRowProps[] | undefined
  isLoading: boolean
  isMobile?: boolean
  paginationConfig: Parameters<typeof Table>[0]['paginationConfig']
  filtersActive: boolean
  onClearAllFilters: () => void
}

const ClaimsTable = ({
  data = [],
  isLoading,
  isMobile,
  filtersActive,
  paginationConfig,
  onClearAllFilters
}: ClaimsTableProps) => {
  const history = useHistory()

  const [showAlert, setShowAlert] = useState<boolean>(true)
  const [isMoreInfoModalOpen, setMoreInfoModalOpen] = useState<boolean>(false)

  const alert = {
    type: 'warning',
    message: isMobile ? (
      <>
        <InfoCircle style={{ height: '1rem'}} />
        <span className="mr-3">
          Deductible and Out-of-Pocket Maximum are now moved to your plan details page.
        </span>
        <Button
          variant="white"
          className="bg-white py-1 text-primary"
          onClick={() =>
            history.push(Paths.myPlan)
          }
        >
          View plan details
        </Button>
        <Button
          onClick={() => setShowAlert(false)}
          style={{
            backgroundColor: 'rgb(0, 0, 0, 0)',
            borderColor: 'rgb(0, 0, 0, 0)',
            color: '#6C757D'
            }}>
          X
        </Button>
      </>
    ) : (
      <Row style={{ marginTop: '-6px', alignItems: 'center' }}>
        <Col className='p-0' style={{ flexGrow: 2, display: 'flex', justifyContent: 'center' }}>
          <InfoCircle style={{ height: '1rem', marginTop: '4px' }} />
          <span className="mr-3">
            Deductible and Out-of-Pocket Maximum are now moved to your plan details page.
          </span>
        </Col>
        <Col style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '5px'
        }}>
          <Button
            variant="white"
            className="bg-white py-1 text-primary view-plan-details-btn"
            onClick={() =>
              history.push(Paths.myPlan)
            }
            style={{
              height: '36px',
              marginTop: '6px'
            }}
          >
            View plan details
          </Button>
          <Button
            onClick={() => setShowAlert(false)}
            style={{
              backgroundColor: 'rgb(0, 0, 0, 0)',
              borderColor: 'rgb(0, 0, 0, 0)',
              color: '#6C757D'
              }}>
            X
          </Button>
        </Col>
      </ Row>
    ),
    style: {
      backgroundColor: '#17A2B833',
      height: isMobile ? '' : '52px'
    },
    className:
      'w-full text-center py-2 flex align-items-center border-0'
  }

  return (
    <>
      {isMoreInfoModalOpen && (
        <MoreInfoModal
          show={isMoreInfoModalOpen}
          requestClose={() => setMoreInfoModalOpen(false)}
          mode={'clc'}
          noBorders={true}
        />
      )}
      {showAlert && (<div style={{ marginBottom: 25 }}>
        <Alert
          className={`my-0 rounded-0 text-center ${alert.className || ''}`}
          style={alert.style}
          variant={alert.type}
          show={showAlert}
        >
          {alert.message}
        </Alert>
      </div>)}
      <Card>
        <Card.Header className="border-0 font-weight-bold fs-20">
          <Col style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0 }}>
            {isLoading ? (
              <Skeleton width={150} />
            ) : (
              `Claims (${paginationConfig?.totalItems})`
            )}
            <Button
              variant="white"
              className="bg-white py-1 text-primary"
              onClick={() => setMoreInfoModalOpen(!isMoreInfoModalOpen)}
            >
              <HelpIcon className="icon icon-question-mark fs-14 mr-2 mb-1" /> See claim lifecycle
            </Button>
          </Col>
        </Card.Header>
        <Card.Body className="px-0" style={{ paddingTop: 0 }}>
          <Table
            className="border-0 table-sm"
            headers={headers}
            headerClassName="thead-light"
            // paginationConfig={paginationConfig}
          >
            <tbody>
              <ClaimsTableBody
                data={data}
                isLoading={isLoading}
                numCols={headers.length}
                onClearAllFilters={onClearAllFilters}
                filtersActive={filtersActive}
                seeMoreClcHandler={setMoreInfoModalOpen}
              />
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimsTable