import React, { ReactNode, useState } from 'react'
import { AnglePlanBadge, Badge, Button, Card, Col, Row } from 'react-angle-dashboard-components'
import { DateHandler } from '@angle/utils'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

// import { ReactComponent as AngleGoldBadge } from '../../../../assets/angle-gold-badge.svg'
import { ICoveredMember } from '../../../../interfaces/coverage'
import MemberListModal from './MemberListModal'


const GridCell = ({
  label,
  children,
  seperator = false
}: {
  label: string
  children: ReactNode
  seperator?: boolean
}) => {
  let className = 'd-flex justify-content-between mb-2 py-3'

  if (seperator) {
    className += ' border-bottom'
  }

  return (
    <Row noGutters className={className} style={{ color: '#6C757D' }}>
      <span
        style={{
          color: '#343A40'
        }}
      >
        {label}
      </span>
      {children || <Skeleton width={100} />}
    </Row>
  )
}

interface PlanDetailCardProps {
  endDate: string | undefined
  isCobra: boolean | undefined
  memberId: string | undefined
  personCode: string | undefined
  planName: string | undefined
  planType: string | undefined
  coveredMembers: ICoveredMember[] | undefined
}

const PlanDetailCard = ({
  coveredMembers,
  endDate,
  memberId,
  personCode,
  planName,
  planType,
  isCobra
}: PlanDetailCardProps) => {
  
  const [showMemberModal, setShowMemberModal] = useState(false)

  const endDateIsInFuture = moment(endDate).isAfter(moment())

  const dateText = endDateIsInFuture
    ? `Plan ends on ${DateHandler.formatForClient(endDate)}`
    : `Plan ended on ${DateHandler.formatForClient(endDate)}`

  return (
    <>
      {coveredMembers && (
        <MemberListModal
          show={showMemberModal}
          requestClose={() => setShowMemberModal(false)}
          coveredMembers={coveredMembers}
        />
      )}
      <Card className="p-3 h-100">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h5>Plan Details</h5>
          {endDate && (
            <Badge
              style={{
                backgroundColor: '#DBDEE280',
                color: '#6C757D',
                minWidth: 60
              }}
            >
              <span className="d-flex flex-row align-items-center fs-16 font-weight-normal">
                <i className="icon-calendar fs-20 text-muted mr-2" />
                {dateText}
              </span>
            </Badge>
          )}
        </div>
        <div className="d-flex align-items-center">
          {planName ? (
            <span
              className="d-flex gx-1 align-items-center"
            >
              <AnglePlanBadge
                planName={planName}
                style={{
                  width: 28
                }}
              />
            </span>
          ) : (
            <Skeleton width={200} />
          )}
          {isCobra && (
            <Badge
              className="ml-2 rounded-pill text-white"
              style={{
                backgroundColor: '#56CCF2',
                height: 'fit-content'
              }}
            >
              COBRA
            </Badge>
          )}
        </div>
        <div className="h-100 d-flex flex-column justify-content-center">
          <Row>
            <Col>
              <GridCell label="Member ID" seperator>
                {memberId?.length === 12 ? memberId.substring(0, 10) : memberId}
              </GridCell>
            </Col>
            <Col>
              <GridCell label="Covered" seperator>
                {coveredMembers && (
                  <Button
                    variant="link"
                    className="p-0 m-0 d-flex align-items-center font-weight-normal"
                    onClick={() => setShowMemberModal(true)}
                  >
                    {coveredMembers.length === 1 ? '1 member' : `${coveredMembers.length} members`}
                    <i className="icon-right-arrow fs-20 text-muted ml-2" />
                  </Button>
                )}
              </GridCell>
            </Col>
          </Row>
          <Row>
            <Col>
              <GridCell label="Person Code">{personCode}</GridCell>
            </Col>
            <Col>
              <GridCell label="Plan Type">{(planType?.charAt(0).toUpperCase() || '') + (planType?.slice(1))}</GridCell>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  )
}

export default PlanDetailCard
