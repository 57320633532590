import React from 'react'
import { Form } from 'react-angle-dashboard-components'
import styled from 'styled-components'
import { ListItemDescription, ListItemTitle } from './style'

interface IListItemProps {
  image: string
  title: string
  description: string
  value: string
  selected: boolean
  onClick: (value: string) => void
}

const ListItem = ({
  image,
  title,
  description,
  value,
  selected,
  onClick
}: IListItemProps) => {
  const ListItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 12px;

    margin-bottom: 16px;

    cursor: pointer;

    ${selected
      ? `
      border: 1px solid #6D39D9;
      border-radius: 8px;
    `
      : `
      border: 1px solid #e7eaf3;
      border-radius: 8px;
    `}
  `

  return (
    <ListItemContainer
      onClick={() => onClick(value)}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Form.Check
        type="radio"
        name="placeOfService"
        value={value}
        checked={selected}
        readOnly
      />
      <img style={{ objectFit: 'contain' }} src={image} alt={`${title}`} />
      <div>
        <ListItemTitle>{title}</ListItemTitle>
        <ListItemDescription>{description}</ListItemDescription>
      </div>
    </ListItemContainer>
  )
}

export default ListItem
