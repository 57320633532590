import * as React from 'react'
import { SVGProps } from 'react'

const DotsSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4 15"
    fill="currentColor"
    {...props}
  >
    <g id="Dots">
      <path
        id="Vector"
        d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585787C0.210713 0.960859 0 1.46957 0 2C0 2.53043 0.210713 3.03914 0.585786 3.41421C0.960859 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.960859 3.41421 0.585787C3.03914 0.210714 2.53043 0 2 0ZM2 5.33333C1.46957 5.33333 0.960859 5.54405 0.585786 5.91912C0.210713 6.29419 0 6.8029 0 7.33333C0 7.86377 0.210713 8.37247 0.585786 8.74755C0.960859 9.12262 1.46957 9.33333 2 9.33333C2.53043 9.33333 3.03914 9.12262 3.41421 8.74755C3.78929 8.37247 4 7.86377 4 7.33333C4 6.8029 3.78929 6.29419 3.41421 5.91912C3.03914 5.54405 2.53043 5.33333 2 5.33333ZM2 10.6667C1.46957 10.6667 0.960859 10.8774 0.585786 11.2525C0.210713 11.6275 0 12.1362 0 12.6667C0 13.1971 0.210713 13.7058 0.585786 14.0809C0.960859 14.456 1.46957 14.6667 2 14.6667C2.53043 14.6667 3.03914 14.456 3.41421 14.0809C3.78929 13.7058 4 13.1971 4 12.6667C4 12.1362 3.78929 11.6275 3.41421 11.2525C3.03914 10.8774 2.53043 10.6667 2 10.6667Z"
      />
    </g>
  </svg>
)

export default DotsSolid
