import * as React from 'react'
import { SVGProps } from 'react'

const AngleBadgeSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    fill="currentColor"
    {...props}
  >
    <g id="AngleBadge">
      <circle id="Ellipse 270" cx={8.5} cy={8.5} r={8.5} />
      <g id="Subtract">
        <path d="M7.23491 10.81H10.0931L10.7512 12.4676H12.5607L11.3152 9.22457H7.82547L7.23491 10.81Z" />
        <path d="M8.6781 6.93554L9.41162 8.91228H11.1977L9.52563 4.6602L8.6781 6.93554Z" />
        <path d="M9.34958 4.21249L9.34112 4.19097H7.67258L4.453 12.4676H6.26255L8.50685 6.47403L8.50702 6.47449L9.34958 4.21249Z" />
      </g>
    </g>
  </svg>
)

export default AngleBadgeSolid
