import React, { useCallback } from 'react'
import ClaimRow, { ClaimRowProps } from './ClaimRow'
import Skeleton from 'react-loading-skeleton'
import { Button } from 'react-angle-dashboard-components'

interface ClaimsTableBodyProps {
  data: ClaimRowProps[] | undefined
  isLoading: boolean
  numCols: number
  filtersActive: boolean
  onClearAllFilters: () => void
  seeMoreClcHandler: (showModal: boolean) => void
}

const ClaimsTableBody = ({
  data,
  isLoading,
  numCols,
  filtersActive,
  onClearAllFilters,
  seeMoreClcHandler
}: ClaimsTableBodyProps) => {
  const LoadingRow = useCallback(
    () => (
      <tr>
        {new Array(numCols).fill(1).map((h) => (
          <td>
            <Skeleton />
          </td>
        ))}
      </tr>
    ),
    [numCols]
  )

  if (isLoading) {
    return (
      <>
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
        <LoadingRow />
      </>
    )
  }

  if (!data || data?.length === 0) {
    const titleText = filtersActive ? 'No search results' : 'No claims to display'
    const detailText = filtersActive
      ? 'Try adjust your filters to find the claim you are looking for.'
      : 'Your claims will be shown here once they are submitted.'
    const ctaButton = filtersActive ? (
      <Button variant="outline-primary" onClick={onClearAllFilters}>
        Clear All Filters
      </Button>
    ) : (
      <Button variant="outline-primary" onClick={() => seeMoreClcHandler(true)}>
        See claim lifecycle
      </Button>
    )
    return (
      <tr>
        <td colSpan={100}>
          <div className="d-flex justify-content-center align-items-center flex-column p-5">
            {filtersActive && (
              <i
                className="icon-search"
                style={{
                  fontSize: 100,
                  color: '#BDBDBD'
                }}
              />
            )}
            <div
              style={{
                fontSize: 20,
                fontWeight: 500
              }}
            >
              {titleText}
            </div>
            <div className="text-muted">{detailText}</div>
            <div className="mt-3">{ctaButton}</div>
          </div>
        </td>
      </tr>
    )
  }

  return (
    <>
      {data?.map(
        ({
          amountBilled,
          claimId,
          dateOfService,
          isDeductibleMet,
          memberName,
          patientResponsibility,
          providerName,
          providerSpecialty,
          status,
          type,
          paymentSource
        }) => (
          <ClaimRow
            claimId={claimId}
            dateOfService={dateOfService}
            status={status}
            providerName={providerName}
            providerSpecialty={providerSpecialty}
            memberName={memberName}
            amountBilled={amountBilled}
            patientResponsibility={patientResponsibility}
            isDeductibleMet={isDeductibleMet}
            type={type}
            paymentSource={paymentSource}
          />
        )
      )}
    </>
  )
}

export default ClaimsTableBody
