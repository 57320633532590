import React, { ReactNode } from 'react'
import { Col, Row } from 'react-angle-dashboard-components'
import { NumberContainer, StepContent, StepTitle } from './style'

const StepRow = ({
  stepNumber,
  title,
  children,
  stepColor
}: {
  stepNumber: string
  title: string
  children: ReactNode
  stepColor?: string
}) => (
  <Row className="my-4">
    <NumberContainer
      style={{
        color: stepColor
      }}
    >
      {stepNumber}
    </NumberContainer>
    <Col>
      <StepTitle>{title}</StepTitle>
      <StepContent>{children}</StepContent>
    </Col>
  </Row>
)

export default StepRow
