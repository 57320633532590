import React from 'react'
import styled from 'styled-components'
import { Button } from 'react-angle-dashboard-components'

const WaiveSuccessWrapper = styled.div`
  padding-bottom: 2rem;
  height: calc(100vh - 75px);
  overflow: auto;
  background-color: #fff;
  position: fixed;
  top: 75px;
  left: 0;
  z-index: 9999999;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
`

const SuccessIcon = styled.i`
  font-size: 18px;
  border-radius: 50%;
  background-color: rgba(125, 213, 145, 0.3);
  color: #219653;
  height: 42px;
  width: 42px;
  margin-bottom: 1rem;
`

const BackButton = styled(Button)`
  border-color: var(--card-border);
  margin: 10px 0 50px 0;
`

const QualifyingEventWrapper = styled.div`
  padding: 1.25rem;
  border: 1px solid var(--card-border);
  border-radius: 6px;
  width: 24%;
`

const QualifyingEvent: React.FC<{ title: string; events: string[] }> = ({
  title,
  events
}) => (
  <QualifyingEventWrapper>
    <b>{title}</b>
    <ul className="pl-4">
      {events.map((event: string) => (
        <li className="mt-3" style={{ color: '#343A40' }} key={event}>
          {event}
        </li>
      ))}
    </ul>
  </QualifyingEventWrapper>
)

interface WaiveCoverageSuccessProps {
  show: boolean
  goBackToPlans?: Function
}

export const WaiveCoverageSuccess: React.FC<WaiveCoverageSuccessProps> = ({
  show,
  goBackToPlans
}) => {
  if (!show) return null

  const qualifyingEvents = [
    {
      title: 'Loss of health coverage',
      events: [
        'Losing existing health coverage, including job-based, individual, and student plans',
        'Losing eligibility for Medicare, Medicaid, or CHIP',
        'Turning 26 and losing coverage through a parent’s plan'
      ]
    },
    {
      title: 'Changes in household',
      events: [
        'Getting married or divorced',
        'Having a baby or adopting a child',
        'Death in the family'
      ]
    },
    {
      title: 'Changes in residence',
      events: [
        'Moving to a different ZIP code or county',
        'A student moving to or from the place they attend school',
        'A seasonal worker moving to or from the place they both live and work',
        'Moving to or from a shelter or other transitional housing'
      ]
    },
    {
      title: 'Other qualifying events',
      events: [
        'Changes in your income that affect the coverage you qualify for',
        'Gaining membership in a federally recognized tribe or status as an Alaska Native',
        'Claims Settlement Act (ANCSA) Corporation shareholder',
        'Becoming a U.S. citizen',
        'Leaving incarceration (jail or prison)',
        'AmeriCorps members starting or ending their service'
      ]
    }
  ]
  return (
    <WaiveSuccessWrapper>
      <SuccessIcon className="icon icon-correct" />
      <h1 className="fs-32 mb-2">You have waived your coverage.</h1>
      <p className="text-muted">
        If a Qualified Life Event occurs, you have the ability to re-enroll into
        a Angle Health Plan.
      </p>
      {goBackToPlans ? (
        <BackButton
          variant="primary"
          size="sm"
          className="fs-16"
          ghost
          onClick={goBackToPlans}
        >
          Go back to plans
        </BackButton>
      ) : null}
      <h4 className="mb-5">Qualifying events</h4>
      <div
        className="mt-3 d-flex justify-content-between align-items-start"
        style={{ width: '80%' }}
      >
        {qualifyingEvents.map((qe) => (
          <QualifyingEvent key={qe.title} title={qe.title} events={qe.events} />
        ))}
      </div>
    </WaiveSuccessWrapper>
  )
}

export default WaiveCoverageSuccess
