import { useQuery } from 'react-query'
import { coverageService } from '../../../services'

export const useGetAvailablePlans = (benefitKey: string) => {
  const {
    data: availablePlans,
    isLoading: isLoadingPlans,
    error: plansError
  } = useQuery([`getAvailable${benefitKey}`], () =>
    coverageService.getAvailablePlans(benefitKey)
  )

  return { availablePlans, isLoadingPlans, plansError }
}
