export const defaultSteps = [
  {
    title: 'Personal Information',
    stepName: 'personal',
    number: 1
  },
  {
    title: 'Contact Information',
    stepName: 'contact',
    number: 2
  },
  {
    title: 'Current Carrier',
    stepName: 'current-carrier',
    number: 3
  },
  {
    title: 'Dependents',
    stepName: 'dependents',
    number: 4
  },
  {
    title: 'Plan',
    stepName: 'plan',
    number: 5
  },
  {
    title: 'Review',
    stepName: 'review',
    number: 6
  },
  {
    title: 'Agreement',
    stepName: 'agreement',
    number: 7
  }
]

export const voluntarySteps = [
  {
    title: 'Personal Information',
    stepName: 'personal',
    number: 1
  },
  {
    title: 'Contact Information',
    stepName: 'contact',
    number: 2
  },
  {
    title: 'Current Carrier',
    stepName: 'current-carrier',
    number: 3
  },
  {
    title: 'Dependents',
    stepName: 'dependents',
    number: 4
  },
  {
    title: 'Plan',
    stepName: 'plan',
    number: 5
  },
  {
    title: 'Voluntary Benefits',
    stepName: 'voluntary',
    number: 6
  },
  {
    title: 'Review',
    stepName: 'review',
    number: 7
  },
  {
    title: 'Agreement',
    stepName: 'agreement',
    number: 8
  }
]

export const voluntaryRenewalSteps = [
  {
    title: 'Personal Information',
    stepName: 'personal',
    number: 1
  },
  {
    title: 'Contact Information',
    stepName: 'contact',
    number: 2
  },
  {
    title: 'Dependents',
    stepName: 'dependents',
    number: 4
  },
  {
    title: 'Plan',
    stepName: 'plan',
    number: 5
  },
  {
    title: 'Voluntary Benefits',
    stepName: 'voluntary',
    number: 6
  },
  {
    title: 'Review',
    stepName: 'review',
    number: 7
  },
  {
    title: 'Agreement',
    stepName: 'agreement',
    number: 8
  }
]

export const renewalSteps = [
  {
    title: 'Personal Information',
    stepName: 'personal',
    number: 1
  },
  {
    title: 'Contact Information',
    stepName: 'contact',
    number: 2
  },
  {
    title: 'Dependents',
    stepName: 'dependents',
    number: 3
  },
  {
    title: 'Plan',
    stepName: 'plan',
    number: 4
  },
  {
    title: 'Review',
    stepName: 'review',
    number: 5
  },
  {
    title: 'Agreement',
    stepName: 'agreement',
    number: 6
  }
]
