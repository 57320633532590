import classNames from 'classnames'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  Row,
  PlaceAutocomplete,
  SwitchRow,
  SectionTitle,
  IDependentForm,
  useMultiCustomCheck,
  DatePicker
} from 'react-angle-dashboard-components'
import {
  Control,
  Controller,
  ControllerRenderProps,
  UseFormSetValue
} from 'react-hook-form'
import InputMask from 'react-input-mask'
import { limitLength, states, typeOfCoverageList } from '@angle/utils'
import { FooterButtons } from './styles'
import { SpouseIcon } from './SpouseIcon'
import { ChildIcon } from './ChildIcon'
import moment from 'moment'
import { CheckBoxControl } from './CheckBoxControl'

interface RegistrationFormProps
  extends React.AllHTMLAttributes<HTMLDivElement> {
  onContinue: Function
  onBack: () => void
  control: Control<IDependentForm>
  isSpouse: string
  healthInsurance: boolean
  getErrors: Function
  formState: any
  getValues: Function
  trigger: Function
  unregister: Function
  setValue: UseFormSetValue<IDependentForm>
  error?: any
}

export const DependentRegistrationForm = ({
  onContinue,
  onBack,
  control,
  isSpouse,
  getErrors,
  formState,
  getValues,
  trigger,
  setValue,
  healthInsurance,
  unregister,
  error
}: RegistrationFormProps) => {
  const config = (window as any).runtimeConfig

  const googleApiKey = config?.googleApiKey || '****'

  const [sameAddress, setSameAddress] = useState(false)

  const submitForm = async () => {
    await trigger()

    const hasError = Object.keys(formState.errors).length

    if (!hasError) onContinue(sameAddress)
  }

  const requiredOnlySameAddress = (value: any) => {
    if (!sameAddress && !value) return false

    return true
  }

  const { defaultChecked, defaultValue, init, inputRef, isCustomChecked } =
    useMultiCustomCheck(
      typeOfCoverageList,
      getValues('carrierInfo.typesOfCoverage') || []
    )

  const onCheckCoverage =
    (
      field: ControllerRenderProps<
        IDependentForm,
        'carrierInfo.typesOfCoverage'
      >
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = field.value || []

      if (e.target.checked) {
        const newValue = e.target.value
        field.onChange([...value, newValue])
      } else {
        field.onChange(value.filter((v) => v !== e.target.value))
      }
    }

  const personalInfoError = getErrors('personalInfo') as any
  const carrierInfoError = getErrors('carrierInfo') as any

  const unregisterSpouseFields = () => {
    unregister('personalInfo.personalPhoneNumber')
    unregister('personalInfo.workPhoneNumber')
    unregister('personalInfo.workEmail')
  }

  const unregisterAddressFields = (sameAddress: boolean) => {
    if (sameAddress) {
      unregister('personalInfo.state')
      unregister('personalInfo.streetAddress')
      unregister('personalInfo.streetAddress2')
      unregister('personalInfo.zipCode')
      unregister('personalInfo.city')
    }
  }

  const unregisterCarrierFields = (otherInsurance: boolean) => {
    if (!otherInsurance) {
      unregister('carrierInfo.insurer')
      unregister('carrierInfo.policyNumber')
      unregister('carrierInfo.dateOfCoverage.startAt')
      unregister('carrierInfo.dateOfCoverage.endAt')
      unregister('carrierInfo.typesOfCoverage')
    }
  }

  return (
    <div style={{ paddingBottom: 200 }}>
      <SectionTitle>Dependent's Personal information</SectionTitle>
      <Form>
        <Row className="my-3">
          <Col>
            <h6
              className={classNames({
                'text-danger':
                  personalInfoError.errors?.isSpouse && formState.isSubmitted
              })}
            >
              Is this dependent a spouse/domestic partner or child?
            </h6>
          </Col>
        </Row>
        <Row>
          <Controller
            name="personalInfo.isSpouse"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <CheckBoxControl value="yes" field={field}>
                  <SpouseIcon selected={field.value === 'yes'} />
                  Spouse/Domestic Partner
                </CheckBoxControl>

                <CheckBoxControl
                  value="no"
                  field={field}
                  onChange={(e) => {
                    unregisterSpouseFields()
                    field.onChange(e)
                  }}
                >
                  <ChildIcon selected={field.value === 'no'} />
                  Child
                </CheckBoxControl>
              </>
            )}
          />
        </Row>
        {personalInfoError.errors?.isSpouse?.type === 'required' && (
          <span className="text-danger">Please choose dependent type</span>
        )}
        <Row className="mt-3">
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.firstName && formState.isSubmitted
                })}
              >
                First Name
              </Form.Label>
              <Controller
                name="personalInfo.firstName"
                defaultValue=""
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="First Name"
                    className="bg-white"
                    isInvalid={
                      personalInfoError.errors?.firstName?.type === 'required'
                    }
                  />
                )}
              />
              {personalInfoError.errors?.firstName?.type === 'required' && (
                <span className="text-danger">First Name is required</span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.middleName &&
                    formState.isSubmitted
                })}
              >
                Middle Name (Optional)
              </Form.Label>
              <Controller
                name="personalInfo.middleName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Middle Name"
                    className="bg-white"
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.lastName && formState.isSubmitted
                })}
              >
                Last Name
              </Form.Label>
              <Controller
                name="personalInfo.lastName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    className="bg-white"
                    placeholder="Last Name"
                    isInvalid={
                      personalInfoError.errors?.lastName?.type === 'required'
                    }
                  />
                )}
              />
              {personalInfoError.errors?.lastName?.type === 'required' && (
                <span className="text-danger">Last Name is required</span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.suffix && formState.isSubmitted
                })}
              >
                Suffix (Optional)
              </Form.Label>
              <Controller
                name="personalInfo.suffix"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    type="text"
                    placeholder="Suffix"
                    className="bg-white"
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.gender && formState.isSubmitted
                })}
              >
                What gender does the dependent identify as?
              </Form.Label>
              <Controller
                name="personalInfo.identifiedGender"
                rules={{ required: true }}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    as="select"
                    placeholder="Select gender"
                    className={
                      !field.value ? 'text-muted bg-white' : 'bg-white'
                    }
                    isInvalid={
                      personalInfoError.errors?.gender?.type === 'required'
                    }
                  >
                    <option value="" disabled hidden>
                      Select gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non_binary">Non-binary</option>
                    <option value="non_conforming">Non-conforming</option>
                  </Form.Control>
                )}
              />
              {personalInfoError.errors?.gender?.type === 'required' && (
                <span className="text-danger">
                  Identified Gender is required
                </span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.gender && formState.isSubmitted
                })}
              >
                Sex (Gender at Birth)
              </Form.Label>
              <Controller
                name="personalInfo.gender"
                rules={{ required: true }}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    as="select"
                    placeholder="Select gender"
                    className={
                      !field.value ? 'text-muted bg-white' : 'bg-white'
                    }
                    isInvalid={
                      personalInfoError.errors?.gender?.type === 'required'
                    }
                  >
                    <option value="" disabled hidden>
                      Select gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                )}
              />
              {personalInfoError.errors?.gender?.type === 'required' && (
                <span className="text-danger">Gender is required</span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            {/* Had to make the form group flex, and the label w-100, in order to keep the flatpickr input in correct placement while the flatpickr has static set to true. Not sure why bu setting static to true is the only way to rpevent the month flickering issue */}
            <Form.Group className="d-flex flex-column">
              <Form.Label
                style={{ minWidth: '100%' }}
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.dateOfBirth &&
                    formState.isSubmitted
                })}
              >
                Date Of Birth
              </Form.Label>
              <Controller
                name="personalInfo.dateOfBirth"
                key="personalInfo.dateOfBirth"
                rules={{
                  required: true,
                  validate: {
                    notFuture: (value) => {
                      if (moment(value).isSameOrBefore(new Date())) return true
                      else return false
                    }
                  }
                }}
                control={control}
                render={({ field }) => <DatePicker {...field} />}
              />
              {personalInfoError.errors?.dateOfBirth?.type === 'required' && (
                <span className="text-danger">Date Of Birth is required</span>
              )}
              {personalInfoError.errors?.dateOfBirth?.type === 'notFuture' && (
                <span className="text-danger">
                  Date of Birth cannot be in the future.{' '}
                </span>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.ssn && formState.isSubmitted
                })}
              >
                Social Security Number
              </Form.Label>
              <Controller
                name="personalInfo.ssn"
                rules={{
                  required: true,
                  validate: {
                    correctPattern: (value) => {
                      if (String(value).match(/[a-zA-Z]+/g)) return false
                      if (
                        (String(value)
                          ?.match(/[0-9]+/g)
                          ?.join('')?.length as any) < 9
                      )
                        return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    as={InputMask}
                    mask="999-99-9999"
                    placeholder="Enter dependent's SSN"
                    className={
                      !field.value ? 'text-muted bg-white' : 'bg-white'
                    }
                    isInvalid={
                      personalInfoError.errors?.ssn?.type === 'required'
                    }
                  />
                )}
              />
              {personalInfoError.errors?.ssn &&
                formState.isSubmitted &&
                personalInfoError.errors?.ssn?.type === 'correctPattern' && (
                  <span className="text-danger">SSN must be 9 digits.</span>
                )}
              {personalInfoError.errors?.ssn?.type === 'required' && (
                <span className="text-danger">
                  Social Security Number is required
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="pb-2">
          <Col xs={12} md={6}>
            {' '}
            <Form.Group>
              <Form.Label
                className={classNames({
                  'text-danger':
                    personalInfoError.errors?.personalEmail &&
                    formState.isSubmitted
                })}
              >
                Personal Email <span>{isSpouse === 'no' && '(Optional)'}</span>
              </Form.Label>
              <Controller
                name="personalInfo.personalEmail"
                control={control}
                rules={{
                  required: isSpouse === 'yes'
                }}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                      trigger('personalInfo.personalEmail')
                    }}
                    type="email"
                    placeholder="Dependent's email address"
                    className={
                      !field.value ? 'text-muted bg-white' : 'bg-white'
                    }
                    isInvalid={
                      personalInfoError.errors?.personalEmail?.type ===
                      'required'
                    }
                  />
                )}
              />
              {personalInfoError.errors?.personalEmail?.type === 'required' && (
                <span className="text-danger">Email is required</span>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            {isSpouse === 'yes' && (
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.personalPhoneNumber &&
                      formState.isSubmitted
                  })}
                >
                  Phone Number
                </Form.Label>
                <Controller
                  name="personalInfo.personalPhoneNumber"
                  rules={{
                    required: true,
                    validate: {
                      noLessThan10: (value) => {
                        if (
                          String(value?.match(/[0-9]+/g)?.join('')).length < 10
                        )
                          return false
                        return true
                      }
                    }
                  }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      as={InputMask}
                      mask="999 999-9999"
                      type="tel"
                      placeholder="Personal Phone Number"
                      isInvalid={
                        personalInfoError.errors?.personalPhoneNumber?.type ===
                        'required'
                      }
                    />
                  )}
                />
                {personalInfoError.errors?.personalPhoneNumber &&
                  formState.isSubmitted &&
                  personalInfoError.errors?.personalPhoneNumber?.type ===
                    'noLessThan10' && (
                    <span className="text-danger">
                      Phone number is invalid.
                    </span>
                  )}
                {personalInfoError.errors?.personalPhoneNumber?.type ===
                  'required' && (
                  <span className="text-danger">Phone Number is required</span>
                )}
              </Form.Group>
            )}
          </Col>
        </Row>
        {isSpouse === 'yes' && (
          <small className="text-primary font-weight-bold">
            * Please note that a unique email is required in order to grant a
            dependent with their own account and log in.{' '}
          </small>
        )}
        <hr />

        <SectionTitle className="my-5">
          Dependent's Contact Information
        </SectionTitle>

        <Form.Check
          onChange={(e) => {
            unregisterAddressFields(e.target.checked)
            setSameAddress(e.target.checked)
          }}
          className="my-4"
          name="sameAddress"
          id="sameAddress"
          label="Address is same as my address"
          checked={sameAddress}
        />

        {!sameAddress && (
          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.streetAddress &&
                      formState.isSubmitted
                  })}
                >
                  Street Address
                </Form.Label>

                <Controller
                  name="personalInfo.streetAddress"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <PlaceAutocomplete
                      ref={field.ref}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      className="form-control bg-white"
                      placeholder="Street Address"
                      defaultValue={field.value}
                      apiKey={googleApiKey}
                      onPlaceSelection={({
                        address,
                        city,
                        zip,
                        stateShort
                      }) => {
                        setValue('personalInfo.streetAddress', address)
                        setValue('personalInfo.state', stateShort)
                        setValue('personalInfo.city', city)
                        setValue('personalInfo.zipCode', zip)
                      }}
                    />
                  )}
                />
                {personalInfoError.errors?.streetAddress?.type ===
                  'required' && (
                  <span className="text-danger">
                    Street Address is required
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.streetAddress2 &&
                      formState.isSubmitted
                  })}
                >
                  Street Address Line 2
                </Form.Label>
                <Controller
                  name="personalInfo.streetAddress2"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      className="bg-white"
                      placeholder="Street Address Line 2 "
                      isInvalid={
                        personalInfoError.errors?.streetAddress2 &&
                        formState.isSubmitted
                      }
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.state && formState.isSubmitted
                  })}
                >
                  State
                </Form.Label>
                <Controller
                  name="personalInfo.state"
                  rules={{ validate: requiredOnlySameAddress }}
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      as="select"
                      placeholder="Select a state"
                      className={
                        !field.value ? 'text-muted bg-white' : 'bg-white'
                      }
                      isInvalid={
                        personalInfoError.errors?.state?.type === 'validate'
                      }
                    >
                      <option value="" disabled hidden>
                        Select a state
                      </option>

                      {Object.keys(states).map((s) => (
                        <option key={s} value={s}>
                          {(states as any)[s]}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                {personalInfoError.errors?.state?.type === 'validate' && (
                  <span className="text-danger">State is required</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.city && formState.isSubmitted
                  })}
                >
                  City
                </Form.Label>
                <Controller
                  name="personalInfo.city"
                  rules={{ validate: requiredOnlySameAddress }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="City"
                      className={
                        !field.value ? 'text-muted bg-white' : 'bg-white'
                      }
                      isInvalid={
                        personalInfoError.errors?.city?.type === 'validate'
                      }
                    />
                  )}
                />
                {personalInfoError.errors?.city?.type === 'validate' && (
                  <span className="text-danger">City is required</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger':
                      personalInfoError.errors?.zipCode && formState.isSubmitted
                  })}
                >
                  Zip Code
                </Form.Label>
                <Controller
                  name="personalInfo.zipCode"
                  rules={{
                    validate: {
                      requiredOnlySameAddress,
                      validateNumber: (value) =>
                        !Number.isNaN(value) && value?.length === 5
                    }
                  }}
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Form.Control
                      {...field}
                      onChange={(e) => limitLength(e, 5, onChange)}
                      type="text"
                      placeholder="Zip Code"
                      className="bg-white"
                      isInvalid={
                        personalInfoError.errors?.zipCode?.type ===
                        'requiredOnlySameAddress'
                      }
                    />
                  )}
                />
                {personalInfoError.errors?.zipCode?.type ===
                  'requiredOnlySameAddress' && (
                  <span className="text-danger">Zip Code is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}

        <hr />

        <SectionTitle>Current Insurance Carrier Information</SectionTitle>
        <SwitchRow
          name="healthInsurance"
          label="Does the dependent have insurance coverage that will coverage continue?"
          className="py-3"
          control={control}
          sideEffect={unregisterCarrierFields}
        />
        {healthInsurance && (
          <>
            <Row>
              <Col xs="12" sm="6">
                <Form.Group>
                  <Form.Label
                    className={classNames({
                      'text-danger':
                        carrierInfoError.errors?.insurer &&
                        formState.isSubmitted
                    })}
                  >
                    Insurance Carrier
                  </Form.Label>
                  <Controller
                    name="carrierInfo.insurer"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          trigger('carrierInfo.coverageContinue')
                        }}
                        placeholder="Insurance Carrier"
                        className={
                          !field.value ? 'text-muted bg-white' : 'bg-white'
                        }
                        isInvalid={
                          carrierInfoError.errors?.insurer?.type === 'required'
                        }
                      />
                    )}
                  />
                  {carrierInfoError.errors?.insurer?.type === 'required' && (
                    <span className="text-danger">
                      Insurance Carrier is required
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-2">
              <Col>
                <Form.Group>
                  <Form.Label>
                    Type of coverage (Check all that apply)
                  </Form.Label>
                  <Controller
                    name="carrierInfo.typesOfCoverage"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => {
                      const { onCheckChanges, onInputBlur } = init(
                        field.value,
                        field.onChange
                      )

                      return (
                        <div>
                          {typeOfCoverageList.map((type, i) => (
                            <Form.Check
                              key={i}
                              {...field}
                              onChange={onCheckCoverage(field)}
                              className="my-2"
                              id={`coverage-${type.value}`}
                              value={type.value}
                              label={type.text}
                            />
                          ))}

                          <Form.Check
                            {...field}
                            className="my-2 d-inline"
                            id={'coverage-custom'}
                            onChange={onCheckChanges}
                            defaultChecked={defaultChecked}
                          />
                          <Form.Control
                            style={{ minWidth: 150, width: '27%' }}
                            size="sm"
                            className="ml-2 d-inline bg-white"
                            disabled={!isCustomChecked}
                            placeholder="Other"
                            ref={inputRef}
                            onBlur={onInputBlur}
                            defaultValue={defaultValue}
                          />
                        </div>
                      )
                    }}
                  />
                  {carrierInfoError.errors?.typesOfCoverage?.type ===
                    'required' && (
                    <span className="text-danger">
                      type of coverage is required
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <span className="text-danger">
              {formState.isSubmitted && carrierInfoError.message}
            </span>
          </>
        )}
        {error && <p className="text-danger">{`Error: ${error}`}</p>}
        <FooterButtons className="mb-5">
          <Button variant="link" onClick={onBack}>
            Cancel
          </Button>
          <div>
            <Button id="save-dependent" onClick={submitForm}>
              Continue
            </Button>
          </div>
        </FooterButtons>
      </Form>
    </div>
  )
}

export default DependentRegistrationForm
