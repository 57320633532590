import { InfoWithIcon } from 'react-angle-dashboard-components'

export const ErrorMessage: React.FC<{ errorText?: string }> = ({
  errorText
}) => {
  return (
    <div>
      <InfoWithIcon
        iconEl={
          <i
            className="icon icon-error text-danger"
            style={{ fontSize: '35px', marginBottom: '0.625rem' }}
          />
        }
      >
        <p className="fs-18 text-muted">
          {errorText || 'An unknown error occurred.'}
        </p>
        <p className="fs-18 text-muted">
          Please contact member services at{' '}
          <a className="font-weight-bold mx-1" href="tel:855-937-1855">
            +1 (855) 937-1855
          </a>{' '}
          for more support.
        </p>
      </InfoWithIcon>
    </div>
  )
}
