import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToasterProvider } from 'react-angle-dashboard-components'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { LangProvider } from './data/languages/language'
import '@angle/theme/theme.scss'
import { AuthContextProvider } from './context/Auth/Auth'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      retryDelay: 1
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LangProvider>
          <ToasterProvider>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </ToasterProvider>
        </LangProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(() => {})
