import React from 'react'
import { Logo } from 'react-angle-dashboard-components'
import { LoginPageContainer, LogoWrapper } from '../../style'

export const CreateAccountContainer: React.FC = ({ children }) => {
  return (
    <LoginPageContainer>
      <LogoWrapper className="py-5">
        <Logo variant="default" color="primary" />
      </LogoWrapper>

      {children}
    </LoginPageContainer>
  )
}
