import React from 'react'
import { Table, TableHeader } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { formService } from '../../services'

// forms currently hardcoded on FE, will pull from BE when BE is ready
const FormRow: React.FC<{ title: string; link: string }> = ({
  title,
  link
}) => {
  return (
    <tr>
      <td>{title}</td>
      <td>
        <a rel="nofollow noreferrer noopener" target="_blank" href={link}>
          View PDF
        </a>
      </td>
    </tr>
  )
}

export const FormsTab: React.FC = () => {
  const { data: formLinks } = useQuery(['getforms'], () =>
    formService.getForms()
  )

  return (
    <section>
      <Table>
        <thead className="thead-light">
          <tr>
            <TableHeader className="py-2" wide>
              Form Name
            </TableHeader>
            <TableHeader className="py-2" />
          </tr>
        </thead>

        <tbody>
          {formLinks?.map((form) => (
            <FormRow title={form.title} link={form.link} />
          ))}
        </tbody>
      </Table>
    </section>
  )
}

export default FormsTab
