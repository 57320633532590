import * as React from 'react'
import { SVGProps } from 'react'

const PhoneSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 17"
    fill="currentColor"
    {...props}
  >
    <g id="Phone">
      <path
        id="Vector"
        d="M2.00847 0.373141L2.92357 0.0807325C3.78107 -0.193265 4.69724 0.249543 5.06444 1.11547L5.79527 2.83872C6.11368 3.58965 5.93697 4.47273 5.35837 5.02193L3.74818 6.55024C3.84754 7.46436 4.15486 8.36451 4.67004 9.25072C5.18523 10.1368 5.82876 10.8719 6.60048 11.456L8.5344 10.8111C9.26744 10.5666 10.0657 10.8475 10.5153 11.5082L11.5629 13.0471C12.0855 13.815 11.9915 14.8744 11.343 15.5255L10.6479 16.2233C9.95611 16.9178 8.9679 17.1698 8.05381 16.8847C5.89583 16.2115 3.91159 14.2129 2.10131 10.8891C0.288373 7.56033 -0.351457 4.73611 0.181808 2.41646C0.4062 1.44038 1.10109 0.663076 2.00847 0.373141Z"
      />
    </g>
  </svg>
)

export default PhoneSolid
