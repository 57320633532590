import { Card } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const StyledBackground = styled.div`
  width: 100%;
  height: 350px;
  @media (max-width: 767px) {
    height: 200px;
  }
`

export const StyledCard = styled(Card)`
  padding: 0 1rem;
  border-radius: 9px;
  margin-top: -130px;
  margin-bottom: 100px;
  @media (max-width: 767px) {
    padding: 0;
    margin-top: 0;
  }
`

export const StyledUl = styled.ul`
  border: none;
  margin: 0;
  padding-left: 1.2rem;
  columns: 2;
  @media (max-width: 767px) {
    columns: 1;
  }
`
