import React from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorPageLayout, Button } from 'react-angle-dashboard-components'
import { HomeLayout } from '../layouts/HomeLayout'
import { useAppStatus, AppStatus } from '../../context/Auth/Auth'

// TODO refactor this page and NotFound because there is repetitive code

const NavButton: React.FC<{ path: string }> = ({ children, path }) => {
  const history = useHistory()
  return <Button onClick={() => history.push('/')}>{children}</Button>
}

export const ClaimsRoutingError = () => {
  const appStatus = useAppStatus()

  let path = '/'

  let errorTitle = '404 - Page Not Found'

  let buttonText = 'View claims'

  let errorText = ''

  if (appStatus === AppStatus.onboarding) {
    errorTitle = 'Onboarding has not been completed'
    buttonText = 'Go to onboarding'
    errorText =
      'Please complete your Angle Health onboarding to access your member information.'
  } else if (appStatus === AppStatus.public) {
    // shouldn't get to this block but adding just in case
    buttonText = 'Log in'
    errorText = 'Please log in to view your claims.'
    path = '/login?page=claims'
  } else if (appStatus === AppStatus.temporary) {
    errorTitle = 'Temporary Password has not been changed'
    buttonText = 'Change Password'
    errorText =
      'Please change your temporary password to access your member information.'
  }

  return (
    <HomeLayout noSearchBar noMenu noBreadcrumbs>
      <div style={{ marginTop: -75 }}>
        <ErrorPageLayout
          errorTitle={errorTitle}
          errorSubtitle={errorText}
          button={<NavButton path={path}>{buttonText}</NavButton>}
        />
      </div>
    </HomeLayout>
  )
}

export default ClaimsRoutingError
