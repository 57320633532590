import * as React from 'react'
import { SVGProps } from 'react'

const QuestionMarkSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    fill="currentColor"
    {...props}
  >
    <g id="QuestionMark">
      <g id="Icons/iOS-Glyph/Question mark">
        <path
          id="Vector"
          d="M8.5 1.7C4.7447 1.7 1.7 4.7447 1.7 8.5C1.7 12.2553 4.7447 15.3 8.5 15.3C12.2553 15.3 15.3 12.2553 15.3 8.5C15.3 4.7447 12.2553 1.7 8.5 1.7ZM8.5 12.75C8.0308 12.75 7.65 12.3692 7.65 11.9C7.65 11.4308 8.0308 11.05 8.5 11.05C8.9692 11.05 9.35 11.4308 9.35 11.9C9.35 12.3692 8.9692 12.75 8.5 12.75ZM9.67187 8.78333C9.29673 9.12957 9.06667 9.3568 9.06667 9.63333H7.93333C7.93333 8.84623 8.4711 8.34983 8.9029 7.95033C9.35567 7.53213 9.63333 7.25277 9.63333 6.8C9.63333 6.17497 9.12503 5.66667 8.5 5.66667C7.87497 5.66667 7.36667 6.17497 7.36667 6.8H6.23333C6.23333 5.54993 7.24993 4.53333 8.5 4.53333C9.75007 4.53333 10.7667 5.54993 10.7667 6.8C10.7667 7.77183 10.1598 8.33227 9.67187 8.78333Z"
        />
      </g>
    </g>
  </svg>
)

export default QuestionMarkSolid
