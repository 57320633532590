import React, { Dispatch, SetStateAction } from 'react'
import { Button, Modal } from 'react-angle-dashboard-components'

export const NoEmailModal: React.FC<{
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
  onContinue: Function
}> = ({ show, setShow, onContinue }) => {
  return (
    <Modal show={show} centered>
      <Modal.Body className="text-center" style={{ padding: 50 }}>
        <h5 className="mb-4">
          Are you sure you want to proceed without providing an email address?
        </h5>
        <p>
          We will not be able to send you updates about your plan to your email.
        </p>
        <div className="d-flex flex-column">
          <Button className="d-block mb-3" onClick={() => onContinue()}>
            Continue without email address
          </Button>
          <Button
            className="d-block"
            variant="outline-primary"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
