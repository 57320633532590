import React from 'react'
import {
  Button,
  Form,
  DatePicker,
  InputGroup,
  useToaster
} from 'react-angle-dashboard-components'
import { useHistory, useParams } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'
import { LoginPageCard } from '../../LoginPageCard'
import { GoBackButton, OutlineButton } from '../../style'
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { useMemberData } from '../context/CreateAccountContext'
import { unformatPhoneNumber, validateEmail } from '@angle/utils'
import { VerificationType } from '../types'
import { useMutation } from 'react-query'
import { ticketService } from '../../../../services'
import { MemberNotFoundTicketDTO } from '../../../../services/api/tickets'

interface NoResultForm {
  email: string
  phone?: string
  date_of_birth: string
  member_id?: string
  ssn?: string
  first_name?: string
  last_name?: string
}

export const CreateAccountNoResult = () => {
  const { showError } = useToaster()
  const history = useHistory()
  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const { memberData } = useMemberData()

  const defaultValues: Partial<NoResultForm> = {
    date_of_birth: memberData?.date_of_birth || ''
  }

  defaultValues.member_id =
    verificationType === 'angle' ? memberData?.member_id || '' : ''

  defaultValues.first_name = memberData?.first_name || ''
  defaultValues.last_name = memberData?.last_name || ''

  const { handleSubmit, formState, register, control } = useForm({
    mode: 'onChange',
    defaultValues
  })

  const submitTicket = useMutation((data: MemberNotFoundTicketDTO) =>
    ticketService.sendMemberNotFoundTicket(data)
  )

  const onSubmit = handleSubmit(async (data: NoResultForm) => {
    try {
      const {
        email,
        phone,
        member_id,
        date_of_birth,
        first_name,
        last_name,
        ssn
      } = data
      const DTO: MemberNotFoundTicketDTO = {
        contact: { email },
        member: { date_of_birth, first_name, last_name }
      }

      if (phone) {
        DTO.contact.phone = unformatPhoneNumber(phone)
      }

      if (member_id) {
        DTO.member.angle_id = 'ANG' + member_id
      }
      if (ssn) {
        DTO.member.ssn = unformatPhoneNumber(ssn)
      }

      await submitTicket.mutateAsync(DTO)
      history.push(`${Paths.createAccount}/${verificationType}/not-found/sent`)
    } catch (error: any) {
      showError(
        error?.message || 'An unknown error occurred. Please reach out directly'
      )
    }
  })

  return (
    <LoginPageCard
      headerText="We couldn't find your information"
      subheaderText=" If you think you have active coverage and should be in our system, please fill out this form and we will get back to you."
      showErrorIcon
    >
      <div>
        {' '}
        {verificationType === 'angle' ? (
          <Form.Group>
            <Form.Label>Angle Health Member ID</Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0
                }}
              >
                ANG
              </InputGroup.Text>
              <Form.Control
                className="bg-white"
                placeholder="Member ID"
                isInvalid={
                  !!formState?.errors?.member_id && formState?.isSubmitted
                }
                {...register('member_id', {
                  required: true,
                  validate: {
                    length: (val: string | undefined) => (val?.length === 7 || val?.length === 9)
                  }
                })}
              />
            </InputGroup>
            {!!formState?.errors?.member_id && formState?.isSubmitted && (
              <span className="mt-3 text-danger d-inline-block">
                Please enter the member ID number.
              </span>
            )}
          </Form.Group>
        ) : (
          <>
            <Form.Group>
              <Form.Label>Social Security Number</Form.Label>
              <Form.Control
                as={InputMask}
                className="bg-white"
                mask="999-99-9999"
                placeholder="Social Security Number"
                isInvalid={!!formState?.errors?.ssn && formState?.isSubmitted}
                {...register('ssn', {
                  required: true
                })}
              />
            </Form.Group>
          </>
        )}
        <div className="d-flex justify-content-between">
          <Form.Group style={{ width: '48%' }}>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              className="bg-white"
              placeholder="First Name"
              isInvalid={
                !!formState?.errors?.first_name && formState?.isSubmitted
              }
              {...register('first_name', {
                required: true
              })}
            />
          </Form.Group>
          <Form.Group style={{ width: '48%' }}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              className="bg-white"
              placeholder="Last Name"
              isInvalid={
                !!formState?.errors?.last_name && formState?.isSubmitted
              }
              {...register('last_name', {
                required: true
              })}
            />
          </Form.Group>
        </div>
        <Form.Group>
          <Form.Label>Date of Birth</Form.Label>
          <Controller
            name="date_of_birth"
            rules={{
              required: true
            }}
            control={control}
            render={({ field }) => (
              <DatePicker {...field} backgroundColor="white" />
            )}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            className="bg-white"
            placeholder="Email Address"
            type="email"
            {...register('email', {
              required: true,
              validate: (val: string | undefined) =>
                validateEmail(val as string)
            })}
          />
          {formState?.errors?.email && formState?.isSubmitted && (
            <span className="fs-14 text-danger d-inline-block mt-2">
              Please enter a valid email address.
            </span>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Phone Number (optional)</Form.Label>
          <Form.Control
            as={InputMask}
            mask="999-999-9999"
            className="bg-white"
            type="tel"
            {...register('phone')}
            {...{ maskChar: null }}
            placeholder="Phone number"
          />
        </Form.Group>
      </div>
      <div className="text-center pt-3">
        <Button block onClick={() => onSubmit()}>
          Submit
        </Button>
        <OutlineButton
          className="mt-3"
          variant="outline-primary"
          block
          onClick={() => history.push(Paths.createAccount)}
        >
          Try Again
        </OutlineButton>
        <GoBackButton
          variant="link"
          className="mt-4"
          block
          onClick={() => history.goBack()}
        >
          Go Back
        </GoBackButton>
      </div>
    </LoginPageCard>
  )
}
