import { Col, Form, Row } from 'react-angle-dashboard-components'
import { FormCheckProps } from 'react-bootstrap'

export const CheckBoxControl: React.FC<
  { field: any; value: string } & FormCheckProps
> = ({ field, value, children, ...others }) => (
  <Col>
    <Form.Group
      className={'rounded m-0 p-0'}
      style={
        field.value === value
          ? {
              background: '#EEEAF7',
              border: '0.0625rem solid var(--primary)'
            }
          : {
              border: '0.0625rem solid #e7eaf3'
            }
      }
    >
      <Form.Label className={'pl-3 pb-3 pt-4 mb-0 w-100'}>
        <Row>
          <Col xs="10">
            <h6 className="mb-0">{children}</h6>
          </Col>
          <Col className="text-center px-0 d-flex justify-content-center align-items-center">
            <Form.Check {...field} {...others} type="radio" value={value} />
          </Col>
        </Row>
      </Form.Label>
    </Form.Group>
  </Col>
)
