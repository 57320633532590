import { ApiService } from 'react-angle-dashboard-components'

interface IProviderTransparencyResponse {
  dist: number
  cost_before_benefits: number
  first_name: string
  last_name: string
  facility_name: string
  provider_type: string
  npi: string
  street_1: string
  street_2: string
  city: string
  state: string
  zip: string
  lat: number
  lon: number
  procedure: {
    code: string
    code_type: string
    benefit_category: string
    short_description: string
    long_description: string
    prior_auth: boolean
  }
}

export interface IProviderTransparency extends IProviderTransparencyResponse {
  full_name: string
  is_provider: boolean
  address: string
  truncDist: number
}

interface IProviderParams {
  sort_by?: 'distance' | 'price'
  sort_direction?: 'asc' | 'desc'
  cpt?: number | string
  pos?: string
  'price[gte]'?: number
  'price[lte]'?: number
  provider?: string
  lat: number
  lon: number
}

const googleApiKey = 'AIzaSyDPBwUxz2P0_FGmy_1xA3319NVtH7zoJqc'

const mapResponse = (
  p: IProviderTransparencyResponse
): IProviderTransparency => ({
  ...p,
  full_name: `${p.first_name} ${p.last_name}`,
  address: `${p.street_1} ${p.street_2} ${p.city} ${p.state}, ${p.zip}`,
  is_provider: p.provider_type === 'provider',
  truncDist: Math.trunc(p.dist * 10) / 10
})

export class PriceTransparencyService extends ApiService {
  async getCoordinates(zip: string): Promise<any> {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zip}|country:US&key=${googleApiKey}`
      )
      return response.json()
    } catch (error) {
      console.error(error)
    }
  }

  async getInNetworkProviders(
    params: IProviderParams
  ): Promise<IProviderTransparency[]> {
    return this.request({
      method: 'get',
      url: '/price-transparency/providers/inn',
      credentials: true,
      params
    }).then((res: { data: IProviderTransparencyResponse[] }) =>
      res.data.map(mapResponse)
    )
  }

  async getOutNetworkProviders(
    params: Partial<IProviderParams>
  ): Promise<IProviderTransparency[]> {
    return this.request({
      method: 'get',
      url: '/price-transparency/providers/oon',
      credentials: true,
      params
    }).then((res: { data: IProviderTransparencyResponse[] }) =>
      res.data.map(mapResponse)
    )
  }
}
