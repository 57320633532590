import React from 'react'
import { Form, InputGroup } from 'react-angle-dashboard-components'
import { InlineSpace, InputCostInputHeader, InputCostLabel } from './style'

interface IInputCostSingle {
  label: string
  copay: number
  setCopay: (val: number) => void
  coinsurance: number
  setCoinsurance: (val: number) => void
}

const setValue = (set: any) => (e: any) => {
  const val = Number(e.target.value)
  if (!isNaN(val)) {
    set(val)
  }
}

const InputCost = ({
  label,
  copay,
  setCopay,
  coinsurance,
  setCoinsurance
}: IInputCostSingle) => {
  return (
    <div>
      <InputCostLabel>{label}</InputCostLabel>
      <InlineSpace>
        <div>
          <InputCostInputHeader>Copay Amount</InputCostInputHeader>
          <InputGroup>
            <Form.Control value={copay} onChange={setValue(setCopay)} />
            <InputGroup.Text>$</InputGroup.Text>
          </InputGroup>
        </div>
        <div>
          <InputCostInputHeader>Coinsurance</InputCostInputHeader>
          <InputGroup>
            <Form.Control
              value={coinsurance}
              onChange={setValue(setCoinsurance)}
            />
            <InputGroup.Text>%</InputGroup.Text>
          </InputGroup>
        </div>
      </InlineSpace>
    </div>
  )
}

export default InputCost
