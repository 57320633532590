import React, { ReactNode, createContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { messages as english } from './messages/english'
import { languageCode as codes } from './language-codes'

export type ILangMessages = typeof english

export interface ILangContext {
  language: string
  messages: ILangMessages
  setLanguage: (language?: string | null) => void
}

export interface ILangProvider {
  children: ReactNode
}

export const available = [
  Object.freeze({
    value: 'en',
    codes: ['en', 'en-us'],
    messages: english
  })
]

export const LangContext = createContext({} as ILangContext)

export const LangProvider: React.FC<ILangProvider> = ({ children }) => {
  const userLang = (language: string) =>
    available.filter((l) => l.codes.includes(language))?.[0]?.messages

  const { search } = useLocation()
  const query = queryString.parse(search)?.locale as string
  const defaultLanguage = query || 'en'
  const [data, setData] = useState({
    messages: userLang(defaultLanguage),
    language: defaultLanguage
  })

  const handleLanguage = () => {
    const language = codes.get()
    const messages = userLang(language) || available[0].messages

    setData({
      messages,
      language
    })
  }

  const setLanguage = (language?: string | null) => {
    codes.set(language, query)

    return handleLanguage()
  }

  return (
    <LangContext.Provider value={{ ...data, setLanguage }}>
      {children}
    </LangContext.Provider>
  )
}
