import React, { useMemo } from 'react'
import {
  Button,
  Card,
  Col,
  Row,
  useToaster
} from 'react-angle-dashboard-components'
import { AgChartsReact, AgChartProps } from 'ag-charts-react'
import { currencyFormatter } from '@angle/utils'
import { useParams, Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import { claimsStatusEnums, claimsTypeEnums } from '../../services/api/claims/claims'
import { documentService } from '../../services'
import { DownloadArrowIcon } from './DownloadArrowIcon'
import { IUnifiedClaimDetail } from '../../services/api/claims/mappers'
import { Paths } from '../../routes/paths'

import { FloatingCard } from './style'

enum DataColor {
  inNetworkSavings = '#7DD491',
  paidByAngle = '#EDCA60',
  patientResponsibility = '#17A2B8',
  processing = '#F8F9FA',
}

const dollarsToNumber = (str: string | undefined) =>
  Number(str?.replace(/[$,]+/g, ''))

const ColorDot = ({ color }: { color: DataColor }) => (
  <Col className="col-auto d-flex align-items-center mr-1 px-0">
    <div
      style={{
        width: 8,
        height: 8,
        backgroundColor: color,
        borderRadius: '100%'
      }}
    />
  </Col>
)

const TotalRow = ({
  label,
  value,
  color,
  isLoading
}: {
  label: string | undefined
  value: string | undefined
  color?: DataColor
  isLoading?: boolean
}) => (
  <Row className="mx-0">
    <Col className="pl-0 font-weight-bold">
      <Row noGutters>
        {color && <ColorDot color={color} />}
        {label}
      </Row>
    </Col>
    <Col className="pl-0 text-right font-weight-bold col-auto">
      {isLoading ? <Skeleton width={80} /> : value}
    </Col>
  </Row>
)

const SubTotalRow = ({
  label,
  value,
  color,
  isLoading
}: {
  label: string | undefined
  value: string | undefined
  color?: DataColor
  isLoading?: boolean
}) => (
  <Row className="mx-0 ml-3">
    <Col className="pl-0">
      <Row className="pl-3 text-muted">
        {color && <ColorDot color={color} />}
        {label}
      </Row>
    </Col>
    <Col className="pl-0 text-right text-muted col-auto">
      {isLoading ? <Skeleton width={80} /> : value}
    </Col>
  </Row>
)

const typeToLabelsMap = {
  processing: 'Total Amount Billed',
  inNetworkSavings: 'In-Network Savings',
  paidByAngle: 'Paid By Angle',
  patientResponsibility: 'Patient Responsibility'
}

const typeToColorMap: {
  [key: string]: string;
} = {
  processing: DataColor.processing,
  inNetworkSavings: DataColor.inNetworkSavings,
  paidByAngle: DataColor.paidByAngle,
  patientResponsibility: DataColor.patientResponsibility
}

export const ClaimSummary: React.FC<{
  data: IUnifiedClaimDetail | undefined
  isLoading: boolean
}> = ({ data, isLoading }) => {
  const { showError } = useToaster()

  const { id } = useParams<{ id: string }>()

  const onDownload = async () => {
    try {
      await documentService.downloadDocument(
        `/claims/${id}/mails/eob/download`,
        {
          contentType: 'application/pdf',
          filename: 'eob-claim.pdf'
        }
      )
    } catch (e) {
      showError('Unable to download the document, try again later')
    }
  }

  const chartOptions = useMemo<AgChartProps['options']>(
    () => ({
      // Data: Data to be displayed in the chart
      data: data?.status === claimsStatusEnums.processing ? [
        {
          label: typeToLabelsMap.processing,
          amount: dollarsToNumber(data?.billedAmount),
          type: 'processing'
        }
      ] : 
      // the medical chart
      data?.type === claimsTypeEnums.medical ? [
        {
          label: typeToLabelsMap.inNetworkSavings,
          amount: dollarsToNumber(data?.savedAmount),
          type: 'inNetworkSavings'
        },
        {
          label: typeToLabelsMap.paidByAngle,
          amount: dollarsToNumber(data?.paidAmount),
          type: 'paidByAngle'
        },
        {
          label: typeToLabelsMap.patientResponsibility,
          amount: dollarsToNumber(data?.patientResponsibility),
          type: 'patientResponsibility'
        }
      ] : 
      // the pharmacy chart
      [
        {
          label: typeToLabelsMap.paidByAngle,
          amount: dollarsToNumber(data?.paidAmount),
          type: 'paidByAngle'
        },
        {
          label: typeToLabelsMap.patientResponsibility,
          amount: dollarsToNumber(data?.patientResponsibility),
          type: 'patientResponsibility'
        }
      ],
      // Series: Defines which chart type and data to use
      series: [
        {
          type: 'pie',
          angleKey: 'amount',
          fills: data?.status === claimsStatusEnums.processing ? [
            DataColor.processing
          ] :
          // the medical chart
          data?.type === claimsTypeEnums.medical ? [
            DataColor.inNetworkSavings,
            DataColor.paidByAngle,
            DataColor.patientResponsibility
          ] :
          // the pharmacy chart
          [
            DataColor.paidByAngle,
            DataColor.patientResponsibility],
          innerRadiusRatio: 0.6,
          innerLabels: [
            {
              text: data?.billedAmount ?? '',
              fontWeight: 'bold',
              fontSize: 20
            },
            {
              text: 'Total Amount Billed',
              fontSize: 10,
              color: '#6c757d'
            }
          ],
          tooltip: {
            renderer: (params) => {
              const label = params.datum.label
              const value = `$${params.datum.amount}`
              return {
                content: `<span style="color: ${typeToColorMap[params.datum.type]}">${label}</span> ${value}`
              }
            }
          }
        }
      ],
      width: 300
    }),
    [
      data?.billedAmount,
      data?.paidAmount,
      data?.patientResponsibility,
      data?.savedAmount,
      data?.status,
      data?.type
    ]
  )

  const allowedAmount = currencyFormatter.format(
    dollarsToNumber(data?.billedAmount) - dollarsToNumber(data?.savedAmount)
  )

  return (
    <Col
      md={4}
      className="d-sm-flex justify-content-sm-end"
      style={{ position: 'relative' }}
    >
      <FloatingCard>
        <Card
          style={{
            marginBottom: '1.25rem',
            width: '100%'
          }}
        >
          <Card.Body className="px-3" style={{ minWidth: 320 }}>
            <Row className="mx-0 w-100 mb-3">
              <Col className="pl-0 d-flex flex-column justify-content-center">
                <strong>Claim Breakdown</strong>
              </Col>
              <Col className="text-right p-0">
                {!isLoading && data?.hasEOB && (
                  <Button
                    className="d-flex justify-content-center align-items-center p-1 w-100 text-nowrap"
                    disabled={data?.status === claimsStatusEnums.processing}
                    onClick={() => onDownload()}
                    variant="outline-primary"
                  >
                    <DownloadArrowIcon className="mr-2" />
                    Download EOB
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="justify-content-center mb-3">
              {isLoading ? (
                <Skeleton height={300} width={300} circle />
              ) : (
                <AgChartsReact options={chartOptions} />
              )}
            </Row>
            <TotalRow
              label="Total Amount Billed"
              value={data?.billedAmount}
              isLoading={isLoading}
            />

            { data?.type === claimsTypeEnums.medical && (<SubTotalRow
              label="In-Network Savings"
              value={ data?.status === claimsStatusEnums.processing ? 'TBD' : `-${data?.savedAmount}`}
              color={DataColor.inNetworkSavings}
              isLoading={isLoading}
            />)}
            <hr />
            <TotalRow
              label="Allowed Amount"
              value={data?.status === claimsStatusEnums.processing ? 'TBD' : data?.allowedAmount || allowedAmount}
              isLoading={isLoading}
            />
            {data?.paidAmount !== '$0.00' && data?.status !== claimsStatusEnums.processing && (<SubTotalRow
              label="Paid By Angle"
              value={`-${data?.paidAmount}`}
              color={DataColor.paidByAngle}
              isLoading={isLoading}
            />)}
            <hr />
            <Card className="bg-light border-0 px-2 py-1">
              <TotalRow
                label="Patient Responsibility"
                value={ data?.status === claimsStatusEnums.processing ? 'TBD' : data?.patientResponsibility}
                color={DataColor.patientResponsibility}
                isLoading={isLoading}
              />
              { data?.type === claimsTypeEnums.medical && ( <><SubTotalRow
                label="Non Covered"
                value={ data?.status === claimsStatusEnums.processing ? 'TBD' : data?.notCoveredAmount}
                isLoading={isLoading}
              />
              <SubTotalRow
                label="Applied to Deductible"
                value={ data?.status === claimsStatusEnums.processing ? 'TBD' : data?.deductibleAmount}
                isLoading={isLoading}
              />
              <SubTotalRow
                label="Co-Pay/Co-Insurance"
                value={ data?.status === claimsStatusEnums.processing ? 'TBD' : data?.copayAmount}
                isLoading={isLoading}
              /></>)}
            </Card>
          </Card.Body>
        </Card>
        {data?.status !== claimsStatusEnums.processing && (
          <Card>
            <Card.Body>
              <Row>
                <Col className="d-flex flex-row">
                  <div>
                    <i
                      className="icon-question-mark text-primary"
                      style={{
                        height: '3rem',
                        width: '3rem',
                        fontSize: 28
                      }}
                    />
                  </div>
                  {data?.status === claimsStatusEnums.approved && (<div className="mx-2">
                    <strong>Want to go paperless? </strong>
                    <p className="text-muted">
                      <Link
                        to={Paths.settings + '/communication'}
                        style={{ textDecoration: 'underline' }}
                      >
                        Click here
                      </Link>{' '}
                      to manage your communications preferences.
                    </p>
                  </div>)}
                  {(data?.status === claimsStatusEnums.denied || data?.status === claimsStatusEnums.denied_partial) && (<div className="mx-2">
                    <strong>Was Your Claim Denied?</strong>
                    <p className="text-muted">
                      If your claim was denied and you believe additional information will affect the processing of the claim please
                      {' '}<a
                        href={'mailto:careteam@anglehealth.com'}
                        style={{ textDecoration: 'underline' }}
                      >
                        email us
                      </a>{' '}
                      or chat with us via the mobile app
                    </p>
                  </div>)}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </FloatingCard>
    </Col>
  )
}