import React, { Dispatch, SetStateAction } from 'react'
import { CloseButton } from 'react-angle-dashboard-components'
import styled from 'styled-components'
import AppStore from '../../assets/app-store.svg'
import GooglePlay from '../../assets/google-play-badge.svg'

const Wrapper = styled.div`
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  height: 132px;
  width: 268px;
  padding: 14px;
  border-radius: 6px;
  z-index: 999999;
  background-color: #fff;
`
export const MobileDownload: React.FC<{
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}> = ({ setShow }) => {
  const hide = () => {
    sessionStorage.setItem('showMobileDownload', 'false')
    setShow(false)
  }

  return (
    <Wrapper className={`shadow`}>
      <div className="d-flex justify-content-between align-items-start">
        {' '}
        <strong className="m-0 text-center d-block pt-3">
          Download Your Member Mobile App Today
        </strong>
        <CloseButton className="fs-16" onClick={() => hide()} />
      </div>

      <div className="d-flex justify-content-between">
        <a
          href="https://play.google.com/store/apps/details?id=com.anglehealth.inc"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={GooglePlay}
            alt="Download member app on google play"
            height="60"
            width="120"
          />
        </a>
        <a
          href="https://apps.apple.com/us/app/angle-health-inc/id1572887423?ign-mpt=uo%3D2"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{ padding: '9%', boxSizing: 'border-box' }}
            src={AppStore}
            alt="Download member app on the app store"
            height="60"
            width="120"
          />
        </a>
      </div>
    </Wrapper>
  )
}

export default MobileDownload
