import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { tempAuthService } from '../services'

export const useTempAuth = (
  code: string | null,
  service?: { token: string }
) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<any>(null)
  const [memberFullName, setMemberFullName] = useState('')

  useEffect(() => {
    if (!code) setError({ message: 'Invalid code' })
    else {
      tempAuthService
        .tempAuthNoPW(code)
        .then(({ first_name, access_token, last_name }) => {
          if (service) service.token = access_token
          setMemberFullName(first_name + ' ' + last_name)
          setLoading(false)
        })
        .catch((err) => {
          setError(err)
          setLoading(false)
        })
    }
  }, [code, history, service])

  return {
    isLoading: loading,
    error,
    memberFullName
  }
}
