import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useHistory } from 'react-router-dom'

import {
  Avatar,
  Button,
  Card,
  Col,
  ErrorPageLayout,
  PageContainer,
  Row,
  Spinner,
  useErrorHandler,
  withErrorBoundary
} from 'react-angle-dashboard-components'
import { DateHandler } from '@angle/utils'
import { acaService, claimsService, coverageService, memberService } from '../../services'
import { getCigna } from '../../utils/cigna'
import { getAlert } from './utils/getAlert'
import { HomeLayout } from '../layouts/HomeLayout'
import { identifyIterate } from './utils/identifyIterate'
import { Paths } from '../../routes/paths'
import { RenewalsCTA } from './RenewalsCTA'
import AccessPlusInfoCard from '../../components/AccessPlusInfoCard/AccessPlusInfoCard'
import MainContentContainer from './MainContentContainer'

import styled from 'styled-components'
import ClaimsWidget from './ClaimsWidget'
import { getDirectoryUrlByIdCardType } from '../../utils/directoryUrls'

export const SmallHeader = styled.h6`
  font-weight: 700;
  margin-bottom: 21px;
`

export const Home = () => {
  const [width, setWidth] = useState<number>(window.innerWidth)
  
  const history = useHistory()

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, [])

  const isMobile = width <= 768;

  const { data, error, isLoading: memberIsLoading } = useQuery('getMember', async () => {
    return memberService.getMemberInfo()
  })

  const {
    data: planData,
    isLoading: isLoadingPlan,
    // error: getPlanError
  } = useQuery('getMedicalPlanV2', () =>
    coverageService.getMedicalPlanV2()
  )

  const {
    data: claimsDTO,
    isLoading: claimsIsLoading
  } = useQuery('getUnifiedClaims', async () =>{
    return claimsService.getUnifiedClaims()
  } )

  const { data: memberAcaData, isLoading: memberAcaDataIsLoading } = useQuery(
    'getMemberAca',
    async () => {
      return acaService.getMemberACAInfo(data?.member_id)
    },
    {
      enabled: !!data?.member_id
    }
  )

  const isRenewal = data?.in_renewal
  const isCompleted = data?.onboarding_status === 'completed'

  const isLargeGroup = data?.group_size === 'large'

  identifyIterate(data)

  useErrorHandler(error)

  const { hidePlanInfo } = getCigna(data)

  const formularyIndicator = data?.formulary_indicator

  
  const directoryUrl = getDirectoryUrlByIdCardType(data?.id_card_type)

  const homeMenu = [
    {
      text: 'Virtual Care',
      icon: 'icon-virtual-care',
      color: '#F6F2EF',
      link: Paths.virtualCare
    },
    {
      text: 'Find Care',
      icon: 'icon-find-care',
      color: '#F6F2EF',
      link: directoryUrl
    },
    {
      text: 'Pharmacy',
      icon: 'icon-pharmacy',
      color: '#EDCB8C',
      link: Paths.drugs
    }
  ]

  if (!hidePlanInfo) {
    homeMenu.push({
      text: 'Plan',
      icon: 'icon-home-plan',
      color: '#A2CFDE',
      link: Paths.myPlan
    })
  }

  const firstName = data?.name?.first_name || ''
  const coverageFromOldLogic: any = data?.coverages?.length
    ? data.coverages[0]
    : null
  const currentCoverage = data?.current_medical_coverage || null
  const futureCoverage = data?.future_medical_coverages[0] || null
  const pastCoverage =
    data?.past_medical_coverages[data?.past_medical_coverages?.length - 1] ||
    null

  const displayCoverage = currentCoverage || futureCoverage || pastCoverage

  const acaData = memberAcaData?.acaData?.[0]

  const acaUpcomingBannerMessage = (
    <>
      <span className="mr-3">
        You are going to be transitioning off of your Angle Health plan{' '}
        on { DateHandler.formatForClient(pastCoverage?.term_date) } to a {acaData?.companyName}{' '}
        plan that starts on { DateHandler.formatForClient(acaData?.startDate) }
      </span>
      <Button
        variant="white"
        className="bg-white py-1 text-primary"
        onClick={() => history.push(Paths.accessPlusPlanDetails)}
      >
        New Plan Details
      </Button>
    </>
  )

  const acaActiveBannerMessage = (
    <>
      <span className="mr-3">
        You transitioned to your new plan with {acaData?.companyName} on{' '}
        {DateHandler.formatForClient(acaData?.startDate)}
      </span>
    </>
  )

  const angleRxBannerMessage = (
    <>
      <span className="mr-3">
        Your benefit plan was recently updated to a new pharmacy benefit manager
        (PBM) AngleRx
      </span>
      <Button
        variant="white"
        className="bg-white py-1 text-primary"
        onClick={() =>
          window.open(
            'https://angle-frontend-resources.s3.us-east-2.amazonaws.com/angle_rx_learn_more_2024.pdf',
            '_blank'
          )
        }
      >
        Learn More
      </Button>
    </>
  )

  const alert = getAlert(data, {
    acaUpcomingMessage: acaUpcomingBannerMessage,
    acaActiveMessage: acaActiveBannerMessage,
    angleRxMessage: angleRxBannerMessage
  })

  const cardType = data?.id_card_type

  const accessPlusIsActive = data?.aca_status === 'active' // on aca plan

  let isLoading = memberIsLoading || memberAcaDataIsLoading || isLoadingPlan || claimsIsLoading
  if (isLoading) {
    return <Spinner animation="border" />
  }

  if (accessPlusIsActive && memberAcaData) {
    return (
      <HomeLayout noSearchBar noBreadcrumbs breadcrumbs={[]} showAlert={alert}>
        <section className="bg-primary" style={{ paddingTop: '70px' }}>
          <PageContainer size="xl">
            <Row className="pb-7">
              <Col xs="12" sm="12" md="12" lg="8">
                <div className="mb-5">
                  <h1
                    className="text-white"
                    style={{ visibility: firstName ? 'visible' : 'hidden' }}
                  >{`Welcome back, ${firstName}!`}</h1>
                </div>
              </Col>
            </Row>
          </PageContainer>
        </section>
        <PageContainer size="xl" className="py-5">
          <AccessPlusInfoCard memberAcaDataProp={memberAcaData} hideHomeButton />
        </PageContainer>
      </HomeLayout>
    )
  }

  return (
    <HomeLayout noSearchBar noBreadcrumbs breadcrumbs={[]} showAlert={alert}>
      <section className="bg-primary" style={{ paddingTop: '70px' }}>
        <PageContainer size="xl">
          <Row className="pb-7">
            <Col xs="12" sm="12" md="12" lg="8">
              <div className="mb-5">
                <h1
                  className="text-white"
                  style={{ visibility: firstName ? 'visible' : 'hidden' }}
                >{`Welcome back, ${firstName}!`}</h1>
                {!hidePlanInfo && displayCoverage !== null && (
                  <Link to="/my-plan/member-id-card">
                    <span
                      className="text-white"
                      style={{ borderBottom: '1px solid white' }}
                    >
                      View my ID card
                    </span>
                  </Link>
                )}
              </div>

              <div className="pt-2" style={{ maxWidth: '700px' }}>
                <Row data-testid="menu-section">
                  {homeMenu.map((i) => (
                    <Col xs="12" sm="3" className="mb-3" key={i.text}>
                      {
                        <a href={i.link}>
                          {' '}
                          <div
                            className="bg-white text-center rounded"
                            style={{ height: '125px' }}
                          >
                            <div className="p-3">
                              <Avatar
                                size="lg"
                                centered
                                fallbackContent={
                                  <i
                                    key={i.text}
                                    className={`fs-28 icon ${i.icon} text-white `}
                                  />
                                }
                                customBg={i.color}
                                circle
                              />
                            </div>
                            <h5>{i.text}</h5>
                          </div>
                        </a>
                      }
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </PageContainer>
      </section>
      <PageContainer size="xl">
        <Row className="mt-5" style={{ paddingBottom: 80 }}>
          <MainContentContainer
            updatesProps = {{
              isRenewal: isRenewal,
              isLoading: isLoading,
              cardType: cardType,
              formularyIndicator: formularyIndicator
            }}
            planWidgetProps={{
              hidePlanInfo: hidePlanInfo || !planData,
              isMobile: isMobile,
              memberId: data?.member_id || '',
              memberSinceDate: coverageFromOldLogic?.effective_date || '',
              metalTier: displayCoverage.plan?.metal_tier || '',
              planName: displayCoverage.plan?.plan_name || ''
            }}
            planData={planData}
          />
          <Col>
            <div>
              {isRenewal && isCompleted === false && (
                <RenewalsCTA className="mb-3" />
              )}
              {claimsDTO && (<ClaimsWidget
                claims={claimsDTO.claims} />)}
              {isLargeGroup && (
                <Card className="mt-3">
                  <Card.Body className="pb-2">
                    <SmallHeader>Paperless Updates</SmallHeader>
                    <p className="text-muted">
                      Want to go paperless?{' '}
                      <Link
                        to={Paths.settings + '/communication'}
                        style={{ textDecoration: 'underline' }}
                      >
                        Click here
                      </Link>{' '}
                      to update
                    </p>
                  </Card.Body>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </PageContainer>
    </HomeLayout>
  )
}

export default withErrorBoundary(Home, {
  FallbackComponent: () => (
    <HomeLayout noSearchBar noBreadcrumbs>
      <ErrorPageLayout
        errorTitle="Something went wrong"
        errorSubtitle="If the issue continues, please reach out to your care team by phone (855 937 1855) or sessions on your mobile app."
        button={
          <Button onClick={() => window.location.reload()}>
            Reload the page
          </Button>
        }
      />
    </HomeLayout>
  )
})
