import React from 'react'
import { Container } from 'react-angle-dashboard-components'

export const ErrorPage = () => {
  return (
    <Container style={{ marginTop: 30 }}>
      <div className="text-center">
        <h4>Invalid Link</h4>
        <p className="fs-16">
          Please contact member services to obtain a new link.
        </p>
      </div>
    </Container>
  )
}
