import {
  IProviderForm,
  IDependentForm,
  IDependentProviderForm
} from './interfaces'

export const getEmptyDependent = (): IDependentForm => ({
  id: String(Date.now()),
  firstName: '',
  lastName: '',
  age: '',
  relation: '',
  email: ''
})

export const getEmptyProvider = (): IProviderForm => ({
  id: String(Date.now()),
  firstName: '',
  lastName: '',
  phone: '',
  specialty: ''
})

const fieldsMap: any = {
  firstName: 'First Name',
  lastName: 'Last Name',
  age: 'Age',
  relation: 'Relation'
}

export const validateFields = (
  data: IDependentProviderForm,
  checkDependents: boolean
) => {
  data.providers = data.providers
    .map((row) => {
      delete (row as any)['id']
      const isEmpty = Object.keys(row).every((e) => !Boolean((row as any)[e]))
      if (isEmpty) return undefined
      return row
    })
    .filter(Boolean) as IProviderForm[]

  if (checkDependents) {
    for (const d of data.dependents) {
      Object.keys(d).forEach((k) => {
        if (!(d as any)[k]) {
          if (k !== 'email') {
            throw new Error(`${fieldsMap[k]} is a required field`)
          }
        }
      })
    }
  }

  return data
}
