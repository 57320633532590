import {
  ApiService,
  IMemberEnrollment
} from 'react-angle-dashboard-components'

export class AcaService extends ApiService {

  // the endpoint is actually a bulk get - this function will treat it as a single fetch for abstraction
  async getMemberACAInfo(memberId?: string, mapper?: Function): Promise<IMemberEnrollment> {
    return this.request({
      method: 'post',
      url: '/member_infos/get_enrollment',
      data: { member_ids: [memberId] },
    }).then((r: any) => (mapper ? mapper(r) : r.data[0]))
  }
}
