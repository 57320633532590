import React from 'react'
import { Container, PortalLayout } from 'react-angle-dashboard-components'
import { CenteredContainer, Title } from './styles'

export const CompletedPage = () => {
  return (
    <PortalLayout breadcrumbs={[]} noBreadcrumbs>
      <Container className="pt-5">
        <div className="text-center">
          <div className="mb-5">
            <div className="mb-3">
              <Title className="text-primary">Thank You</Title>
              {/* <p>Thank You</p> */}
            </div>

            <CenteredContainer
              style={{
                marginTop: '4em',
                height: '200px'
              }}
              className="bg-light mt-5 p-5 rounded"
            >
              <div className="font-weight-bold">
                <p>
                  Thank you for submitting your form. Your Care Team will reach
                  out with any questions or concerns regarding the information
                  provided.
                </p>
                <p>-Angle Health</p>
              </div>
            </CenteredContainer>
          </div>
        </div>
      </Container>
    </PortalLayout>
  )
}

export default CompletedPage
