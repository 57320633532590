import React, { ReactNode, useState } from 'react'
import { Accordion, Card } from 'react-angle-dashboard-components'
import { AccordionContent, AccordionHeader } from './style'

const defaultCarotStyle = {
  width: 13.21,
  height: 7.21
}

const InfoAccordion = ({
  children,
  title
}: {
  children: ReactNode
  title: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Accordion>
      <Card className="mb-2 p-2">
        <Card.Title>
          <Accordion.Toggle
            eventKey="0"
            as="div"
            onClick={() => setIsOpen((prev) => !prev)}
            style={{
              cursor: 'pointer'
            }}
            className="my-2"
          >
            <AccordionHeader className="d-flex align-items-center">
              <img
                src="/carot.png"
                alt="carot"
                style={
                  !isOpen
                    ? { transform: 'rotate(-90deg)', ...defaultCarotStyle }
                    : defaultCarotStyle
                }
              />
              <div className="ml-2">{title}</div>
            </AccordionHeader>
          </Accordion.Toggle>
        </Card.Title>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <AccordionContent>{children}</AccordionContent>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default InfoAccordion
