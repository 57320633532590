import React from 'react'

export const BackButton: React.FC = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5275 4.83332L3.08088 4.83332L6.33421 1.57999C6.59421 1.31999 6.59421 0.89332 6.33421 0.63332C6.07421 0.37332 5.65421 0.37332 5.39421 0.63332L1.00088 5.02665C0.74088 5.28666 0.74088 5.70666 1.00088 5.96666L5.38755 10.3667C5.5121 10.4915 5.6812 10.5616 5.85755 10.5616C6.03389 10.5616 6.20299 10.4915 6.32755 10.3667C6.58755 10.1067 6.58755 9.68666 6.32755 9.42666L3.08088 6.16666L10.5275 6.16665C10.8942 6.16665 11.1942 5.86665 11.1942 5.49999C11.1942 5.13332 10.8942 4.83332 10.5275 4.83332Z"
      fill="#6D39D9"
    />
  </svg>
)

export default BackButton
