import React from 'react'
import moment from 'moment'

import { Button, Modal } from 'react-angle-dashboard-components'
import { ICoveredMember } from '../../../../interfaces/coverage'
import MemberListItem from './MemberListItem'


interface MemberListModalProps {
  show: boolean
  requestClose: () => void
  coveredMembers: ICoveredMember[]
}

const MemberListModal = ({
  show,
  requestClose,
  coveredMembers
}: MemberListModalProps) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={requestClose}
      dialogClassName=""
      className=""
    >
      <Modal.Header closeButton className="fs-20 border-bottom py-4">
        {`Covered Member${coveredMembers.length > 1 ? 's' : ''}`}
      </Modal.Header>
      <Modal.Body className="gy-2">
        {coveredMembers.map(({ dob, memberId, name, relationship }) => (
          <MemberListItem
            name={name}
            age={ moment().diff(dob, 'years') >= 2 ? `${moment().diff(dob, 'years')}y` : `${moment().diff(dob, 'months')}mo` }
            relationship={relationship}
            memberId= {memberId}
          />
        ))}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="outline-primary" onClick={requestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MemberListModal
