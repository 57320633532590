import React from 'react'
import { Button, Col, Container } from 'react-angle-dashboard-components'
import { Link } from 'react-router-dom'
import { Paths } from '../../routes/paths'
import { HomeLayout } from '../layouts/HomeLayout'
import InfoAccordion from './InfoAccordion'
import StepRow from './StepRow'
import { PageContent, PageHeader } from './style'

const PrescriptionsAtHome = () => {
  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'Pharmacy', link: Paths.drugs },
        {
          title: 'Receive Prescriptions at Home',
          link: Paths.prescriptionsAtHome
        }
      ]}
    >
      <Container className="d-flex justify-content-sm-center">
        <Col sm={9}>
          <Col>
            <Link to={Paths.drugs} className="d-flex align-items-center">
              <i
                className="icon icon-left-arrow"
                style={{ marginLeft: -15, marginRight: -10 }}
              />
              Go Back to Pharmacy Home
            </Link>
            <PageHeader>
              Sign Up to Receive Your Prescriptions at Home Through Walmart
            </PageHeader>
            <PageContent>
              Skip the line at the pharmacy and get mail-order prescriptions
              (often at a more affordable rate) through Walmart.
              <br />
              <br />
              Fill out the Walmart Mail Order Form with your personal
              information, prescription information, and Angle Health plan
              details. Once you've filled it out, you can send it to Walmart via
              email, fax, or mail.
            </PageContent>
            <Button
              className="mb-6"
              onClick={() =>
                window.open(
                  'https://angle-public-documents.s3.amazonaws.com/cap-rx/Walmart+Mail+Order+Form.pdf',
                  '_blank'
                )
              }
            >
              Fill out the Walmart Mail Order Form
            </Button>
          </Col>
          <Col>
            <InfoAccordion title="How do I request reimbursement for my pharmacy claim if it did not process through my pharmacy benefit?">
              If you have a prescription for a maintenance medication (i.e.,
              long-term conditions like arthritis, asthma, diabetes, high blood
              pressure or high cholesterol), mail order delivery may be a great
              solution for you. You will find mail order provides greater
              savings on most prescription benefit plans and saves time
              typically spent traveling and waiting at a retail pharmacy.
            </InfoAccordion>
            <InfoAccordion title="Getting Started with Walmart Home Delivery Pharmacy">
              Please reach out to your prescriber and update your mail order
              pharmacy provider as Walmart. Before prescriptions can be filled
              through Walmart Home Delivery, a profile needs to be created
              through one of the following options.
              <br />
              <ul className="pl-3">
                <li>
                  Phone: Call{' '}
                  <a target="_blank" rel="noreferrer" href="tel:18335990971">
                    1-833-599-0971
                  </a>{' '}
                  and follow the prompts for medications delivered to your home.
                </li>
                <li>
                  Mail: Send a completed order form to 1025 W. Trinity Mills,
                  Carrollton, TX 75006.
                </li>
                <li>
                  Email: email a completed order form to{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="mailto:WMSRX@wal-mart.com"
                  >
                    WMSRX@wal-mart.com.
                  </a>
                </li>
              </ul>
            </InfoAccordion>
            <InfoAccordion title="Choose one of the following methods to options to request refills of current prescriptions or send new prescriptions to Walmart Home Delivery">
              <ul className="pl-3">
                <li>
                  E-prescribe: Have your doctor e-prescribe to: Walmart Pharmacy
                  Mail Order 2625.
                </li>
                <li>
                  Fax: Have your doctor fax your prescription to{' '}
                  <a target="_blank" rel="noreferrer" href="tel:18004068976">
                    1-800-406-8976
                  </a>
                  . Faxed prescriptions may only be sent by a doctor's office
                  and must include patient information and diagnosis for timely
                  processing.
                </li>
              </ul>
            </InfoAccordion>
            <InfoAccordion title="How do I fill my Specialty medication?">
              <>
                If you are prescribed a specialty medication (i.e., multiple
                sclerosis, hepatitis, oncology, HIV, and many others), you can
                quickly and easily order your prescription by following the
                steps below.
                <br />
                <br />
                Not sure if you've been prescribed a specialty medication?
                Search for the medication in the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://caprx.adaptiverx.com/webSearch/index?key=8F02B26A288102C27BAC82D14C006C6FC54D480F80409B6860374AA7ED985B45"
                >
                  Capital Rx Formulary Tool
                </a>{' '}
                and look for "Specialty Drug" in the EDITS column.
                <Col>
                  <StepRow title="Your Doctor Prescribes" stepNumber="1.">
                    Have your doctor e-prescribe to Walmart Specialty Pharmacy
                    or fax your prescription to{' '}
                    <a target="_blank" rel="noreferrer" href="tel:18335990971">
                      1-833-599-0971
                    </a>
                    .
                  </StepRow>
                  <StepRow title="You Receive a Call" stepNumber="2.">
                    A representative from Walmart Specialty Pharmacy will call
                    you to gather more information and schedule your first
                    delivery.
                  </StepRow>
                  <StepRow title="Your Prescription Arrives" stepNumber="3.">
                    Your prescription will arrive when and where you've
                    requested it! Walmart Specialty Pharmacy will stay in touch
                    over the course of your therapy and call you with refill
                    reminders or to address any questions you may have about
                    your treatment.
                  </StepRow>
                  <StepRow
                    title="Prior Authorization Required"
                    stepNumber="!"
                    stepColor="red"
                  >
                    Prior Authorization Required The pharmacy will tell you if a
                    prior authorization is required. You'll need to complete{' '}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://assets.website-files.com/62a8d34ea6c525655dc2b65b/637d288dacb252a86d7e37b2_CapitalRx_Judi_General_PA_Form_11.22.pdf"
                    >
                      this form
                    </a>{' '}
                    and send it to Capital Rx.
                    <br />
                    <br />
                    Fill out the Member and Insurance Information sections.
                    Then, have your prescribing provider finalize the rest and
                    send it.
                    <br />
                    <br />
                    Forwarding directions are at the bottom of the form.
                  </StepRow>
                  <div>
                    For additional specialty pharmacy questions, please call
                    your dedicated Capital Rx Customer Care team{' '}
                    <a target="_blank" rel="noreferrer" href="tel:18335990971">
                      1-833-599-0971
                    </a>
                    .
                  </div>
                </Col>
              </>
            </InfoAccordion>
          </Col>
        </Col>
      </Container>
    </HomeLayout>
  )
}

export default PrescriptionsAtHome
