import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'
import { CreateAccountInitialPage } from '../pages/CreateAccountInitialPage'
import { CreateAccountConfirmMember } from '../pages/CreateAccountConfirmMember/CreateAccountConfirmMember'
import { CreateAccountContextProvider } from '../context/CreateAccountContext'
import { CreateAccountLookup } from '../pages/CreateAccountLookup/CreateAccountLookup'
import { CreateAccountEmail } from '../pages/CreateAccountEmail/CreateAccountEmail'
import { CreateAccountSetPw } from '../pages/CreateAccountSetPw/CreateAccountSetPw'
import { CreateAccountNoResult } from '../pages/CreateAccountNoResult'
import { CreateAccountContainer } from './CreateAccountContainer'
import { CreateAccountNoResultSent } from '../pages/CreateAccountNoResultSent'
import { CreateAccountSuccess } from '../pages/CreateAccountSuccess'

export const CreateAccountRouter = () => {
  return (
    <CreateAccountContextProvider>
      <CreateAccountContainer>
        <Switch>
          <Route
            path={Paths.createAccount + '/success'}
            component={CreateAccountSuccess}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/not-found/sent'}
            component={CreateAccountNoResultSent}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/not-found'}
            component={CreateAccountNoResult}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/set-pw'}
            component={CreateAccountSetPw}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/success'}
            component={CreateAccountNoResult}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/confirm'}
            component={CreateAccountConfirmMember}
          />
          <Route
            path={Paths.createAccount + '/:verificationType/email'}
            component={CreateAccountEmail}
          />
          <Route
            path={Paths.createAccount + '/:verificationType'}
            component={CreateAccountLookup}
          />
          <Route
            path={Paths.createAccount}
            component={CreateAccountInitialPage}
          />
        </Switch>
      </CreateAccountContainer>
    </CreateAccountContextProvider>
  )
}
