import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CenteredSpinner,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Modal
} from 'react-angle-dashboard-components'
import {
  FormState,
  useFieldArray,
  UseFormRegister,
  UseFormReturn
} from 'react-hook-form'
import { surveyService } from '../../../services'
import { IDependentProviderForm, IProviderForm } from './interfaces'
import { getEmptyDependent } from './shared'
import { icon, Title } from './styles'
import ReactInputMask from 'react-input-mask'
import { validateEmail } from '@angle/utils'

export const DependentForm = ({
  control,
  register,
  reset,
  setValue,
  getValues,
  title,
  formState,
  watch
}: UseFormReturn<IDependentProviderForm> & { title: string }) => {
  const [isLoading, setLoading] = useState(true)

  const {
    fields: dependentFields,
    append: dependentAppend,
    remove
  } = useFieldArray({
    control,
    name: 'dependents'
  })

  useEffect(() => {
    surveyService
      .getDependents()
      .then((e) => {
        if (!e.dependents || !e.dependents?.length) {
          setLoading(false)
          return
        }
        const { providers } = getValues()

        reset({
          providers,
          dependents: e.dependents
        })
        setLoading(false)

        e.dependents.forEach((p: IProviderForm, i: number) => {
          Object.keys(p).forEach((k: any) => {
            setValue(`dependents.${i}.${k}` as any, (p as any)[k])
          })
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }, [setValue, reset, getValues])

  const onAddNewDependent = () => dependentAppend(getEmptyDependent())

  return (
    <div className="text-center">
      <div className="mb-5">
        <div className="mb-3">
          <Title className="text-primary">{title}</Title>
          <p>List your current dependents</p>
        </div>

        <b>
          In order to best serve you we’d like to confirm your dependent
          information below:
        </b>
      </div>

      <div className="bg-light py-3 pr-3 rounded">
        {isLoading && <CenteredSpinner />}

        {!isLoading &&
          dependentFields.map((field, index) => (
            <Row className="mx-0" key={field.id}>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                className="px-0"
                xs="1"
              >
                <b>#{index + 1}</b>
              </Col>
              <Col className="px-0">
                <DependentRow
                  {...field}
                  index={index}
                  register={register}
                  formState={formState}
                  watch={watch}
                  onRemove={() => remove(index)}
                />
              </Col>
            </Row>
          ))}

        <div className="text-right pt-3">
          <b className="mr-3">Missing any dependents? </b>{' '}
          <Button variant="outline-primary" onClick={onAddNewDependent}>
            Add
          </Button>
        </div>
      </div>
    </div>
  )
}

const DependentRow: React.FC<{
  register: UseFormRegister<any>
  index: number
  onRemove: Function
  formState: FormState<IDependentProviderForm>
  watch: any
}> = ({ register, index, onRemove, formState, watch }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  // required behavior is to display an error state on the email field if it is empty (while still allowing user to submit without an email)

  const email = watch(`dependents.${index}.email`)
  const hasEmail = email && email.length > 3

  const formClassName = isEditing ? 'bg-white' : 'bg-light'

  const popover = (
    <Popover
      id="popover-basic"
      className="border shadow p-3"
      style={{ minWidth: 350 }}
    >
      A email is only necessary if you would like for your dependent to have
      their own unique account and login. If left blank the dependent will not
      receieve a log in and you can reference their ID card from the subscriber
      account (often appropriate for small children).
    </Popover>
  )

  return (
    <>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Body className="fs-16 font-weight-bold">
          Are you sure you would like to remove this dependent?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button
            variant="outline-primary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button onClick={() => onRemove()}>Remove</Button>
        </Modal.Footer>
      </Modal>
      <Card className="text-left mt-2">
        <Card.Body>
          <Row>
            <Col style={{ minWidth: 200 }}>
              <Form.Group>
                <Form.Label> First Name</Form.Label>
                <Form.Control
                  {...register(`dependents.${index}.firstName`, {
                    required: true
                  })}
                  className={formClassName}
                  readOnly={!isEditing}
                  placeholder="First Name"
                  isInvalid={Boolean(
                    formState.errors.dependents &&
                      formState.errors.dependents[index]?.firstName
                  )}
                />
              </Form.Group>
            </Col>
            <Col style={{ minWidth: 200 }}>
              <Form.Group>
                <Form.Label> Last Name</Form.Label>
                <Form.Control
                  {...register(`dependents.${index}.lastName`, {
                    required: true
                  })}
                  className={formClassName}
                  readOnly={!isEditing}
                  placeholder="Last Name"
                  isInvalid={Boolean(
                    formState.errors.dependents &&
                      formState.errors.dependents[index]?.lastName
                  )}
                />
              </Form.Group>
            </Col>

            <Col style={{ minWidth: 250 }}>
              <Form.Group>
                <Form.Label>
                  {' '}
                  Email{' '}
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover}
                  >
                    {icon}
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  {...register(`dependents.${index}.email`, {
                    validate: validateEmail
                  })}
                  className="bg-white"
                  placeholder="Email"
                  isInvalid={
                    Boolean(
                      formState.errors.dependents &&
                        formState.errors.dependents[index]?.email
                    ) || !hasEmail
                  }
                />
              </Form.Group>
              {hasEmail &&
                formState.errors.dependents &&
                formState.errors.dependents[index]?.email &&
                formState.isSubmitted && (
                  <span className="text-danger font-weight-bold">
                    Please enter a valid email.
                  </span>
                )}
            </Col>

            <Col style={{ minWidth: 100 }}>
              <Form.Group>
                <Form.Label> Age</Form.Label>
                <Form.Control
                  {...register(`dependents.${index}.age`, { required: true })}
                  className={formClassName}
                  readOnly={!isEditing}
                  type="text"
                  placeholder="35"
                  as={ReactInputMask}
                  isInvalid={Boolean(
                    formState.errors.dependents &&
                      formState.errors.dependents[index]?.age
                  )}
                  mask="99"
                  {...{ maskChar: null }}
                />
              </Form.Group>
            </Col>

            <Col style={{ minWidth: 200, maxWidth: 300 }}>
              <Form.Group>
                <Form.Label> Relation</Form.Label>
                <Form.Control
                  {...register(`dependents.${index}.relation`, {
                    required: true
                  })}
                  className={formClassName}
                  readOnly={!isEditing}
                  as="select"
                  isInvalid={Boolean(
                    formState.errors.dependents &&
                      formState.errors.dependents[index]?.relation
                  )}
                >
                  <option value="Spouse">Spouse</option>
                  <option value="Child">Child</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="pr-0 d-flex align-items-center  justify-content-end">
              <Button variant="link" onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? 'Save' : 'Edit'}
              </Button>
              <Button
                variant="link"
                onClick={() => setShowDeleteModal(true)}
                className="text-danger"
              >
                Delete
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  )
}
