import { DateHandler } from '@angle/utils'
import { IAccums, IAccumsDTOItem, ICoveredMember, ICoveredMembersDTO, IPlan, IPlanDTOItem } from '../../../interfaces/coverage'
import moment from 'moment'

export const getMostRecentPlanAndAccumFromPlans = (plans: ReturnType<typeof mapDTOtoPlans>) => {

  const data = plans

  const map = data.planIdToPlanPeriodMap
  let mostRecentDateFound = moment('01/01/1900', 'MM/DD/YYYY')
  let mostRecentPlanFound = - 1

  for (let i = 0; i < map.length; i++) {
    
    const parsedEndDate = moment(map[i].end_date, 'MM/DD/YYYY')
    const isCurrentPlan = parsedEndDate.isAfter(moment())

    if (isCurrentPlan) {
      mostRecentPlanFound = i
      break
    } else if (parsedEndDate.isAfter(mostRecentDateFound)) {
      mostRecentDateFound = parsedEndDate
      mostRecentPlanFound = i
    }
  }

  const tempPlanDetails = data.plans[mostRecentPlanFound]
  const accum = tempPlanDetails.accums.find((a) => a.memberId === tempPlanDetails.memberId)

  return {
    accum: accum,
    planDetails: tempPlanDetails
  }
}

export const mapDTOtoPlans = (r: any) => {
  
  const planIdToPlanPeriodMap: {
      start_date: string
      end_date: string
    }[] = []
  
  const plans: IPlan[] = r.data?.map((plan: IPlanDTOItem, idx: number) => {
    planIdToPlanPeriodMap[idx] = {
      start_date: DateHandler.formatForClient(plan.start_date),
      end_date: DateHandler.formatForClient(plan.end_date)
    }

    return {
      accums: plan.accums.map(accum => mapDTOtoAccum(accum)),
      coverageTier: plan.coverage_tier,
      coveredMembers: plan.covered_members.map(member => mapDTOtoCoveredMember(member)),
      endDate: DateHandler.formatForClient(plan.end_date),
      isCobra: plan.is_cobra,
      memberId: plan.member_id,
      planId: plan.plan_id,
      planName: plan.plan_name,
      planRestartDate: DateHandler.formatForClient(plan.next_accum_reset_date),
      planType: plan.plan_type,
    }
  })

  return {
    planIdToPlanPeriodMap: planIdToPlanPeriodMap,
    plans: plans
  }
}

export const mapDTOtoCoveredMember = (r: ICoveredMembersDTO): ICoveredMember => {
  return {
    age: `${r.age}`,
    dob: r.date_of_birth,
    memberId: r.member_id,
    name: r.name,
    relationship: r.relationship
  }
}

export const mapDTOtoAccum = (a: IAccumsDTOItem): IAccums => {
  return {
    famInnDed: a.fam_inn_ded,
    famInnDedMax: a.fam_inn_ded_max,
    famInnDedMed: a.fam_inn_ded_med,
    famInnDedRx: a.fam_inn_ded_rx,
    famInnOop: a.fam_inn_oop,
    famInnOopMax: a.fam_inn_oop_max,
    famInnOopMed: a.fam_inn_oop_med,
    famInnOopRx: a.fam_inn_oop_rx,
    famOonDed: a.fam_oon_ded,
    famOonDedMax: a.fam_oon_ded_max,
    famOonDedMed: a.fam_oon_ded_med,
    famOonDedRx: a.fam_oon_ded_rx,
    famOonOop: a.fam_oon_oop,
    famOonOopMax: a.fam_oon_oop_max,
    famOonOopMed: a.fam_oon_oop_med,
    famOonOopRx: a.fam_oon_oop_rx,
    indInnDed: a.ind_inn_ded,
    indInnDedMax: a.ind_inn_ded_max,
    indInnDedMed: a.ind_inn_ded_med,
    indInnDedRx: a.ind_inn_ded_rx,
    indInnOop: a.ind_inn_oop,
    indInnOopMax: a.ind_inn_oop_max,
    indInnOopMed: a.ind_inn_oop_med,
    indInnOopRx: a.ind_inn_oop_rx,
    indOonDed: a.ind_oon_ded,
    indOonDedMax: a.ind_oon_ded_max,
    indOonDedMed: a.ind_oon_ded_med,
    indOonDedRx: a.ind_oon_ded_rx,
    indOonOop: a.ind_oon_oop,
    indOonOopMax: a.ind_oon_oop_max,
    indOonOopMed: a.ind_oon_oop_med,
    indOonOopRx: a.ind_oon_oop_rx,
    innCurrentStep: a.inn_current_step,
    memberId: a.member_id,
    firstName: a.first_name,
    lastName: a.last_name,
    oonCurrentStep: a.oon_current_step,
    personCode: a.sequence_id.length === 1 ? `0${a.sequence_id}` : a.sequence_id,
    relationship: a.relationship
  }
}
