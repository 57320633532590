import styled from 'styled-components'

export const DropdownButton = styled.button<{ disabled?: boolean }>`
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  background: #fff;
  border: none;
  // transition: 0.2s background;
  cursor: ${(props) => (props.disabled ? 'default;' : 'pointer;')}
    ${(props) =>
      !props.disabled &&
      `&:hover {
    // background: #f2f5f9;
  }`};
`

export const ArrowDownIcon = styled.i`
  width: 10px;
  height: 10px;
  font-weight: 600;
`
