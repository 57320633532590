import React from 'react'
import { Button, Card } from 'react-angle-dashboard-components'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Paths } from '../../../../../routes/paths'
import { LoginPageCard } from '../../../LoginPageCard'
import { GoBackButton, OutlineButton } from '../../../style'
import { VerificationType } from '../../types'
import { useConfirmMember } from './useConfirmMember'

export const CreateAccountConfirmMember = () => {
  const history = useHistory()
  const { verificationType } = useParams<{
    verificationType: VerificationType
  }>()

  const { memberData, members, error, confirmMember } = useConfirmMember()

  const onConfirm = async (member: any) => {
    const requestBody: any = { ...member }
    requestBody.date_of_birth = memberData.date_of_birth

    await confirmMember.mutateAsync(requestBody)
  }

  const onNotFound = () => {
    history.push(`${Paths.createAccount}/${verificationType}/not-found`)
  }

  const headerText =
    members?.length === 1 ? 'Is this you?' : 'Are any of these you?'
  const buttonText =
    members?.length === 1 ? 'This is not me' : 'None of these are me'

  return (
    <LoginPageCard
      headerText={headerText}
      subheaderText="Please confirm your information"
    >
      {members?.map((member: any) => (
        <div key={member?.member_id}>
          <Card
            className="p-2 d-flex flex-row align-items-center"
            style={{ borderWidth: 1.5 }}
          >
            <div
              className="p-3 d-flex align-items-center justify-content-center mr-3"
              style={{
                background: '#EEEAF7',
                borderRadius: '50%',
                height: 60,
                width: 60
              }}
            >
              <i className="icon icon-user text-primary" />
            </div>

            <div>
              <span className="d-block text-capitalize fs-18">
                {member.first_name} {member.last_name}
              </span>
              <span className="d-block">
                {member.member_id.substring(0, 10)}
              </span>
              <span className="d-block text-secondary">
                {member.employer_name}
              </span>
            </div>
          </Card>
          <Button
            className="my-4"
            block
            onClick={() =>
              onConfirm({
                first_name: member.first_name,
                last_name: member.last_name,
                member_id: member.member_id
              })
            }
          >
            Confirm
          </Button>
          {error?.status === 403 && (
            <span className="mb-3 d-block">
              This account already exists. Please try{' '}
              <Link to={Paths.forgotPassword}>resetting your password</Link> if
              you are having trouble logging in.
            </span>
          )}
          {error && error?.status !== 403 && (
            <span className="mb-3 d-block text-danger">
              An error has occurred. Please try again later.
            </span>
          )}
        </div>
      ))}
      <div className="text-center">
        <OutlineButton
          variant="outline-primary"
          block
          onClick={() => onNotFound()}
        >
          {buttonText}
        </OutlineButton>
        <GoBackButton
          className="mt-4"
          variant="link"
          block
          onClick={() =>
            history.push(`${Paths.createAccount}/${verificationType}`)
          }
        >
          Go Back
        </GoBackButton>
      </div>
    </LoginPageCard>
  )
}
