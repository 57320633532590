import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

import { Col, Form, PageContainer, Row } from 'react-angle-dashboard-components'
import PlanGlanceCard from './PlanGlanceCard/PlanGlanceCard'
import PlanDetailCard from './PlanDetailCard/PlanDetailCard'
import NavigateCard from './NavigateCard/NavigateCard'
import { mapDTOtoPlans } from '../../../services/api/coverage/mappers'
import { IPlan } from '../../../interfaces/coverage'

export type PlansAccumMember = {
  memberId: string
  name: string
  personCode: string
  relationship: string
}


export const PlanContent: React.FC<{
  data: ReturnType<typeof mapDTOtoPlans> | undefined
  voluntaryBenefits: any
  member: any
  tabKey: string
  isLoading: boolean
}> = ({ data, isLoading }) => {

  const [selectedAccumIdx, setSelectedAccumIdx] = useState<number>(0)
  const [selectedPlansAccumMembers, setSelectedPlansAccumMembers] = useState<PlansAccumMember[]>([])
  const [selectedMemberId, setSelectedMemberId] = useState<string>()
  const [selectedMemberName, setSelectedMemberName] = useState<string>()
  const [selectedPlanIdx, setSelectedPlanIdx] = useState<number>(-1)
  const [planDetails, setPlanDetails] = useState<IPlan | undefined>(undefined)
  const [planPeriodOptions, setPlanPeriodOptions] = useState<{[key: string]: string}>({})

  useEffect(() => {
    if (data?.planIdToPlanPeriodMap && !Object.keys(planPeriodOptions).length) {
     const map = data.planIdToPlanPeriodMap
     let hasCurrentPlan = false
     let mostRecentDateFound = moment('01/01/1900', 'MM/DD/YYYY')
     let mostRecentPlanFound = - 1

      for (let i = 0; i < map.length; i++) {
        
        const startDate = moment(map[i].start_date, 'MM/DD/YYYY').format('MMMM DD, YYYY')
        const parsedEndDate = moment(map[i].end_date, 'MM/DD/YYYY')
        const endDate = parsedEndDate.format('MMMM DD, YYYY')
        const isCurrentPlan = parsedEndDate.isAfter(moment())

        if (isCurrentPlan) {
          planPeriodOptions[i] = `${startDate} - Present`
          setSelectedPlanIdx(i)
          hasCurrentPlan = true
        } else {
          planPeriodOptions[i] = `${startDate} - ${endDate}`

          if (parsedEndDate.isAfter(mostRecentDateFound)) {
            mostRecentDateFound = parsedEndDate
            mostRecentPlanFound = i
          }
        }
      }

      if (!hasCurrentPlan && mostRecentPlanFound >= 0) {
        /*
        1. we need to find the most recent plan in the list, 
        and 
        set the plan period options & 
        3. the selected plan to that plan
        */
        setSelectedPlanIdx(mostRecentPlanFound)
      }

      setPlanPeriodOptions(planPeriodOptions)
    }
  }, [data, planPeriodOptions, selectedPlanIdx])

  useEffect(() => {

    let tempPlanDetails

    if (data?.plans && selectedPlanIdx >= 0) {
      tempPlanDetails = data.plans[selectedPlanIdx]
      setPlanDetails(tempPlanDetails)
    }

    if (tempPlanDetails) {
      const plansAccumMembers: PlansAccumMember[] = []
      for (let i = 0; i < tempPlanDetails.accums.length; i++) {
        const accum = tempPlanDetails.accums[i]
        if (accum.memberId === tempPlanDetails.memberId) {
          setSelectedMemberId(accum.memberId)
          setSelectedMemberName(accum.firstName)
          setSelectedAccumIdx(i)
        } 

        plansAccumMembers.push({ 
          memberId: accum.memberId,
          name: accum.firstName + ' ' + accum.lastName,
          personCode: accum.personCode,
          relationship: accum.relationship
         })
      }

      setSelectedPlansAccumMembers(plansAccumMembers)
    }
  }, [data, selectedPlanIdx])

  useEffect(() => {
    if (planDetails) {
      for (let member of planDetails.coveredMembers) {
        if (member.memberId === selectedMemberId) {
          setSelectedMemberName(planDetails.accums.find(obj => obj.memberId === selectedMemberId)?.firstName)
          setSelectedAccumIdx(planDetails.accums.findIndex((accum) => accum.memberId === member.memberId))
        }
      }
    }
  }, [selectedMemberId, planDetails])

  return (
    <div>
      <div
        className="border-bottom py-3 bg-white"
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 100
        }}
      >
        <PageContainer size="xl">
          <Form.Group className="d-flex flex-row align-items-center mb-0">
            <Form.Label className="mr-3 mb-0">Plan Period</Form.Label>
            <Form.Control
              as="select"
              className="w-auto"
              disabled={Object.keys(planPeriodOptions).length === 1}
              onChange={(e) => setSelectedPlanIdx(parseInt(e.target.value))}>
              {Object.keys(planPeriodOptions).map((planId) => (
                <option value={planId}>{planPeriodOptions[planId]}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </PageContainer>
      </div>
      <PageContainer size="xl" className="py-5 gy-3">
        <Row>
          <Col>
            {planDetails ? (
              <PlanDetailCard
                coveredMembers={planDetails.coveredMembers}
                endDate={planDetails.endDate}
                isCobra={planDetails.isCobra}
                memberId={planDetails.accums[selectedAccumIdx].memberId}
                personCode={planDetails.accums[selectedAccumIdx].personCode}
                planName={planDetails.planName}
                planType={planDetails.planType}
              />
            ) : (
              <Skeleton />
            )}
          </Col>
          <Col>
            <NavigateCard />
          </Col>
        </Row>
        <div>
          <PlanGlanceCard
            accum={planDetails?.accums[selectedAccumIdx]}
            isLoading={isLoading}
            planDetails={planDetails}
            selectedMemberId={selectedMemberId}
            selectedMemberName={selectedMemberName}
            selectedPlansAccumMembers={selectedPlansAccumMembers}
            setSelectedMemberId={setSelectedMemberId} />
        </div>
        {/* <div>
        <PlanBenefitsCard />
        </div> */}
      </PageContainer>
    </div>
  )
}
