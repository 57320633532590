import { useQuery } from 'react-query'
import { coverageService } from '../../../../../../services'
import { mapDependentsToSelectedArray } from './useSelectVoluntaryPlan'
import { SelectedDependentsKey } from '../../../../../../components/SelectDependentForPlan/SelectDependentForPlan'

type GetPremiumsConfig = {
  selectedDependentsKey: SelectedDependentsKey
  apiKey: string
  // coverage amount is only for critical illness
  coverageAmount?: number
}
export const useGetPremiums = (config: GetPremiumsConfig) => {
  const { selectedDependentsKey, apiKey, coverageAmount } = config
  const { data: premiums } = useQuery(
    ['getAvailablePlanPremiums', selectedDependentsKey, apiKey, coverageAmount],
    () =>
      coverageService.getAvailablePlansPremiums(
        apiKey,
        mapDependentsToSelectedArray(selectedDependentsKey),
        coverageAmount
      )
  )

  return { premiums }
}
