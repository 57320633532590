import React from 'react'
import { ErrorMessage } from './ErrorMessage'

export const VoluntaryPlansContainer: React.FC<{
  benefitDisplayName: string
  error?: any
}> = ({ children, benefitDisplayName, error }) => {
  return (
    <div className="pt-1">
      {benefitDisplayName && <h3>{benefitDisplayName} benefits</h3>}
      <div className="d-flex flex-wrap pb-5 py-3">
        {error ? <ErrorMessage /> : children}
      </div>
    </div>
  )
}
