import {
  IFaq,
  IMedicalBenefit,
  IPlanBenefitTableDTO,
  PlanService as BasePlanService
} from 'react-angle-dashboard-components'

export class PlanService {
  planService: BasePlanService

  constructor(base: string) {
    this.planService = new BasePlanService(base)
  }

  getSBC(
    planId: string,
    params?: string
  ): Promise<{ faqs: IFaq[]; events: IPlanBenefitTableDTO[] }> {
    return this.planService.getSBC(planId, params).then((sbc) => ({
      faqs: sbc.faqs.map((f) => ({
        title: f.question,
        description: f.answer,
        section: {
          title: 'Why this matters',
          text: f.why_this_matters
        }
      })),
      events: sbc.events
    }))
  }

  getMedicalBenefits(): Promise<IMedicalBenefit[]> {
    return this.planService.getMedicalBenefits().then((r) =>
      r.benefits.map((b, i) => ({
        id: i,
        name: b.top_level_name,
        subCategories: b.sub_categories.map((sc: any) => ({
          id: sc.benefit_id,
          name: sc.sub_category,
          inNetwork: sc.in_network_main,
          inNetworkSubtitle: sc.in_network_sub,
          inlineDescription: sc.inline_description,
          modalDescription: sc.modal_description,
          outNetwork: sc.out_network_main,
          outNetworkSubtitle: sc.out_network_sub
        }))
      }))
    )
  }
}
