export const memberDataMapper = (data: any) => {
  let showVoluntary = false
  for (const key in data.plan_type_availabilities) {
    if (data.plan_type_availabilities[key] === true && key !== 'medical') {
      showVoluntary = true
    }
  }
  return {
    coverageEffectiveDate: data.coverage_effective_date,
    serverStep: data.in_progress_state,
    inRenewal: data.in_renewal,
    showVoluntary
  }
}
