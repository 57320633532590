import * as React from 'react'
import { SVGProps } from 'react'

const FacilityOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <g id="Facility">
      <path
        id="Facility_2"
        fill="black"
        d="M8 0L7.50868 0.323398L0 5.27986V15.2291C0 16.197 0.806166 17 1.77778 17H14.2222C15.1938 17 16 16.197 16 15.2291V5.27986L8 0ZM8 2.1237L14.2222 6.23103V15.2291H1.77778V6.23103L8 2.1237ZM7.11111 6.37457V9.03093H4.44444V10.8018H7.11111V13.4582H8.88889V10.8018H11.5556V9.03093H8.88889V6.37457H7.11111Z"
      />
    </g>
  </svg>
)

export default FacilityOutline
