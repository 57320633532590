import React from 'react'
import { Col } from 'react-angle-dashboard-components'
import { BodyText, InfoLabel, NumberContainer } from './style'

interface IInfoContainer {
  step: string
  header: string
  body: string
}

const InfoContainer = ({ step, header, body }: IInfoContainer) => {
  return (
    <Col>
      <NumberContainer>{step}</NumberContainer>
      <InfoLabel className="my-2">{header}</InfoLabel>
      <BodyText>{body}</BodyText>
    </Col>
  )
}

export default InfoContainer
