import React from 'react'
import { Container, Row, Col } from 'react-angle-dashboard-components'
import styled from 'styled-components'

const Text = styled.span`
  font-size: 40px;
  color: var(--white);
  position: absolute;
  bottom: 60px;
  left: 46px;
  max-width: 350px;
  line-height: 1.1;
`

export const LoginLayout: React.FC = ({ children }) => (
  <Container fluid className="px-0 vh-100" style={{ background: '#f6f2ec' }}>
    <Row className="mx-0 px-0">
      <Col
        className="bg-primary vh-100 d-none d-lg-block"
        sm={6}
        style={{
          backgroundImage: 'url(/login-bg.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          position: 'relative'
        }}
      >
        <Text>Healthcare for the modern employee</Text>
      </Col>
      <Col
        sm={6}
        style={{ background: '#f6f2ec' }}
        className="min-vh-100 m-auto d-flex flex-column justify-content-center align-items-center"
      >
        {children}
      </Col>
    </Row>
  </Container>
)
