import React from 'react'
import { currencyFormatter } from '@angle/utils'

export const PlanCardPremium: React.FC<{ premium?: number }> = ({
  premium
}) => {
  return (
    <div style={{ visibility: premium === undefined ? 'hidden' : 'visible' }}>
      <span className="fs-20 font-weight-bold">
        {typeof premium === 'number' ? currencyFormatter.format(premium) : ''}
      </span>
      <span className="fs-20 text-secondary">/mo</span>
    </div>
  )
}
