import React, { ReactElement, ReactNode, useState } from 'react'

import { Col, Row } from 'react-angle-dashboard-components'
import { ReactComponent as IconClock } from '../../../../assets/iconClock.svg'
import { ReactComponent as IconCheckman } from '../../../../assets/iconCheckman.svg'
import { ReactComponent as IconCheckmark } from '../../../../assets/iconCheckmarkGreyCircle.svg'
import { ReactComponent as IconSscope } from '../../../../assets/iconSscope.svg'

interface MoreInfoModalItemProps {
  icon?: string
  noBorder: boolean
  noCollapse?: boolean
  subtitle: string
  text: string
  titledSections?: ReactNode[]
  underTitle?: string
}

const iconMap: {[key: string]: ReactElement} = {
  sscope: <IconSscope />,
  checkman: <IconCheckman />,
  clock: <IconClock />,
  checkmark: <IconCheckmark />
}

const MoreInfoModalItem = ({ icon, noBorder, noCollapse, subtitle, text, titledSections, underTitle }: MoreInfoModalItemProps) => {

  const [showText, setShowText] = useState<boolean>(false)
  
  
  return (
    <div>
      <div className={`rounded ${noBorder ? '': 'border-bottom '}d-flex justify-content-between align-items-center`}>
        <div
          className="p-3"
          style={{ width: '100%' }}
        >
          <Row>
            {icon && (
            <Col>
              {iconMap[icon]}
            </Col>)}
            <Col style={{ flexGrow: 100 }}>
              <Row
                onClick={() => { setShowText(!showText) }}>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}>
                  <h5
                    style={{
                      fontWeight: 500,
                      verticalAlign: 'bottom'
                    }}
                    className="m-0 p-0"
                  >
                    {subtitle}
                  </h5>
                  {underTitle && (
                    <h6
                      style={{
                        color: '#6C757D',
                        verticalAlign: 'bottom'
                      }}
                      className="m-0 p-0"
                    >
                      {underTitle}
                    </h6>
                  )}
                </Col>
                {!noCollapse && (<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <i
                    className={
                      showText
                        ? 'icon icon-down-arrow text-primary'
                        : 'icon icon-left-arrow text-primary'
                    }
                  />
                </Col>)}
              </Row>
              {(noCollapse || showText) && (
                <Row style={{ display: 'flex', marginTop: '10px', marginLeft: 0 }}>
                  <Row>
                    <Col style={{ flexGrow: 3 }}>
                      <p
                        className="m-0 p-0"
                        style={{
                          color: '#6C757D'
                        }}
                      >
                        {text}
                      </p>
                    </Col>
                  </Row>
                  {titledSections
                  // titledSections.map((text) => {
                  //   return (
                  //     <Row
                  //       className="m-0 p-0"
                  //       style={{
                  //         color: '#6C757D'
                  //       }}
                  //     >
                  //       {text}
                  //     </Row>
                  //   )
                  // }
                  // )
                  }
                </Row>)}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default MoreInfoModalItem
