import React from 'react'
import {
  Table,
  TableHeader,
  CenteredSpinner,
  InfoWithIcon
} from 'react-angle-dashboard-components'
// import { Link } from 'react-router-dom'

import { useDocuments } from '../../hooks/use-documents'

const UnableToLoad: React.FC = () => (
  <InfoWithIcon
    iconEl={
      <i
        className="icon icon-error text-danger"
        style={{ fontSize: '35px', marginBottom: '0.625rem' }}
      />
    }
  >
    We were unable to load network resources. If your issue continues please
    <a style={{ textDecoration: 'underline' }} href="tel://+18559371855">
      contact member services.
    </a>
  </InfoWithIcon>
)

export const DocumentsTab: React.FC = () => {
  const { data, isLoading, isError } = useDocuments() as any
  const docs = data?.documents || []
  return (
    <section>
      {isLoading && <CenteredSpinner style={{ height: 'auto' }} />}
      {isError && <UnableToLoad />}
      {docs && (
        <Table>
          <thead className="thead-light">
            <tr>
              <TableHeader className="py-2" wide>
                Document Name
              </TableHeader>
              <TableHeader className="py-2" />
            </tr>
          </thead>
          {docs.length ? (
            <tbody>
              {docs.map((document: any, i: number) => (
                <tr key={i}>
                  <td>{document.name}</td>
                  <td>
                    <a
                      rel="nofollow noreferrer noopener"
                      target="_blank"
                      href={document.link}
                    >
                      View PDF
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={2} className="text-center">
                  There are no documents available.
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      )}
    </section>
  )
}

export default DocumentsTab
