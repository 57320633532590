import React from 'react'
import { StyledCard, Header, Subheader } from '../../style'

export const CreateAccountNoResultSent = () => {
  return (
    <StyledCard>
      <div className="text-success text-center mt-5">
        <i className="icon icon-correct" style={{ fontSize: 30 }} />
      </div>
      <Header>We got your request!</Header>
      <Subheader>
        You will be contacted shortly, please check your inbox.
      </Subheader>
    </StyledCard>
  )
}
