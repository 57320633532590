import * as React from 'react'
import { SVGProps } from 'react'

const SubtractSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 11"
    fill="currentColor"
    {...props}
  >
    <g id="Subtract">
      <g id="Subtract_2">
        <path d="M3.77432 8.79706H7.65217L8.54493 11H11L9.31015 6.6899H4.57555L3.77432 8.79706Z" />
        <path d="M5.73235 3.64766L6.72754 6.27485H9.15073L6.88222 0.623625L5.73235 3.64766Z" />
        <path d="M6.64336 0.0285936L6.63188 0H4.36812L0 11H2.45507L5.5 3.03429L5.50023 3.0349L6.64336 0.0285936Z" />
      </g>
    </g>
  </svg>
)

export default SubtractSolid
