import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { StyledCard, Header, Subheader } from './style'

export const LoginPageCard: React.FC<{
  headerText: string
  subheaderText: string
  showErrorIcon?: boolean
}> = ({ children, headerText, subheaderText, showErrorIcon }) => {
  return (
    <StyledCard>
      <Card.Body style={{ padding: 40 }}>
        {showErrorIcon && (
          <div className="text-center">
            <i
              className="icon icon-error text-danger"
              style={{ fontSize: 36 }}
            />
          </div>
        )}
        <Header>{headerText}</Header>
        <Subheader>{subheaderText}</Subheader>
        {children}
      </Card.Body>
    </StyledCard>
  )
}
