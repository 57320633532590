import styled from 'styled-components'

export const Header = styled.h1`
  font-size: 30px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
`

export const PharmCardTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Text/01. Body */
  color: #212529;

  margin-top: 10px;
`

export const PharmCardText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  /* Text/02. Muted */
  color: #6c757d;
`

export const CheckIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

export const MajorPharmHeader = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 150% */
  letter-spacing: -0.01em;

  /* Text/01. Body */
  color: #212529;
`

export const MajorPharmText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* or 150% */
  letter-spacing: -0.01em;

  /* Text/01. Body */
  color: #212529;
`

export const MajorPharmInfo = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  /* Text/02. Muted */
  color: #6c757d;
`

export const AdditionalResourcesHeader = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */
  letter-spacing: -0.01em;

  /* Text/01. Body */
  color: #212529;
`

export const DownloadHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height, or 120% */

  color: #171725;
`

export const DownloadContent = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;

  /* Text/02. Muted */
  color: #6c757d;
`
