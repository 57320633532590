import React, {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react'
import { Row, Col, Card } from 'react-angle-dashboard-components'
import { useMutation } from 'react-query'
import { coverageService } from '../../services'

export type SelectedDependentsKey = {
  [dependentId: string]: boolean
}

type SelectDependent = {
  name: string
  id: string
}
export const SelectDependentForPlan: React.FC<{
  dependents?: SelectDependent[]
  selectedDependentsKey?: SelectedDependentsKey
  setSelectedDependentsKey?: Dispatch<SetStateAction<SelectedDependentsKey>>
  apiKey?: string
  refetchSelectedPlan?: any
}> = ({
  dependents,
  setSelectedDependentsKey,
  selectedDependentsKey,
  apiKey,
  refetchSelectedPlan
}) => {
  return (
    <Row className="mb-3">
      <Col>
        <h5>Who do you want to enroll?</h5>
        <div className="d-flex flex-wrap" style={{ columnGap: '15px' }}>
          {apiKey === 'medical' ? (
            <SelectMemberCard disabled={true} />
          ) : (
            <SelectMemberCard
              apiKey={apiKey}
              refetchSelectedPlan={refetchSelectedPlan}
              setSelectedDependentsKey={setSelectedDependentsKey}
            />
          )}

          {dependents?.map((dep: any) => (
            <SelectDependentCard
              label={dep.name}
              id={dep.id}
              key={dep.id}
              selectedDependentsKey={selectedDependentsKey}
              setSelectedDependentsKey={setSelectedDependentsKey}
            />
          ))}
        </div>
      </Col>
    </Row>
  )
}

export const SelectDependentCard: React.FC<{
  label: string
  id?: string
  selectedDependentsKey?: SelectedDependentsKey
  setSelectedDependentsKey?: Dispatch<SetStateAction<SelectedDependentsKey>>
}> = ({ label, id, setSelectedDependentsKey, selectedDependentsKey }) => {
  const [isSelected, setIsSelected] = useState(false)

  // set checkbox UI based on previously saved state
  useEffect(() => {
    if (
      selectedDependentsKey &&
      selectedDependentsKey[id as string] !== undefined
    ) {
      setIsSelected(!!selectedDependentsKey[id as string])
    }
  }, [selectedDependentsKey, id])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSelected(e.target.checked)

    if (setSelectedDependentsKey && id) {
      setSelectedDependentsKey((prevState: SelectedDependentsKey) => {
        return {
          ...prevState,
          [id]: e.target.checked
        }
      })
    }
  }

  return (
    <Card
      className={`fs-14 p-2 my-2 ${
        isSelected ? 'border-primary' : 'border-gray'
      }`}
      style={{
        width: 200,
        height: 50
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '85%'
          }}
        >
          {label}
        </div>
        <div>
          {' '}
          <input
            className="d-block"
            type="checkbox"
            checked={isSelected}
            onChange={onChange}
          />
        </div>
      </div>
    </Card>
  )
}

export const SelectMemberCard: React.FC<{
  apiKey?: string
  refetchSelectedPlan?: any
  setSelectedDependentsKey?: Dispatch<SetStateAction<SelectedDependentsKey>>
  disabled?: boolean
}> = ({
  apiKey,
  refetchSelectedPlan,
  setSelectedDependentsKey,
  disabled = false
}) => {
  const [isSelected, setIsSelected] = useState(true)

  const waiveCoverage = useMutation(() =>
    coverageService.selectPlan({ waived: true, dependents: [] }, apiKey || '')
  )

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsSelected(e.target.checked)
    // send request to backend to waive coverage for this benefit
    if (e.target.checked === false) {
      await waiveCoverage.mutateAsync()
      if (setSelectedDependentsKey) {
        setSelectedDependentsKey((prevState: SelectedDependentsKey) => {
          const newState = { ...prevState }
          for (const key in newState) {
            newState[key] = false
          }

          return newState
        })
      }

      refetchSelectedPlan()
    }
  }

  return (
    <Card
      className={`fs-14 p-2 my-2 ${
        isSelected ? 'border-primary' : 'border-gray'
      }`}
      style={{
        width: 200,
        height: 50
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '85%'
          }}
        >
          Me
        </div>
        <div>
          {' '}
          <input
            className="d-block"
            type="checkbox"
            checked={isSelected}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      </div>
    </Card>
  )
}
