import classNames from 'classnames'
import React from 'react'
import {
  Button,
  CenteredSpinner,
  Col,
  Form,
  Row,
  SectionTitle,
  SwitchRow,
  useMultiCustomCheck,
  useToaster,
  DatePicker
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { Paths } from '../../../../routes/paths'
import { useQuery } from 'react-query'
import { memberService } from '../../../../services'
import { Footer } from '../../style'
import { onboardingPaths } from '../../Router'
import { mapCurrentCarrierToForm, mapCurrentCarrierToServer } from './mappers'
import { typeOfCoverageList } from '@angle/utils'

import { useUpdateMember } from '../../hooks/useUpdateMember'

export const CurrentCarrier = () => {
  const { showError } = useToaster()
  const history = useHistory()

  const {
    control,
    unregister,
    watch,
    trigger,
    formState,
    getValues,
    setValue,
    handleSubmit,
    reset
  } = useForm({
    mode: 'onChange'
  })
  const { data, isLoading } = useQuery(
    'getCurrentCarrier',
    () => memberService.getMemberInfo(mapCurrentCarrierToForm),
    {
      onSuccess: (data: any) => {
        reset({ ...data })
      }
    }
  )

  const updateMember = useUpdateMember()

  const { defaultChecked, defaultValue, init, inputRef } = useMultiCustomCheck(
    typeOfCoverageList,
    getValues('typesOfCoverage') || []
  )

  const unregisterCarrierFields = (otherInsurance: boolean) => {
    if (!otherInsurance) {
      setValue('healthInsurance', false)
      unregister('insurer')
      unregister('policyNumber')
      unregister('dateOfCoverage.startAt')
      unregister('dateOfCoverage.endAt')
      unregister('typesOfCoverage')
    }
  }

  const healthInsurance = watch('healthInsurance')
  const medicareElegible = watch('medicareElegible')

  const handleContinue = handleSubmit(async (formData) => {
    try {
      const mappedData = mapCurrentCarrierToServer(
        formData,

        data?.serverStep || 0
      )

      await updateMember.mutateAsync(mappedData)
      history.push(Paths.onboarding + onboardingPaths.dependents)
    } catch (e: any) {
      showError(e?.message)
    }
  })

  const handleBack = () => {
    history.push(Paths.onboarding + onboardingPaths.contact)
  }

  const onCheckCoverage =
    (field: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = field.value || []

      if (e.target.checked) {
        const newValue = e.target.value
        field.onChange([...value, newValue])
      } else {
        field.onChange(value.filter((v: any) => v !== e.target.value))
      }
    }

  if (isLoading) {
    return <CenteredSpinner />
  }

  return (
    <div>
      <SwitchRow
        name="medicareElegible"
        label="Are you eligible for Medicare?"
        className="py-3"
        id="medicare-switch"
        control={control}
        selected={medicareElegible}
      />

      <hr />

      <SectionTitle>Current Carrier Information</SectionTitle>
      <SwitchRow
        name="healthInsurance"
        label="Do you expect to keep another health insurance plan/policy?"
        className="py-3"
        id="other-insurance-switch"
        control={control}
        selected={healthInsurance}
        sideEffect={unregisterCarrierFields}
      />
      {healthInsurance && (
        <>
          <Row>
            <Col xs="12" sm="6">
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': formState.errors?.insurer
                  })}
                >
                  Insurance Carrier
                </Form.Label>
                <Controller
                  name="insurer"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        trigger('coverageContinue')
                      }}
                      placeholder="Insurance Carrier"
                      className={`bg-white ${!field.value ? 'text-muted' : ''}`}
                      isInvalid={Boolean(formState.errors?.insurer)}
                    />
                  )}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': formState.errors?.policyNumber
                  })}
                >
                  Policy Number
                </Form.Label>
                <Controller
                  name="policyNumber"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        trigger('coverageContinue')
                      }}
                      placeholder="Policy number"
                      className="bg-white"
                      isInvalid={!!formState.errors?.policyNumber}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': formState.errors?.dateOfCoverage?.startAt
                  })}
                >
                  Date of Coverage Start Date
                </Form.Label>
                <Controller
                  name="dateOfCoverage.startAt"
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: {
                      notLaterEndDate: (value) => {
                        if (!formState.touchedFields?.dateOfCoverage?.endAt)
                          return true

                        const endAt = getValues('dateOfCoverage.endAt')

                        return (
                          new Date(endAt).getTime() > new Date(value).getTime()
                        )
                      }
                    }
                  }}
                  control={control}
                  render={({ field }) => <DatePicker {...field} />}
                />
              </Form.Group>
              {formState.errors?.dateOfCoverage?.startAt &&
                formState.errors?.dateOfCoverage?.startAt?.type ===
                  'notLaterEndDate' && (
                  <span className="text-danger mt-1">
                    Start date cannot be later than end date.
                  </span>
                )}
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label
                  className={classNames({
                    'text-danger': formState.errors?.dateOfCoverage?.endAt
                  })}
                >
                  Date of Coverage End Date
                </Form.Label>
                <Controller
                  name="dateOfCoverage.endAt"
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: {
                      notBeforeStartDate: (value) => {
                        if (!formState.touchedFields?.dateOfCoverage?.startAt)
                          return true

                        const startAt = getValues('dateOfCoverage.startAt')
                        return (
                          new Date(startAt).getTime() <
                          new Date(value).getTime()
                        )
                      }
                    }
                  }}
                  control={control}
                  render={({ field }) => <DatePicker {...field} />}
                />
              </Form.Group>
              {formState.errors?.dateOfCoverage?.endAt &&
                formState.errors?.dateOfCoverage?.endAt?.type ===
                  'notBeforeStartDate' && (
                  <span className="text-danger mt-1">
                    End date cannot be before start date.
                  </span>
                )}
            </Col>
          </Row>

          <Row className="my-2">
            <Col>
              <Form.Group>
                <Form.Label>Type of coverage (Check all that apply)</Form.Label>
                <Controller
                  name="typesOfCoverage"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    const { onCheckChanges, onInputBlur } = init(
                      field.value,
                      field.onChange
                    )

                    return (
                      <div>
                        {typeOfCoverageList.map((type, i) => (
                          <Form.Check
                            key={i}
                            {...field}
                            onChange={onCheckCoverage(field)}
                            className="my-2"
                            id={`coverage-${type.value}`}
                            value={type.value}
                            label={type.text}
                          />
                        ))}

                        <Form.Check
                          {...field}
                          className="my-2 d-inline"
                          id={'coverage-custom'}
                          onChange={onCheckChanges}
                          defaultChecked={defaultChecked}
                        />
                        <Form.Control
                          style={{ minWidth: 150, width: '27%' }}
                          size="sm"
                          className="ml-2 d-inline bg-white"
                          // disabled={!isCustomChecked}
                          placeholder="Other"
                          ref={inputRef}
                          onBlur={onInputBlur}
                          defaultValue={defaultValue}
                        />
                      </div>
                    )
                  }}
                />
                {formState.errors?.typesOfCoverage && (
                  <span className="text-danger">
                    Type of coverage is a required field
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </Footer>
    </div>
  )
}
