import { ApiService } from 'react-angle-dashboard-components'
import { getBenefitCoverage } from './utils/getBenefitCoverage'
import { mapDTOtoPlans } from './mappers'

// maybe combine this with plan service later

export class CoverageService extends ApiService {
  // gets first coverage that is not null/empty with prority: current, future, past
  // my plan page
  async getDisplayPlan(benefitKey: string) {
    return this.request({
      method: 'get',
      url: `/${benefitKey}/coverage`
    }).then((r: any) => {
      const coverage = getBenefitCoverage(r?.data)
      return coverage || new Error('Coverage not found')
    })
  }

  async getMedicalPlanV2() {
    return this.request({
      method: 'get',
      url: `/v2/plans`,
      credentials: true
    }).then((r: any) => mapDTOtoPlans(r))
  }

  // onboarding
  async getAvailablePlans(benefitKey: string) {
    return this.request({
      method: 'get',
      url: `/${benefitKey}/available-plans`
    }).then((r: any) => {
      return r.data
    })
  }

  // onboarding - premiums
  async getAvailablePlansPremiums(
    benefitKey: string,
    dependentIds: string[],
    coverageAmount?: number
  ) {
    const data: { dependents: string[]; coverage_amount?: number } = {
      dependents: dependentIds
    }
    // coverage amount only applies to critical illness
    if (coverageAmount) {
      data.coverage_amount = coverageAmount
    }
    return this.request({
      method: 'post',
      url: `/${benefitKey}/available-plans/premiums`,
      data
    }).then((r: any) => r.data)
  }

  // onboarding - select plan
  async selectPlan(data: any, benefitKey: string) {
    return this.request({
      method: 'post',
      url: `/${benefitKey}/coverage`,
      data
    })
  }

  async waiveCoverage(data: any) {
    return this.request({
      method: 'post',
      url: `/medical/coverage`,
      data
    })
  }

  // onbaording - summary
  async getSelectedPlan(benefitKey: string) {
    return this.request({
      method: 'get',
      url: `/${benefitKey}/coverage`
    }).then((r: any) => r.data?.selected_coverage)
  }
}
