import React from 'react'
import {
  About,
  Col,
  FormControl,
  Row,
  useDrugProfile
} from 'react-angle-dashboard-components'
import { useLang } from '../../hooks'
import { AboutCol, LayoutContainer } from './style'
import { ReactComponent as SpecialtyDrug } from '../../assets/specialty-drug.svg'
import { ReactComponent as QuantityLimit } from '../../assets/quantity-limit.svg'
import { ReactComponent as PriorAuthorization } from '../../assets/prior-authorization.svg'
import styled from 'styled-components'

const GroupBox = styled.div`
  @media (max-width: 769px) {
    & {
      margin-left: 12px;
    }
  }
  @media (max-width: 580px) {
    & {
      margin-left: 8px;
    }
  }
  @media (max-width: 470px) {
    & {
      margin-left: 5px;
    }
  }
`

export const AboutSection = () => {
  const {
    specialty_drug,
    pa_applies,
    tier,
    selectByForm,
    dosages,
    is_covered,
    quantity_limit,
    age_limit,
    gender
  } = useDrugProfile()
  const {
    messages: { drugsProfile }
  } = useLang()

  return (
    <section className="pt-5 pb-2">
      {is_covered && (
        <LayoutContainer>
          <h5>{drugsProfile.about.title}</h5>
          <Row className="my-3">
            <Col md="12">
              <Row>
                <Col md={12} className="mb-5">
                  <FormControl
                    as="select"
                    className="text-capitalize"
                    onChange={(e) => selectByForm(e.target.value)}
                    disabled={
                      dosages.length === 1 && !dosages[0].label_form_dosage
                    }
                  >
                    {dosages.map((d, i) => (
                      <option key={i} value={i}>
                        {d.label_form_dosage?.toUpperCase() || '-'}
                      </option>
                    ))}
                  </FormControl>
                </Col>
                {/*         
              <AboutCol md={6}>
                <About
                  smallText="Duration of fill"
                  icon="icon-calendar-time"
                  helpButtonTooltip="Duration of fill"
                >
                  {fillDuration || '-'}
                </About>
              </AboutCol> 
              <AboutCol md={6}>
                <About smallText="Form" icon="icon-pill">
                  {currentForm || '-'}
                </About>
              </AboutCol> 
              */}
                <AboutCol md={6}>
                  <About smallText="Drug Tier" icon="icon-drugs">
                    <span className="text-capitalize">
                      {tier?.toLocaleLowerCase() || '-'}
                    </span>
                  </About>
                </AboutCol>
                <AboutCol md={6} style={{ marginTop: '5px' }}>
                  <GroupBox>
                    <PriorAuthorization
                      style={{ marginLeft: '-10px', marginTop: '5px' }}
                    />
                  </GroupBox>
                  <About
                    smallText="Prior Authorization Required"
                    helpButtonTooltip="This medication may require your doctor to obtain approval from us before it will cover the costs of a specific medicine."
                    icon=""
                    style={{ marginLeft: '-15px', marginTop: '-30px' }}
                  >
                    <span className="text-capitalize">
                      {pa_applies ? 'Yes' : 'No'}
                    </span>
                  </About>
                </AboutCol>
                <AboutCol md={6} style={{ marginTop: '5px' }}>
                  <GroupBox>
                    <SpecialtyDrug
                      style={{ marginLeft: '-10px', marginTop: '5px' }}
                    />
                  </GroupBox>
                  <About
                    smallText="Specialty Drug"
                    icon=""
                    style={{ marginLeft: '-15px', marginTop: '-30px' }}
                  >
                    <span className="text-capitalize">
                      {specialty_drug ? 'Yes' : 'No'}
                    </span>
                  </About>
                </AboutCol>
                {age_limit && (
                  <AboutCol md={6}>
                    <About
                      smallText="Age Limit"
                      helpButtonTooltip="The prescription drug may only be covered if you meet the minimum or maximum age limit."
                      icon="icon-calendar"
                      iconStyle={{
                        fontSize: '24px'
                      }}
                    >
                      <span className="text-capitalize">{age_limit}</span>
                    </About>
                  </AboutCol>
                )}
                {quantity_limit && (
                  <AboutCol md={6} style={{ marginTop: '5px' }}>
                    <GroupBox>
                      <QuantityLimit
                        style={{ marginLeft: '-13px', marginTop: '7px' }}
                      />
                    </GroupBox>
                    <About
                      smallText="Quantity"
                      icon=""
                      style={{ marginLeft: '-15px', marginTop: '-30px' }}
                      iconStyle={{
                        fontSize: '24px'
                      }}
                    >
                      <span className="text-capitalize">{quantity_limit}</span>
                    </About>
                  </AboutCol>
                )}
                {gender && (
                  <AboutCol md={6}>
                    <About
                      smallText="Gender"
                      helpButtonTooltip="This prescription drug is restricted for a single gender."
                      icon="icon-user"
                      iconStyle={{
                        fontSize: '24px'
                      }}
                    >
                      <span className="text-capitalize">{gender}</span>
                    </About>
                  </AboutCol>
                )}
              </Row>
            </Col>
          </Row>
        </LayoutContainer>
      )}
    </section>
  )
}

export default AboutSection
