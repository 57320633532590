import { useQuery } from 'react-query'
import { coverageService } from '../../../../services'

export const useGetSelectedBenefits = () => {
  const { data: medicalData, isLoading: isLoadingMedical } = useQuery(
    'getSelectedMedical',
    () => coverageService.getSelectedPlan('medical')
  )

  const { data: dentalData, isLoading: isLoadingDental } = useQuery(
    'getSelectedDental',
    () => coverageService.getSelectedPlan('dental')
  )

  const { data: criticalData, isLoading: isLoadingCritical } = useQuery(
    'getSelectedCritical',
    () => coverageService.getSelectedPlan('critical-illness')
  )

  const { data: accidentalData, isLoading: isLoadingAccidental } = useQuery(
    'getSelectedAccidental',
    () => coverageService.getSelectedPlan('accidental')
  )

  const { data: basicLifeData, isLoading: isLoadingBasicLife } = useQuery(
    'getSelectedBasicLife',
    () => coverageService.getSelectedPlan('basic-life-add')
  )

  const { data: hospitalData, isLoading: isLoadingHospital } = useQuery(
    'getSelectedHospital',
    () => coverageService.getSelectedPlan('hospital-indemnity')
  )

  const { data: identityData, isLoading: isLoadingIdentity } = useQuery(
    'getSelectedIdentity',
    () => coverageService.getSelectedPlan('identity')
  )

  const { data: visionData, isLoading: isLoadingVision } = useQuery(
    'getSelectedVision',
    () => coverageService.getSelectedPlan('vision')
  )

  const isLoading =
    isLoadingMedical ||
    isLoadingDental ||
    isLoadingCritical ||
    isLoadingAccidental ||
    isLoadingBasicLife ||
    isLoadingHospital ||
    isLoadingVision ||
    isLoadingBasicLife ||
    isLoadingIdentity

  const selectedBenefits = [
    {
      key: 'medical',
      selectedPlanName: medicalData?.plan_name,
      members: medicalData?.members || []
    },
    {
      key: 'dental',
      selectedPlanName: dentalData?.family_coverage?.plan?.plan_name,
      members: dentalData?.family_coverage?.members || [],
      carrierName: dentalData?.family_coverage?.plan?.carrier?.name
    },
    {
      key: 'vision',
      selectedPlanName: visionData?.family_coverage?.plan?.plan_name,
      members: visionData?.family_coverage?.members || [],
      carrierName: visionData?.family_coverage?.plan?.carrier?.name
    },
    {
      ...criticalData,
      key: 'critical_illness',
      selectedPlanName: criticalData?.family_coverage?.plan?.plan_name,
      members: criticalData?.family_coverage?.members || [],
      carrierName: criticalData?.family_coverage?.plan?.carrier?.name
      // TODO add amount
    },
    {
      ...accidentalData,
      key: 'accidental',
      selectedPlanName: accidentalData?.family_coverage?.plan?.plan_name,
      members: accidentalData?.family_coverage?.members || [],
      carrierName: accidentalData?.family_coverage?.plan?.carrier?.name
    },
    {
      ...basicLifeData,
      key: 'basic_life_add',
      selectedPlanName: basicLifeData?.family_coverage?.plan?.plan_name,
      members: basicLifeData?.family_coverage?.members || [],
      carrierName: basicLifeData?.family_coverage?.plan?.carrier?.name
    },
    {
      ...hospitalData,
      key: 'hospital_indemnity',
      selectedPlanName: hospitalData?.family_coverage?.plan?.plan_name,
      members: hospitalData?.family_coverage?.members || [],
      carrierName: hospitalData?.family_coverage?.plan?.carrier?.name
    },
    {
      ...identityData,
      key: 'identity',
      selectedPlanName: identityData?.family_coverage?.plan?.plan_name,
      members: identityData?.family_coverage?.members || [],
      carrierName: identityData?.family_coverage?.plan?.carrier?.name
    }
  ]
  return { selectedBenefits, isLoading }
}
