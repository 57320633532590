import { ApiService } from 'react-angle-dashboard-components'
import { ICareDoctor, ICareFacility } from '../../interfaces/care'
export class CareService extends ApiService {
  async findCareDoctors(
    name_searchterm: string,
    page_limit: number,
    page_number: number
  ): Promise<{ doctors: ICareDoctor[]; total: number }> {
    return this.request({
      method: 'post',
      url: '/providers/namefilter',
      data: {
        name_searchterm,
        page_limit,
        page_number
      }
    }).then((r) => ({ doctors: r.providers, total: r.total_count }))
  }

  async findCareFacilities(
    name_searchterm: string,
    page_limit: number,
    page_number: number
  ): Promise<{ facilities: ICareFacility[]; total: number }> {
    return this.request({
      method: 'post',
      url: '/facilities/namefilter',
      data: {
        name_searchterm,
        page_limit,
        page_number
      }
    }).then((r) => ({ facilities: r.providers, total: r.total_count }))
  }
}
