import React, { useState } from 'react'
import {
  Button,
  CenteredSpinner,
  ErrorFallback,
  useToaster
} from 'react-angle-dashboard-components'
import { useQuery, useMutation } from 'react-query'
import { useDependents } from '../../hooks/useDependents'
import { Paths } from '../../../../routes/paths'
import { coverageService, memberService } from '../../../../services'
import { useHistory } from 'react-router-dom'
import { Footer } from '../../style'
import { WaiveCoverageSuccess } from '../../WaiveCoverageSuccess'
import { onboardingPaths } from '../../Router'
import { SelectDependentForPlan } from '../../../../components/SelectDependentForPlan/SelectDependentForPlan'
import { useGetAvailablePlans } from '../../hooks/useGetAvailablePlans'
import { memberDataMapper } from '../../utils/memberDataMapper'
import { SBCModal } from './components/SBCModal'
import { mapDependentsToSelectedArray } from '../Voluntary/BenefitDetails/hooks/useSelectVoluntaryPlan'
import { useSbc } from './useSbc'
import { PlanContent } from './components/PlanContent'
import { useUpdateMember } from '../../hooks/useUpdateMember'

export const Plan = () => {
  const history = useHistory()
  const { showError } = useToaster()

  // Get plans
  const benefitKey = 'medical'
  const { availablePlans, isLoadingPlans, plansError } =
    useGetAvailablePlans(benefitKey)

  const { data: selectedPlan } = useQuery('getSelectedMedicalPlan', () =>
    coverageService.getSelectedPlan(benefitKey)
  )

  // SBC
  const {
    currentSBCData,
    showSbcModal,
    setShowSbcModal,
    isLoadingSbc,
    openSBCPDF
  } = useSbc()

  // Waive coverage
  const [showWaiveSuccess, setShowWaiveSuccess] = useState(false)

  // Get / select dependents
  const {
    dependents,
    isLoadingDependents,
    dependentsError,
    selectedDependentsKey,
    setSelectedDependentsKey
  } = useDependents(selectedPlan?.members)

  // Get premiums
  const { data: premiums } = useQuery(
    ['getAvailablePlanPremiums', selectedDependentsKey],
    () =>
      coverageService.getAvailablePlansPremiums(
        benefitKey,
        mapDependentsToSelectedArray(selectedDependentsKey)
      )
  )

  // Get member data

  const { data: memberData } = useQuery('getOnboardingGeneral', () =>
    memberService.getMemberInfo(memberDataMapper)
  )

  // const updateMember = useMutation((data: any) =>
  //   memberService.updateMemberInfo(data)
  // )

  const updateMember = useUpdateMember()

  const selectPlan = useMutation((data: any) =>
    coverageService.selectPlan(data, benefitKey)
  )

  const onClickSelectPlan = async (planId: string) => {
    const selectedDependentIds = mapDependentsToSelectedArray(
      selectedDependentsKey
    )

    try {
      const data = {
        waived: false,
        plan_id: planId,
        dependents: selectedDependentIds
      }

      await selectPlan.mutateAsync(data)

      const memberPatchData = {
        in_progress_state: memberData?.inRenewal ? 5 : 6
      }
      await updateMember.mutateAsync(memberPatchData)

      const onboardingPath = memberData?.showVoluntary
        ? onboardingPaths.voluntary
        : onboardingPaths.review

      history.push(Paths.onboarding + onboardingPath)
    } catch (e: any) {
      showError(e?.message)
    }
  }

  const handleBack = () => {
    history.push(Paths.onboarding + onboardingPaths.dependents)
  }

  const showDependents =
    dependents && dependents?.length && !isLoadingDependents && !dependentsError

  if (isLoadingPlans || isLoadingDependents) {
    return <CenteredSpinner />
  } else if (plansError || availablePlans?.length === 0) {
    return (
      <ErrorFallback className="mb-3">
        Something went wrong obtaining your available plans. Please reach out to
        our customer service team to resolve this issue.
      </ErrorFallback>
    )
  }

  return (
    <div>
      <SBCModal
        show={showSbcModal}
        setShow={setShowSbcModal}
        isLoading={isLoadingSbc}
        sbcData={currentSBCData}
      />
      <WaiveCoverageSuccess
        show={showWaiveSuccess}
        goBackToPlans={() => {
          setShowWaiveSuccess(false)
        }}
      />
      {!!showDependents && (
        <SelectDependentForPlan
          dependents={dependents}
          selectedDependentsKey={selectedDependentsKey}
          setSelectedDependentsKey={setSelectedDependentsKey}
          apiKey="medical"
        />
      )}
      <PlanContent
        availablePlans={availablePlans}
        coverageEffectiveDate={memberData?.coverageEffectiveDate}
        premiums={premiums || []}
        openSBCModal={openSBCPDF}
        onClickSelectPlan={onClickSelectPlan}
      />
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </Footer>
    </div>
  )
}
