import React from 'react'
import { Container, Nav, Tab } from 'react-angle-dashboard-components'
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  Switch
} from 'react-router-dom'
import { useLang } from '../../hooks/use-language'
import { HomeLayout } from '../layouts/HomeLayout'
import { DocumentsTab, FormsTab } from '.'

export const DocumentsAndForms: React.FC = () => {
  const { messages } = useLang()
  const texts = messages.documentsAndForms

  const history = useHistory()
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const key = pathname.slice(`${path}/`.length)

  const onSelect = (k: any) => {
    history.push(`${path}/${k}`)
  }
  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: texts.title, link: '/documents' }
      ]}
    >
      <Tab.Container
        id="document-tabs-container"
        activeKey={key}
        onSelect={onSelect}
      >
        <section className="bg-light">
          <Container className="mt-5 pt-3 px-5 mx-auto">
            <h1>{texts.title}</h1>
            <Nav variant="tabs" className="mt-5">
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="">{texts.tabs.documents}</Nav.Link>
              </Nav.Item>
              <Nav.Item className="mr-3">
                <Nav.Link eventKey="forms">{texts.tabs.forms}</Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
        </section>
        <Container className="mt-5 mx-auto px-5 pb-5">
          <Switch>
            <Route exact path={`${path}/`} component={DocumentsTab} />
            <Route exact path={`${path}/forms`} component={FormsTab} />
          </Switch>
        </Container>
      </Tab.Container>
    </HomeLayout>
  )
}

export default DocumentsAndForms
