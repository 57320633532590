export const SpouseIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="d-inline mr-1"
    >
      <path
        d="M10 2C9.20435 2 8.44129 2.31607 7.87868 2.87868C7.31607 3.44129 7 4.20435 7 5C7 5.79565 7.31607 6.55871 7.87868 7.12132C8.44129 7.68393 9.20435 8 10 8C10.7956 8 11.5587 7.68393 12.1213 7.12132C12.6839 6.55871 13 5.79565 13 5C13 4.20435 12.6839 3.44129 12.1213 2.87868C11.5587 2.31607 10.7956 2 10 2ZM18 2C17.2044 2 16.4413 2.31607 15.8787 2.87868C15.3161 3.44129 15 4.20435 15 5C15 5.79565 15.3161 6.55871 15.8787 7.12132C16.4413 7.68393 17.2044 8 18 8C18.7956 8 19.5587 7.68393 20.1213 7.12132C20.6839 6.55871 21 5.79565 21 5C21 4.20435 20.6839 3.44129 20.1213 2.87868C19.5587 2.31607 18.7956 2 18 2ZM21.002 6.00195C21.002 6.00195 20.925 7.42105 21.752 8.24805C22.579 9.07505 23.998 8.99805 23.998 8.99805C23.998 8.99805 24.075 7.57895 23.248 6.75195C22.421 5.92495 21.002 6.00195 21.002 6.00195ZM9 10C7.346 10 6 11.346 6 13V19C6 19.553 6.448 20 7 20V25.9668C7 26.5368 7.4632 27 8.0332 27C8.5772 27 9.02845 26.5782 9.06445 26.0352L9.50195 20.4648C9.51995 20.2038 9.737 20 10 20C10.263 20 10.48 20.2048 10.498 20.4668L10.9355 26.0352C10.9647 26.474 11.2715 26.8221 11.6738 26.9434C11.7787 26.9802 11.8889 26.9994 12 27H24C24.2652 27 24.5195 26.8946 24.7071 26.7071C24.8946 26.5195 25 26.2652 25 26C25 19.2526 21.692 15.4926 21.0762 14.8223L21.2422 14.1523C21.7644 12.0664 20.1504 10 18 10C16.8296 10 15.8329 10.6213 15.2383 11.5156C15.1881 11.5629 15.1429 11.6153 15.1035 11.6719L14 13.2266V13C14 11.346 12.654 10 11 10H9ZM18 12C18.8976 12 19.5205 12.7979 19.3027 13.668H19.3008L19.0293 14.7578C18.9876 14.9253 18.9899 15.1008 19.0361 15.2671C19.0824 15.4335 19.1708 15.585 19.293 15.707C19.293 15.707 22.4945 19.0748 22.8691 25H13.1211C13.2878 21.7954 14.0455 19.4374 14.8691 17.9961C15.7877 16.3886 16.5996 15.8008 16.5996 15.8008C16.7573 15.6828 16.8766 15.5209 16.9426 15.3353C17.0086 15.1498 17.0184 14.9489 16.9707 14.7578L16.6992 13.668H16.6973C16.6169 13.3469 16.6532 13.0364 16.7734 12.7715C16.7905 12.7436 16.8061 12.7149 16.8203 12.6855C17.0427 12.2801 17.4712 12 18 12Z"
        className="fill-muted"
      />
    </svg>
  )
}
