import { Form } from 'react-angle-dashboard-components'
import styled from 'styled-components'

export const Header = styled.h6`
  font-weight: 700;
  margin-bottom: 20px;
`

export const Label = styled.h6`
  font-weight: 500;
`

export const MutedLabel = styled(Form.Label)`
  font-family: 'SF-Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height, or 150% */

  /* Text/02. Muted */
  color: #6c757d;
`
