import React from 'react'
import { Container, IDCardContainer } from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { PageHeaderLight } from '../../components/PageHeaderLight/PageHeaderLight'
import { memberService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'

export const MemberIDCard = () => {
  const { id } = useParams<{ id: string }>()
  const location = useLocation()

  const isDependent = location.pathname.includes('dependent')

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['memberInfo', id, isDependent],
    () => {
      return id && isDependent
        ? memberService.getDependentById(id)
        : memberService.getMemberInfo()
    }
  )

  const title =
    (isDependent && !isLoading && data ? data?.name.first_name + "'s" : '') +
    '  Member ID Card'

  return (
    <HomeLayout
      breadcrumbs={[
        { title: 'Home', link: '/' },
        { title: 'My Plan', link: '/my-plan' },
        { title: title, link: location.pathname }
      ]}
    >
      <PageHeaderLight style={{ textAlign: 'center' }}>
        <Container>
          <h1>{title}</h1>
        </Container>
      </PageHeaderLight>
      <IDCardContainer
        data={data}
        isLoading={isLoading}
        employeeService={memberService}
        isAdmin={false}
        refetchMemberData={refetch}
        isRefetching={isRefetching}
      />
    </HomeLayout>
  )
}

export default MemberIDCard
