import { ApiService } from 'react-angle-dashboard-components'

export interface MemberLookupData {
  date_of_birth: string
  member_id?: string
  ssn?: string
  first_name?: string
  last_name?: string
}

export class TempAuthService extends ApiService {
  async tempAuthNoPW(code: string) {
    return this.request({
      method: 'post',
      url: '/temp-auth/no-pw',
      data: {
        code
      }
    })
  }

  async memberLookup(data: MemberLookupData) {
    return this.request({
      method: 'post',
      url: '/activate/search',
      data
    }).then((data: any) => {
      if (Array.isArray(data?.data)) {
        return data
      } else throw new Error('Unexpected response type')
    })
  }

  async confirmMemberLookup(data: MemberLookupData) {
    return this.request({
      method: 'post',
      url: '/activate/confirm',
      data
    }).then((data: any) => data)
  }
}
