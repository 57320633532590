import React from 'react'
import { Card } from 'react-angle-dashboard-components'
import { ReactComponent as MetlifeLogo } from '../../../../../../../assets/metlife.svg'
import { MoreDetailsFooter } from '../../components/MoreDetailsFooter'
import { PlanCardPremium } from '../../components/PlanCardPremium'
import { BenefitData, BenefitLabel } from '../../style'
import { hospitalPlans } from './hospitalPlans'
import { SelectPlanButton } from '../../../../../components/SelectPlanButton'

export const HospitalCard: React.FC<{
  plan?: any
  onClickSelectPlan?: any
  premium?: number
  isSelected: boolean
}> = ({ plan, onClickSelectPlan, premium, isSelected }) => {
  const planDetails =
    plan.plan_id === 'MLHI0002' ? hospitalPlans.highPlan : hospitalPlans.lowPlan
  return (
    <Card style={{ width: 380 }} className="mr-4 mb-3">
      <Card.Header className="py-4">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <h5>{plan.plan_name}</h5>
            <div className="ml-2">
              <MetlifeLogo />
            </div>
          </div>

          <PlanCardPremium premium={premium} />
          <SelectPlanButton
            isSelected={isSelected}
            onClick={() => onClickSelectPlan(plan.plan_id)}
          />
        </div>
      </Card.Header>
      <div className="border-bottom bg-light">
        <table className="fs-14 w-100 ">
          <thead style={{ background: '#E9ECEF' }} className="text-secondary">
            <tr>
              <th className="font-weight-bold" style={{ padding: '12px 15px' }}>
                Plan Coverage
              </th>
              <th className="px-3 font-weight-bold">Benefit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <BenefitLabel className="pt-4">Admission</BenefitLabel>
              <BenefitData className="pt-4">
                {planDetails.admission_benefit}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>ICU Admission</BenefitLabel>
              <BenefitData>
                {planDetails.icu_admission_supplemental}
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>ICU Confinement</BenefitLabel>
              <BenefitData>
                <span className="font-weight-bold">
                  ${planDetails.confinement_benefit}
                </span>
                <span>/day</span>
              </BenefitData>
            </tr>
            <tr>
              <BenefitLabel>Health Screen</BenefitLabel>
              <BenefitData>${planDetails.health_screening_benefit}</BenefitData>
            </tr>
          </tbody>
        </table>
      </div>
      <MoreDetailsFooter link={plan.benefit_document_url} />
    </Card>
  )
}
