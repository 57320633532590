import * as React from 'react'
import { SVGProps } from 'react'

const HeartSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 12"
    fill="currentColor"
    {...props}
  >
    <g id="Heart">
      <path
        id="Vector"
        d="M3.54517 0C1.58704 0 0 1.58704 0 3.54517C0 6.95355 3.95361 10.2103 5.8007 11.6606C5.80246 11.6621 5.80422 11.6635 5.80599 11.6649C5.81865 11.6748 5.83585 11.689 5.84831 11.6987L5.84937 11.6977C6.03677 11.8393 6.26512 11.9161 6.5 11.9167C6.73525 11.9164 6.964 11.8395 7.15169 11.6977V11.6987C7.15534 11.6959 7.16073 11.6921 7.16439 11.6892C7.16717 11.687 7.17111 11.684 7.17391 11.6818C7.17746 11.6787 7.18098 11.6755 7.18449 11.6723C9.02585 10.2272 13 6.96168 13 3.54517C13 1.58704 11.413 0 9.45483 0C7.58338 0 6.5 1.625 6.5 1.625C6.5 1.625 5.41662 0 3.54517 0Z"
      />
    </g>
  </svg>
)

export default HeartSolid
