export const mapMemberDataToVoluntaryOnboarding = (data: any) => {
  const availablePlanKeys = []
  let showVoluntary = false
  for (const key in data.plan_type_availabilities) {
    if (data.plan_type_availabilities[key] === true && key !== 'medical') {
      showVoluntary = true
      availablePlanKeys.push(key)
    }
  }

  return { ...data, showVoluntary, availablePlanKeys }
}
