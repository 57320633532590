import React, { useContext, useState, useEffect } from 'react'
import {
  AngleNavbar,
  Avatar,
  MemberPortalLayout,
  SearchBar,
  UserDropdown,
  useWindowSize
} from 'react-angle-dashboard-components'
import { useHistory, useLocation } from 'react-router-dom'
import { MobileDownload } from '../../../components/MobileDownload'
import { AppStatus, AuthContext } from '../../../context/Auth/Auth'
import { Paths } from '../../../routes/paths'
import { useQuery } from 'react-query'
import { memberService } from '../../../services'
import { IAlertMessage } from 'react-angle-dashboard-components/dist/layouts/MemberPortalLayout'
import { getCigna } from '../../../utils/cigna'
import { getDirectoryUrlByIdCardType } from '../../../utils/directoryUrls'

interface IHomeLayout {
  breadcrumbs?: {
    title: string
    link: string
  }[]
  noSearchBar?: boolean
  noBreadcrumbs?: boolean
  noMenu?: boolean
  showAlert?: IAlertMessage
}

const SearchBarComponent = ({ toggleBar, barIsOpen, showingMenu }: any) => {
  const [searchTerm, setSearchTerm] = useState('')

  const isSearching = !!searchTerm.length

  return (
    <SearchBar
      {...{
        toggleBar,
        barIsOpen,
        showingMenu,
        isSearching,
        onChange: (e: any) => setSearchTerm(e.target.value),
        largeClassName: 'transparent-3 border-0 text-white',
        iconClassName: 'text-white',
        inlineClassName: 'w-50',
        inlineStyle: {
          maxWidth: 500
        }
      }}
    />
  )
}

export const HomeLayout: React.FC<IHomeLayout> = ({
  children,
  breadcrumbs = [{ title: 'Home', link: '/' }],
  noSearchBar = true,
  noBreadcrumbs,
  noMenu,
  showAlert
}) => {
  const history = useHistory()
  const location = useLocation()
  const windowSize = useWindowSize();
  const { data } = useQuery('getMember', async () => {
    return memberService.getMemberInfo()
  })

  const renewal = data?.in_renewal

  const { hidePlanInfo } = getCigna(data)

  const cardType = data?.id_card_type || ""

  const { userData: user, appStatus, logout } = useContext(AuthContext)

  const formularyIndicator = data?.formulary_indicator

  const hideNetworkResources =
    formularyIndicator === 'cap_rx' ||
    formularyIndicator === 'smith_rx' ||
    formularyIndicator === 'angle_rx'

  let showMobileDownloadSessionSetting =
    sessionStorage.getItem('showMobileDownload')
  const [showMobileDownload, setShowMobileDownload] = useState(false);

  useEffect(() => {
    const isOnPhone = windowSize.width <= 768;
    const isEnabledInSessionSettings = showMobileDownloadSessionSetting === 'false' ? false : true
    if (isOnPhone) {
      setShowMobileDownload(false);
    }
    else {
      setShowMobileDownload(isEnabledInSessionSettings);
    }
  }, [windowSize, showMobileDownloadSessionSetting])

  let initials
  if (user) {
    initials =
      user?.first_name[0].toUpperCase() + user?.last_name[0].toUpperCase()
  }

  const avatarImg = (
    <Avatar
      style={{ top: '2px' }}
      circle
      border="lg"
      size="sm"
      fallbackContent={initials}
      customBg="#28347E"
    />
  )

  const userDropdownItems = [
    {
      title: 'Sign Out',
      icon: 'sign-out',
      callback: async () => {
        await logout()
      },
      iconClass: 'text-danger'
    }
  ]

  if (appStatus === AppStatus.full) {
    userDropdownItems.unshift({
      title: 'Settings',
      icon: 'settings',
      callback: async () => history.push(Paths.settings),
      iconClass: ''
    })
  }

  const avatar = (
    <UserDropdown
      avatar={avatarImg}
      items={userDropdownItems}
      userName={user?.first_name + ' ' + user?.last_name}
      email={user?.username || ''}
    />
  )

  const accessPlusIsActive = data?.aca_status === 'active' // on aca plan

  let planItems = [
    {
      title: 'Claims',
      subtitle: 'List of all your claims',
      icon: 'authorizations',
      onClick: () => history.push(Paths.claims)
    },
    {
      title: 'Shoppable Services',
      subtitle: 'Shop prices before you get care',
      icon: 'money',
      onClick: () => history.push(Paths.comparePrices)
    },
    {
      title: 'Documents & Forms',
      subtitle: 'Tax & plan documents, HIPAA authorization form...',
      icon: 'documents',
      onClick: () => history.push(Paths.documentsAndForms)
    }
  ]

  if (!hideNetworkResources) {
    planItems.push({
      title: 'Network Resources',
      subtitle: 'Hospitals & Pharmacy Available',
      icon: 'authorizations',
      onClick: () => history.push(Paths.networkResources)
    })
  }

  if (!hidePlanInfo) {
    planItems.unshift({
      title: 'Plan & Benefits',
      subtitle: 'Your plan summary and benefits list',
      icon: 'dotted-list',
      onClick: () => history.push(Paths.myPlan)
    })
  }

  const redirectToFindCare = () => {
    const url = getDirectoryUrlByIdCardType(cardType)
    window.open(url, "_blank")
  }

  let nav = (
    <AngleNavbar
      variant="light"
      items={
        noMenu && !renewal
          ? [
              {
                title: 'Go to AngleHealth.com',
                onClick: () =>
                  window.open('https://www.anglehealth.com', '_blank')
              }
            ]
          : [
              {
                title: 'Directories',
                onClick: redirectToFindCare
              },
              {
                title: 'Pharmacy',
                onClick: () => history.push(Paths.drugs),
                active: location.pathname.startsWith(Paths.drugs)
              },
              {
                title: 'Care',
                onClick: () => history.push(Paths.care),
                active:
                  location.pathname.startsWith(Paths.care) ||
                  location.pathname.startsWith(Paths.healthProfile) ||
                  location.pathname.startsWith(Paths.providers),
                items: [
                  {
                    title: 'Find Care',
                    subtitle: 'Find care near you',
                    icon: 'search',
                    onClick: redirectToFindCare
                  },
                  {
                    title: 'Virtual Care',
                    subtitle: 'Doctor on Demand Virtual Care',
                    icon: 'chat',
                    onClick: () => history.push(Paths.virtualCare)
                  }
                ]
              },
              {
                title: 'My Plan',
                active:
                  location.pathname.includes(Paths.myPlan) ||
                  location.pathname.includes(Paths.claims),
                items: planItems
              }
            ]
      }
      barComponent={!noSearchBar ? SearchBarComponent : undefined}
      avatar={user ? avatar : <div />}
    />
  )

  if (accessPlusIsActive) {
    nav = (
      <AngleNavbar
        variant="light"
        items={[
          {
            title: 'Claims',
            onClick: () => history.push(Paths.claims)
          }
        ]}
        avatar={user ? avatar : <div />}
      />
    )
  }

  return (
    <MemberPortalLayout
      nav={nav}
      breadcrumbs={breadcrumbs}
      noBreadcrumbs={noBreadcrumbs}
      alert={showAlert}
    >
      {children}
      {appStatus === AppStatus.full && showMobileDownload && (
        <MobileDownload
          show={showMobileDownload}
          setShow={setShowMobileDownload}
        />
      )}
    </MemberPortalLayout>
  )
}

export default HomeLayout
