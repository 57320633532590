import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  IMemberInfo,
  Nav,
  Row,
  Spinner,
  Table
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { coverageService, memberService } from '../../../../services'
import { DateHandler } from '@angle/utils'
import { Footer } from '../../style'
import { useHistory } from 'react-router-dom'
import { Paths } from '../../../../routes/paths'
import { onboardingPaths } from '../../Router'
import { useUpdateMember } from '../../hooks/useUpdateMember'

interface TableRow {
  id: string
  name: IMemberInfo['name']
  plan: string
  ssn: string
  dob: string
  relationship: string
}

const getFullName = (name: IMemberInfo['name'] | null) => {
  if (!name) {
    return ''
  }

  return `${name.first_name} ${
    name.middle_name ? `${' '}${name.middle_name}` : ''
  } ${name.last_name}`
}

const ssnFormatter = (ssn: string | undefined) => {
  if (!ssn) {
    return ''
  }

  return `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5, 10)}`
}

const Review = () => {
  const history = useHistory()

  const updateMember = useUpdateMember()

  const { data: memberData, isLoading: memberLoading } = useQuery(
    'rawMemberInfo',
    () => memberService.getMemberInfo(),
    {
      staleTime: 0
    }
  )

  const { data: dependents, isLoading: depLoading } = useQuery(
    'rawDependents',
    () => memberService.getDependents((res: any) => res.dependents),
    {
      staleTime: 0
    }
  )

  const benefitKey = 'medical'
  const { data: selectedPlan, isLoading: planLoading } = useQuery(
    'getSelectedMedicalPlan',
    () => coverageService.getSelectedPlan(benefitKey),
    {
      staleTime: 0
    }
  )

  const [activeTabKey, setActiveTabKey] = useState<
    'all' | 'covered' | 'non-covered'
  >('all')

  const handleContinue = async () => {
    const inRenewal = memberData?.inRenewal
    const voluntary = memberData?.showVoluntary

    let nextStep = 7

    if (voluntary) {
      nextStep = 8
    } else if (inRenewal) {
      nextStep = 6
    }

    const memberPatchData = {
      in_progress_state: nextStep
    }
    await updateMember.mutateAsync(memberPatchData)

    history.push(Paths.onboarding + onboardingPaths.agreement)
  }

  const handleBack = () => {
    const onboardingPath = memberData?.showVoluntary
      ? onboardingPaths.voluntary
      : onboardingPaths.plan

    history.push(Paths.onboarding + onboardingPath)
  }

  const planName = selectedPlan?.plan_name ?? ''

  const [tableData, setTableData] = useState<TableRow[]>([])

  const isMemberCovered = useCallback(
    (id: any) => {
      const coveredMembers = selectedPlan?.members ?? []
      return coveredMembers.findIndex((m: any) => m.member_id === id) > -1
    },
    [selectedPlan?.members]
  )

  const allTableData = useMemo<TableRow[]>(() => {
    const getPlanCellValue = (member: any) =>
      isMemberCovered(member?.member_id) ? planName : 'Not Covered'

    return [
      {
        id: memberData?.member_id,
        name: memberData?.name,
        plan: getPlanCellValue(memberData),
        ssn: memberData?.ssn,
        dob: memberData?.date_of_birth,
        relationship: memberData?.relationship
      },
      ...(dependents?.map((dep: any) => ({
        id: dep.member_id,
        name: dep.name,
        plan: getPlanCellValue(dep),
        ssn: dep.ssn,
        dob: dep.date_of_birth,
        relationship: dep.relationship
      })) ?? [])
    ]
  }, [dependents, isMemberCovered, memberData, planName])

  const coveredTableData = useMemo(() => {
    return allTableData.filter((row) => isMemberCovered(row.id))
  }, [allTableData, isMemberCovered])

  const nonCoveredTableData = useMemo(() => {
    return allTableData.filter((row) => !isMemberCovered(row.id))
  }, [allTableData, isMemberCovered])

  useEffect(() => {
    switch (activeTabKey) {
      case 'all':
        setTableData(allTableData)
        break
      case 'covered':
        setTableData(coveredTableData)
        break
      case 'non-covered':
        setTableData(nonCoveredTableData)
        break
    }
  }, [activeTabKey, allTableData, coveredTableData, nonCoveredTableData])

  const onTabSelect = (tabKey: typeof activeTabKey) => {
    switch (tabKey) {
      case 'all':
        setTableData(allTableData)
        break
      case 'covered':
        setTableData(coveredTableData)
        break
      case 'non-covered':
        setTableData(nonCoveredTableData)
        break
    }
    setActiveTabKey(tabKey)
  }

  if (memberLoading || depLoading || planLoading) {
    return <Spinner animation="border" />
  }

  return (
    <div>
      <Col>
        <h1>Review</h1>
        <Row>
          <Col>
            <Alert variant="light">
              Eligible for Medicare:{' '}
              {memberData?.medicare_eligible ? 'Yes' : 'No'}
            </Alert>
          </Col>
          <Col>
            {/* hidden for now */}
            {/* <Alert variant="light">
              Additional Insurance:{' '}
              {memberData?.current_carrier?.medical_continue ? 'Yes' : 'No'}
            </Alert> */}
          </Col>
        </Row>
        <h4>Members & Dependents</h4>
        <Nav
          variant="tabs"
          activeKey={activeTabKey}
          onSelect={(key) => onTabSelect(key as typeof activeTabKey)}
        >
          <Nav.Item>
            <Nav.Link eventKey="all">All Members</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="covered">Covered</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="non-covered">Non-Covered</Nav.Link>
          </Nav.Item>
        </Nav>
        <Table className="mt-3">
          <thead>
            <tr className="text-muted">
              <th>MEMBER</th>
              <th>PLAN</th>
              <th>SSN</th>
              <th>DATE OF BIRTH</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length === 0 && (
              <tr className="text-center w-100">
                <td colSpan={4}>
                  <p className="text-muted">
                    There are no {activeTabKey} members
                  </p>
                </td>
              </tr>
            )}
            {tableData.map(({ name, plan, ssn, dob, relationship }) => (
              <tr className="text-muted">
                <td>
                  <Col>
                    <Row className="font-weight-bold text-dark">
                      {getFullName(name)}
                    </Row>
                    <Row className="text-muted text-capitalize">
                      {relationship}
                    </Row>
                  </Col>
                </td>
                <td>{plan}</td>
                <td>{ssnFormatter(ssn)}</td>
                <td>{DateHandler.formatForClient(dob)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Footer>
          <div className="text-right w-100">
            <Button
              ghost
              variant="primary"
              style={{
                borderColor: 'var(--card-border)',
                marginRight: '1rem'
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button onClick={handleContinue}>Continue</Button>
          </div>
        </Footer>
      </Col>
    </div>
  )
}

export default Review
