import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CenteredSpinner,
  Col,
  Form,
  Row
} from 'react-angle-dashboard-components'
import {
  FormState,
  useFieldArray,
  UseFormRegister,
  UseFormReturn
} from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import ReactInputMask from 'react-input-mask'
import { surveyService } from '../../../services'
import { IDependentProviderForm, IProviderForm } from './interfaces'
import { getEmptyProvider } from './shared'
import { CenteredContainer, Title } from './styles'

export const ProviderForm = ({
  control,
  register,
  setValue,
  reset,
  getValues,
  formState,
  watch,
  title
}: UseFormReturn<IDependentProviderForm> & { title: string }) => {
  const [isLoading, setLoading] = useState(true)
  const location = useLocation()
  const addDependents = location?.pathname?.includes('dependent')

  const {
    fields: providerFields,
    append: providerAppend,
    remove
  } = useFieldArray({
    control,
    name: 'providers'
  })

  useEffect(() => {
    surveyService
      .getProviders()
      .then((e) => {
        if (!e.providers || !e.providers?.length) {
          setLoading(false)
          return
        }
        const { dependents } = getValues()

        reset({
          providers: e.providers,
          dependents: addDependents ? dependents : []
        })

        setLoading(false)

        e.providers.forEach((p: IProviderForm, i: number) => {
          Object.keys(p).forEach((k: any) => {
            setValue(`providers.${i}.${k}` as any, (p as any)[k])
          })
        })
      })
      .catch(() => {
        setLoading(false)
      })
  }, [setValue, reset, getValues, addDependents])

  const onAddNewProvider = () => providerAppend(getEmptyProvider())

  const providers = watch('providers')

  return (
    <div className="text-center mt-5">
      <div className="mb-5">
        <div className="mb-3">
          <Title as={'h2'} className="text-primary">
            {title}
          </Title>
          <p>Please list your current providers</p>
        </div>

        <b>
          Your Care Team would like to collect information about your current
          provider(s). This allows us to reach out to them proactively in
          preparation for your visit(s) so that you’re seen without issue:
        </b>
      </div>

      <div className="bg-light py-3 pr-3 rounded">
        {isLoading && <CenteredSpinner />}

        {!isLoading &&
          providerFields.map((field, index) => {
            const row = providers[index]
            delete (row as any)['id']
            const isRequired = Object.keys(row).some((e) => (row as any)[e])

            return (
              <Row className="mx-0" key={field.id}>
                <Col as={CenteredContainer} className="px-0" xs="1">
                  <b>#{index + 1}</b>
                </Col>
                <Col className="px-0">
                  <ProviderRow
                    {...field}
                    index={index}
                    register={register}
                    formState={formState}
                    isRequired={isRequired}
                    onRemove={() => remove(index)}
                  />
                </Col>
              </Row>
            )
          })}

        <div className="text-right pt-3">
          <Button variant="outline-primary" onClick={onAddNewProvider}>
            Add Provider
          </Button>
        </div>
      </div>
    </div>
  )
}

const ProviderRow: React.FC<{
  register: UseFormRegister<any>
  index: number
  onRemove?: Function
  formState: FormState<IDependentProviderForm>
  isRequired: boolean
}> = ({ register, index, onRemove, isRequired, formState }) => {
  return (
    <Card className="text-left mt-2">
      <Card.Body>
        <Row>
          <Col style={{ minWidth: 200 }}>
            <Form.Group>
              <Form.Label> First Name</Form.Label>
              <Form.Control
                {...register(`providers.${index}.firstName`, {
                  required: isRequired
                })}
                className="bg-white"
                placeholder="First Name"
                isInvalid={Boolean(
                  isRequired &&
                    formState.errors.providers &&
                    formState.errors.providers[index]?.firstName
                )}
              />
            </Form.Group>
          </Col>

          <Col style={{ minWidth: 200 }}>
            <Form.Group>
              <Form.Label> Last Name</Form.Label>
              <Form.Control
                {...register(`providers.${index}.lastName`, {
                  required: isRequired
                })}
                className="bg-white"
                placeholder="Last Name"
                isInvalid={Boolean(
                  isRequired &&
                    formState.errors.providers &&
                    formState.errors.providers[index]?.lastName
                )}
              />
            </Form.Group>
          </Col>

          <Col style={{ minWidth: 200 }}>
            <Form.Group>
              <Form.Label> Phone</Form.Label>
              <Form.Control
                {...register(`providers.${index}.phone`, {
                  required: isRequired
                })}
                className="bg-white"
                placeholder="Phone Number"
                as={ReactInputMask}
                mask="999 999-9999"
                type="tel"
                {...{ maskChar: null }}
                isInvalid={Boolean(
                  isRequired &&
                    formState.errors.providers &&
                    formState.errors.providers[index]?.phone
                )}
              />
            </Form.Group>
          </Col>

          <Col style={{ minWidth: 200 }}>
            <Form.Group>
              <Form.Label> Specialty</Form.Label>
              <Form.Control
                {...register(`providers.${index}.specialty`)}
                className="bg-white"
                placeholder="Specialty"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {' '}
          <Col className="pr-0 d-flex align-items-center justify-content-end">
            {onRemove && (
              <Button
                variant="link"
                onClick={() => onRemove()}
                className="text-danger"
              >
                Delete
              </Button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
