import React from 'react'
import { WaiveCoverageSuccessScreen } from './WaiveCoverageSuccessScreen'
import { HomeLayout } from '../layouts/HomeLayout'

// TODO clean up this component

export const WaiveCoverageSuccess = () => {
  return (
    <HomeLayout noMenu>
      <WaiveCoverageSuccessScreen show />
    </HomeLayout>
  )
}

export default WaiveCoverageSuccess
