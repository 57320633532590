import { useQueryParams } from '@angle/hooks'
import React, { useContext } from 'react'
import {
  CenteredSpinner,
  ErrorPageLayout
} from 'react-angle-dashboard-components'
import { useQuery } from 'react-query'
import { AuthContext } from '../../context/Auth/Auth'
import { tempAuthService } from '../../services'
import { HomeLayout } from '../layouts/HomeLayout'

// this temp auth page is currently being used by Homebase users to log in to a member's account as a superuser

export const TempAuth = () => {
  const { refreshUserData, loginWithToken } = useContext(AuthContext)

  const params = useQueryParams()
  const token = params.get('token') || ''

  const { isLoading, isError } = useQuery(
    ['tempAuthNoPw', token],
    () => tempAuthService.tempAuthNoPW(token),
    {
      enabled: !!token,
      onSuccess: (data) => {
        loginWithToken(data)
        refreshUserData()
      }
    }
  )

  if (isLoading) {
    return (
      <div style={{ marginTop: 100 }}>
        <CenteredSpinner />
      </div>
    )
  }
  if (!token || isError) {
    return (
      <HomeLayout noMenu>
        {' '}
        <ErrorPageLayout
          errorTitle="Invalid Link"
          errorSubtitle={
            'This link is invalid. Please try again or reach out to support to obtain a new link.'
          }
        />
      </HomeLayout>
    )
  }

  return <div />
}

export default TempAuth
