import React, { useState } from 'react'
import {
  Col,
  Row,
  Form,
  SectionTitle,
  Button,
  useToaster,
  PlaceAutocomplete,
  CenteredSpinner
} from 'react-angle-dashboard-components'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { onboardingPaths } from '../../Router'
import { memberService } from '../../../../services'
import { Footer } from '../../style'
import ReactInputMask from 'react-input-mask'
import { limitLength, states } from '@angle/utils'
import { mapper, mapToServer } from './mapper'
import { Paths } from '../../../../routes/paths'
import { useRuntimeConfig } from '@angle/hooks'

import { useUpdateMember } from '../../hooks/useUpdateMember'

export const Contact = () => {
  const history = useHistory()
  const { showError } = useToaster()
  const config = useRuntimeConfig()
  const googleApiKey = config?.googleApiKey || '****'

  const [placeholder, setPlaceholder] = useState(true)

  const { handleSubmit, control, formState, setValue, reset } = useForm({
    mode: 'onChange'
  })

  const { data, isLoading } = useQuery(
    'getContact',
    () => memberService.getMemberInfo(mapper),
    {
      onSuccess: (data) => {
        reset({ ...data })
      }
    }
  )

  const updateMember = useUpdateMember()

  const handleBack = () => {
    history.push(Paths.onboarding + onboardingPaths.personal)
  }

  const handleContinue = handleSubmit(async (formData) => {
    try {
      const mappedData = mapToServer(formData, data?.serverStep || 0)
      await updateMember.mutateAsync(mappedData)

      if (data?.inRenewal) {
        history.push(Paths.onboarding + onboardingPaths.dependents)
      } else {
        history.push(Paths.onboarding + onboardingPaths['current-carrier'])
      }
    } catch (e: any) {
      showError(e?.message)
    }
  })

  const getLabelStyles = (key: keyof typeof formState.errors) => {
    return {
      className: classNames({
        'text-danger':
          Boolean(formState.errors) &&
          Boolean(formState.errors[key]) &&
          formState.isSubmitted
      })
    }
  }
  if (isLoading) {
    return <CenteredSpinner />
  }
  return (
    <div>
      <SectionTitle>Contact information</SectionTitle>
      <Form>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label {...getLabelStyles('streetAddress')}>
                Street Address
              </Form.Label>

              <Controller
                name="streetAddress"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <PlaceAutocomplete
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    className={`form-control bg-white ${
                      Boolean(formState.errors?.streetAddress) &&
                      formState.isSubmitted
                        ? 'is-invalid'
                        : ''
                    }`}
                    placeholder="Street Address"
                    defaultValue={field.value}
                    apiKey={googleApiKey}
                    onPlaceSelection={({ address, city, zip, stateShort }) => {
                      setValue('streetAddress', address || '')
                      setValue('state', stateShort || '')
                      setPlaceholder(false)
                      setValue('city', city || '')
                      setValue('zipCode', zip || '')
                    }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('city')}>City</Form.Label>
              <Controller
                name="city"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    className="bg-white"
                    isInvalid={
                      Boolean(formState.errors?.city) && formState.isSubmitted
                    }
                    type="text"
                    placeholder="City"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('zipCode')}>Zip Code</Form.Label>
              <Controller
                name="zipCode"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Form.Control
                    {...field}
                    className="bg-white"
                    onChange={(e) => limitLength(e, 5, onChange)}
                    isInvalid={
                      Boolean(formState.errors?.zipCode) &&
                      formState.isSubmitted
                    }
                    type="text"
                    placeholder="Zip Code"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('personalEmail')}>
                Personal Email Address (Your username will be updated to your
                personal email address)
              </Form.Label>
              <Controller
                name="personalEmail"
                rules={{
                  required: true
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    className="bg-white"
                    isInvalid={
                      Boolean(formState.errors?.personalEmail) &&
                      formState.isSubmitted
                    }
                    type="email"
                    placeholder="Enter your personal email address"
                  />
                )}
              />
              {formState.errors?.personalEmail?.type === 'validEmail' &&
                formState.isSubmitted && (
                  <span className="text-danger">
                    Please input a valid email address
                  </span>
                )}
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>Street Address Line 2</Form.Label>
              <Controller
                name="streetAddressLine2"
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    className="bg-white"
                    type="text"
                    placeholder="Street Address Line 2"
                  />
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('state')}>State</Form.Label>
              <Controller
                name="state"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, ...field } }) => (
                  <Form.Control
                    className={`${placeholder ? 'text-muted' : ''} bg-white`}
                    {...field}
                    isInvalid={
                      Boolean(formState.errors?.state) && formState.isSubmitted
                    }
                    as="select"
                    onChange={(e) => {
                      setPlaceholder(false)
                      onChange(e.target.value)
                    }}
                  >
                    <option value="" disabled hidden>
                      Select state
                    </option>

                    {Object.keys(states).map((s) => (
                      <option key={s} value={s}>
                        {(states as any)[s]}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label {...getLabelStyles('personalPhoneNumber')}>
                Personal Phone Number
              </Form.Label>
              <Controller
                name="personalPhoneNumber"
                rules={{
                  required: true,
                  validate: {
                    noLessThan10: (value) => {
                      if (String(value.match(/[0-9]+/g)?.join('')).length < 10)
                        return false
                      return true
                    }
                  }
                }}
                control={control}
                render={({ field }) => (
                  <Form.Control
                    {...field}
                    isInvalid={
                      Boolean(formState.errors?.personalPhoneNumber) &&
                      formState.isSubmitted
                    }
                    as={ReactInputMask}
                    mask="999-999-9999"
                    className="bg-white"
                    type="tel"
                    placeholder="Personal Phone Number"
                  />
                )}
              />
              {formState.errors?.personalPhoneNumber &&
                formState.errors?.personalPhoneNumber?.type ===
                  'noLessThan10' &&
                formState.isSubmitted && (
                  <span className="text-danger">Phone number is invalid.</span>
                )}
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Footer>
        <div className="text-right w-100">
          <Button
            ghost
            variant="primary"
            style={{
              borderColor: 'var(--card-border)',
              marginRight: '1rem'
            }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button onClick={handleContinue}>Continue</Button>
        </div>
      </Footer>
    </div>
  )
}
