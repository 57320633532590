import React from 'react'
import {
  Button,
  Card,
  SectionTitle,
  CenteredSpinner
} from 'react-angle-dashboard-components'
import { Link } from 'react-router-dom'
import { SMITH_RX_MEMBERPORTAL_URL } from '../../constants/pharmacy-portals'

const RenewalCard = () => {
  return (
    <Card.Body>
      <h3 className="mb-3">Questions?</h3>
      <p>
        If you have any questions, please reach out to Member Services at{' '}
        <a className="font-weight-bold ml-1" href="tel:855-937-1855">
          +1 (855) 937-1855
        </a>
        .
      </p>
    </Card.Body>
  )
}

const WelcomeCard = () => {
  return (
    <Card.Body>
      <h3 className="mb-3">New Member</h3>
      <p>
        Welcome to Angle Health! We're thrilled to have you with us and look
        forward to being your partner in your care journey. Keep on the look out
        for an email giving you access to your mobile app. If you have any
        questions, please reach out to Member Services at{' '}
        <a className="font-weight-bold ml-1" href="tel:855-937-1855">
          +1 (855) 937-1855
        </a>
        .
      </p>
    </Card.Body>
  )
}

export const Updates: React.FC<{
  isRenewal: boolean
  isLoading: boolean
  cardType: string
  formularyIndicator: string | undefined | null
}> = ({ isRenewal, isLoading, cardType, formularyIndicator }) => {
  const isCapRx = formularyIndicator === 'cap_rx'
  const isSmithRx = formularyIndicator === 'smith_rx'

  return (
    <>
      <SectionTitle>Updates</SectionTitle>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <section className="pt-3">
          {isSmithRx && (
            <Card className="mb-3">
              <Card.Body>
                <h3 className="mb-3">Check out your new Pharmacy Benefits!</h3>
                <p>
                  SmithRx is a next generation pharmacy benefit manager,
                  overseeing prescription benefit plans on behalf of Angle
                  Health. They work hard every day to ensure your prescription
                  benefits are cost-effective while never losing sight of our
                  ultimate mission: your health.
                  <br />
                  <br />
                  Please register a new account and log in to your{' '}
                  <a
                    style={{
                      textDecoration: 'underline'
                    }}
                    href={SMITH_RX_MEMBERPORTAL_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    SmithRx Member Portal
                  </a>{' '}
                  to find one of 60,000+ retail pharmacies near you. Once you
                  arrive at the pharmacy, don't forget to show the pharmacist
                  your new ID card to ensure you only pay the out-of-pocket cost
                  associated with your prescription benefit plan.
                  <br />
                  <br />
                  Once you receive your new Angle Member ID card in the mail,
                  feel free to throw the old one away. Your mobile ID card will
                  always be up to date and ready for use in case you haven't
                  received your new card in the mail yet.
                  <br />
                  <br />
                  To learn more about specialty pharmacy, 90-day prescriptions,
                  prior authorizations, step therapy, quantity limits, or if you
                  have any additional questions, please contact SmithRx at{' '}
                  <a href="tel:8444545201">+1(844) 454-5201</a>, visit the{' '}
                  <a href={SMITH_RX_MEMBERPORTAL_URL}>SmithRx Member Portal</a>,
                  or read through the{' '}
                  <a
                    href="https://angle-public-documents.s3.amazonaws.com/smith-rx/SmithRx+Welcome+Packet.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Welcome Packet
                  </a>
                  .
                </p>
              </Card.Body>
            </Card>
          )}
          {isCapRx && (
            <Card className="mb-3">
              <Card.Body>
                <h3 className="mb-3">Check out your new Pharmacy Benefits!</h3>
                <p>
                  Capital Rx is a next generation pharmacy benefit manager,
                  overseeing prescription benefit plans on behalf of Angle
                  Health. They work hard every day to ensure your prescription
                  benefits are cost-effective while never losing sight of our
                  ultimate mission: your health.{' '}
                  <a
                    style={{
                      textDecoration: 'underline'
                    }}
                    href="https://www.youtube.com/watch?v=5daoWPGLSHA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check out their video to learn more!
                  </a>
                  <br />
                  <br />
                  Please register a new account and log in to your{' '}
                  <a
                    style={{
                      textDecoration: 'underline'
                    }}
                    href="https://app.cap-rx.com/login"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Capital Rx Member Portal
                  </a>{' '}
                  to find one of 60,000+ retail pharmacies near you. Once you
                  arrive at the pharmacy, don't forget to show the pharmacist
                  your new ID card to ensure you only pay the out-of-pocket cost
                  associated with your prescription benefit plan.
                  <br />
                  <br />
                  Once you receive your new Angle Member ID card in the mail,
                  feel free to throw the old one away. Your mobile ID card will
                  always be up to date and ready for use in case you haven't
                  received your new card in the mail yet.
                  <br />
                  <br />
                  To learn more about specialty pharmacy, 90-day prescriptions,
                  prior authorizations, step therapy, quantity limits, or if you
                  have any additional questions, please contact Capital Rx at{' '}
                  <a href="tel:18335990971">+1(833) 599-0971</a>, visit your new{' '}
                  <a href="/drugs">Pharmacy</a> homepage, or read through the{' '}
                  <a
                    href="https://angle-public-documents.s3.amazonaws.com/cap-rx/Capital+Rx+Welcome+Flyer.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Welcome Flyer
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://angle-public-documents.s3.amazonaws.com/cap-rx/Capital+Rx+FAQs.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    FAQ
                  </a>
                  .
                </p>
              </Card.Body>
            </Card>
          )}
          <Card>{isRenewal ? <RenewalCard /> : <WelcomeCard />}</Card>
          <Card className="mt-3">
            <Card.Body>
              <h3 className="mb-3">Working with Providers</h3>
              <p>
                Having trouble with eligibility at an In-Network provider?
                Download this form to inform your Provider about Angle Health.
              </p>
              <Button
                onClick={() => {
                  if (cardType === 'angle') {
                    window.open(
                      'https://angle-public-documents.s3.amazonaws.com/providers/provider_letter_first_health_q4_23.pdf',
                      '_blank'
                    )
                  } else if (cardType === 'sns' || cardType === 'centivo') {
                    window.open(
                      'https://angle-public-documents.s3.amazonaws.com/providers/provider_letter_cigna_q4_23.pdf',
                      '_blank'
                    )
                  } else if (cardType === 'mpcn') {
                    window.open(
                      'https://angle-public-documents.s3.amazonaws.com/providers/provider_letter_mpcn_q4_23.pdf',
                      '_blank'
                    )
                  } else {
                    window.open(
                      'https://angle-public-documents.s3.amazonaws.com/providers/Provider+Letter.pdf',
                      '_blank'
                    )
                  }
                }}
              >
                Provider Letter
              </Button>
            </Card.Body>
          </Card>
          <Card className="my-3 d-flex flex-row">
            <img
              src="/homepage.png"
              className="d-none d-sm-inline"
              alt="Angle Telehealth Coming Fall 2021"
              style={{ borderRadius: '5px 0 0 5px' }}
            />
            <Card.Body className="d-flex flex-column justify-content-between">
              <h3>Talk to a Doctor 24/7</h3>
              <p className="text-muted">
                Don't wait to feel better. Get access to care wherever you are,
                anytime.
              </p>
              <Link to="/virtual-care">
                <Button size="md">Get Virtual Care</Button>
              </Link>
            </Card.Body>
          </Card>
        </section>
      )}
    </>
  )
}
