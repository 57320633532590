import React, { ReactNode } from 'react'

import { Button, Modal } from 'react-angle-dashboard-components'
import MoreInfoModalItem from './MoreInfoModalItem'


interface MoreInfoModalProps {
  show: boolean
  requestClose: () => void
  mode?: string
  noBorders?: boolean
}

const MoreInfoModal = ({
  show,
  requestClose,
  mode,
  noBorders
}: MoreInfoModalProps) => {

  let title = ''
  let moreInfoList: {
    icon?: string 
    noCollapse?: boolean
    subtitle: string
    text: string
    titledSections?: ReactNode[]
    underTitle?: string
  }[] = []

  // out of pocket
  if (mode === 'oop') {
    title = 'About Out-of-Pocket Maximum'
    moreInfoList = moreInfoList.concat([
      {
        subtitle: 'What is my out-of-pocket maximum?',
        text: 'Your out-of-pocket maximum is the most you could pay in a year for covered services. Sets a limit on what you pay for covered services before your plan pays 100% for eligible services.'
      },
      {
        subtitle: 'What expenses apply to my out-of-pocket maximum?',
        text: 'Your in-network out-of-pocket expenses include your deductible, copay and coinsurance for covered medical and pharmacy services.'
      }
    ])
  }

  // deductible
  if (mode === 'ded' || mode === 'dedAggFam' || mode === 'dedEmbFam') {
    title = 'About Deductibles'
    moreInfoList = moreInfoList.concat([
      {
        subtitle: 'What is my deductible?',
        text: "Your in-network deductible is the amount you pay to your providers each year for covered services before your plan begins to share in the cost of eligible in-network services. If your deductible is waived for any covered service, it's noted in the covered service details. Eligible in-network preventive care is covered 100% by your plan, and doesn't count toward meeting your deductible."
      },
      {
        subtitle: 'What expenses are applied to my deductible?',
        text: "Expenses that apply to your in-network deductible, include payments for covered medical and pharmacy services before your plan begins to pay. Premiums, copays, and coinsurance don't count toward your deductible."
      },
      {
        subtitle: 'How do I meet my deductible?',
        text: mode === 'dedAggFam' ?
          "On an aggregate family deductible, the health plan doesn’t begin paying for post-deductible expenses of anyone in the family until the entire family deductible has been met."
            : mode === 'dedEmbFam' ? "On an embedded family deductible, each person on the plan has their own deductible, but the family also has a maximum total deductible if multiple members need care during the year. No single individual on a family plan will have to pay a deductible higher than the individual deductible amount."
              : "Your cost for services contribute to your individual deductible. Once met, the post-deductible coverage kicks in."
      }
    ])
  }

  // claims life cycle
  if (mode === 'clc') {
    title = 'Claim Lifecycle'
    moreInfoList = moreInfoList.concat([
      {
        icon: 'sscope',
        noCollapse: true,
        subtitle: 'Provider Visit',
        text: "You have a provider visit, surgery, or other medical procedure"
      },
      {
        icon: 'checkman',
        noCollapse: true,
        underTitle: 'Up to 12 months',
        subtitle: 'Claim Submitted',
        text: "Your provider (doctor or medical facility) submit information to Angle about why and when you received your care and along with the type of care you received. This may be immediate but the provider has up to 180 days to submit."
      },
      {
        icon: 'clock',
        noCollapse: true,
        underTitle: 'Up to 30 days',
        subtitle: 'Claim Processing',
        text: "The claim is reviewed to ensure services are covered based on negotiated rates with your plan. Pended claims require additional information from you or your provider."
      },
      {
        icon: 'checkmark',
        noCollapse: true,
        underTitle: '7-14 days',
        subtitle: 'Claim Processed',
        text: "Once the claim has been processed, the claim is approved or denied. We will send you an Explanation of benefits (EOB) with a breakdown of all the information.",
        titledSections: [
          (
            <div style={{ color: 'rgb(108, 117, 125)', marginTop: '5px' }}>
              <span style={{ fontWeight: 700 }}>Approved:{' '}</span> Angle will send any payments that’s needed from our end to the provider or facility, as well as a breakdown of what was paid. Depending on the type of coverage and claim type, you may owe a patient responsibility. The claim is applied to your deductible and/or out-of-pocket maximum.
            </div>
          ),
          (
            <div style={{ color: 'rgb(108, 117, 125)', marginTop: '5px' }}>
              <span style={{ fontWeight: 700 }}>Partially Denied:{' '}</span> A partially denied claim means that while we are able to provide coverage for some of the services or items in your claim, others do not fall under your plan's coverage. For detailed information on coverage limits and exclusions, please refer to your plan benefits.
            </div>
          ),
          (
            <div style={{ color: 'rgb(108, 117, 125)', marginTop: '5px' }}>
              <span style={{ fontWeight: 700 }}>Denied:{' '}</span>When a claim is denied, the claim is not approved for coverage on Angle’s behalf. This could be occur due to various reasons, such as: not enough details, additional coverages, or services paid under another claim.
            </div>
          ),
        ]
      }
    ])
  }

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={requestClose}
      dialogClassName=""
      className=""
    >
      <Modal.Header closeButton className="fs-20 border-bottom py-4" style={{paddingLeft: '50px'}}>
        {title}
      </Modal.Header>
      <Modal.Body className="gy-2 moreInfoModalBody">
        {moreInfoList.map(({ noCollapse, icon, subtitle, text, titledSections, underTitle }, i) => (
          <MoreInfoModalItem
            icon={icon}
            noBorder={noBorders || i === moreInfoList.length - 1}
            noCollapse={noCollapse}
            subtitle={subtitle}
            text={text}
            titledSections={titledSections}
            underTitle={underTitle}
          />
        ))}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="outline-primary" onClick={requestClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MoreInfoModal
