import React, { MouseEventHandler } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const OtherDependentWrapper = styled.div<{
  onClick: MouseEventHandler<HTMLDivElement>
}>`
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
    background-color: var(--input-bg);
  }
`

export const OtherDependent: React.FC<{
  name: string
  isSpouse?: boolean
  onClick: MouseEventHandler<HTMLDivElement>
}> = ({ name, isSpouse, onClick }) => (
  <OtherDependentWrapper className="border" onClick={onClick}>
    <p className="m-0">{name}</p>
    <p className="text-muted m-0">{isSpouse ? 'Spouse' : 'Child'} </p>
  </OtherDependentWrapper>
)

export const OtherDependents: React.FC<{
  dependents: any[]
  activeTab: string
}> = ({ dependents, activeTab }) => {
  const history = useHistory()

  return (
    <>
      {dependents.map((d: any) => (
        <OtherDependent
          onClick={() =>
            history.push(`/dependents/${d.member_id}/${activeTab}`)
          }
          name={`${d.name.first_name} ${d.name.last_name}`}
          key={d.member_id}
        />
      ))}
    </>
  )
}

export default OtherDependents
