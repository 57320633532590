import React from 'react'
import { ProgressBar } from 'react-angle-dashboard-components'
import { CardTitle } from '../style'

export const ZeroDeductibleCard = () => {
  return (
    <>
      <CardTitle>Current Deductible (In-Network)</CardTitle>
      <div className="fs-28 mt-2">
        <span>$0 Deductible</span>
      </div>
      <ProgressBar now={100} className="mb-1 mt-2" />
      <small className="text-secondary">You have a zero deductible plan</small>
    </>
  )
}
