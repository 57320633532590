import React, { useEffect, useState } from 'react'
import {
  Button,
  OverlayTrigger,
  Tab,
  Tabs,
  Tooltip
} from 'react-angle-dashboard-components'
import ReactToPrint from 'react-to-print'
import { useQuery } from 'react-query'
import { claimsService, memberService } from '../../../services'
import { FamilyDropdown } from '../style'
import {
  CostCard,
  CostCardContent,
  BoldText,
  DisclaimerContainer,
  HelpIcon,
  AbsoluteContainer
} from '../style'
import { getAccumsValues } from '../utils/getAccumsValues'
import { AccumsCard } from './AccumsCard'
import { ZeroDeductibleCard } from './ZeroDeductibleCard'

export const AccumsCol: React.FC<{
  printRef: any
  currentCardMemberId: string
  setCurrentCardMemberId: any
}> = ({ printRef, currentCardMemberId, setCurrentCardMemberId }) => {
  const [tabKey, setTabKey] = useState('Individual')

  const onFamilyTab = tabKey === 'Family'

  const {
    data: memberData,
    isLoading: isMemberLoading,
    isError: isMemberError
  } = useQuery('getMember', () => memberService.getMemberInfo())

  const {
    data: accumsData,
    isLoading: isAccumsLoading,
    isError: isAccumsError
  } = useQuery(['getAccums'], () => claimsService.getAccums())

  const { data: dependentsData, isLoading: isDependentsLoading } = useQuery(
    ['getDependents'],
    () => memberService.getDependents()
  )

  const indData = accumsData?.members?.find(
    (member: any) => member.memberId === currentCardMemberId
  )

  const {
    max: oopMax,
    current: oopCurrent,
    percentage: oopPercentage,
    remaining: oopRemaining
  } = getAccumsValues(onFamilyTab ? accumsData?.familyOOP : indData?.oop)

  const {
    max: deductibleMax,
    current: deductibleCurrent,
    percentage: deductiblePercentage,
    remaining: deductibleRemaining
  } = getAccumsValues(
    onFamilyTab ? accumsData?.familyDeductible : indData?.deductible
  )

  const onChange = (e: any) => {
    const memberId = e.target.value
    setCurrentCardMemberId(memberId || '')
  }

  // show account holder's accums by default
  useEffect(() => {
    if (!currentCardMemberId && memberData?.member_id) {
      setCurrentCardMemberId(memberData?.member_id)
    }
  }, [memberData?.member_id, currentCardMemberId, setCurrentCardMemberId])

  const isLoading = isAccumsLoading || isDependentsLoading || isMemberLoading
  if (isLoading) return null

  const isError = isAccumsError || isMemberError

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {!isError && (
        <AbsoluteContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10
            }}
          >
            <div>
              <FamilyDropdown
                as="select"
                className="bg-light text-secondary"
                onChange={onChange}
                value={currentCardMemberId}
              >
                <option value={memberData?.member_id}>
                  {memberData?.name?.first_name +
                    ' ' +
                    memberData?.name?.last_name}
                </option>
                {dependentsData?.dependents?.map((dep: any) => (
                  <option key={dep.member_id} value={dep.member_id}>{`${
                    dep?.name?.first_name || 'Unknown Member'
                  } ${dep?.name?.last_name || ''}`}</option>
                ))}
              </FamilyDropdown>
            </div>
            <div className="mr-2">
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="p-0 mr-1"
                    variant="link"
                    as={'a'}
                    target="blank"
                    onClick={() => {}}
                  >
                    <i className="icon icon-download d-inline mr-1" />
                    Print results
                  </Button>
                )}
                content={() => printRef.current}
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip data-testid="about-tooltip" id="copay assistance">
                    If you'd like this price estimate mailed to you, please save
                    the PDF and send it to careteam@anglehealth.com with your
                    request.
                  </Tooltip>
                }
              >
                <HelpIcon
                  data-testid="about-tooltip-trigger"
                  className="icon icon-question-mark fs-12"
                />
              </OverlayTrigger>
            </div>
          </div>
          <CostCard>
            <Tabs
              fill
              activeKey={tabKey}
              defaultActiveKey="ind"
              onSelect={(key) => setTabKey(key || 'ind')}
            >
              <Tab eventKey="Individual" title="Individual Costs" />
              {!!dependentsData && !!dependentsData?.dependents?.length && (
                <Tab eventKey="Family" title="Family Costs" />
              )}
            </Tabs>
            {currentCardMemberId && (
              <CostCardContent>
                <AccumsCard
                  title={` Current OOP Max ${tabKey} (In-Network)`}
                  current={oopCurrent}
                  max={oopMax}
                  percentage={oopPercentage}
                  remaining={oopRemaining}
                />

                <hr className="mt-1 mb-3" />
                {deductibleMax === 0 ? (
                  <ZeroDeductibleCard />
                ) : (
                  <AccumsCard
                    title={` Current Deductible ${tabKey} (In-Network)`}
                    current={deductibleCurrent}
                    max={deductibleMax}
                    percentage={deductiblePercentage}
                    remaining={deductibleRemaining}
                  />
                )}
              </CostCardContent>
            )}
          </CostCard>
          <DisclaimerContainer>
            <BoldText>DISCLAIMER</BoldText>
            This calculation is an estimate based on the negotiated rate between
            the provider network and Angle Health or historical claims.
            <br />
            Multiple factors, including service location and provider
            qualifications, may cause discrepancies with the price estimate.
            <br />
            Your care needs may be different than what is covered by the price
            estimate. Please contact your provider so that your price and
            insurance eligibility can be confirmed.
          </DisclaimerContainer>
        </AbsoluteContainer>
      )}
    </div>
  )
}
