import { unformatPhoneNumber } from '@angle/utils'

export const mapper = (data: any) => {
  return {
    city: data.home_address.city,
    streetAddress: data.home_address.street_1,
    streetAddressLine2: data.home_address.street_2,
    state: data.home_address.state,
    zipCode: data.home_address.zip,
    serverStep: data?.in_progress_state,
    personalEmail: data.contact_info.home_email || '',
    personalPhoneNumber: unformatPhoneNumber(
      data.contact_info.home_phone as string
    ),
    inRenewal: data?.in_renewal
  }
}

export const mapToServer = (formData: any, serverStep: number) => {
  return {
    contact_info: {
      home_email: formData.personalEmail,
      home_phone: unformatPhoneNumber(formData.personalPhoneNumber)
    },
    home_address: {
      street_1: formData.streetAddress,
      street_2: formData.streetAddressLine2,
      city: formData.city,
      state: formData.state,
      zip: formData.zipCode
    },
    mailing_address: {
      street_1: formData.streetAddress,
      street_2: formData.streetAddressLine2,
      city: formData.city,
      state: formData.state,
      zip: formData.zipCode
    },
    in_progress_state: serverStep > 3 ? serverStep : 3
  }
}
