/* eslint-disable quotes */
export const messages = Object.freeze({
  notFoundPage: {
    title: '404 - Page not found',
    text: 'The page you were looking for could not be found.',
    button: 'Go to home page'
  },
  healthProfilePage: {
    tabs: {
      overview: 'Health Overview',
      medications: 'Medications',
      authorizations: 'Care Authorizations',
      labs: 'Labs',
      vaccines: 'Vaccines',
      vitals: 'Vitals',
      allergies: 'Allergies',
      wellness: 'Wellness'
    },
    title: 'My Health Profile',
    allergies: {
      title: 'Allergies',
      tableHeaders: {
        name: 'Allergy name',
        update: 'Updated'
      }
    },
    authorizations: {
      title: 'Care Authorizations',
      tableHeaders: {
        authorization: 'Authorization',
        facility: 'Facility',
        requestType: 'Request type',
        status: 'Status',
        decisionLetter: 'Decision Letter'
      }
    },
    overview: {
      upcomingTitle: 'Upcoming',
      upcomingText:
        'Protect yourself and your community. Book a Covid-19 vaccine appointment.',
      wellGuideTitle: 'Health Profile Checklist'
    },
    labs: {
      title: 'Labs',
      tableHeaders: {
        labName: 'Lab name',
        provider: 'Provider',
        date: 'Date'
      }
    },
    medications: {
      title: 'Medications',
      tableHeaders: {
        drug: 'Drug',
        provider: 'Prescriber',
        lastFilledDate: 'Last Filled',
        status: 'Status',
        refillLeft: 'Refill Left'
      }
    },
    vaccines: {
      title: 'Vaccines',
      tableHeaders: {
        vaccine: 'Vaccine',
        date: 'Date'
      }
    },
    vitals: {
      title: 'Vitals',
      tableHeaders: {
        measurements: 'Measurements',
        value: 'Your Value',
        lastUpdated: 'Last Updated'
      }
    }
  },
  breadcrumbs: {
    home: 'Home',
    healthProfile: 'My Health Profile'
  },
  documentsAndForms: {
    title: 'Documents and Forms',
    tabs: {
      documents: 'Documents',
      forms: 'Forms',
      upload: 'Upload'
    }
  },
  networkResources: {
    title: 'Network resources',
    hospitals: 'Hospitals',
    drugs: 'Drugs',
    tableHeaders: { documentName: 'Document Name' }
  },
  myPlan: {
    title: 'My Plan',
    planDetails: 'Plan Details',
    memberIDCardButton: 'Member ID Card',
    claimsButton: 'Claims',
    docsButton: 'Docs/Forms',
    memberSince: 'Member since',
    monthlyPremium: 'Monthly Premium',
    memberID: 'Member ID',
    planBenefitsTitle: 'Plan benefits',
    searchPlaceholder: 'Search your benefits',
    remainingText: 'remaining',
    memberOutOfPocket: 'Member Out-of-Pocket Max',
    familyOutOfPocket: 'Family Out-of-Pocket Max',
    familyMembersTitle: 'Family members',
    tabs: {
      medical: 'Medical'
    }
  },
  login: {
    email: 'Email',
    password: 'Password',
    remberMe: 'Remember me',
    buttons: {
      signIn: 'Sign In',
      signingIn: 'Signing In',
      forgotPassword: 'Forgot Password?'
    }
  },
  drugsProfile: {
    about: { title: 'Drug Info' },
    additionalInfo: {
      title: 'Additional info',
      tabs: {
        use: 'What is it used for',
        whatToTell: 'What to tell your doctor',
        sideEffects: 'Side Effects',
        whereToBuy: 'Where to buy',
        facilityVisitText: 'View facility',
        closeTo: 'Close to:'
      },
      none: 'None'
    },
    cost: {
      title: 'Cost',
      beforeDeductible: 'Before deductible',
      deductibleMet: 'Deductible met',
      outOfPocketMaxMet: 'Out-of-pocket max met'
    },
    warnings: {
      title: 'Warnings',
      subtitle: 'WARNING/CAUTION',
      content: {
        info: `Even though it may be rare, some people may have very bad and
          sometimes deadly side effects when taking a drug. Tell your
          doctor or get medical help right away if you have any of the
          following signs or symptomps that may be realted to a very bad
          side effect:`,
        sideEffects: [
          `Signs of allergic reaction, like rash; hives; itching; red,
          swollen, blistered, or peeling skin with or without fever;
          wheezing; tightness in the chest or throat; trouble breathing,
          swallowing, or talking; unsusual hoarseness; or swelling of
          the mouth, face, lips, tongue, or throat.`,
          'Any unexplained burising or bleeding.',
          'Fever or chills.'
        ]
      }
    },
    header: {
      covered: 'Covered',
      cost: 'Cost',
      beforeDeductible: 'Before deductible',
      deductibleMet: 'Deductible met',
      outOfPocket: 'Out-of-pocket max met'
    }
  },
  drugSearch: {
    resultsFound: 'Search results',
    noResults: 'No results',
    commonSearches: 'Common searches',
    title: 'Drug search',
    placeholder: 'Amoxicillin, Raloxifene hcl, etc.',
    nextPage: 'Next',
    emptySearchFirst: 'No search results for ',
    emptySearchSecond:
      'Please make sure that the drug name you entered is spelled correctly.'
  },
  forgotPassword: {
    title: 'Forgot Password?',
    email: 'Email',
    emailPlaceholder: 'Enter your email address',
    info: `Enter the email address you used when you joined and we'll send
      you instructions to reset your password.`,
    buttons: {
      signIn: 'Sign In',
      back: 'Back to Sign In'
    }
  },
  facilityProfile: {
    gallery: {
      title: 'Gallery'
    },
    facilityLocation: {
      title: 'Facility Location'
    },
    about: {
      title: 'About'
    },
    header: {
      seeAProblem: 'See a problem?',
      suggest: 'Suggest an edit',
      inNetwork: 'In-network',
      outOfNetwork: 'Out-of-network'
    }
  },
  providerProfile: {
    facilityLocation: {
      title: 'Office locations'
    },
    about: {
      title: 'About'
    },
    header: {
      inNetwork: 'In-network',
      outOfNetwork: 'Out-of-network',
      seeAProblem: 'See a problem?',
      suggest: 'Suggest an edit'
    }
  },
  settings: {
    title: 'Settings',
    tabs: {
      accountDetails: 'Account Details',
      security: 'Security',
      healthSurvey: 'Health Survey',
      communication: 'Communication'
    },
    communication: {
      paperless: 'Paperless updates',
      paperlessInfo: 'Receive all care-related documents by email',
      email: 'Email',
      appointmentReminders: 'Appointment reminders',
      blogNewsletters: 'Blog newsletters',
      productNews: 'Product news',
      sms: 'SMS',
      buttons: {
        cancel: 'Cancel',
        save: 'Save changes'
      }
    },
    healthSurveys: {
      title: 'Health Surveys',
      tableHeaders: {
        name: 'Survey Name',
        lastUpdated: 'Last Updated',
        status: 'Status',
        actions: 'Actions'
      },
      takeThisSurvey: ' Take this survey '
    },
    security: {
      title: 'Password change',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      repeat: 'Repeat the new Password',
      success: 'Your password has been succesfully changed!',
      buttons: {
        save: 'Save changes'
      }
    },
    accountDetails: {
      title: 'Contact information',
      infoFirst: 'Please fill out',
      linkForm: ' this form ',
      infoSecond: 'in order to change this information',
      firstName: 'First Name',
      lastName: 'Last Name',
      memberId: 'Member ID',
      dob: 'Date of Birth',
      dobPlaceholder: 'MM/DD/YYYY',
      profileImage: 'Profile image',
      uploadNewPhoto: 'Upload new photo',
      removePhoto: 'Remove photo',
      email: 'Email address',
      phoneNumber: 'Phone number',
      phoneNumberPlaceholder: 'Phone number',
      language: 'Language',
      selectLanguage: 'Select your language',
      languages: ['English', 'Spanish'],
      buttons: {
        save: 'Save changes'
      }
    }
  },
  facilitiesDirectory: {
    itemsName: 'Facilities',
    infoTitle: 'Find affordable facilities using our cost budgets',
    infoText:
      'We estimate that the average visit to Urgent Care Center costs $78 per visit.',
    visitLink: 'facilities',
    visitText: 'Visit Facility',
    emptySearchText: 'Please alter your search parameters to find facilities.',
    emptySearchTitle: 'No results found.'
  },
  providersDirectory: {
    itemsName: 'Providers',
    infoTitle: 'Find affordable providers using our cost budgets',
    infoText:
      'We estimate that the average amount a Primary Care Physician charges $78 per visit.',
    visitLink: 'providers',
    visitText: 'Visit Profile',
    emptySearchText: 'Please alter your search parameters to find providers.',
    emptySearchTitle: 'No results found.'
  },
  care: {
    healthIssueProviders: {
      findProvidersFirst: 'Find providers for ',
      findProvidersSecond: ' near you',
      placeholder: 'Search health issues',
      list: {
        title: 'Schedule a visit'
      }
    },
    healthIssues: {
      title: 'Find care near you',
      placeholder: 'Search health issues',
      list: {
        searchResults: 'Search results',
        commonHealthIssues: 'Common health issues'
      }
    },
    doctors: {
      title: 'Find doctors near you',
      placeholder: 'Search doctors',
      list: {
        searchResults: 'Search results',
        savedDoctors: 'Saved doctors',
        noSearchResults: 'No results'
      }
    },
    specialties: {
      title: 'Find doctors near you',
      placeholder: 'Search doctor specialties',
      list: {
        searchResults: 'Search results',
        noSearchResults: 'No results'
      }
    }
  },
  claims: {
    medical: {
      title: 'Claims',
      subtitle: 'Medical Claims',
      tableHeaders: {
        provider: 'Provider & Facility',
        claimID: 'Claim ID',
        date: 'Date of Service',
        status: 'Status',
        cost: 'Cost'
      },
      noClaims: 'No claims'
    },
    prescription: {
      title: 'Claims',
      subtitle: 'Prescription Claims',
      tableHeaders: {
        provider: 'Provider & Facility',
        claimID: 'Claim ID',
        date: 'Date of Service',
        status: 'Status',
        cost: 'Cost'
      },
      noClaims: 'No claims'
    },
    mentalHealth: {
      title: 'Claims',
      subtitle: 'Mental Health Claims',
      tableHeaders: {
        provider: 'Provider & Facility',
        claimID: 'Claim ID',
        date: 'Date of Service',
        status: 'Status',
        cost: 'Cost'
      },
      noClaims: 'No claims'
    }
  },
  billing: {
    title: 'Billing',
    currentBalanceDue: {
      title: 'Current Balance Due',
      dueBy: 'Due by',
      makePayment: 'Make Payment'
    },
    bankAccountInfo: {
      title: 'Bank Account Information',
      paperless: {
        title: 'Paperless billing',
        on: 'ON',
        off: 'OFF'
      },
      account: 'Account',
      accountSettings: 'Bank account settings'
    },
    paymentHistory: 'Payment History',
    table: {
      payment: 'Payment',
      id: 'Id',
      status: 'Status',
      amount: 'Amount'
    }
  }
})
